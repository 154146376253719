<template>
    <div class="product-details-wrapper">
        <div class="product-details">
            <div class="image-slider" v-if="images.length || (product.video_ids.length && !showVideoCard)">
                <SiteProductDetailsImageSlider
                    :key="`image-slider-with-${images.length}-slides`"
                    :images="images"
                    :video-ids="product.video_ids"
                    @show-video-card="showVideoCard = $event"
                />
            </div>
            <div class="other-details">
                <div class="primary-details">
                    <div class="primary-details-left">
                        <div class="product-breadcrumbs" v-if="product.category">
                            <a :href="product.shop_url" title="Internal link back to our shop page">Shop</a>
                            <span>/</span>
                            <a :href="product.category_permalink" :title="`Internal link to our ${product.category} products page`">{{ product.category }}</a>
                        </div>
                        <h1 class="product-name m-0">{{ product.name }}</h1>
                        <p class="product-brand" v-if="product.manufacturer">Brand: <a :href="product.manufacturer_permalink" :title="`Internal link to our ${product.manufacturer} products page`">{{ product.manufacturer }}</a></p>

                        <!-- Pricing -->
                        <div class="pricing">
                            <p class="product-price" v-if="activeAttribute.store_price !== '0.00'">
                                <template v-if="activeAttributeId">
                                    <span class="displayed-price" :class="{'discounted': discounted}">
                                        ${{ discounted ? activeAttribute.sale_price : activeAttribute.store_price }}
                                        <span v-if="discounted">On Sale</span>
                                    </span>
                                    <template v-if="discounted">
                                        <span class="discounted-price">Reg ${{ activeAttribute.store_price }}</span>
                                        <span class="discounted-price">Save ${{ discountedPrice }} ({{ activeAttribute.discount_percent }}% Off)</span>
                                    </template>
                                </template>
                                <span class="displayed-price" v-else>From {{ displayedPrices }}</span>
                            </p>
                        </div>

                        <!-- Options & Variants -->
                        <div style="margin: 8px -4px;" v-if="optionValues.length">
                            <SiteProductDetailsOptionsSelectors
                                v-for="(option, index) in optionValues"
                                :key="`option-select-${index}`"
                                :name="option.name"
                                :options="option.selectOptions"
                                v-model.number="optionModels[option.id]"
                            />
                        </div>
                    </div>
                    <div class="primary-details-right">
                        <div class="product-details-card">
                            <p class="availability mb-1" :class="availability.status" v-if="availability && settings.showStockStatus">{{ availability.message }}</p>

                            <div class="status-badges" v-if="product.is_insurance_eligible || product.is_insurance_only || product.is_rx_required">
                                <p class="status-badge text-info" v-if="product.is_insurance_eligible">
                                    <span class="fa-stack fa-1x fa-fw">
                                        <span class="fas fa-stack-2x fa-shield-alt"></span>
                                        <span class="fas fa-stack-2x fa-circle" data-fa-transform="shrink-8 right-5 down-5"></span>
                                        <span class="fas fa-stack-2x fa-check text-white" data-fa-transform="shrink-12 right-5 down-5"></span>
                                    </span>
                                    <span class="badge-text">Insurance Eligible</span>
                                </p>
                                <p class="status-badge text-warning" v-if="product.is_insurance_only">
                                    <span class="fa-stack fa-1x fa-fw">
                                        <span class="fas fa-stack-2x fa-shield-alt"></span>
                                        <span class="fas fa-stack-2x fa-circle" data-fa-transform="shrink-8 right-5 down-5"></span>
                                        <span class="fas fa-stack-2x fa-lock text-white" data-fa-transform="shrink-12 right-5 down-5"></span>
                                    </span>
                                    <span class="badge-text">Insurance Only</span>
                                </p>
                                <p class="status-badge text-danger" v-if="product.is_rx_required">
                                    <span class="fa-stack fa-1x fa-fw">
                                        <span class="fas fa-stack-2x fa-prescription"></span>
                                    </span>
                                    <span class="badge-text">RX Required</span>
                                </p>
                            </div>

                            <a
                                :href="orderUrl"
                                class="btn btn-primary btn-block"
                                :title="settings.callToAction.buttonTitle"
                                v-if="settings.callToAction.buttonText && orderUrl"
                                :target="settings.callToAction.defaultCta ? '_self' : '_blank'"
                            >{{ settings.callToAction.buttonText }}</a>
                        </div>
                    </div>
                </div>

                <div class="product-details-cards">
                    <div class="product-details-card">
                        <p class="h4 product-details-card-heading">About This Item</p>
                        <div class="about-row">
                            <div class="about-col" v-if="product.features.length">
                                <h6 class="text-font">Features</h6>
                                <ul class="mb-0 pl-2">
                                    <li
                                        class="text-14"
                                        v-for="(feature, index) in product.features"
                                        :key="`feature_${index}`"
                                    >{{ feature }}</li>
                                </ul>
                            </div>
                            <div class="about-col">
                                <h6 class="text-font">Description</h6>
                                <div class="description" v-html="product.description"></div>
                            </div>
                        </div>
                    </div>

                    <div class="product-details-card" v-if="showSpecs">
                        <p class="h4 product-details-card-heading">Specifications</p>
                        <div class="product-specs">
                            <template v-for="(specGroup, specGroupIndex) in product.specs">
                                <div class="product-spec" v-if="specGroup.title || specGroup.group.some(p => p.name || p.value)" :key="`spec-group-${specGroupIndex}`">
                                    <p class="title" v-if="specGroup.title">{{ specGroup.title }}</p>
                                    <div class="spec-values">
                                        <template v-for="(specValues, specValuesIndex) in specGroup.group">
                                            <p class="key" v-if="specValues.name || specValues.value" :key="`spec-name-${specValuesIndex}`">{{ specValues.name }}</p>
                                            <p class="value" v-if="specValues.name || specValues.value" :key="`spec-value-${specValuesIndex}`">{{ specValues.value }}</p>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <div class="product-spec" v-if="activeAttribute.hcpcs_code || activeAttribute.sku || activeAttribute.unit_of_measure">
                                <p class="title">Other Specifications</p>
                                <div class="spec-values">
                                    <p class="key" v-if="activeAttribute.hcpcs_code">HCPCS Code</p>
                                    <p class="value" v-if="activeAttribute.hcpcs_code">{{ activeAttribute.hcpcs_code || '-' }}</p>
                                    <p class="key">SKU</p>
                                    <p class="value">{{ activeAttribute.sku || '-' }}</p>
                                    <p class="key">UOM (Unit of Measure)</p>
                                    <p class="value">{{ activeAttribute.unit_of_measure || '-' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="product-details-card" v-if="product.documents.length">
                        <p class="h4 product-details-card-heading">Documents</p>
                        <div class="documents">
                            <a
                                class="document"
                                v-for="(file, index) in product.documents"
                                :key="`file-${index}`"
                                :href="file.path"
                                target="_blank"
                            >
                                <span class="document-icon fas fa-file"></span>
                                <span class="path-text">{{ file.name }}</span>
                            </a>
                        </div>
                    </div>

                    <div class="product-details-card" v-if="showVideoCard">
                        <p class="h4 product-details-card-heading">Videos</p>
                        <div class="videos wrap-gap">
                            <a
                                class="video"
                                v-for="id in product.video_ids"
                                :key="id"
                                :href="`https://youtube.com/watch?v=${id}`"
                                target="_blank"
                                :title="`Opens product youtube video (${id}) in new tab`"
                            >
                                <span class="video-icon fas fa-play"></span>
                                <img :src="`https://img.youtube.com/vi/${id}/0.jpg`" :alt="`Thumbnail for product youtube video (${id})`">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SiteProductDetailsPage',
        props: [
            'product',
            'settings'
        ],
        data() {
            return {
                optionModels: {},
                showVideoCard: false
            }
        },
        computed: {
            showSpecs() {
                let aa = this.activeAttribute;
                let aaHasProps = (aa && (aa.hcpcs_code || aa.sku || aa.unit_of_measure));
                let prodHasSpecs = (this.product?.specs.length);
                return aaHasProps || prodHasSpecs;
            },
            validCombinations() {
                return Object.values(this.product.attributes).map(att => att.option_values);
            },
            shownOptions() {
                // Change structure from [{1:1,2:7,3:4},{1:1,2:7,3:5},...] to {1:[1,2,3],2:[7,8,9],3:[4,5,6]}
                let optionValues = this.validCombinations.reduce((a,c) => {
                    Object.entries(c).forEach(([key, value]) => {
                        // is key in object ? check if value in array, add if not : add key with first value
                        key in a ? (!a[key].includes(value) && a[key].push(value)) : a[key] = [value];
                    })
                    return a;
                }, {});

                // Filter models so there aren't any with empty values e.g. 1: ''
                let filteredModelEntries = Object.entries(this.optionModels).filter(entry => entry[1]); // [['1',[1,2,3]],['2',[7,8,9]],['3',[4,5,6]]]

                return Object.fromEntries(Object.entries(optionValues).map(([option, values]) => {
                    let otherModelEntries = filteredModelEntries.filter(entry => entry[0] !== option); // Get models that aren't the currently looped one eg ${option} == 1, return 2 & 3
                    return [option, values.filter(value => { // remove values from different options if they don't fall in a valid combo eg 1: [1,2,3] => 1: [2,3]
                        return !!this.validCombinations.find(combo => {
                            let modelsMatch = otherModelEntries.every(([modelOption, modelValue]) => combo[modelOption] == modelValue); // if no models selected, every returns true
                            return modelsMatch && combo[option] == value; // return if models match in combo and current option check works
                        });
                    })]
                }))
            },
            optionValues() {
                let result = [];

                Object.entries(this.product.options).forEach(([optionKey,optionValue]) => { // Loop through all options
                    let values = this.validCombinations.reduce((a,c) => {
                        if (c[optionKey] && !a.includes(c[optionKey])) a.push(c[optionKey]);
                        return a;
                    }, []);

                    let selectOptions = values.map(selectOptionValue => {
                        return {
                            name: this.product.values[selectOptionValue],
                            value: selectOptionValue,
                            hidden: !this.shownOptions[optionKey].includes(selectOptionValue)
                        }
                    })

                    result.push({
                        id: optionKey,
                        name: optionValue,
                        values: values,
                        selectOptions: [
                            // {name:`Select a ${optionValue}`,value:"",selected:true},
                            ...selectOptions
                        ]
                    })
                })

                return result;
            },
            activeAttributeId() {
                let check = this.optionModels;
                let modelEntries = Object.entries(check);

                let validWithCurrentlySelected = this.validCombinations.filter(combo => { // loop through valid combinations
                    if (!modelEntries.length) return true;
                    return modelEntries.every(([k,v]) => v ? combo[k] == v : true);
                })

                if (validWithCurrentlySelected.length === 1) check = validWithCurrentlySelected[0];
                let match = Object.entries(this.product.attributes).map(([k,v]) => { return {id:k,...v} }).find(att => this.checkObjectEquality(att.option_values, check));
                return match?.id || null;
            },
            activeAttribute() {
                return this.activeAttributeId ? this.product.attributes[this.activeAttributeId] : this.product;
            },
            images() {
                let prod     = this.product,
                    variants = prod.has_variants,
                    active   = prod.attributes[this.activeAttributeId],
                    general  = prod.images,
                    first    = Object.entries(prod.attributes).map(([k,v]) => { return {id:k,...v} }).find(att => att.images.length)?.id,
                    result   = [];

                if (variants && active && !active.images?.length && !general.length);
                else if (variants && active?.images?.length) result.unshift(...active.images, ...general);
                else if (general.length) result.unshift(...general);
                else if (variants && first) result.unshift(...prod.attributes[first].images)

                return result;
            },
            displayedPrices() {
                let prices = Object.values(this.product.attributes).map(att => att.store_price),
                    min = Math.min(...prices),
                    max = Math.max(...prices),
                    minf = min.toFixed(2),
                    maxf = max.toFixed(2);

                if (min === max) return `$${minf}`

                return `$${minf} - $${maxf}`;

            },
            discounted() {
                return this.activeAttribute.sale_price && this.activeAttribute.discount_percent;
            },
            discountedPrice() {
                return (this.activeAttribute.store_price - this.activeAttribute.sale_price).toFixed(2);
            },
            availability() {
                let attributeSelected = !!this.activeAttributeId,
                    inStock           = attributeSelected ? this.activeAttribute.is_in_stock : 0,
                    qty               = attributeSelected ? this.activeAttribute.qty : 0,
                    response          = { message: "", status: "" };

                // I'm stealing these responses from Amazon
                if (!attributeSelected) return false;
                if (!inStock || (inStock && !qty)) [response.message, response.status] = ['Currently unavailable', 'unavailable'];
                if (inStock && qty) {
                    if (qty > 5) [response.message, response.status] = ['In Stock', 'available'];
                    else [response.message, response.status] = [`Only ${qty} left in stock`, 'low'];
                }

                return response
            },
            orderUrl() {
                // We don't really have the ability to modify WHERE the product request form is, not sure how we're going to point this to the right place
                // `${/wherever/the/form/is}?brand=${encodeURIComponent(this.product.brand)&name=${encodeURIComponent(this.product.name)}`
                // Also not sure how we are supposed to convey the variant that has been selected in the product request form, that wasn't thought of until now
                let buttonUrl = 'url' in this.settings.callToAction && this.settings.callToAction?.defaultCta ? `${this.settings.callToAction.url}?brand=${encodeURIComponent(this.product.manufacturer)}&name=${encodeURIComponent(this.product.name)}` : this.settings.callToAction.url;

                return buttonUrl ? buttonUrl : 'Not Setup yet';
            }
        },
        methods: {
            checkObjectEquality(obj1, obj2) {
                return Object.entries(obj1).every(([k,v])=>obj2[k]==v) && Object.entries(obj2).every(([k,v])=>obj1[k]==v);
            },
            formatPriceHtml(price_prop) {
                let html = '';
                if (!this.activeAttributeId) html += `<span style="margin-right:.25em;">From</span>`;
                html += `$${this.activeAttribute[price_prop]}`;
                return html;
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';

    .product-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -1rem;

        .primary-details {
            display: flex;
            flex-wrap: wrap;
            margin: -1rem;

            > * {
                flex: 1 1 50%;
                min-width: min(250px, 100%);
                padding: 1rem;
            }

            .availability {
                font-size: 18px;
                font-weight: bold;

                &.unavailable { color: var(--gray); }
                &.available { color: var(--success); }
                &.low { color: var(--danger); }
            }
        }

        .product-breadcrumbs, .product-brand {
            font-size: 14px;
        }

        .product-name {
            font-size: 24px;
        }

        .image-slider {
            flex: 1 1 38.197%;
            padding: 1rem;
            min-width: min(300px, 100%);
            max-width: 700px;
            position: relative;

            .product-image-slider-wrapper {
                position: sticky;
                top: 1rem;
            }
        }

        .other-details {
            flex: 1 1 61.803%;
            padding: 1rem;
            max-width: 100%;
        }

        .product-price {
            .displayed-price {
                font-size: 24px;
                font-weight: bold;

                &.discounted {
                    color: var(--optimumOnWhite-hsl);

                    span {
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
            }

            .discounted-price {
                font-size: 14px;
                padding: 0 4px;
            }
        }

        .product-details-cards {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1rem;

            .product-details-card {
                flex: 1 1 420px;
                min-width: calc(50% - 2rem);
                margin: 1rem;
            }
        }

        .product-details-card {
            margin: 1rem 0;
            padding: 1rem;
            border-radius: 5px;
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);

            .product-details-card-heading {
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                text-align: center;
                border-bottom: 1px solid hsl(0deg, 0%, 90%);
            }
        }

        .description {
            font-size: 14px;

            &::v-deep * {
                font-size: 14px;
            }
        }

        .status-badges {
            &:not(:last-child) { margin-bottom: 1rem; }

            .status-badge {
                display: flex;
                align-items: center;
                margin: 0 -.5rem;


                > * { padding: .5rem; }

                .badge-text { font-weight: bold; }
            }
        }

        .about-row {
            margin: -.5rem;
            display: flex;
            flex-wrap: wrap;

            .about-col {
                margin: .5rem;
                flex: 1 1 270px;
            }
        }

        .product-specs {
            margin: -.5rem;
            display: flex;
            flex-wrap: wrap;

            p {
                margin: 0;
                font-size: 14px;
                line-height: 1;
                padding: 8px;
            }

            .product-spec {
                margin: .5rem;
                border-radius: 5px;
                overflow: hidden;
                flex: 1 1 max-content;
            }

            .title {
                font-size: 16px;
                font-weight: bold;
                background-color: hsl(0deg, 0%, 90%);
                border-radius: 5px 5px 0 0;

                + .spec-values {
                    border-radius: 0 0 5px 5px;
                }
            }

            .spec-values {
                @extend %minimal-scrollbar;
                display: grid;
                grid-template-columns: max-content 1fr;
                grid-auto-rows: max-content;
                grid-auto-flow: row dense;
                font-size: 14px;
                border-radius: 5px;
                border: solid hsl(0deg, 0%, 90%);
                border-width: 0 1px 1px;
                overflow-x: auto;

                .key, .value {
                    border-top: 1px solid hsl(0deg, 0%, 90%);
                }

                .key {
                    font-weight: bold;
                    border-right: 1px solid hsl(0deg, 0%, 90%);
                }
            }
        }

        .documents {
            .document {
                display: flex;
                align-items: center;
                font-size: 32px;
                margin: -.5rem;

                > * {
                    margin: .5rem;
                }

                .document-icon {
                    color: hsl(0deg, 0%, 90%);
                }

                .path-text {
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .videos {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: center;
            margin: calc(var(--negative-sidebar-gap) / 2) var(--negative-sidebar-gap);
            margin-right: 0;

            .video {
                flex: 0 0 calc(25% - (var(--g) / 2));
                min-width: 100px;
                outline: 0;
                border: 0;
                padding: calc(var(--sidebar-gap) / 2) var(--sidebar-gap);
                background-color: transparent;
                transition: 200ms ease-out;
                position: relative;

                &.active, &:hover {
                    opacity: 1;

                    .video-icon { color: var(--themeColor-hsl); }
                }

                img {
                    width: 100%;
                    height: auto;
                }

                .video-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    transition: 200ms ease-out;
                }
            }
        }
    }
</style>