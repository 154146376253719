import { logApplicationError } from "../global/functions";

/**
 * Show notification
 *
 * @param {string} message The body of the notification
 * @param {string} [type='info'] The type of the notification. Available options are info | success | warning | danger
 * @param {string} [title=null] The title of the notification
 */
export function showNotification(message, type = 'info', title = null ) {
    let iconClass;
    if (type == 'error') {
        type = 'danger';
    }

    switch (type) {
        case "info":
            iconClass = 'fa-info-circle';
            break;
        case "success":
            iconClass = 'fa-check-circle';
            break;
        case "warning":
            iconClass = 'fa-exclamation-triangle';
            break;
        case "danger":
            iconClass = 'fa-hand-paper';
            break;
        default:
            iconClass = 'fa-info-circle';
            break;
    }

    let notification = {
        options : {
            icon : `fas ${iconClass}`,
            title : title ? title : null,
            message : message
        },
        settings: {
            type: type.toLowerCase(),
            timer: 4000,
            placement: {
                from: 'top',
                align: 'right'
            },
            template:   '<div data-notify="container" class="d-flex align-items-center p-2 alert alert-{0}" role="alert">' +
                            '<span class="mr-1" data-notify="icon"></span>' +
                            '<span data-notify="title">{1}</span>' +
                            '<span data-notify="message">{2}</span>' +
                            '<div class="progress" data-notify="progressbar">' +
                                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                            '</div>' +
                            '<a href="{3}" target="{4}" data-notify="url"></a>' +
                            '<button type="button" aria-hidden="true" class="close ml-2" data-notify="dismiss">×</button>' +
                        '</div>'
        }
    }

    try {
        $.notify(notification.options, notification.settings);
    } catch (e) {
        console.log('Notify library is missing, please make sure you have the notifications library added.');
    }
}

export function openApplicationErrorModal(error, message = "Don't worry, your data was saved.") {
    const applicationErrorModal = document.querySelector('#js-application-error-modal');

    const errorMessage          = applicationErrorModal.querySelector('#message');
    errorMessage.innerText = message;

    let modals  = document.querySelectorAll('.modal');

    //Hide all other modals
    modals.forEach(modal => {
        $(modal).modal('hide');
    });

    $(applicationErrorModal).data('bs.modal',null);

    //Open error modal
    $(applicationErrorModal).modal({
        backdrop: 'static',
        keyboard: false
    });

    const refreshBtn = applicationErrorModal.querySelector('.refresh-btn');

    if (refreshBtn) {
        refreshBtn.addEventListener('click', evt => {
            evt.target.innerText = 'Refreshing...'
            location.reload();
        });
    }


    logApplicationError(error);
}


/**
 * Function for opening file manager
 *
 * @export
 * @param {Element} element The Node element that should trigger the file manager window
 * @param {function} callback Custom function to apply to window.SetUrl that should handle what happens after a doc/image has been selected.
 */
export function openFileManager(element, callback) {

    if (!element.dataset.input) {
        throw new Error('Target element must have data-input')
    }

    localStorage.setItem('target_input', element.dataset.input);

    if (element.dataset.preview) {
        localStorage.setItem('target_preview', element.dataset.preview);
    }

    window.open('/vpanel/file-manager/app', 'FileManager', 'width=900,height=600');

    let actionFunction;
    if (callback) {
        actionFunction = callback;
    } else {
        actionFunction = function (fmObject) {
            if (element.dataset.preview) {
                //set or change the preview image src
                let target_preview = document.querySelector('#' + localStorage.getItem('target_preview'));
                target_preview.setAttribute('src', fmObject[0].url);
            }

            //set the value of the desired input to document/image url
            let target_input   = document.querySelector('#' + localStorage.getItem('target_input'));
            target_input.value = fmObject[0].url;
        }
    }

    window.SetUrl = actionFunction;
}