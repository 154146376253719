<template>
    <div class="recycle-bin-nav">
        <router-link :to="{ name: 'Recycled Pages'}" class="nav-link" active-class="current" title="Internal link that opens recycled pages" exact>Pages</router-link>
        <router-link :to="{ name: 'Recycled Assets'}" class="nav-link" active-class="current" title="Internal link that opens recycled assets" exact>Assets</router-link>
    </div>
</template>
<script>
    export default {
        name: 'RecycleBinSecondaryNav'
    }
</script>