<template>
    <BasicInputShell
        v-bind="[$attrs, questionBinder]"
        type="date"
        default-required-message="Please select a date"
        :custom-validation-func="validateFunc"
        v-on="questionEventBinder"
    />
</template>

<script>
import { questionChute, questionLadder } from './mixins/SiteFormBuilderControlMixins';

export default {
    inheritAttrs: false,
    mixins: [ questionChute, questionLadder ],
    methods: {
        validateFunc(vm) {
            let val = vm.$refs.control.value.trim(),
                regex = /\d{4}-\d{2}-\d{2}/;
            if (!vm.required) {
                if (!val.length) return -1;
                return regex.test(val);
            }
            return val.length && regex.test(val);
        },
    }
}
</script>