<template>
    <button type="button" :class="`btn btn-${buttonColor} m-0`" @click.prevent="showForm">
        <span v-if="buttonIcon" :class="`${buttonIcon}`"></span>
        <template v-if="buttonText">{{ buttonText }}</template>
    </button>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'VpanelBlogAddNewRecordButton',
        props:{
            namespace: String,
            buttonText: {
                type: String,
                default: null
            },
            buttonColor: {
                type: String,
                default: 'primary'
            },
            routerPath : {
                type: String,
                default: null
            },
            buttonIcon: {
                type: String,
                default: null
            }
        },
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.methods = {
                ...mapActions(storeNamespace, [
                    'resetForm',
                    'openModal'
                ]),
                showForm(){
                    if(this.routerPath){
                        this.$router.push({ name: this.routerPath });
                    } else {
                        this.resetForm();
                        this.openModal();
                    }
                }
            }
        }
    }
</script>