<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div :class="{'float-wrapper': floatLabel, 'float-label': labelFloating}">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <input
                class="form-control"
                :value="value || undefined"
                v-bind="modifiedBinder"
                v-on="inputListener"
                @focus="focus"
                @blur="unfocus"
            >
            <div class="password-preview" @click="togglePasswordPreview">
                <div v-show="!passwordPreview">
                    <span class="fas fa-eye"></span>
                </div>
                <div v-show="passwordPreview">
                    <span class="fas fa-eye-slash"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    data() {
        return {
            passwordPreview: false
        }
    },
    computed: {
        modifiedBinder() {
            return {
                ...this.inputBinder,
                type: this.passwordPreview ? 'text' : 'password'
            }
        }
    },
    methods: {
        togglePasswordPreview() {
            this.passwordPreview = !this.passwordPreview;
        }
    }
}
</script>

<style lang="scss" scoped>
    .float-wrapper { position: relative; }

    .form-control { padding-right: 2.75rem; }

    .password-preview {
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);

        .svg-inline--fa { color: #767676 }
    }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .float-wrapper & {
            position: absolute;
            left: .5rem;
            top: .875rem;
            transform: translateY(0);
            background-color: transparent;
            padding: 0 .25rem;
            transition: 200ms ease-out;
            pointer-events: none;
            line-height: 1;
            border-radius: 2px;
            opacity: .5;
        }

        .float-wrapper.float-label & {
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
        }
    }
</style>