<template>
    <ChipSelectorShell
        v-bind="[$attrs, questionBinder]"
        type="checkbox"
        default-required-message="Please select at least one option"
        v-on="questionEventBinder"
    />
</template>

<script>
import { questionChute, questionLadder } from './mixins/SiteFormBuilderControlMixins';

export default {
    inheritAttrs: false,
    mixins: [ questionChute, questionLadder ]
}
</script>