<template>
    <div class="st-dropdown" :class="{'active':isActive}">
        <slot></slot>
    </div>
</template>

<script>
/**
 * Should be looped in addition to the rows and look like this
 * <ExampleDropdown
 *     :key="row.id+'dropdown'" // This can be whatever as it will no longer be needed once upgraded to vue3
 *     v-bind="dropdownBinder(row)"
 * />
 */

export default {
    props: {
        row: {
            type: Object,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>
    .st-dropdown {
        position: relative;
        overflow: hidden;
        transition: 200ms ease-out;
        display: block !important;
        grid-column: 1 / -1;

        &:not(.active) {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            opacity: 0;
        }

        &.active {
            height: auto;
            padding: 1rem;
        }
    }
</style>