<template>
    <div class="locations" :data-view="activeView">
        <template v-if="Object.keys(locations).length">
            <frontend-location-top-bar
                :types="types"
                :services="services"
                v-show="!detailsIndex"
                :active-view="activeView"
                :filters-visible="filtersVisible"
                :is-map-view-enabled="isMapViewEnabled"
                :is-services-enabled="isServicesEnabled"
                :is-list-view-enabled="isListViewEnabled"
                :is-grid-view-enabled="isGridViewEnabled"
                :postal-filter="postalFilter"
                :state-filter="stateFilter"
                :type-filter="typeFilter"
                :service-filter="serviceFilter"
            />

            <frontend-location-map-view
                v-if="isActiveView('map') && isMapViewEnabled"
                :services="services"
                :sidebar-details="sidebarDetails"
                :is-services-enabled="isServicesEnabled"
            />

            <frontend-location-list-view
                v-show="isActiveView('list') && isListViewEnabled"
                :services="services"
                :is-services-enabled="isServicesEnabled"
            />

            <frontend-location-grid-view
                v-show="isActiveView('grid') && isGridViewEnabled"
            />

            <frontend-location-details-view
                v-if="detailsIndex"
                :location="locations[detailsIndex]"
                :id="detailsIndex"
                :services="services"
                :is-services-enabled="isServicesEnabled"
            />

            <div
                class="overlay"
                :class="{active: filtersVisible}"
                @click="setFiltersVisible(false)"
            ></div>
        </template>
        <template v-else>
            <div class="alert alert-info">
                <p class="m-0">No location information is available at this time.</p>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name: 'FrontendLocationIndex',
    props: [
        'types',
        'services',
        'locations',
        'moduleSettings'
    ],
    created(){
        this.setLocations(this.locations).then(() => {
            let url = new URL(window.location),
                id = url.search && url.searchParams.has('id') ? url.searchParams.get('id') : false,
                loc = url.search && url.searchParams.has('loc') ? url.searchParams.get('loc') : false;

            if (loc && typeof loc == 'string') {
                let locId = Object.values(this.locations).find(l=>l.title.toLowerCase().replace(/\s/g,"-") == loc)?.id;
                if (locId) this.setDetails(Number(locId));
            } else if (id) {
                this.setDetails(Number(id));
            }
        });
        this.setServices(this.services);
        this.setTypes(this.types);
        this.setModuleSettings(this.moduleSettings);
        this.fetchPreferred();
    },
    computed: {
        ...mapState('location', [
            'activeView',
            'detailsIndex',
            'filtersVisible',
            'sidebarDetails',
            'preferredIndex',
            'postalFilter',
            'stateFilter',
            'typeFilter',
            'serviceFilter',
        ]),
        ...mapState('location/settings', [
            'isMapViewEnabled',
            'isListViewEnabled',
            'isGridViewEnabled',
            'isServicesEnabled',
        ]),
        ...mapGetters('location', [
            'isActiveView'
        ])
    },
    methods: {
        ...mapActions('location', [
            'setDetails',
            'setLocations',
            'setServices',
            'setTypes',
            'setFiltersVisible',
            'fetchPreferred'
        ]),
        ...mapActions('location/settings', [
            'setModuleSettings'
        ]),
        repositionScroll() {
            // Scroll to the top of the component accounting for the header's height
            let yPos = this.$el.getBoundingClientRect().top + window.pageYOffset - parseInt(document.body.style.getPropertyValue('--hd-height'), 10);
            window.scrollTo({top:yPos})
        }
    },
    watch: {
        detailsIndex() {
            this.repositionScroll();
        },
        activeView() {
            this.repositionScroll();
        },
        preferredIndex(n) {
            if (n) this.repositionScroll();
        }
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    $dark: hsl(0deg,0%,15%);
    $light: hsl(0deg,0%,90%);
    $lighter: hsl(0deg,0%,95%);
    $transition: 0.6s cubic-bezier(0.16, 1, 0.3, 1);

    // eventually move this to the base, maybe clean it up a bit
    @mixin border-widths($t, $r: null, $b: null, $l: null) {
        // conditionally handle shorthand
        @if not $r {$r:$t;$b:$t;$l:$t;}
        @else if not $b {$b:$t;$l:$r;}
        @else if not $l {$l:$r;}

        border-style: solid;
        border-color: var(--light);
        border-width: $t $r $b $l;
    }

    .locations {
        position: relative;
        overflow: hidden;
        --dark: #{$dark};
        --light: #{$light};
        --lighter: #{$lighter};
        --transition: #{$transition};

        .overlay {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: var(--dark);
            opacity: 0;
            pointer-events: none;
            transition: 200ms ease-out;

            &.active {
                opacity: .5;
                pointer-events: all;
            }
        }
    }
</style>