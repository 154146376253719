<template>
    <BasicInputShell
        v-bind="[$attrs, questionBinder]"
        type="email"
        icon="faAt"
        format-text="Format: john.doe@email.com"
        default-required-message="Please enter a valid email"
        :custom-validation-func="validateFunc"
        v-on="questionEventBinder"
    />
</template>

<script>
import { questionChute, questionLadder } from './mixins/SiteFormBuilderControlMixins';

export default {
    inheritAttrs: false,
    mixins: [ questionChute, questionLadder ],
    methods: {
        validateFunc(vm) {
            let val = vm.$refs.control.value.trim(),
                regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            if (!vm.required) {
                if (!val.length) return -1;
                return regex.test(val);
            }
            return val.length && regex.test(val);
        },
    }
}
</script>