<template>
    <table id="datatable" class="table table-striped table-bordered dataTable dtr-inline" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="cspNonce">
        <thead>
            <tr role="row">
                <th class="table-head" tabindex="0" aria-controls="datatable">Name</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Last Modified</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Status</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Options</th>
            </tr>
        </thead>
    </table>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'VpanelBlogCategoryList',
        created() {
            this.addClickEventsToCategoryListButtons();
        },
        computed: {
            ...mapState('category/list', {
                cspNonce: state => state.cspNonce,
            })
        },
        methods: {
            ...mapActions('category/list', [
                'addClickEventsToCategoryListButtons'
            ])
        }
    }
</script>