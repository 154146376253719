<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div class="input-wrapper form-control">
            <input
                type="checkbox"
                v-bind="inputBinder"
                v-on="inputListener"
                :checked="localValue"
                :value="checkedValue"
            >
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: ["checkedValue"],
    computed: {
        localValue() {
            return this.value;
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-wrapper {
        display: flex;
        align-items: flex-start;
        position: relative;
    }

    .form-control {
        display: flex;
        align-items: center;
        min-height: calc(1.5em + .75rem + 2px);
        height: auto;
    }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        user-select: none;

        .input-wrapper & {
            opacity: 1;
            background-color: white;
            padding-left: .5rem;
            line-height: 1;
        }
    }
</style>