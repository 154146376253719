<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div :class="{'float-wrapper': floatLabel, 'float-label': labelFloating}">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <input
                class="form-control"
                :type="type"
                :value="value || undefined"
                v-bind="inputBinder"
                v-on="inputListener"
                @focus="focus"
                @blur="unfocus"
            >
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        type: {
            type: String,
            default: "text",
            validator: (value) => {
                if ([
                    'email',
                    'hidden',
                    'tel',
                    'text',
                    'url',
                ].indexOf(value) !== -1) {
                    return true;
                } else {
                    // If type not in array, inform that it may not be valid and suggest dedicated component
                    console.error(`[Invalid Prop]: Either "${value}" is not a valid type at all, or there is a separate component that should be used instead.\n\n Try using <vue-${value}>`);
                    return false;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .float-wrapper { position: relative; }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .float-wrapper & {
            position: absolute;
            left: .5rem;
            top: .875rem;
            transform: translateY(0);
            background-color: transparent;
            padding: 0 .25rem;
            transition: 200ms ease-out;
            pointer-events: none;
            line-height: 1;
            border-radius: 2px;
            opacity: .5;
        }

        .float-wrapper.float-label & {
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
        }
    }
</style>