<template>
    <p :class="textStyle">{{ question.label }}</p>
</template>

<script>
import { general } from './mixins/SiteFormBuilderControlMixins';

export default {
    inheritAttrs: false,
    mixins: [ general ],
    computed: {
        textStyle() {
            return this.question.attributes?.textStyle || false;
        }
    }
}
</script>