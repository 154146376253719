<template>
	<div class="p-2" :class="oddInput ? 'bg-light' : ''">
		<div class="d-flex justify-content-end mb-2 w-100">
			<button
				class="btn btn-danger"
				v-if="formInputs.length > 1"
				@click.prevent="removeInput()"
			>Remove Input</button>
		</div>
		<div class="row">
            <div class="col-md form-group">
                <template v-if="input.type == 'paragraph'">
                    <label class="required" :for="`input_name_${index}`">Heading/Paragraph Content</label>
                    <textarea
                        required
                        maxlength="255"
                        class="form-control"
                        v-model="input.label"
                        :id="`input_name_${index}`"
                        :name="`input_name_${index}`"
                        title="Heading/Paragraph Content"
                        data-invalid-feedback="Please provide label text"
                    ></textarea>
                </template>
                <template v-else>
                    <label class="required" :for="`input_name_${index}`">Label Text</label>
                    <input
                        required
                        type="text"
                        maxlength="255"
                        title="Label Text"
                        class="form-control"
                        v-model="input.label"
                        :id="`input_name_${index}`"
                        :name="`input_name_${index}`"
                        data-invalid-feedback="Please provide label text"
                    />
                </template>
                <div class="invalid-feedback">
                    Please provide label text
                </div>
            </div>

			<div class="col-md form-group">
				<label class="required" :for="`input_type_${index}`">Type</label>
				<input
					required
					title="Type"
					type="text"
					class="form-control"
					v-model="input.type"
					v-mask="inputTypeMask"
					:id="`input_type_${index}`"
					:name="`input_type_${index}`"
                    data-invalid-feedback="Please provide input type."
					@input="checkIfOptionsNeedDisplayed();checkIfInputIsFileType()"
				/>
                <div class="invalid-feedback">
                    Please provide input type.
                </div>
			</div>

			<!-- Has to be valid JSON -->
			<div class="col-md form-group">
				<label :for="`input_attributes_${index}`">Attributes</label>
				<input
					type="text"
					title="Attributes"
					class="form-control"
					v-model="input.attributes"
					:id="`input_attributes_${index}`"
					:name="`input_attributes_${index}`"
					placeholder='{"attribute" : "value", "attribute1" : "value1"}'
				/>
			</div>
			<div class="col-md form-group" v-if="input.required">
				<label :for="`input_required_message_${index}`">Required Message</label>
				<input
					type="text"
					class="form-control"
					title="Required Message"
					v-model="input.required_message"
					:id="`input_required_message_${index}`"
					:name="`input_required_message_${index}`"
				/>
			</div>
		</div>

		<div class="d-flex justify-content-between align-items-center">
			<div class="form-group m-0">
				<div class="d-flex align-items-center">
					<input
						type="checkbox"
						v-model="input.required"
						class="js-boolean-control mr-1"
						:id="`input_required_${index}`"
						:name="`input_required_${index}`"
					/>
					<label class="p-0" :for="`input_required_${index}`">Required?</label>
				</div>
			</div>
		</div>

		<div class="p-2 border border-light-gray mt-2 bg-white" v-if="showOptions">
			<div>
				<vpanel-form-input-options
					v-for="(option, i) in input.options"
					:key="i"
					:index="i"
					:option="option"
					:options="input.options"
				></vpanel-form-input-options>
			</div>
			<button class="btn btn-secondary" @click.prevent="addNewOption()">Add Option</button>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
            "index",
            "input",
            "oddInput",
            "formInputs"
        ],
		data() {
			return {
				inputTypeMask: {
					mask: "cccccccccccccccccccccccccccccc",
					tokens: {
						c: {
							pattern: /[0-9a-zA-Z-]/,
							transform: v => v.toLocaleLowerCase()
						}
					}
				},
                showOptions: this.input.options.length
			};
        },
		methods: {
			removeInput() {
				if (this.formInputs.length > 1) {
					this.formInputs.splice(this.index, 1);
				}
			},
			addNewOption() {
				this.input.options.push({
					value: ""
				});
			},
            checkIfInputIsFileType(){
                const inputAttrs = this.input.attributes ? JSON.parse(this.input.attributes) : JSON.parse(JSON.stringify({}));

                if(this.input.type == 'file'){
                    Vue.set(inputAttrs, 'maxFiles', 1);
                    Vue.set(inputAttrs, 'accept', 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf');

                    Vue.set(this.input, 'attributes', JSON.stringify(inputAttrs));
                } else {
                    let removeAttrs = ['accept', 'maxFiles'];

                    removeAttrs.forEach(attribute => {
                        if(inputAttrs.hasOwnProperty(attribute)){
                            Vue.delete(inputAttrs, attribute);

                            let formattedJson = Object.keys(inputAttrs).length ? JSON.stringify(inputAttrs) : null;

                            Vue.set(this.input, 'attributes', formattedJson);
                        }
                    });
                }
            },
			checkIfOptionsNeedDisplayed() {
				let value = event.target.value;
				let optionInputs = ["select", "radio", "checkbox"];

				if (optionInputs.indexOf(value) !== -1) {
					this.showOptions = true;

					if (!this.input.options.length) {
						this.input.options.push({
							value: ""
						});
					}
				} else {
					this.showOptions = false;
				}
			}
		}
	};
</script>