export default {
    props: {
        row: {
            type: Object,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        wrapperBinder() {
            return {
                row: this.row,
                isActive: this.isActive
            }
        }
    }
}