<template>
    <vue-modal :visible="isModalOpen" @on-close="close" id="recycle-bin-confirm-delete-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Delete</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you wish to permanently delete this?</p>
            <p><strong>You will not be able to recover it in the future.</strong></p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click.prevent="close">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click.prevent="confirmed" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Yes, Permanently Delete' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    export default {
        name: 'RecycleBinDeleteModal',
        props: [
            'modalId',
            'isModalOpen',
            'isModalProcessing',
        ],
        methods: {
            confirmed(){
                this.$emit('permanently-delete-event');
            },
            close(){
                this.$emit('close-delete-modal');
            }
        }
    }
</script>