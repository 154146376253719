<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p @click="sortBy('full_name')" :class="sortClass('full_name')">Name</p>
            <p @click="sortBy('position')" :class="sortClass('position')">Position</p>
            <p @click="sortBy('is_active', 'number')" :class="sortClass('is_active')">Status</p>
            <p @click="sortBy('updated_at', 'date')" :class="sortClass('updated_at')">Last Modified</p>
        </div>
        <div class="simple-table" style="--cols:5;">
            <p class="first-col st-header" @click="sortBy('full_name')" :class="sortClass('full_name')">Name</p>
            <p class="st-header" @click="sortBy('position')" :class="sortClass('position')">Position</p>
            <p class="st-header" @click="sortBy('is_active', 'number')" :class="sortClass('is_active')">Status</p>
            <p class="st-header" @click="sortBy('updated_at', 'date')" :class="sortClass('updated_at')">Last Modified</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <p class="first-col" :key="row.id+'name'">
                        <span class="st-hidden">Name</span>
                        <strong>{{ row.full_name }}</strong>
                    </p>

                    <p :key="row.id+'position'">
                        <span class="st-hidden">Position</span>
                        <strong>{{ row.position }}</strong>
                    </p>

                    <div :key="row.id+'status'">
                        <span class="st-hidden">Status</span>
                        <p class="m-0" :class="row.is_active?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                    </div>

                    <p :key="row.id+'updated_at'">
                        <span class="st-hidden">Last Modified</span>
                        <strong>{{ row.updated_at }}</strong>
                    </p>

                    <div class="last-col" :key="row.id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <RouterLink exact class="btn btn-primary" :to="{ name: 'Modify Staff',  params: { id: row.id }}" :title="`Internal link to modify ${row.full_name}`">Modify</RouterLink>
                            <button type="button" class="staff-recycle btn btn-outline-primary" @click="openStaffDeleteModal(row.id)">Delete</button>
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelStaffTable',
        mixins: [ tableMixin ],
        methods: {
            ...mapActions('staff/list', [
                'openConfirmDeleteModal'
            ]),
            status(row) {
                return row.is_active ? 'Active' : 'Inactive';
            },
            openStaffDeleteModal(staffId){
                this.openConfirmDeleteModal({recordId: staffId, namespace: 'staff'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(calc(var(--cols) - 1), minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>