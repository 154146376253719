<template>
    <div class="p-3 flex-grow-1">
        <div class="border-bottom">
            <p class="h5">
                <span class="fas fa-pallet mr-1 text-tertiary"></span>&nbsp;{{ getAddModifyHeading('Product Manufacturer') }}
            </p>
        </div>
        <div class="pt-1">
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                class="pb-1"
                v-if="!isActionAdd"
                :status="getStatusAsString"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <form class="product-manufacturer-add-modify-form needs-validation" name="product_manufacturer_add_modify_form" id="product-manufacturer-add-modify-form" novalidate @submit.prevent="submitProductManufacturerForm">
                <!-- Error Messages -->
                <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

                <!-- Product Manufacturer Form Tabs Nav -->
                <VpanelEcommAddModifyFormNav
                    section-name="product manufacturer"
                    namespace="productManufacturer"
                    :nav-items="[{
                        name: 'General Details',
                        href: 'product-manufacturer-add-modify-general-details',
                        id: 'product-manufacturer-add-modify-general-details-tab',
                        is_active_tab: getActiveFormTab == 'product-manufacturer-add-modify-general-details-tab' ? true : false
                    },
                    {
                        name: 'Seo',
                        href: 'product-manufacturer-add-modify-seo',
                        id: 'product-manufacturer-add-modify-seo-tab',
                        is_active_tab: getActiveFormTab == 'product-manufacturer-add-modify-seo-tab' ? true : false
                    }]"
                />

                <!-- Tabbed Content -->
                <div class="tab-content" id="add-modify-tab-content">
                    <!-- Product  General Details Tab -->
                    <div class="tab-pane fade" :class="{'show active': getActiveFormTab == 'product-manufacturer-add-modify-general-details-tab'}" id="product-manufacturer-add-modify-general-details" role="tab-panel" aria-labelledby="product-manufacturer-add-modify-general-details-tab">
                        <div class="row">
                            <div class="col-md-7 col-lg-8">
                                <!-- Product Manufacturer General Details Form -->
                                <VpanelProductManufacturerGeneralDetailsForm
                                    :active-record="activeRecord"
                                    @update="updateProductManufacturerValue"
                                />
                            </div>
                            <div class="col-md-5 col-lg-4 mt-3 mt-md-0">
                                <!-- Image Form -->
                                <VpanelEcommAddModifyImageForm
                                    :active-record="activeRecord"
                                    @update="updateProductManufacturerValue"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Product Manufacturer Seo Tab -->
                    <div class="tab-pane fade" :class="{'show active': getActiveFormTab == 'product-manufacturer-add-modify-seo-tab'}" id="product-manufacturer-add-modify-seo" role="tab-panel" aria-labelledby="product-manufacturer-add-modify-seo-tab">
                        <VpanelEcommSeoSettingsForm
                            section-name="manufacturer"
                            :active-record="activeRecord"
                        />
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary my-0 save-button">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';

    export default {
        name: "VpanelAddModifyProductManufacturerPage",
        computed: {
            ...mapGetters('productManufacturer/addModify', [
                'getActiveFormTab',
                'getStatusAsString',
                'getAddModifyHeading'
            ]),
            ...mapState('productManufacturer/addModify', {
                formId: state => state.formId,
                activeRecord: state => state.activeRecord,
                errorMessage: state => state.errorMessage,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
        },
        methods: {
            ...mapActions('productManufacturer/addModify', [
                'submitForm',
                'setFormIsValid',
                'checkIfPageIsValid',
                'setValueInActiveRecord'
            ]),
            updateProductManufacturerValue(payload){
                let inputValue = 'value' in payload ? payload.value : (payload.parseAsInt ? parseInt(event.target.value) : event.target.value);

                this.setValueInActiveRecord({ prop: payload.prop, value: inputValue });
            },
            submitProductManufacturerForm(){
                let addDispatch     = this.isActionAdd ? 'productManufacturer/addModify/setActiveRecord' : null;
                let addModifyRoute  = this.isActionAdd ? '/vpanel/modules/ecommerce/product/manufacturer/store' : '/vpanel/modules/ecommerce/product/manufacturer/update';
                let routerPath      = this.isActionAdd ? 'Modify Product Manufacturer' : 'Product Manufacturer List';

                this.checkIfPageIsValid();

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    isUsingModal: false,
                    routerPath: routerPath,
                    dispatchPath: addDispatch
                });
            }
        }
    }
</script>