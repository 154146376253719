<template>
    <div class="col-md-6 form-group has-label">
        <div class="d-flex justify-content-between align-items-center">
            <label :class="isSkuRequired ? 'required' : ''" :for="`product_attribute_sku_${inputIndex}`">SKU</label>
            <a
                href="#"
                data-toggle="tooltip"
                data-placement="right"
                data-boundary="window"
                title="Provide a SKU."
            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
        </div>
        <input
            type="text"
            :value="inputValue"
            class="form-control"
            @input="$emit('update')"
            :required="isSkuRequired"
            name="product_attribute_sku[]"
            :id="`product_attribute_sku_${inputIndex}`"
            title="Please enter the sku of this product"
            data-invalid-feedback="Please enter a SKU for this product."
        />
        <div class="invalid-feedback">
            Please enter a SKU for this product.
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        props: [
            'inputIndex',
            'inputValue'
        ],
        computed: {
            ...mapGetters([
                'getModuleConfigs'
            ]),
            isSkuRequired(){
                return Boolean(parseInt(this.getModuleConfigs['SKU Required']));
            }
        },
    }
</script>