<template>
    <FieldsetDropdownShell
        v-bind="[$attrs, questionBinder]"
        type="radio"
        v-on="questionEventBinder"
    />
</template>

<script>
import { questionChute, questionLadder } from './mixins/SiteFormBuilderControlMixins';

export default {
    inheritAttrs: false,
    mixins: [ questionChute, questionLadder ]
}
</script>