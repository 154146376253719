<template>
    <div>
        <p class="h6">Upload Image</p>
        <div class="mt-2">
            <img
                v-if="imagePath"
                :src="imagePath"
                id="image_preview"
                alt="placeholder"
                class="mb-2"
            >
            <div class="fake-background" v-else></div>
            <div class="form-group">
                <input
                    disabled
                    type="text"
                    id="image_path"
                    name="image_path"
                    :value="imagePath"
                    class="form-control"
                />
                <div> <!-- Placeholder for validation errors --> </div>
                <div class="mb-2">
                    <button
                        type="button"
                        id="choose_image"
                        data-input="image_path"
                        data-preview="image_preview"
                        class="btn btn-tertiary mr-1"
                        @click.prevent="selectImage"
                        title="Opens the file manager modal where you can choose to upload a new image or choose from your image library"
                    >Choose Image</button>
                    <button
                        type="button"
                        id="remove_image"
                        @click.prevent="removeImage"
                        class="btn btn-outline-primary"
                        title="Clears the current background image"
                    >Delete Image</button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="d-flex justify-content-between align-items-center">
                <label for="image_alt" :class="imagePath ? 'required' : ''">Alternative Text</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Web Content Accessibilty Guidelines require alternative text to be present for an image."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                type="text"
                id="image_alt"
                name="image_alt"
                class="form-control"
                v-model="activeRecord.image_alt"
                :required="imagePath ? true : false"
                title="Please enter the alternative text for the image"
            />
            <div class="invalid-feedback">
                Please add alternative text that describes this image.
            </div>
        </div>
    </div>
</template>
<script>
	import { openFileManager } from "@j/core/vpanel/vpanel-functions";
    export default {
        name: 'LocationImageForm',
        props: [
            'activeRecord'
        ],
        data() {
			return {
                currentLocation: this.activeRecord,
                imagePath: ''
			}
        },
        mounted() {
            if (this.currentLocation.image_path) this.imagePath = this.currentLocation.image_path
        },
        methods: {
            selectImage(){
                let chooseImageButton = event.target;
                let vm = this;
                openFileManager(chooseImageButton, (fmObject) => {
                    vm.imagePath = fmObject[0].url
                });
            },
			removeImage(){
                this.imagePath = '';
                Vue.set(this.currentLocation, 'image_path', '');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fake-background {
        padding-top: 56.25%;
        margin-bottom: 1rem;
        width: 100%;
        position: relative;
        background-color: hsl(0deg,0%,85%);

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            height: 33%;
            width: 33%;
            transform: translate(-50%, -50%);
            background-color: white;
            clip-path: polygon(0% 100%, 33% 0%, 50% 50%, 70% 25%, 100% 100%);
        }
    }
</style>