<template>
    <div class="card">
        <div class="card-header border-bottom">
            <p class="h5 mr-3">
                <span class="fas fa-sitemap mr-1"></span>
                Variants
            </p>
        </div>
        <div>
            <div
                :key="index"
                @click="setActiveVariant([variant, true])"
                v-for="(variant, index) in productVariants"
                :class="{'bg-light-gray': isVariantActive(variant.id), 'border-top pt-1': index !== 0}"
                class="cursor-pointer p-1 d-flex justify-content-start align-items-center"
            >
                <VpanelEcommImageDiv
                    :image="getImageUrl(variant)"
                    image-wrapper-width="width:80px;"
                />

                <p class="m-0">
                    <span v-for="(optionValueId, optionId) in variant.option_values" :key="optionId" class="variant">
                        {{ optionValueName(optionValueId) }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: "VpanelProductVariantList",
        computed: {
            ...mapGetters({
                productVariants: 'productVariant/list/getTableData',
                activeVariant: 'productVariant/addModify/getActiveRecord',
                optionValueName: 'productOptionValue/list/getOptionValueNameById'
            })
        },
        methods: {
            ...mapActions('productVariant/addModify', [
                'setActiveVariant'
            ]),
            isVariantActive(variantId){
                return variantId === this.activeVariant.id ? true : false;
            },
            getImageUrl(variant){
                let firstImage = Object.values(variant.images)[0]
                return firstImage?.full ? firstImage.full : firstImage;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .variant + .variant:before {
        content: "/ ";
    }
</style>