<template>
    <div class="nav-icon dropright">
        <a class="nav-icon-link" href="#" title="Manage Modules" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="fas fa-puzzle-piece"></span>
            <p class="nav-icon-label">Modules</p>
        </a>
        <div class="dropdown-menu bg-black px-1 ml-1 border-0 rounded-0">
            <div class="my-n1">
                <p
                    class="nav-icon-label text-white d-flex align-items-center text-left px-1 position-relative btn btn-link my-1"
                    v-for="vpanelModule in modules"
                    :key="vpanelModule.name"
                >
                    <span :class="`fas fa-${vpanelModule.icon} my-0 mr-1`"></span>
                    {{ vpanelModule.name }}
                    <vue-link
                        link-class="absolute-fill text-throw"
                        :link-path="vpanelModule.url"
                        :link-title="`Manage ${vpanelModule.name} Module`"
                    >{{ vpanelModule.name }}</vue-link>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'modules'
        ]
    }
</script>