<template>
    <div class="col-4 used-on-section" v-if="!isActionAdd">
        <p class="uo-legend">Used On</p>
        <div class="uo-container" v-html="getUsedOnPages"></div>
    </div>
</template>
<script>
    export default {
        name: 'UsedOnPagesList',
        props: [
            'isActionAdd',
            'activeRecord'
        ],
        computed: {
            getUsedOnPages(){
                let pagesHtml = '';
                if (this.activeRecord.pages.length) {
                    this.activeRecord.pages.forEach(page => {
                        pagesHtml += `<a class="uo-link" href="${location.origin + page.url}" title="Opens ${page.title} in new tab" target="_blank">${page.title}</a>`;
                    });
                } else {
                    pagesHtml = `<p class="uo-link">Not used on any pages</p>`;
                }

                return pagesHtml;
            }
        }
    }
</script>