<template>
    <component
        v-if="template != 'hidden'"
        :is="templateComponent"
        v-bind="{...$props,...$attrs}"
    ></component>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        designConfig: {
            type: Object,
        },
    },
    computed: {
        template() {
            return this.designConfig?.template?.footer_template || 'Template1';
        },
        templateComponent() {
            let prefix = 'VueFooter',
                check  = Object.keys(Object.getPrototypeOf(this.$options.components)).includes(prefix + this.template);

            return prefix + ( check ? this.template : 'Template1');
        },
    }
}
</script>