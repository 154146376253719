<template>
    <div class="sidebar-details" :class="{active: sidebarDetails}">
        <div class="top-bar">
            <preferred-location-button :id="sidebarDetails" />

            <button
                class="icon-button details-close"
                @click="closeSidebarDetails()"
            ><span class="fas fa-times"></span></button>
        </div>
        <template v-if="sidebarDetails && location">
            <p class="title">{{ location.title }}</p>
            <p class="m-0" v-if="location.type">{{ location.type }}</p>
            <frontend-location-short-hours
                v-for="(hours, index) in location.hours"
                :key="index + '_sbd'"
                :hours="hours"
            />

            <frontend-location-address :location="location"/>

            <p class="heading">Contact Information</p>
            <div class="group">
                <frontend-location-phone-numbers :location="location" :id="sidebarDetails" />
                <view-location-details-button class="mt-2" :id="sidebarDetails" />
            </div>

            <p class="heading">Hours</p>

            <frontend-location-full-hours
                v-for="(hours, index) in location.hours"
                :key="index"
                :hours="hours"
            />

            <template v-if="isServicesEnabled && Object.keys(services).length && location.services.length">
                <p class="heading">Services</p>
                <frontend-location-services
                    :location="location"
                    :id="sidebarDetails"
                    :services="services"
                />
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: ["sidebarDetails", "location", "isServicesEnabled", "services"],
    methods: {
        ...mapActions('location', [
            'setSidebarDetails'
        ]),
        ...mapActions('location/googleMap', [
            'closeInfoWindows'
        ]),
        closeSidebarDetails() {
            this.setSidebarDetails(null);
            this.closeInfoWindows();
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';

    // eventually move this to the base, maybe clean it up a bit
    @mixin border-widths($t, $r: null, $b: null, $l: null) {
        // conditionally handle shorthand
        @if not $r {$r:$t;$b:$t;$l:$t;}
        @else if not $b {$b:$t;$l:$r;}
        @else if not $l {$l:$r;}

        border-style: solid;
        border-color: var(--light);
        border-width: $t $r $b $l;
    }

    .sidebar-details {
        @extend %minimal-scrollbar;
        grid-area: sidebar;
        position: relative;
        left: -100%;
        overflow-y: auto;
        padding: 1rem .75rem;
        font-size: 14px;
        background-color: white;
        opacity: 0;
        pointer-events: none;
        transition: var(--transition);

        @include desktop { @include border-widths(0, 0, 1px, 1px); }
        @include not-desktop { @include border-widths(0, 0, 1px); }

        &.active {
            opacity: 1;
            left: 0;
            pointer-events: all;
        }

        .top-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            .details-close {
                color: var(--dark);
                font-size: 20px;
            }
        }

        .title {
            @include fluid-size(22px, 30px, font-size);
            font-weight: bold;
            line-height: 1.1;
            margin-bottom: .5rem;
        }

        .heading {
            margin-top: 1rem;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: .25rem;
            border-bottom: 1px solid var(--light);

            + .group {
                padding: .5rem 0;

                p { margin: 0; }

                > *:first-child { margin-top: 0; }
                > *:last-child { margin-bottom: 0; }
            }
        }

        .hours-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.today { font-weight: bold; }
        }
    }
</style>