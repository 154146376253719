<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-start">
            <p class="h5 mb-0 mr-3">
                <span class="fas fa-sitemap mr-1"></span>
                Variants
            </p>

            <!-- Add Additional Options Button -->
            <VpanelAddAdditionalInputButton
                @add-new-array-item="addOption"
                v-if="hasVariants && getProductOptionCount < 3"
            />
        </div>

        <div class="card-body">
            <!-- Has Variants Checkbox -->
            <div class="m-2">
                <label class="form-check-label" for="has_variants">
                    <input
                        type="checkbox"
                        id="has_variants"
                        name="has_variants"
                        :value="hasVariants"
                        :checked="hasVariants"
                        class="form-check-input"
                        @change="updateHasVariants"
                    />
                    This product has multiple options. ie: size, color, etc.
                </label>
            </div>
            <div class="option-selectors" v-if="hasVariants">
                <div class="option-selector" :key="optionIndex" v-for="(productOption, optionIndex) in productOptions">
                    <div class="form-group has-label m-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" :for="`product_options_${optionIndex}`">Option</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="If you don’t see an option, it might not have values, or it isn’t set to active."
                            >
                                <span class="fas fa-question-circle text-info cursor-pointer"></span>
                            </a>
                        </div>
                        <select
                            required
                            :value="optionIndex"
                            class="form-control"
                            :id="`product_options_${optionIndex}`"
                            :name="`product_options_${optionIndex}`"
                            data-invalid-feedback="Please select an option."
                            @change="addProductOption({ addTempOption: false, previousIndex: optionIndex})"
                        >
                            <option value="" selected disabled hidden>Select Option</option>
                            <option
                                v-for="(option, index) in getOptionsThatHaveValues"
                                :key="index"
                                :value="option.id"
                                :selected="optionIndex == option.id"
                            >{{ option.name }}</option>
                        </select>
                        <div class="invalid-feedback">
                            Please select an option.
                        </div>
                    </div>
                    <div class="remove-option-button">
                        <!-- Remove Options Button -->
                        <VpanelRemoveAdditionalItemButton
                            v-if="getProductOptionCount > 1"
                            @add-new-array-item="addOption"
                            :array-count="getProductOptionCount"
                            @remove-additional-item="removeOption(optionIndex)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: 'VpanelProductVariantForm',
        props: [
            'hasVariants',
            'productOptions',
        ],
        mounted(){
            if(Object.keys(this.productOptions).length == 0){
                this.addOption();
            }
        },
        computed: {
            ...mapGetters('productOption/list', [
                'getOptionsThatHaveValues'
            ]),
            getProductOptionCount(){
                return Object.keys(this.productOptions).length;
            }
        },
        methods: {
            ...mapActions('product/addModify', [
                'addProductOption',
                'updateProductValue',
                'removeAdditionalItem',
                'removeOptionValueFromAttribute'
            ]),
            updateHasVariants(){
                this.updateProductValue({ prop: 'has_variants', isCheckBox: true });

                //if hasvaraints was true but now false
                if(this.hasVariants && !event.target.checked){
                    this.$emit('reset-variants');
                }
            },
            removeOption(optionIndex){
                this.removeOptionValueFromAttribute(optionIndex);
                this.removeAdditionalItem([optionIndex, 'options']);
            },
            addOption(){
                let options = this.productOptions;
                //will check if there are any empty product option values
                let shouldAddNewValue = options.hasOwnProperty('temp');

                if(!shouldAddNewValue){
                    //will add new object to array
                    this.addProductOption({ addTempOption: true });
                } else {
                    //will show user a message if theres a option that doesn't have a value
                    showNotification('Make sure all option inputs are filled in, before adding a new one.', 'error');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .option-selectors {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -1rem;

        .option-selector {
            flex: 1 1 300px;
            min-width: min(300px, calc(100% - 1rem));
            display: flex;
            align-items: flex-start;
            margin: 0 .5rem;

            .form-group {
                flex-grow: 1;
            }
        }
    }
</style>