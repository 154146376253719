<template>
    <button type="button" class="btn btn-primary" id="js-export-as-csv" @click.prevent="exportAsCsv">{{ isProcessing ? 'Processing...' : 'Export As CSV' }}</button>
</template>
<script>
    import { mapState, mapActions } from "vuex";
    export default {
        name: 'ExportAsCsvButton',
        computed: {
            ...mapState('auditLog/exportButtons', {
                isProcessing: state => state.isCsvProcessing
            })
        },
        methods: {
            ...mapActions('auditLog/exportButtons', [
                'exportLogs',
                'setExportType'
            ]),
            exportAsCsv(){
                this.setExportType('csv');
                this.exportLogs();
            }
        }
    }
</script>