<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <h5 class="m-0">{{ formName }}</h5>
            <template v-if="showBadges">
                <span class="badge bg-warning text-white mb-0 mx-1" v-if="currentFilter == 'unread'">Unread</span>
                <span class="badge bg-gray border-0 text-black mb-0 mx-1" v-if="currentFilter == 'seen'">Read</span>
                <span class="badge bg-light-gray border-0 text-black mb-0 mx-1" v-if="currentFilter == 'archived'">Archived</span>
            </template>
        </div>
        <div class="vp-status-bar-list" v-if="showDate">
            <p> Created: <span>{{ createdDate }}</span> </p>
        </div>
    </div>

</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'VpanelFormSubmissionListingHeader',
        props: [
            'formName',
            'showDate',
            'showBadges',
            'createdDate'
        ],
        computed: {
            ...mapState('forms/submissions/list', {
                currentFilter: 'tableFilter'
            })
        }
    }
</script>