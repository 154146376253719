<template>
    <nav
        class="vue-nav-1"
        :data-expanded="isExpanded"
        :data-headertemplate="template"
        :data-navtemplate="navTemplate"
    >

        <!--<div
            class="vn-family nav-search"
            data-depth="0"
        >
            <div class="vn-item" data-depth="0">
                <VueSiteSearch
                    search-template="PowerwebTemplate1"
                    :nav-info="navInfo"
                />
            </div>
        </div>-->

        <div class="mobile-controls">
            <p class="close-mobile-menu" @click="closeMobileMenu">
                <span class="fas fa-chevron-left"></span>
            </p>
        </div>

        <template v-for="(link, index) in headerLinks">
            <div
                v-if="link"
                :key="`hidden-link-${index}`"
                class="vn-family d-block d-md-none"
                data-depth="0"
            >
                <VueLink
                    :link-obj="link"
                    class="vn-item"
                    data-depth="0"
                />
            </div>
        </template>

        <template v-for="(ni, index) in navInfo">
            <vue-nav-item
                :depth="0"
                :nav-item="ni"
                :key="ni.page.uuid"
                :style="`--maxRowCount: ${depth2MaxChildren[index]}`"
                v-if="ni.page.show_in_nav"
                :current-page-id="currentPageId"
            ></vue-nav-item>
        </template>
    </nav>
</template>

<script>
export default {
    props: [
        "currentPageId",
        "mobileExpanded",
        "navInfo",
        "navTemplate",
        "template",
        "headerLinks"
    ],
    data() {
        return {
            timeout: null,
        }
    },
    computed: {
        isExpanded() {
            return this.mobileExpanded;
        },
        depth2MaxChildren() {
            // Should document this at some point, I forgot what it does lol
            let realMax = this.navInfo.reduce((a1,c1) => {
                a1.push(c1.children.reduce((a2,c2) => {
                    return c2.children.length > a2 ? c2.children.length : a2;
                },0));
                return a1;
            }, [])

            return realMax.map(max => {
                return max ? Math.round(max / Math.ceil(max/6)) : 0;
            })
        },
    },
    methods: {
        closeMobileMenu() {
            this.$emit('close-mobile-menu');
        },
        checkOverflow() {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                // This function sucks because there is no way to make this work in EVERY situation
                // This won't work if the nav is placed in a different header. This only works for PowerwebTemplate2
                document.body.removeAttribute('data-nav-overflow');

                let header         = document.querySelector('.header-wrapper'),
                    logo           = header.querySelector('.header-wrapper .logo'),
                    nav            = header.querySelector('.header-wrapper nav.vue-nav-1'),
                    logoStyles     = window.getComputedStyle(logo),
                    logoMargin     = parseInt(logoStyles.getPropertyValue('margin-right')) + parseInt(logoStyles.getPropertyValue('margin-left'));

                let combinedWidths = logo.clientWidth + logoMargin + nav.clientWidth,
                    overflowing    = header.clientWidth < combinedWidths;

                document.body.dataset.navOverflow = true;

                if (!overflowing) document.body.removeAttribute('data-nav-overflow');
            }, 50);
        }
    },
    mounted() {
        ["load","resize"].forEach(evt => window.addEventListener(evt, this.checkOverflow));
    },
    beforeDestroy() {
        ["load","resize"].forEach(evt => window.removeEventListener(evt, this.checkOverflow));
    }
}
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../../sass/vue-import';

    $base-h: 0deg;
    $base-s: 0%;
    $base-l: 100%;
    $base-hsl: hsl($base-h, $base-s, $base-l);
    $accent-hsl: var(--optimumOnWhite-hsl);
    $accentCompliant-hsl: var(--optimumOnWhiteCompliant-hsl);

    @mixin desktop-styles {
        display: flex;
        background-color: $base-hsl;
        @include fluid-size(-8px, -16px, margin-right, $min-vw: $lg-screen, $max-vw: 1400px);

        .mobile-controls { display: none }

        // Global Styling
        .vn-item {
            display: block;
            cursor: pointer;
            margin: 0;
            line-height: 1.25;
            color: $accent-hsl;
            padding: .5rem 1rem;
            text-decoration: none;

            &:hover { text-decoration: underline; }
        }

        // Root Level Families
        [data-depth="0"] {
            &.vn-family {
                flex-shrink: 0;

                // When hovering over a family...
                &:hover {
                    [data-depth="0"] {
                        &.vn-item { text-decoration: underline; }

                        &.vn-children {
                            opacity: 1;
                            visibility: visible;
                            pointer-events: all;
                        }
                    }
                }
            }

            &.vn-item {
                @include fluid-size(14px, 16px, font-size, $min-vw: $lg-screen, $max-vw: 1400px);
                @include fluid-size(8px, 16px, padding-left, $min-vw: $lg-screen, $max-vw: 1400px);
                @include fluid-size(8px, 16px, padding-right, $min-vw: $lg-screen, $max-vw: 1400px);
                position: relative;
                display: flex;
                align-items: center;
                font-weight: 600;
                color: $accent-hsl;
                text-decoration: none;
                text-transform: uppercase;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            &.vn-children {
                display: flex;
                align-items: stretch;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: hsl($base-h, $base-s, 95%);
                opacity: 0;
                transition: 200ms ease-out;
                visibility: hidden;
                pointer-events: none;
                z-index: 1;
            }
        }

        // First Level Families
        [data-depth="1"] {
            &.vn-family {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding: 1.5rem;

                &:nth-child(odd) { background-color: hsl($base-h, $base-s, 90%); }
            }

            &.vn-item {
                font-size: 18px;
                font-weight: bold;
                padding: 0;

                + .vn-children { padding-top: 1rem; }
            }

            &.vn-children {
                display: grid;
                grid-auto-flow: column;
                grid-template-rows: repeat(var(--maxRowCount, 6), max-content);
                grid-auto-columns: auto;
                margin: 0 -.75rem;
            }
        }

        // Second Level Families
        [data-depth="2"] {
            &.vn-item {
                padding: .25rem .75rem;
                font-size: 14px;
            }
        }
    }

    @mixin not-desktop-styles {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: $base-hsl;
        height: 100vh;
        width: 100%;
        top: 0;
        right: -100%;
        transition: 200ms ease-out;

        &[data-expanded] { right: 0; }

        .nav-search { display: none; }

        .mobile-controls {
            display: flex;
            background-color: $accent-hsl;

            .close-mobile-menu {
                color: $base-hsl;
                margin: 0;
                padding: 1rem;
            }
        }

        .vn-item {
            color: $accent-hsl;
            font-family: "benton-sans-condensed", sans-serif;
            font-weight: bold;
            line-height: 1;
            text-transform: uppercase;
            margin-right: calc(var(--hc-circle-size) + 2rem);
            display: block;
            position: relative;
            overflow-wrap: break-word;
        }

        .vn-family {
            & > .vn-children {
                max-height: 0;
                height: max-content;
                overflow: hidden;
                transition: 200ms ease-out;
            }

            &[data-expanded] > .vn-children {
                max-height: 50vh;
                transition: 200ms ease-in;
            }

            // Items with children
            &[data-has-children] {
                > .vn-item {
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        pointer-events: none;
                    }

                    &:before {
                        width: var(--hc-circle-size);
                        height: var(--hc-circle-size);
                        top: 50%;
                        right: calc(-1rem - var(--hc-circle-size));
                        transform: translateY(-50%);
                        border-radius: 50rem;
                        background-color: $base-hsl;
                    }

                    &:after {
                        border: 3px solid;
                        border-color: transparent $accent-hsl $accent-hsl transparent;
                        height: calc((3 / 8) * var(--hc-circle-size));
                        width: calc((3 / 8) * var(--hc-circle-size));
                        transform: translateY(-25%) rotate(45deg);
                        transform-origin: 50% 50%;
                        left: calc(100% + 1rem);
                        top: calc(50% - (var(--hc-circle-size) / 2));
                        margin: calc((var(--hc-circle-size) - ((3 / 8) * var(--hc-circle-size))) / 2);
                        transition: .2s ease-out;
                    }
                }

                &[data-expanded] > .vn-item:after { transform: translateY(25%) rotate(-135deg); }
            }
        }

        // Root Level Families
        [data-depth="0"] {
            &.vn-family {
                --hc-circle-size: 40px;
                --hc-arrow-width: 3px;
                background-color: $base-hsl;
                border-bottom: 3px solid $accent-hsl;
                position: relative;
            }

            &.vn-item {
                @include rem(22px);
                padding: 1rem;
            }

            &.vn-children {
                display: flex;
                flex-direction: column;
            }
        }

        [data-depth="1"] {
            &.vn-family {
                --hc-circle-size: 30px;
                --hc-arrow-width: 2px;
                background-color: $accent-hsl;
                border-bottom: 1px solid $base-hsl;

                &:last-child { border-width: 0; }
            }

            &.vn-item {
                @include rem(16px);
                padding: .5rem 1rem;
                color: $accentCompliant-hsl;
            }
        }

        [data-depth="2"] {
            &.vn-item {
                @include rem(14px);
                font-weight: normal;
                padding: .5rem 1.5rem;
            }
        }

        [data-depth="3"] { display: none; }
    }

    .vue-nav-1 {
        z-index: 4;

        body:not([data-nav-overflow]) & {
            &::v-deep {
                @include desktop { @include desktop-styles; }
                @include not-desktop { @include not-desktop-styles }
            }
        }

        body[data-nav-overflow] & {
            &::v-deep {
                @include not-desktop-styles;
                width: min(500px, 100%);

                &:before {
                    content: "";
                    position: fixed;
                    height: 100%;
                    width: 100vw;
                    background-color: rgba(black,.7);
                    right: -100%;
                    top: 0;
                    pointer-events: none;
                    opacity: 0;
                    transition: 200ms ease-out;
                    z-index: -1;
                }

                &[data-expanded]:before {
                    pointer-events: all;
                    opacity: 1;
                    right: min(500px, 100%);
                }
            }
        }
    }
</style>

<style lang="scss">
    body[data-nav-overflow] {
        .mobile-controls {
            display: flex;
            align-items: center;
        }

        .top-bar-links { display: none !important; }
        .nav .vn-family.d-block.d-md-none { display: block !important; }
    }
</style>