<template>
    <vue-modal :visible="isModalOpen" @on-close="closeTypeAddModifyModal" id="type-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-map-marked-alt mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <!-- Modify Details Section -->
        <VueModifyFormDetailsSection
            v-if="!isActionAdd"
            :status="getStatus"
            :added-date="activeRecord.created_at"
            :last-modified-date="activeRecord.updated_at"
        />

        <!-- Form Include -->
        <add-modify-location-type-form
            :is-action-add="isActionAdd"
            :active-record="activeRecord"
            :error-message="errorMessage"
            :class="{'d-none': isFormProcessing}"
            @submit-type-form="submitLocationTypeForm"
            @close-type-modal="closeTypeAddModifyModal"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeTypeAddModifyModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="type-add-modify-form">{{ isFormProcessing ? 'Processing...' : 'Apply' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'AddModifyLocationTypeModal',
        computed: {
            ...mapState('locationType/addModify', {
                isModalOpen: state => state.isModalOpen,
                activeRecord: state => state.activeRecord,
                errorMessage: state => state.errorMessage,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
            getModalHeader(){
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';

                return action + 'Type';
            },
            getStatus(){
                return this.activeRecord.status ? 'Active' : 'Inactive';
            }
        },
        methods: {
            ...mapActions('locationType/addModify', [
                'submitForm',
                'closeModal',
                'resetModal'
            ]),
            submitLocationTypeForm(){
                let addModifyRoute = this.isActionAdd ? '/vpanel/modules/location/type/store' : '/vpanel/modules/location/type/update';
                let addModifyCommit = this.isActionAdd ? 'locationType/list/ADD_RECORD_TO_TABLE_DATA' : 'locationType/list/UPDATE_RECORD_IN_TABLE_DATA';

                this.submitForm({ ajaxRoute: addModifyRoute, commitPath: addModifyCommit });
            },
            closeTypeAddModifyModal(){
                let vm = this;
                vm.closeModal();

                setTimeout(function(){vm.resetModal()}, 200);
            }
        }
    }
</script>