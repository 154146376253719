<template>
    <vue-modal :visible="isModalOpen" @on-close="closeUserAddModifyModal" id="user-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-users mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <div class="page-settings-details pb-3 pt-0" v-if="!isActionAdd">
            <p class="psd-published">Added Date: <span>{{ activeRecord.created_at }}</span></p>
            <p class="psd-modified">Last Modified: <span>{{ activeRecord.updated_at }}</span></p>
            <p class="psd-status">Status: <span>{{ activeRecord.status }}</span></p>
        </div>

        <!-- Form Include -->
        <add-modify-user-form
            :class="{'d-none': isFormProcessing}"
            @submit-user-form="submitForm"
            @close-user-modal="closeUserAddModifyModal"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeUserAddModifyModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="user-add-modify-form" id="vpanel-users-add-modify-btn">{{ isFormProcessing ? 'Processing...' : 'Apply' }}</button>
            </div>
        </template>
    </vue-modal>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    
    export default {
        name: 'AddModifyUserModal',
        computed: {
            ...mapState('userManager/addModifyUser', {
                isModalOpen: state => state.isModalOpen,
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
            getModalHeader(){
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';

                return action + 'User';
            }
        },
        methods: {
            ...mapActions('userManager/addModifyUser', [
                'submitForm',
                'closeModal',
                'resetUserModal'
            ]),
            closeUserAddModifyModal(){
                let vm = this;
                vm.closeModal();

                setTimeout(function(){vm.resetUserModal()}, 200);
            }
        }
    }
</script>