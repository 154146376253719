<template>
    <div class="border rounded product-image d-flex align-items-center justify-content-center p-1 bg-white" :style="imageWrapperWidth ? imageWrapperWidth : ''">
        <img :src="image" v-if="image">
        <div class="no-image" v-else>
            <span class="fas fa-image"></span>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VpanelEcommImageDiv',
        props: {
            imageWrapperWidth: {
                type: String,
                default: null
            },
            image: {
                type: String,
                default: null,
            }
        }
    }
</script>
<style lang="scss" scoped>
    .product-image {
        margin-right: 1rem;

        img, svg {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .no-image {
            font-size: 32px;
            position: relative;
            width: 100%;
            padding-top: 56.25%;

            > span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
</style>