<template>
	<div class="pagination" :class="{'d-none': totalPages < 2}">
		<button
            :disabled="activePage == 1"
            @click="updateActive(1)"
        >First</button>

		<button
            :disabled="activePage == 1"
            @click="updateActive(activePage - 1)"
        >Prev</button>

		<button
			v-for="(page, index) in pagesShown"
			:key="index"
			:disabled="page == '...'"
			:class="{ ellipses: page == '...', active: page == activePage }"
			@click="updateActive(page)"
		>{{ page }}</button>

		<button
            :disabled="activePage == totalPages"
            @click="updateActive(activePage + 1)"
        >Next</button>

		<button
			:disabled="activePage == totalPages"
			@click="updateActive(totalPages)"
		>Last</button>
	</div>
</template>

<script>
export default {
    props: ["activePage", "itemCount", "itemsPerPage"],
    computed: {
        totalPages() {
            return Math.ceil(this.itemCount / this.itemsPerPage);
        },
        pagesShown() {
            if (this.totalPages == 1) return [1];
            let arr = [];
            for (let i=-1;i<=1;i++) {
                let newPage = this.activePage + i;
                if (newPage > 0 && newPage <= this.totalPages) {
                    arr.push(newPage);
                }
            }
            // Always have first number be one
            if (arr[0] != 1) arr.unshift(1);
            // If the next number after 1 is not 2 then ellipses
            if (arr[1] != 2) arr.splice(1,0,'...');
            // If the last number has the last page in it we're done
            if (arr[arr.length - 1] == this.totalPages) return arr;
            // If the last number isn't the number before the last page add ellipses
            if (arr[arr.length - 1] != this.totalPages - 1) arr.push('...');
            // Add last page
            arr.push(this.totalPages);
            return arr;
        }
    },
    methods: {
        updateActive(index) {
            this.$emit('on-update', index);
        }
    }
};
</script>

<style lang="scss" scoped>
    .pagination {
        margin: -.25rem;
        display: flex;
        flex-wrap: wrap;
    }

    button {
        background: none;
        border: 0;
        padding: .25rem .5rem;
        margin: .25rem;
        background-color: hsl(0deg,0%,95%);
        color: hsl(0deg,0%,15%);
        line-height: 1.1;
        font-size: 14px;
        min-width: 2em;
        border-radius: 3px;
        transition: 200ms ease-out;

        &.active {
            background-color: hsl(0deg,0%,15%);
            color: hsl(0deg,0%,95%)
        }

        &[disabled] {
            opacity: .5;
        }
    }
</style>