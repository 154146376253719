<template>
    <form id="staff-add-modify-form" class="staff-form needs-validation" name="staff-add-modify-form" novalidate @submit.prevent="submitStaffForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>
        <!-- CRSF token -->
        <input type="hidden" name="_token" :value="activeRecord._token" />
        <!-- Staff Member Id -->
        <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />

        <div class="row">
            <div class="col-md">
                <div class="row">
                    <div class="col-md">
                        <div class="form-group has-label">
                            <div class="d-flex justify-content-between align-items-center">
                                <label class="required" for="full_name">Name</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="The full name of the staff member"
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                            <input
                                required
                                type="text"
                                name="name"
                                id="full_name"
                                autocomplete="off"
                                class="form-control"
                                @input="setValue('full_name')"
                                :value="activeRecord.full_name"
                                title="Please enter staff member full name"
                                data-invalid-feedback="Please enter staff member's full name"
                            />
                            <div class="invalid-feedback">
                            Please enter staff member's full name
                            </div>
                        </div>
                    </div>

                    <div class="col-md" v-if="checkModuleConfigSetting('EE')">
                        <div class="form-group has-label" >
                            <div class="d-flex justify-content-between align-items-center">
                                <label for="email">Email</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="The email of the user"
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                autocomplete="off"
                                class="form-control"
                                title="Please enter email"
                                @input="setValue('email')"
                                :value="activeRecord.email"
                            />
                        </div>
                    </div>

                    <div class="col-md" v-if="checkModuleConfigSetting('EPH')">
                        <div class="form-group has-label">
                            <div class="d-flex justify-content-between align-items-center">
                                <label for="staff_phone">Phone</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="The phone of the staff member"
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                            <input
                                name="phone"
                                type="phone"
                                id="staff_phone"
                                autocomplete="off"
                                class="form-control"
                                v-mask="'(###) ###-####'"
                                title="Please enter phone"
                                @input="setValue('phone')"
                                :value="activeRecord.phone"
                            />
                        </div>
                    </div>
                </div>

                <div class="row" v-if="checkModuleConfigSetting('EP') || checkModuleConfigSetting('NMLS')">
                    <div class="col-md" v-if="checkModuleConfigSetting('EP')">
                        <div class="form-group has-label">
                            <div class="d-flex justify-content-between align-items-center">
                                <label class="required" for="staff_position">Position</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="The position of the staff member"
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                            <input
                                required
                                type="text"
                                name="position"
                                autocomplete="off"
                                id="staff_position"
                                class="form-control"
                                title="Please enter position"
                                @input="setValue('position')"
                                :value="activeRecord.position"
                                data-invalid-feedback="Please enter staff member's position."
                            />
                            <div class="invalid-feedback">
                                Please enter staff member's position.
                            </div>
                        </div>
                    </div>
                        <div class="col-md" v-if="checkModuleConfigSetting('NMLS')">
                        <div class="form-group has-label">
                            <div class="d-flex justify-content-between align-items-center">
                                <label for="staff_nmls">NMLS</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="The nmls of the staff member"
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                            <input
                                name="nmls"
                                type="text"
                                id="staff_nmls"
                                autocomplete="off"
                                class="form-control"
                                title="Please enter nmls"
                                :value="activeRecord.nmls"
                                @input="setValue('nmls')"
                            />
                        </div>
                    </div>
                </div>

                <div class="row" v-if="checkModuleConfigSetting('ED') || checkModuleConfigSetting('EL') || checkModuleConfigSetting('EI')">
                    <div class="col-md" v-if="checkModuleConfigSetting('ED')">
                        <div class="form-group">
                            <div class="d-flex justify-content-between align-items-center">
                                <label for="staff_division">Division(s)</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    :title="checkModuleConfigSetting('EMD') ? 'Use Ctrl + Click to select multiple divisions' : 'Specific division a staff member belongs to'"
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>

                            <select
                                name="divisions[]"
                                id="staff_division"
                                ref="divisionsMultiselect"
                                class="form-control form-select-input"
                                :multiple="checkModuleConfigSetting('EMD') == 1"
                                @input="setSelectValue('divisionsMultiselect','divisions')"
                            >
                                <option
                                    :value="divsion.id"
                                    :id="`staff_divisions_${divsion.id}`"
                                    v-for="(divsion, index) in divisions" :key="index"
                                    :selected="activeRecord.divisions.includes(divsion.id)"
                                >{{divsion.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md" v-if="checkModuleConfigSetting('EL') && locations.length">
                        <div class="form-group">
                            <div class="d-flex justify-content-between align-items-center">
                                <label for="staff_location">Location(s)</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    :title="checkModuleConfigSetting('EML') ? 'Use Ctrl + Click to select multiple locations' : 'Specific locations a staff member belongs to'"
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>

                            <select
                                name="locations[]"
                                id="staff_location"
                                ref="locationsMultiselect"
                                class="form-control form-select-input"
                                :multiple="checkModuleConfigSetting('EML') == 1"
                                @input="setSelectValue('locationsMultiselect','locations')"
                            >
                                <option
                                    :value="location.id"
                                    :id="`location${location.id}`"
                                    v-for="(location, index) in locations" :key="index"
                                    :selected="activeRecord.locations.includes(location.id)"
                                >{{location.title}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="form-group has-label" v-if="checkModuleConfigSetting('EB')">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="staff_bio">Bio</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Add a bio for this staff member"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>

                    <textarea
                        rows="10"
                        name="bio"
                        id="staff_bio"
                        autocomplete="off"
                        class="form-control"
                        @input="setValue('bio')"
                        :value="activeRecord.bio"
                        title="Please enter a bio"
                    />
                </div>

                <div class="row">
                    <div class="col-md form-group">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="required mr-3">Status</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Please select a status for this staff member."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status-active">
                                    <input
                                        value="1"
                                        type="radio"
                                        title="status"
                                        id="status-active"
                                        name="status-active"
                                        class="form-check-input"
                                        @input="setValue('is_active')"
                                        :checked="activeRecord.is_active == 1"
                                        data-invalid-feedback="Please select a status for this staff member."
                                    />
                                    <span class="form-check-sign"></span>
                                    Active
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status-inactive">
                                    <input
                                        value="0"
                                        type="radio"
                                        title="status"
                                        id="status-inactive"
                                        name="status-inactive"
                                        class="form-check-input"
                                        @input="setValue('is_active')"
                                        :checked="activeRecord.is_active == 0"
                                        data-invalid-feedback="Please select a status for this staff member."
                                    />
                                    <span class="form-check-sign"></span>
                                    Inactive
                                </label>
                            </div>
                            <div class="invalid-feedback">
                                Please select a status for this staff member.
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md form-group">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="sort_order">Sort</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The order in which staff will be displayed in on the frontend listing."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            id="sort_order"
                            v-mask="'######'"
                            name="sort_order"
                            class="form-control"
                            v-model="activeRecord.sort_order"
                            title="Please enter the order in which staff will be  displayed in on the frontend listing"
                        />
                        <div class="invalid-feedback">
                            Please enter a staff order.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0" v-if="checkModuleConfigSetting('EI')">
                <p class="h6">Upload Image</p>

                <div class="mt-2">
                    <div class="alert alert-info" v-if="showResizeImageMessage">
                        <p class="m-0">Image will be resized after saving.</p>
                    </div>
                    <div class="border rounded staff-image d-flex align-items-center justify-content-center p-1 bg-white">
                        <img :src="activeRecord.image_path" v-if="activeRecord.image_path">
                        <div class="no-image" v-else>
                            <span class="fas fa-image"></span>
                        </div>
                    </div>
                    <div class="form-group has-label mt-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="image_path">Staff Image</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                :title="`An image for the staff member. Accepted file types include ${moduleConfig['AIE'].split('|').join(', ')}`"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>

                        <input
                            readonly
                            type="text"
                            name="image"
                            id="image_path"
                            autocomplete="off"
                            class="form-control"
                            data-input="image_path"
                            title="Please choose an image"
                            :value="activeRecord.image_path"
                            @click.prevent="openFileManager()"
                        />
                    </div>

                    <div class="d-flex justify-content-end flex-wrap mt-n1 mx-n1 mb-1">
                        <button
                            id='openFileManager'
                            data-input="image_path"
                            @click.prevent="openFileManager()"
                            class="btn m-1 flex-grow-1 btn-tertiary"
                            title="Opens the file manager modal where you can choose to upload a new file or choose from your file library"
                        >Choose File</button>
                        <button
                            id="remove_image"
                            title="Clears the current file"
                            class="btn m-1 flex-grow-1 btn-outline-primary"
                            @click.prevent="SET_VALUE_IN_ACTIVE_RECORD(['image_path', '']);showResizeImageMessage = false"
                        >Delete file</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-primary" :disabled="isFormProcessing">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
        </div>
    </form>
</template>

<script>
    import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
    import { openFileManager } from "@j/core/vpanel/vpanel-functions";

    export default {
        name: 'VpanelAddModifyStaffForm',
        props: [
            'isActionAdd',
            'activeRecord'
        ],
        data(){
            return {
                showResizeImageMessage: false
            }
        },
        computed: {
            ...mapGetters({
                moduleConfig: 'getModuleConfigs'
            }),
            ...mapState({
                formId: state => state.staff.addModify.formId,
                locations: state => state.staff.list.locations,
                divisions: state => state.division.list.tableData,
                errorMessage: state => state.staff.addModify.errorMessage,
                isFormProcessing: state => state.staff.addModify.isFormProcessing,
            })
        },
        methods: {
            ...mapActions('staff/addModify', [
               'submitForm'
            ]),
            ...mapMutations('staff/addModify', [
                'SET_VALUE_IN_ACTIVE_RECORD'
            ]),
            checkModuleConfigSetting(setting){
                return parseInt(this.moduleConfig[setting]);
            },
            openFileManager() {
                openFileManager(event.target, (fmObject) => {
                    this.SET_VALUE_IN_ACTIVE_RECORD(['image_path', fmObject[0].url]);
                    this.showResizeImageMessage = true;
                });
            },
            setValue(prop) {
                this.SET_VALUE_IN_ACTIVE_RECORD([prop, event.target.value])
            },
            setSelectValue(ref,prop) {
                let array = [...this.$refs[ref].selectedOptions].map(({value}) => Number(value));
                this.SET_VALUE_IN_ACTIVE_RECORD([prop, array])
            },
            submitStaffForm(){
                let addCommit      = this.isActionAdd ? 'staff/addModify/SET_ACTIVE_RECORD' : null;
                let addModifyRoute = this.isActionAdd ? '/vpanel/modules/staff/store' : '/vpanel/modules/staff/update';
                let routerPath     = this.isActionAdd ? 'Modify Staff' : 'Staff List';

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    isUsingModal: false,
                    routerPath: routerPath,
                    commitPath: addCommit
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .staff-image {
        margin-right: 1rem;

        img, svg {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .no-image {
            font-size: 32px;
            position: relative;
            width: 100%;
            padding-top: 56.25%;

            > span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
</style>