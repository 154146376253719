<template>
    <div class="datatable-section p-2">
		<div class="mb-2">
            <VpanelBlogAddNewRecordButton namespace="blog/addModify" button-text="Add a New Blog" router-path="Add Blog" />
		</div>

        <table id="datatable" class="table table-striped table-bordered dataTable dtr-inline w-100" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="cspNonce">
            <thead>
                <tr role="row">
                    <th class="table-head" tabindex="0" aria-controls="datatable">Name</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Categories</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Publish Date</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Comments Allowed</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Featured</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Options</th>
                </tr>
            </thead>
        </table>

        <!-- Delete Post Modal -->
        <VpanelBlogConfirmRecycleModal
            namespace="blog"
            heading-text="Confirm Delete Post"
            modal-id="blog-post-recycle-modal"
            ajax-route="/vpanel/modules/blog/destroy"
            after-recycle-dispatch-action="blog/list/removeRecordFromDataTable"
        />
	</div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'VpanelBlogList',
        created(){
            document.addEventListener('click', this.buttonEvent);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.buttonEvent);
        },
        computed: {
            ...mapState('blog/list', {
                cspNonce: state => state.cspNonce,
                tableData: state => state.tableData
            }),
        },
        methods: {
            ...mapMutations('blog/addModify', [
                'SET_ACTIVE_RECORD'
            ]),
            ...mapActions('blog/addModify', [
                'setFormIsAdd'
            ]),
            ...mapActions('blog/recycle', [
                'openModal'
            ]),
            buttonEvent(event) {
                if (event.target.closest('.datatable-options')) {
                    const blogId = event.target.closest('.datatable-options').dataset.id;

                    // find the selected record from the array of table data
                    let activeRecord = this.tableData.find(blog => blog.id == blogId);

                    // set active record in the store
                    this.SET_ACTIVE_RECORD(activeRecord);

                    // Recycle blog
                    if (event.target.classList.contains('recycle-blog')) {
                        event.preventDefault();
                        // open modal
                        this.openModal(null);
                    }

                    // Modify blog
                    if (event.target.classList.contains('modify-blog')) {
                        event.preventDefault();
                        // set form action to modify
                        this.setFormIsAdd(false);
                        this.$router.push({ name: 'Modify Blog', params: { id: blogId }});
                    }

                    if (event.target.classList.contains('comment-blog')) {
                        event.preventDefault();
                        this.$router.push({ name: 'Blog Comment List', params: { id: blogId } });
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dataTable {
        &::v-deep {
            .categories {
                p, span {
                    font-size: 14px;
                    line-height: 1.25;
                }
            }
        }
    }
</style>