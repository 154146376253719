<template>
    <table id="datatable" class="table table-striped table-bordered dataTable dtr-inline" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="cspNonce">
        <thead>
            <tr role="row">
                <th class="table-head" tabindex="0" aria-controls="datatable">Blog Name</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Comment Author</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Last Modified</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Status</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Options</th>
            </tr>
        </thead>
    </table>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelBlogCommentList',
        created(){
            this.addClickEventsToCommentListButtons();
        },
        computed: {
            ...mapState('comment/list', {
                cspNonce: state => state.cspNonce,
            })
        },
        methods: {
            ...mapActions('comment/list', [
                'addClickEventsToCommentListButtons'
            ])
        }
    }
</script>