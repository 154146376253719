<template>
    <div class="d-flex flex-row justify-content-center align-items-center h-100">
        <div class="d-flex flex-column align-items-end">
            <p class="display-1 font-weight-bold m-0 lh-xs">404</p>
            <p class="m-0 text-30 font-weight-bold lh-xs">Page Not Found</p>
        </div>
        <p class="my-0 ml-4 display-1 lh-xs">🤔</p>
    </div>
</template>
<script>
    export default {
        name: 'Vpanel404Page'
    }
</script>