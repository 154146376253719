import tinycolor from 'tinycolor2';

export const themingMixin = {
    props: {
        designConfig: { type: Object },
        primaryColor: { type: String },
        secondaryColor: { type: String },
        currentPageTitle: { type: String },
        pageTitleImage: { type: String },
        pageUuid: { type: String },
    },
    computed: {
        themePrimaryColor() {
            return this.primaryColor || this.designConfig?.theme?.primary_color || '#ffffff';
        },
        themeSecondaryColor() {
            return this.secondaryColor || this.designConfig?.theme?.secondary_color || '#000000';
        },
        contrastRatio() {
            let ratioFull = tinycolor.readability(this.themePrimaryColor, this.themeSecondaryColor);
            return Math.round(ratioFull * Math.pow(10, 2)) / Math.pow(10, 2);
        },
        themeComplement() {
            return tinycolor(this.themeSecondaryColor).toRgb();
        },
        themeColorCompliant() {
            return tinycolor.mostReadable(this.themePrimaryColor, ['#000', '#fff']).toRgb();
        },
        themeComplementCompliant() {
            return tinycolor.mostReadable(this.themeSecondaryColor, ['#000', '#fff']).toRgb();
        },
        optimumOnWhite() {
            if (tinycolor.isReadable('#fff', this.themePrimaryColor)) return tinycolor(this.themePrimaryColor).toRgb();
            if (tinycolor.isReadable('#fff', this.themeSecondaryColor)) return tinycolor(this.themeSecondaryColor).toRgb();
            return tinycolor('black').toRgb();
        },
        optimumOnBlack() {
            if (tinycolor.isReadable('#000', this.themePrimaryColor)) return tinycolor(this.themePrimaryColor).toRgb();
            if (tinycolor.isReadable('#000', this.themeSecondaryColor)) return tinycolor(this.themeSecondaryColor).toRgb();
            return tinycolor('white').toRgb();
        },
        logoPath() {
            return this.designConfig?.theme?.logo || '/images/custom/site/logo.png';
        },
        reversedLogoPath() {
            let logo = this.logoPath, // If reversed logo is not set or the background where it is to be used is too light, use original logo
                tccil = tinycolor(this.themeComplementCompliant).isLight();
            if (tccil) {
                logo = null; // if background is dark but reversed logo is not set, set null
                if (this.designConfig?.theme?.reverse_logo) logo = this.designConfig.theme.reverse_logo; // if background is dark and reversed logo is set, use reversed logo
            }
            return logo;
        },
        themeProperties() {
            return {
                '--themeColor':                            this.getPlainHSL(tinycolor(this.themePrimaryColor).toHsl()),
                '--themeColor-hsl':                        tinycolor(this.themePrimaryColor).toHslString(),
                '--themeCompliant':                        this.getPlainHSL(tinycolor(this.themeColorCompliant).toHsl()),
                '--themeCompliant-hsl':                    tinycolor(this.themeColorCompliant).toHslString(),
                '--themeComplement':                       this.getPlainHSL(tinycolor(this.themeComplement).toHsl()),
                '--themeComplement-h':                     tinycolor(this.themeComplement).toHsl()['h'] + 'deg',
                '--themeComplement-s':                     tinycolor(this.themeComplement).toHsl()['s'] * 100 + '%',
                '--themeComplement-l':                     tinycolor(this.themeComplement).toHsl()['l'] * 100 + '%',
                '--themeComplement-hsl':                   tinycolor(this.themeComplement).toHslString(),
                '--themeComplement-minOpacityOnCompliant': this.evaluateMinOpacityOnCompliant(this.themeComplement),
                '--themeComplementCompliant':              this.getPlainHSL(tinycolor(this.themeComplementCompliant).toHsl()),
                '--themeComplementCompliant-h':            tinycolor(this.themeComplementCompliant).toHsl()['h'] + 'deg',
                '--themeComplementCompliant-s':            tinycolor(this.themeComplementCompliant).toHsl()['s'] * 100 + '%',
                '--themeComplementCompliant-l':            tinycolor(this.themeComplementCompliant).toHsl()['l'] * 100 + '%',
                '--themeComplementCompliant-hsl':          tinycolor(this.themeComplementCompliant).toHslString(),
                '--optimumOnWhite':                        this.getPlainHSL(tinycolor(this.optimumOnWhite).toHsl()),
                '--optimumOnWhite-hsl':                    tinycolor(this.optimumOnWhite).toHslString(),
                '--optimumOnBlack':                        this.getPlainHSL(tinycolor(this.optimumOnBlack).toHsl()),
                '--optimumOnBlack-hsl':                    tinycolor(this.optimumOnBlack).toHslString(),
                '--displayFontFamily':                     this.designConfig?.theme?.display_font || "'Quicksand', sans-serif",
                '--bodyFontFamily':                        this.designConfig?.theme?.body_font || "'Montserrat', sans-serif",
            };
        }
    },
    methods: {
        getPlainHSL(hsl) {
            return `${hsl.h}deg, ${hsl.s * 100}%, ${hsl.l * 100}%`;
        },
        evaluateMinOpacityOnCompliant(color) {
            let bw         = ['#000', '#fff'],
                compliant  = tinycolor.mostReadable(color, bw),
                mixPercent = 0,
                cont       = true;

            while (cont) {
                let clone = compliant.clone(),
                    mixed = tinycolor.mix(clone, tinycolor.mostReadable(compliant, bw), mixPercent),
                    ratio = tinycolor.readability(color, mixed);

                if (ratio > 4.51) {
                    mixPercent++;
                } else {
                    cont = false;
                    return 1 - ((mixPercent - 1) / 100);
                }
            }
        }
    },
    mounted() {
        if (this.designConfig && this.themeProperties) {
            Object.entries(this.themeProperties).forEach(([prop, val]) => {
                document.documentElement.style.setProperty(prop, val);
            })
        }
    }
}

export default { themingMixin }