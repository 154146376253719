<template>
    <div>
        <div class="form-group has-label">
            <!-- Product Manufacturer Name -->
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="product_manufacturer_name">Name</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Provide a manufacturer name."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                name="name"
                class="form-control"
                id="product_manufacturer_name"
                :value="activeRecord.name"
                @input="$emit('update', { prop: 'name' })"
                title="Please enter the name of the product manufacturer"
                data-invalid-feedback="Please enter a product manufacturer name."
            />
            <div class="invalid-feedback">
                Please enter a product manufacturer name.
            </div>
        </div>

        <!-- Product Manufacturer Description -->
        <div class="form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="wysiwyg">Description</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Provide a manufacturer description that will help customers understand it better."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                type="text"
                id="wysiwyg"
                class="form-control"
                name="manufacturer_description"
                :value="activeRecord.description"
                data-invalid-feedback="Please enter a description."
                title="Please provide additional product manufacturer details"
            />
            <div class="invalid-feedback">
                Please enter a description.
            </div>
        </div>

        <!-- Product Manufacturer Status -->
        <div class="form-group">
            <div class="radio-legend d-flex justify-content-between align-items-center">
                <span class="mr-3">Status<span class="required"></span></span>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Active = visible to customers. Inactive = not visible to customers."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <fieldset>
                <legend class="label sr-only">Status</legend>
                <div class="form-check form-check-inline form-check-radio">
                    <label class="form-check-label" for="status_yes">
                        <input
                            checked
                            required
                            :value="1"
                            type="radio"
                            name="status"
                            id="status_yes"
                            class="form-check-input"
                            v-model="activeRecord.status"
                            title="Please choose an option"
                            @change="$emit('update', { prop: 'status', value: 1 })"
                            data-invalid-feedback="Please indicate if the product manufacturer is active or inactive."
                        />
                        <span class="form-check-sign"></span>
                        Active
                    </label>
                </div>
                <div class="form-check form-check-inline form-check-radio">
                    <label class="form-check-label" for="status_no">
                        <input
                            required
                            :value="0"
                            type="radio"
                            name="status"
                            id="status_no"
                            class="form-check-input"
                            v-model="activeRecord.status"
                            title="Please choose an option"
                            @change="$emit('update', { prop: 'status', value: 0 })"
                            data-invalid-feedback="Please indicate if the product manufacturer is active or inactive."
                        />
                        <span class="form-check-sign"></span>
                        Inactive
                        <div class="invalid-feedback">
                            Please indicate if the product category is active or inactive.
                        </div>
                    </label>
                </div>
            </fieldset>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'VpanelProductManufacturerGeneralDetailsForm',
        props: [
            'activeRecord',
        ],
        mounted(){
            CKEDITOR.replace('wysiwyg', {
                removePlugins: 'maximize,resize'
            });

            if(this.activeRecord.description){
                CKEDITOR.instances['wysiwyg'].setData(this.activeRecord.description);
            }
        }
    }
</script>