import { render, staticRenderFns } from "./ModuleNavigationLinks.vue?vue&type=template&id=721458e1&"
import script from "./ModuleNavigationLinks.vue?vue&type=script&lang=js&"
export * from "./ModuleNavigationLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports