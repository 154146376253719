<template>
    <div class="list-item">
        <div class="content">
            <p class="title" @click="detailsClicked()" title="Opens location details">{{ location.title }}</p>
            <frontend-location-short-hours
                v-for="(hours, index) in location.hours"
                :key="index + '_li'"
                :hours="hours"
            />
        </div>
        <div class="actions">
            <button
                class="icon-button preferred-location"
                :class="{active: preferredIndex == id}"
                @click="setPreferred(id)"
            >
                <span v-show="preferredIndex == id"><span class="fas fa-star"></span></span>
                <span v-show="preferredIndex != id"><span class="far fa-star"></span></span>
            </button>
            <button
                class="icon-button"
                title="Opens location details"
                @click="detailsClicked()"
            ><span class="fas fa-info-circle"></span></button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ["location", "id"],
    computed: {
        ...mapState('location', [
            'preferredIndex'
        ]),
    },
    methods: {
        ...mapActions('location', [
            'setPreferred',
        ]),
        detailsClicked() {
            // click the associated marker
            let marker = document.querySelector(`.map [role="button"][aria-label="${this.location.title}"]`);
            if (marker) marker.click();
        }
    }
}
</script>

<style lang="scss" scoped>
    .list-item {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: .5rem .75rem;
        border-bottom: 1px solid var(--light);

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: .25rem .25rem .25rem 0;

            .title, .hour-overview {
                margin: 0;
                color: var(--dark);
                line-height: 1.1;
            }

            .title {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: .5rem;
                cursor: pointer;
            }

            .hour-overview { font-size: 12px; }
        }

        .actions {
            display: flex;
            align-items: stretch;
        }
    }
</style>