<template>
	<div class="datatable-section p-2">
		<div class="mb-2">
            <VpanelBlogAddNewRecordButton namespace="tag/addModify" button-text="Add a New Tag" />
		</div>

        <VpanelBlogTagList />

		<VpanelAddModifyTagModal />

        <!-- Delete Post Tag Modal -->
        <VpanelBlogConfirmRecycleModal
            namespace="tag"
            heading-text="Confirm Delete Tag"
            modal-id="blog-tag-recycle-modal"
            ajax-route="/vpanel/modules/blog/tag/destroy"
            after-recycle-dispatch-action="tag/list/removeRecordFromDataTable"
        />
	</div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: "VpanelBlogTagIndex",
        created() {
            this.fetchTags();
        },
        methods: {
            ...mapActions("tag", {
                fetchTags: "list/fetchTags",
            })
        }
    };
</script>