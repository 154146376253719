<template>
    <div>
        <input
            type="search"
            class="st-search"
            v-if="searchable"
            :value="searchModel"
            placeholder="Search..."
            v-on="searchBind.listeners"
            v-bind="searchBind.attributes"
        />

        <slot></slot>

        <div class="st-bottom">
            <p>{{ rangeText }}{{ selectedText }}</p>
            <Pagination
                :active-page="activePage"
                :item-count="itemCount"
                :items-per-page="itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "TableWrapper",
        props: {
            itemCount: {
                type: Number,
                required: true
            },
            activePage: {
                type: Number,
                required: true
            },
            itemsPerPage: {
                type: Number,
                required: true
            },
            searchable: {
                type: Boolean,
                default: true
            },
            selectedCount: {
                type: Number
            },
            searchModel: {
                type: String
            }
        },
        methods: {
            updateActivePage(page) {
                this.$emit('update-page', page);
            },
            emitSearch() {
                this.$emit('on-search', event.target.value);
            }
        },
        computed: {
            rangeText() {
                let [count, per, active] = [this.itemCount, this.itemsPerPage, this.activePage],
                    lastPage   = active>count/per,
                    rangeEnd   = lastPage?count:active*per,
                    rangeStart = lastPage?count-((count%per)-1):(active*per)-(per-1);

                if (count == 0) return '0 results';
                return `Showing ${rangeStart} to ${rangeEnd} of ${count} entries`;
            },
            selectedText() {
                if (this.selectedCount) {
                    return ` - (${this.selectedCount} row${this.selectedCount>1?'s':''} selected)`;
                }
            },
            // Firefox had to go and not support the incremental attribute,
            // adding this to dynamically switch the event listener and attribute when it's not supported
            searchBind() {
                let input  = document.createElement('input');
                input.type = "search";
                let check  = input.incremental || input.incremental === false,
                    result = { "attributes": {}, "listeners": {} };
                if (check) result.attributes["incremental"] = "incremental";
                result.listeners[check ? 'search' : 'input'] = this.emitSearch;
                return result;
            }
        }
    }
</script>

<style lang="scss">
    @import './scss/_table-styles.scss';
</style>