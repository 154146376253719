<template>
    <div class="fb-fieldset" :class="validClass" role="group" :aria-labelledby="`question-${questionId}-label${ validClass === 'fb-invalid' ? ' fb_assistive_${questionId}' : ''}`">
        <p class="fb-legend" :id="`question-${questionId}-label`">{{ question.label }}<span class="fb-required" v-if="required" aria-label="required">*</span></p>

        <div class="fb-options-chip" ref="options">
            <label class="fb-group-chip" v-for="optionId in question.options" :key="`option_${optionId}`">
                <input
                    class="fb-control"
                    v-bind="controlBinder"
                    :type="type"
                    :value="optionId"
                    @change="toggleIfTrigger(optionId)"
                    @focus="focusQuestion(questionId)"
                    @blur="focusQuestion(); checkValue()"
                />
                <span class="fb-option">{{ options[optionId].value }}</span>
            </label>
        </div>
        <SiteFormBuilderAssistiveText
            :message="requiredMessage || defaultRequiredMessage"
            :id="`fb_assistive_${questionId}`"
        />
    </div>
</template>

<script>
import { general, hasOptions, questionChute, questionLadder } from '../mixins/SiteFormBuilderControlMixins';

export default {
    mixins: [ general, hasOptions, questionChute, questionLadder ],
    props: {
        type: {
            type: String,
            default: 'radio'
        },
        defaultRequiredMessage: {
            type: String,
            default: 'Please select an option'
        },
        customValidationFunc: {
            type: Function,
            default(vm) {
                if (!vm.required) return -1;
                return Array.from(vm.$refs.options.querySelectorAll('input')).some(i => i.checked);
            }
        }
    },
    methods: {
        toggleIfTrigger(id) {
            this.checkValue();
        },
        validateFunc(vm) {
            return this.customValidationFunc(vm);
        }
    }
}
</script>