<template>
    <div class="general-details-form">
        <div class="form-group has-label">
            <!-- Product Category Name -->
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="product_category_name">Name</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Provide a category name."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                name="name"
                class="form-control"
                id="product_category_name"
                :value="activeRecord.name"
                @input="$emit('update', { prop: 'name' })"
                title="Please enter the name of the product category"
                data-invalid-feedback="Please enter a product category name."
            />
            <div class="invalid-feedback">
                Please enter a product category name.
            </div>
        </div>

        <!-- Product Category Description -->
        <div class="form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="wysiwyg">Description</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Provide a category description that will help customers understand it better."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                type="text"
                id="wysiwyg"
                class="form-control"
                name="category_description"
                :value="activeRecord.description"
                data-invalid-feedback="Please enter a description."
                title="Please provide additional product category details"
            />
            <div class="invalid-feedback">
                Please enter a description.
            </div>
        </div>


        <div class="d-md-flex align-items-start wrap-gap" style="--gap:30px;--gap-y:15px;">
            <div class="flex-grow-1" style="padding-top:6px">
                <div class="wrap-gap">
                    <!-- Product Category Status -->
                    <div class="form-group flex-grow-1">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="mr-3">Status<span class="required"></span></span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Active = visible to customers. Inactive = not visible to customers."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_yes">
                                    <input
                                        checked
                                        required
                                        :value="1"
                                        type="radio"
                                        name="status"
                                        id="status_yes"
                                        class="form-check-input"
                                        v-model="activeRecord.status"
                                        title="Please choose an option"
                                        @change="$emit('update', { prop: 'status', value: 1 })"
                                        data-invalid-feedback="Please indicate if the product category is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Active
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_no">
                                    <input
                                        required
                                        :value="0"
                                        type="radio"
                                        name="status"
                                        id="status_no"
                                        class="form-check-input"
                                        v-model="activeRecord.status"
                                        title="Please choose an option"
                                        @change="$emit('update', { prop: 'status', value: 0 })"
                                        data-invalid-feedback="Please indicate if the product category is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Inactive
                                    <div class="invalid-feedback">
                                        Please indicate if the product category is active or inactive.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                    <!-- Product Category Show In Nav -->
                    <div class="form-group flex-grow-1" v-if="activeRecord.status">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="mr-3">Show In Nav?<span class="required"></span></span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Choose to show in nav if you want to provide a link to the category from your primary navigation. Only 5 catalog-driven category navigation links are allowed."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Show In Nav?</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="show_in_nav_yes">
                                    <input
                                        :value="1"
                                        type="radio"
                                        id="show_in_nav_yes"
                                        class="form-check-input"
                                        name="show_in_navigation"
                                        title="Please choose an option"
                                        v-model="activeRecord.show_in_nav"
                                        @change="$emit('update', { prop: 'show_in_nav', parseAsInt: true })"
                                        data-invalid-feedback="Please indicate if the a page should be created for this category."
                                    />
                                    <span class="form-check-sign"></span>
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="show_in_nav_no">
                                    <input
                                        required
                                        :value="0"
                                        type="radio"
                                        id="show_in_nav_no"
                                        class="form-check-input"
                                        name="show_in_navigation"
                                        title="Please choose an option"
                                        v-model="activeRecord.show_in_nav"
                                        @change="$emit('update', { prop: 'show_in_nav', parseAsInt: true })"
                                        data-invalid-feedback="Please indicate if the a page should be created for this category."
                                    />
                                    <span class="form-check-sign"></span>
                                    No
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <!-- Order -->
            <div class="form-group has-label" style="width:calc(7ch + 2rem);" v-if="activeRecord.status && activeRecord.show_in_nav">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="page_order_in_nav" class="required">Order</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="This is the place in which the nav link will be displayed in the site navigation. The lowest number will be the closest to top."
                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                </div>
                <input
                    required
                    type="number"
                    class="form-control"
                    id="page_order_in_nav"
                    name="page_order_in_nav"
                    :value="activeRecord.page_order_in_nav"
                    title="Please enter the order of this category page"
                    @input="$emit('update', { prop: 'page_order_in_nav'})"
                />
            </div>
        </div>

        <div class="alert alert-info" v-if="!activeRecord.status && activeRecord.show_in_nav">
            <p class="m-0">If category is inactive, it will not show in nav</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VpanelProductCategoryGeneralDetailsForm',
        props: [
            'activeRecord',
        ],
        mounted(){
            CKEDITOR.replace('wysiwyg', {
                removePlugins: 'maximize,resize'
            });

            if(this.activeRecord.description){
                CKEDITOR.instances['wysiwyg'].setData(this.activeRecord.description);
            }
        }
    }
</script>