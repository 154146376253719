<template>
    <TableWrapper
        :searchable="false"
        v-on="wrapperEvents"
        v-bind="wrapperBinder"
        :item-count="totalItems"
        :active-page="currentPage"
        @update-page="setCurrentPageToSession($event);updateTableData(false);"
    >
        <!--Custom Table Search-->
        <VpanelEcommerceTableSearch namespace="masterCatalog/list" @searched="setSearchTermToSessionStorage($event);updateTableData(true);" />

        <div class="simple-table" style="--cols:5;">
            <div class="first-col st-header">
                <label class="m-0" for="select_all_mc_products_on_page">
                    <span v-show="selectedStatus('mc_id') === -1"><span class="far fa-square"></span></span>
                    <span v-show="selectedStatus('mc_id') === 0"><span class="fas fa-minus-square"></span></span>
                    <span v-show="selectedStatus('mc_id') === 1"><span class="fas fa-check-square"></span></span>
                    <input
                        type="checkbox"
                        class="visually-hidden"
                        id="select_all_mc_products_on_page"
                        @change.prevent="selectAllUnadded('mc_id')"
                        :checked="selectedStatus('mc_id') >= 0"
                        title="This will only select the current products displayed on this page"
                    />
                </label>
            </div>

            <p class="st-header">Name</p>
            <p class="st-header">Category</p>
            <p class="st-header">Manufacturer</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="(row) in displayedList">
                    <div class="first-col" :key="row.mc_id+'select'">
                        <label class="m-0" v-if="!row.already_in_catalog">
                            <span v-show="!isRowSelected(row.mc_id)"><span class="far fa-square"></span></span>
                            <span v-show="isRowSelected(row.mc_id)"><span class="fas fa-check-square"></span></span>
                            <input
                                :value="row.mc_id"
                                type="checkbox"
                                name="selectedRows[]"
                                class="visually-hidden"
                                :checked="isRowSelected(row.mc_id)"
                                @change.prevent="selectRow(row.mc_id)"
                            />
                        </label>
                        <span v-show="row.already_in_catalog">
                            <span class="fas fa-check-circle text-success"></span>
                        </span>
                    </div>

                    <p class="font-weight-bold text-16 mb-0" :key="row.mc_id+'name'">
                        <span class="st-hidden">Name</span>
                        {{ row.name }}
                    </p>

                    <p class="mb-0" :key="row.mc_id+'category'">
                        <span class="st-hidden">Category</span>
                        {{ row.category }}
                    </p>

                    <p class="mb-0" :key="row.mc_id+'manufacturer'">
                        <span class="st-hidden">Manufacturer</span>
                        {{ row.manufacturer }}
                    </p>

                    <div class="last-col" :key="row.mc_id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <button class="btn btn-secondary-blue" @click.prevent="tableButtonAction({activeRecord: row, actionType: 'view'})">View</button>
                            <VpanelSingleAddMasterCatalogProductButton :selected-record="row" />
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        mixins: [tableMixin],
        props: [
            'totalItems',
            'currentPage'
        ],
        watch: {
            selectedRows: {
                deep: true,
                handler(){
                    this.setSelectedTableRows(this.selectedRows);
                }
            }
        },
        methods: {
            ...mapActions('masterCatalog/list', [
                'updateTableData',
                'tableButtonAction',
                'setSelectedTableRows',
                'setCurrentPageToSession',
                'setSearchTermToSessionStorage'
            ]),
            selectAllUnadded(uid) {
                if (this.selectedRows.length) {
                    this.selectAll(uid);
                } else {
                    this.sortedList.forEach(row => {
                        if (row.already_in_catalog) return;
                        this.selectRow(row[uid]);
                    })
                }
            },
            status(row) {
                return row.status ? 'Active' : 'Inactive';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: minmax(min-content, max-content) repeat(calc(var(--cols) - 2), minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>