<template>
    <div class="marketing-message-layout-form">
        <div class="form-group has-label">
            <!-- Marketing Message Title -->
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="mm_title">Title</label>
            </div>
            <input
                required
                type="text"
                name="title"
                id="mm_title"
                class="form-control"
                :value="activeRecord.title"
                @input="$emit('update', { prop: 'title' })"
                title="Please enter the title of this marketing message"
                data-invalid-feedback="Please enter a  marketing message title."
            />
            <div class="invalid-feedback">
                Please enter a  marketing message title.
            </div>
        </div>

        <!-- Marketing Message Tagline -->
        <div class="form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                    <label :class="taglineRequired ? 'required' : ''" for="mm_tagline">Tagline</label>
                    <p class="my-0 ml-3 text-info text-14">
                        <span>{{ totalTaglineCharacters }}</span> Characters Remaining
                    </p>
                </div>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="This is secondary text that will show under the title"
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <textarea
                rows="8"
                type="text"
                name="tagline"
                id="mm_tagline"
                maxlength="255"
                class="form-control"
                :value="activeRecord.tagline"
                title="Please enter a tagline"
                :required="taglineRequired ? true : false"
                @input="$emit('update', { prop: 'tagline' })"
                @keyup="updateCharCount(activeRecord.tagline)"
            ></textarea>
            <div v-if="taglineRequired" class="invalid-feedback">
                Please enter a tagline.
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VpanelMarketingMessageLayoutForm',
        props: [
            'activeRecord',
            'taglineRequired'
        ],
        data() {
			return {
                totalTaglineCharacters: 255
			}
        },
        mounted(){
            if(this.activeRecord.tagline){
                this.updateCharCount(this.activeRecord.tagline);
            }
        },
        methods: {
            updateCharCount(inputValue){
                this.totalTaglineCharacters = (255 - inputValue.length);
            }
        }
    }
</script>