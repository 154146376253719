<template>
    <button
        class="preferred-location"
        :class="{active: preferredIndex == id}"
        @click="setPreferred(id)"
    >
        <span v-show="preferredIndex == id"><span class="fas fa-star"></span>Preferred Location</span>
        <span v-show="preferredIndex != id"><span class="far fa-star"></span>Make this my preferred location</span>
    </button>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ["id"],
    computed: {
        ...mapState('location', [
            'preferredIndex',
        ])
    },
    methods: {
        ...mapActions('location', [
            'setPreferred'
        ])
    }
}
</script>

<style lang="scss" scoped>
    .preferred-location {
        border: 0;
        border-radius: 3px;
        background-color: var(--light);
        color: var(--dark);
        font-size: 14px;
        padding: .25rem .5rem;
        line-height: 1;
        margin-bottom: 1rem;
        transition: 200ms ease-out;

        &.active {
            background-color: var(--themeColor-hsl);
            color: var(--themeCompliant-hsl);
        }

        > span {
            display: flex;
            align-items: center;
        }

        svg {
            margin-right: .5em;
            font-size: 12px;
        }
    }
</style>