<template>
    <nav
        class="vue-nav-2"
        :data-expanded="isExpanded"
        :data-headertemplate="template"
        :data-navtemplate="navTemplate"
    >
        <div class="mobile-controls">
            <p class="close-mobile-menu" @click="closeMobileMenu">
                <span class="fas fa-chevron-left"></span>
            </p>
        </div>

        <div class="vn-family d-none d-md-flex align-items-center p-1">
            <VueSiteSearch
                search-template="PowerwebTemplate1"
            />
        </div>

        <template v-for="(link, index) in headerLinks">
            <div
                v-if="link"
                :key="`hidden-link-${index}`"
                class="vn-family d-block d-md-none"
                data-depth="0"
            >
                <VueLink
                    :link-obj="link"
                    class="vn-item"
                    data-depth="0"
                />
            </div>
        </template>


        <template v-for="ni in navInfo">
            <VueNavItem
                :depth="0"
                :nav-item="ni"
                :key="ni.page.uuid"
                v-if="ni.page.show_in_nav"
                :current-page-id="currentPageId"
            ></VueNavItem>
        </template>
    </nav>
</template>

<script>
export default {
    props: [
        "currentPageId",
        "mobileExpanded",
        "navInfo",
        "navTemplate",
        "template",
        "headerLinks"
    ],
    computed: {
        isExpanded() {
            return this.mobileExpanded;
        }
    },
    methods: {
        closeMobileMenu(event) {
            this.$emit('close-mobile-menu');
        }
    }
}
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../../sass/vue-import';

    $base-hsl: white;
    $accent: var(--optimumOnWhite);
    $accent-hsl: var(--optimumOnWhite-hsl);
    $accentCompliant: var(--optimumOnWhiteCompliant);
    $accentCompliant-hsl: var(--optimumOnWhiteCompliant-hsl);

    .vue-nav-2 {
        z-index: 4;

        &::v-deep {
            .show-site-search {
                color: $accentCompliant-hsl;
            }

            @include desktop {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                justify-content: flex-end;
                margin-left: 1rem;
                @include fluid-size(-8px, -16px, margin-right, $min-vw: $lg-screen, $max-vw: 1400px);

                .mobile-controls { display: none }

                // Global Styling
                .vn-item {
                    cursor: pointer;
                    margin: 0;
                    line-height: 1.25;
                    color: $accentCompliant-hsl;
                    text-decoration: none;

                    &:hover { text-decoration: underline; }
                }

                // Root Level Families
                [data-depth="0"] {
                    &.vn-family {
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        position: relative;

                        // When hovering over a family...
                        &:hover {
                            [data-depth="0"] {
                                &.vn-item { border-color: hsla($accent, 1); }
                                &.vn-children { display: flex; }
                            }
                        }

                        // If family is current and in the top level nav
                        &[data-current] [data-depth="0"].vn-item:before { width: calc(100% - 2rem); }
                    }

                    &.vn-item {
                        @include fluid-size(16px, 18px, font-size, $min-vw: $lg-screen, $max-vw: 1400px);
                        @include fluid-size(8px, 16px, padding-left, $min-vw: $lg-screen, $max-vw: 1400px);
                        @include fluid-size(8px, 16px, padding-right, $min-vw: $lg-screen, $max-vw: 1400px);
                        @include fluid-size(4px, 8px, padding-top, $min-vw: $lg-screen, $max-vw: 1400px);
                        @include fluid-size(4px, 8px, padding-bottom, $min-vw: $lg-screen, $max-vw: 1400px);
                        position: relative;
                        display: flex;
                        align-items: center;
                        color: $accentCompliant-hsl;
                        text-decoration: none;
                        border-bottom: solid 3px hsla($accent, 0);
                        transition: border-bottom 200ms ease-out;
                    }

                    &.vn-children {
                        display: none;
                        flex-direction: column;
                        align-items: stretch;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 0 1rem;
                        width: max-content;
                        min-width: 200px;
                        background-color: $base-hsl;
                        border-bottom: solid 5px $accent-hsl;
                        z-index: 1;
                        filter: drop-shadow(0 2px 5px rgba($accentCompliant, .1));

                        &:before {
                            content: "";
                            position: absolute;
                            bottom: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 20px;
                            height: 10px;
                            background-color: $base-hsl;
                            clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
                        }
                    }
                }

                // First Level Families
                [data-depth="1"] {
                    &.vn-family {
                        flex: 1 1 0;
                        display: flex;
                        flex-direction: column;

                        + .vn-family {
                            border-top: 2px solid hsla($accentCompliant, .1);
                        }
                    }

                    &.vn-item {
                        font-size: 16px;
                        padding: .5rem 0;
                        text-align: center;
                        color: $accentCompliant-hsl;
                    }
                }

                // Second Level Families
                [data-depth="2"] {
                    &.vn-item {
                        display: block;
                        font-size: 14px;
                        padding: .125rem 0 .125rem .5rem;
                    }
                }
            }

            @include not-desktop {
                position: fixed;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                background-color: $base-hsl;
                height: 100vh;
                width: 100%;
                top: 0;
                right: -100%;
                transition: 200ms ease-out;

                &[data-expanded] { right: 0; }

                .mobile-controls {
                    display: flex;
                    background-color: $accent-hsl;

                    .close-mobile-menu {
                        color: $base-hsl;
                        margin: 0;
                        padding: 1rem;
                    }
                }

                .vn-item {
                    color: $accent-hsl;
                    font-family: "benton-sans-condensed", sans-serif;
                    line-height: 1;
                    margin-right: calc(var(--hc-circle-size) + 2rem);
                    display: inline-block;
                    position: relative;
                    word-break: break-word;
                    hyphens: auto;
                }

                .vn-family {
                    & > .vn-children {
                        max-height: 0;
                        height: max-content;
                        overflow: hidden;
                        transition: 200ms ease-out;
                    }

                    &[data-expanded] > .vn-children {
                        max-height: 50vh;
                        transition: 200ms ease-in;
                        border-top: 1px solid hsla($accent, .2);
                    }

                    // Items with children
                    &[data-has-children] {
                        > .vn-item {
                            &:before, &:after {
                                content: '';
                                position: absolute;
                                pointer-events: none;
                                left: calc(100vw - 1rem - var(--hc-circle-size));
                            }

                            &:before {
                                width: var(--hc-circle-size);
                                height: var(--hc-circle-size);
                                top: 50%;
                                transform: translateY(-50%);
                                border-radius: 0;
                                background-color: $accent-hsl;
                            }

                            &:after {
                                // Had to refactor this like 4 times before finding a way that the compiler didn't mess up
                                border: 3px solid;
                                border-color: transparent $base-hsl $base-hsl transparent;
                                height: calc((3 / 8) * var(--hc-circle-size));
                                width: calc((3 / 8) * var(--hc-circle-size));
                                transform: translateY(-25%) rotate(45deg);
                                transform-origin: 50% 50%;
                                top: calc(50% - (var(--hc-circle-size) / 2));
                                margin: calc((var(--hc-circle-size) - ((3 / 8) * var(--hc-circle-size))) / 2);
                                transition: transform .2s ease-out;
                            }
                        }

                        &[data-expanded] > .vn-item:after { transform: translateY(25%) rotate(-135deg); }
                    }
                }

                // Root Level Families
                [data-depth="0"] {
                    &.vn-family {
                        --hc-circle-size: 40px;
                        --hc-arrow-width: 3px;
                        background-color: $base-hsl;
                        border-bottom: 2px solid hsla($accentCompliant, .2);
                        position: relative;
                    }

                    &.vn-item {
                        @include rem(22px);
                        padding: 1rem;
                    }

                    &.vn-children {
                        display: flex;
                        flex-direction: column;
                    }
                }

                [data-depth="1"] {
                    &.vn-family {
                        --hc-circle-size: 30px;
                        --hc-arrow-width: 2px;
                        background-color: $base-hsl;
                        border-bottom: 1px solid hsla($accentCompliant, .2);

                        &:last-child { border-width: 0; }
                    }

                    &.vn-item {
                        @include rem(16px);
                        padding: .5rem 1rem;
                        color: $accentCompliant-hsl;
                    }
                }

                [data-depth="2"] {
                    &.vn-item {
                        @include rem(14px);
                        font-weight: normal;
                        padding: .5rem 1.5rem;
                    }
                }

                [data-depth="3"] { display: none; }
            }
        }
    }

</style>