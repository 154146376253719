<template>
	<div class="vp-tab-nav">
		<router-link
			exact
			class="nav-link"
			active-class="current"
			:to="{ name: 'Blog List' }"
			title="Internal link that opens the blog page"
        >Blogs</router-link>

		<router-link
			exact
			class="nav-link"
			active-class="current"
			:to="{ name: 'Blog Comment List' }"
			title="Internal link that opens the comments page"
        >Comments</router-link>

		<router-link
			exact
			class="nav-link"
			active-class="current"
			:to="{ name: 'Blog Tag List' }"
			title="Internal link that opens the tags page"
        >Tags</router-link>

		<router-link
			exact
			class="nav-link"
			active-class="current"
			:to="{ name: 'Blog Category List' }"
			title="Internal link that opens the categories page"
        >Categories</router-link>
	</div>
</template>

<script>
export default {
	name: "VpanelBlogSecondaryNav",
};
</script>