<template>
    <div class="page-header">
        <div class="page-title">
            <h2 class="page-title-content h5 px-1">{{ pageTitle }}</h2>
            <h2 class="page-title-content vpanel-version h5 px-1">v{{ version }}</h2>
        </div>
        <div class="account-nav">
            <!-- System Info -->
            <vue-link
                link-class="account-nav-icon"
                :link-path="systemInfoRoute"
                link-title="System Info"
                data-toggle="tooltip"
                data-placement="bottom"
                link-icon="fas fa-exclamation-circle"
                v-if="isForbinAdmin"
            >
            </vue-link>

            <!-- System Config Settings -->
            <vue-link
                link-class="account-nav-icon"
                :link-path="systemConfigRoute"
                link-title="Settings"
                data-toggle="tooltip"
                data-placement="bottom"
                link-icon="fas fa-cog"
                v-if="getAdminStatus"
            >
            </vue-link>

            <!-- Logout -->
            <vue-link
                link-class="account-nav-icon logout-btn"
                data-toggle="tooltip"
                data-placement="bottom"
                link-title="Log Out"
                link-icon="fas fa-lock"
                @click.native="logoutUser"
            >
            </vue-link>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'version',
        'pageTitle',
        'logoutRoute',
        'isForbinAdmin',
        'isMasterAdmin',
        'systemInfoRoute',
        'systemConfigRoute',
        'isSettingsManager',
    ],
    computed: {
        getAdminStatus(){
            return this.isForbinAdmin || this.isMasterAdmin || this.isSettingsManager;
        }
    },
    methods: {
        logoutUser(){
            axios.post(this.logoutRoute)
                .then(res =>{
                    window.location.href = res.data.url;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>