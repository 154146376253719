<template>
    <TableWrapper
        :searchable="false"
        v-on="wrapperEvents"
        v-bind="wrapperBinder"
        :item-count="allTableInfo.itemCount"
        :active-page="allTableInfo.activePage"
        @update-page="allTableInfo.updateActivePage($event);setCurrentPageToSession($event);"
    >
        <!--Custom Table Search-->
        <VpanelEcommerceTableSearch namespace="productOptionValue/list" @searched="updateTableDataAfterSearch" :search-model="allTableInfo.searchModel" />

        <div class="st-hidden-sorts">
            <p @click="allTableInfo.sortBy('name')" :class="allTableInfo.sortClass('name')">Name</p>
            <p>Option Name</p>
            <p @click="allTableInfo.sortBy('is_active', 'number')" :class="allTableInfo.sortClass('is_active')">Status</p>
            <p @click="allTableInfo.sortBy('updated_at', 'date')" :class="allTableInfo.sortClass('updated_at')">Last Modified</p>
        </div>
        <div class="simple-table" style="--cols:5;">
            <p class="first-col st-header" @click="allTableInfo.sortBy('name')" :class="allTableInfo.sortClass('name')">Name</p>
            <p class="st-header">Option Name</p>
            <p class="st-header" @click="allTableInfo.sortBy('is_active', null, 'number')" :class="allTableInfo.sortClass('is_active')">Status</p>
            <p class="st-header" @click="allTableInfo.sortBy('updated_at', null, 'date')" :class="allTableInfo.sortClass('updated_at')">Last Modified</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <p class="first-col" :key="row.id+'name'">
                        <span class="st-hidden">Name</span>
                        <strong>{{ row.name }}</strong>
                    </p>

                    <p :key="row.id+'option_name'">
                        <span class="st-hidden">Option Name</span>
                        <strong>{{ row.option_name }}</strong>
                    </p>

                    <div :key="row.id+'status'">
                        <span class="st-hidden">Status</span>
                        <p class="m-0" :class="row.status?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                    </div>

                    <p :key="row.id+'updated_at'">
                        <span class="st-hidden">Last Modified</span>
                        <strong>{{ row.updated_at }}</strong>
                    </p>

                    <div class="last-col" :key="row.id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <button class="btn btn-primary" @click="buttonEvent(row, 'modify')">Modify</button>
                            <button type="button" class="product-category-recycle btn btn-outline-primary" @click="buttonEvent(row, 'recycle')">Delete</button>
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelProductOptionValueTable',
        mixins: [ tableMixin ],
        props: [
            'allTableInfo',
        ],
        methods: {
            ...mapActions('productOptionValue/list', [
                'modifyButtonAction',
                'recycleButtonAction',
                'setCurrentPageToSession',
                'updateTableDataAfterSearch'
            ]),
            status(row) {
                return row.status ? 'Active' : 'Inactive';
            },
            buttonEvent(record, actionType){
                let buttonAction = actionType == 'recycle' ? this.recycleButtonAction : this.modifyButtonAction;

                buttonAction({
                    recordId: record.id,
                    activeRecord: record,
                    storeNamespace: 'productOptionValue'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(4, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>