<template>
    <div>
        <form class="bs-form my-4 needs-validation" @submit.prevent="submitForm" id="site-testimonial-form" novalidate>
            <div class="alert alert-success" v-if="showSuccessMessage">Thank you for submitting your testimonial!</div>
            <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
            <div class="bs-inputs">
                <div class="form-row">
                    <div class="form-group col-md-6" data-bsinput="true">
                        <label for="name">Name<span class="required">*</span></label>
                        <input
                            required
                            id="name"
                            type="text"
                            name="submitted_by"
                            class="form-control"
                            title="Enter your name"
                        />
                        <div class="invalid-feedback">
                            Please enter a name.
                        </div>
                    </div>
                    <div class="form-group col-md-6" data-bsinput="true">
                        <label for="email">Email<span class="required">*</span></label>
                        <input
                            required
                            id="email"
                            type="text"
                            name="email"
                            class="form-control"
                            data-rule-email="true"
                            title="Enter your email"
                        />
                        <div class="invalid-feedback">
                            Please enter an email.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="image_upload">Upload Image</label>
                    <input
                        type="file"
                        accept="image/*"
                        name="image_path"
                        id="image_upload"
                        class="form-control"
                        title="Click to find an image to upload"
                    />
                </div>
                <div class="form-group" data-bstextarea="true">
                    <label for="comment">Testimonial<span class="required">*</span></label>
                    <textarea
                        required
                        id="comment"
                        name="comment"
                        maxlength="800"
                        class="form-control"
                        title="Enter your testimonial"
                    ></textarea>
                    <div class="invalid-feedback">
                        Please enter a testimonial.
                    </div>
                </div>

                <VueRecaptcha
                    :size="recaptchaType"
                    @verify="captchaVerified"
                    @expired="captchaExpired"
                    :execute="executeInvisibleHcaptcha"
                    :hcaptcha-site-key="hcaptchaSiteKey"
                />

                <div class="form-group" data-bssubmit="true">
                    <button type="submit" class="btn btn-primary form-control h-auto w-auto" :disabled="isFormProcessing">{{ isFormProcessing ? 'Processing...' : 'Submit' }}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'SiteTestimonialsForm',
        props: [
            'recaptchaType',
            'hcaptchaSiteKey'
        ],
        mounted(){
            this.set(['recaptchaType', this.recaptchaType]);
        },
        computed: {
            ...mapState('testimonialForm', [
                'errorMessage',
                'isFormProcessing',
                'showSuccessMessage',
                'executeInvisibleHcaptcha',
            ])
        },
        methods: {
            ...mapActions('testimonialForm', [
                'set',
                'submitForm',
                'captchaExpired',
                'captchaVerified'
            ]),
        }
    }
</script>