<template>
    <div class="table-search">
        <form class="search-form" @submit.prevent="setSearchModel">
            <input
                type="search"
                :name="namespace"
                placeholder="Search..."
                @input="checkIfCleared"
                :id="`${tableName}Table`"
                :value="savedSearch(tableName)"
            />
            <button type="submit" class="btn btn-primary"><span class="fas fa-search"></span></button>
        </form>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: "VpanelEcommerceTableSearch",
        props: [
            'namespace',
            'searchModel'
        ],
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.computed = {
                ...mapState(storeNamespace, [
                    'tableName',
                    'searchTerm'
                ]),
                ...mapGetters(storeNamespace, {
                    savedSearch: 'getSearchTermByTable'
                })
            }

            this.$options.methods = {
                ...mapActions(storeNamespace, [
                    'setSearchTermToSessionStorage'
                ]),
                setSearchModel() {
                    let inputSearchTerm   = document.getElementById(`${this.tableName}Table`).value;
                    //set search term to session storage
                    this.setSearchTermToSessionStorage(inputSearchTerm);

                    this.$emit('searched', inputSearchTerm);
                },
                checkIfCleared(){
                    if(!event.target.value){
                        this.setSearchModel();
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .table-search {
        display: flex;
        padding: .5rem;
        margin: .5rem -.5rem .5rem;

        .search-form {
            display: flex;

            &::v-deep {
                .form-group {
                    margin: 0;
                }

                .btn {
                    border-radius: 0 6px 6px 0;
                }

                input {
                    outline: 0;
                    border: 1px solid hsl(0deg,0%,75%);
                    border-radius: 4px;
                    padding: .25rem .5rem;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    border-right-width: 0;
                }
            }
        }
    }
</style>