<template>
    <BasicInputShell
        v-bind="[$attrs, questionBinder]"
        type="tel"
        custom-mask="(###) ###-####"
        format-text="Format: (###) ###-####"
        format-description="Input auto formatted to North American Numbering Plan phone format, please enter 10 digit phone number without country code"
        default-pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
        default-required-message="Please enter a valid phone number"
        icon="faPhone"
        :custom-validation-func="validateFunc"
        v-on="questionEventBinder"
    />
</template>

<script>
import { questionChute, questionLadder } from './mixins/SiteFormBuilderControlMixins';

export default {
    inheritAttrs: false,
    mixins: [ questionChute, questionLadder ],
    methods: {
        validateFunc(vm) {
            let val = vm.$refs.control.value.trim(),
                regex = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
            if (!vm.required) {
                if (!val.length) return -1;
                return regex.test(val);
            }
            return val.length && regex.test(val);
        },
    }
}
</script>