<template>
    <div class="card">
        <div class="card-header">
            <p class="h5 m-0">
                <span class="fas fa-dollar-sign mr-1"></span>
                Price &amp; Weight
            </p>
        </div>
        <div class="card-body" v-if="!hasVariants">
            <div class="row align-items-start">
                <!-- Pricing & Weight Inputs -->
                <template v-for="(priceInput, index) in priceInputs">
                    <VpanelProductPriceAndWeightInput
                        class="col-md-6"
                        :input="priceInput"
                        :input-index="index"
                        :key="`${priceInput.label}_${index}`"
                        @update="updateProductAttributeInput"
                        :input-value="productAttribute[priceInput.label]"
                    />
                </template>

                <!-- Product Unit of Measure Input -->
                <VpanelEcommUomInput
                    class="col-md-6"
                    :input-index="0"
                    :input-value="productAttribute.uom"
                    @update="updateProductAttributeInput('uom')"
                />
            </div>
            <div class="row">
                <!-- Is Taxable -->
                <div class="col-md-6 form-group mx-2">
                    <label class="form-check-label" for="is_taxable">
                        <input
                            id="is_taxable"
                            type="checkbox"
                            name="is_taxable"
                            class="form-check-input"
                            :value="productAttribute.is_taxable"
                            :checked="productAttribute.is_taxable"
                            @input="updateProductAttributeInput('is_taxable')"
                        />
                        Is Taxable?
                    </label>
                </div>
            </div>
        </div>
        <div class="card-body" v-else>
            <div class="alert alert-info m-0">
                <p class="mb-0">These settings are now managed from the variant section.</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VpanelProductPriceAndWeightForm',
        props: [
            'hasVariants',
            'productAttribute'
        ],
        computed: {
            priceInputs(){
                return [
                    {
                        'isRequired' : false,
                        'label'      : 'msrp',
                        'displayName': 'Retail Price',
                        'infoTitle'  : 'Provide the MSRP.'
                    },
                    {
                        'isRequired' : true,
                        'displayName': 'Store Price',
                        'label'      : 'store_price',
                        'infoTitle'  : 'Provide your store price.'
                    },
                    {
                        'isRequired' : false,
                        'displayName': 'Sale Price',
                        'label'      : 'sale_price',
                        'infoTitle'  : 'Provide a sale price, if applicable.'
                    },
                    {
                        'isRequired' : false,
                        'displayName': 'Minimum Advertised Price',
                        'label'      : 'minimum_advertised_price',
                        'infoTitle'  : 'Provide a minimum advertised price, if applicable.'
                    },
                    {
                        'isRequired' : true,
                        'displayName': 'Weight',
                        'label'      : 'weight',
                        'infoTitle'  : 'Provide a weight.'
                    }
                ];
            }
        },
        methods: {
            updateProductAttributeInput(nestedIndex, inputValue = null){
                let itemsToEmitUp = {
                    prop: 'attributes',
                    nestedIndex: nestedIndex,
                    index: 0,
                    isCheckBox: nestedIndex == 'is_taxable' ? true : false,
                    nested: true
                };

                if(inputValue != null){
                    Vue.set(itemsToEmitUp, 'value', inputValue);
                }

                this.$emit('update', itemsToEmitUp);
            }
        }
    }
</script>