<template>
    <div class="ft-row ft-header">
        <div
            v-for="(header, index) in tableObj.headers"
            :key="index"
            class="ft-cell"
            :class="[columnHeaderWidthClass(header), columnSortable(header) ? 'justify-content-between' : '']"
            :title="truncateActive ? header.label : false"
        >
            <p
                :class="{'text-truncate': truncateActive}"
                class="ft-title"
            >{{ header.label }}</p>
            <span
                v-if="tableSortable && columnSortable(header)"
                class="ft-sort"
                :class="sortClass(header.data)"
                @click="toggleSort(header.data)"
            ></span>
        </div>
        <p class="ft-cell" :class="optionsWidth" v-if="tableObj.options.optionsCol">
            {{ tableObj.options.optionsName }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        tableObj: {
            type: Object,
            required: true
        },
        sort: {
            type: String,
            required: true
        },
        sortDir: {
            type: String,
            required: true,
        }
    },
    computed: {
        tableSortable() {
            return this.tableObj.options.sortable || false;
        },
        truncateActive() {
            return this.tableObj.options.truncate || false;
        },
        optionsWidth() {
            return this.tableObj.options.optionsWidth ? `ftw-${this.tableObj.options.optionsWidth}` : false;
        },
    },
    methods: {
        columnHeaderWidthClass(header) {
            return header.width ? `ftw-${header.width}` : false;
        },
        columnSortable(header) {
            return !header.hasOwnProperty('sortable') || header.sortable;
        },
        sortClass(sort) {
            if (this.sort === sort) {
                return this.sortDir === 'asc' ? 'asc' : 'desc';
            }
        },
        toggleSort(sort) {
            if (this.sort === sort) {
                this.$emit('sort-dir', this.sortDir === 'asc' ? 'desc' : 'asc')
            } else {
                this.$emit('sort', sort);
                this.$emit('sort-dir', 'asc');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../sass/_vue-import.scss';

    .ft-row {
        display: flex;
        flex-wrap: wrap;
        border-top: $form-table-border;
        border-left: $form-table-border;

        .ft-cell {
            @include rem(16px);
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: $form-table-base-cell-width;
            min-width: $form-table-min-cell-width;
            max-width: $form-table-max-cell-width;
            margin: 0;
            padding: .5rem;
            line-height: 1;
            border-right: $form-table-border;
            border-bottom: $form-table-border;

            @for $i from 1 through 10 {
                &:last-child:nth-child(#{$i}) {
                    $basis: (100% - ($form-table-base-cell-width * ($i - 1)));
                    @if ($basis >= 0) {
                        flex-basis: $basis;
                    } @else {
                        flex-basis: $form-table-base-cell-width;
                    }
                }
            }
        }
    }

    @for $i from 0 through 12 {
        .ft-cell.ftw-#{$i} {
            @include desktop { flex-basis: (math.div($i, 12) * 100%) !important; }
            @include tablet { flex-basis: 50% !important; }
            @include mobile { flex-basis: 100% !important; }
        }
    }

    .ft-header {
        .ft-cell {
            font-weight: bold;
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }
    }

    .ft-title { margin: 0; }

    .ft-sort {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        border-radius: 3px;
        background-image: linear-gradient(
            to bottom right,
            rgba($form-table-font-color, .2) 0%,
            rgba($form-table-font-color, .2) 45%,
            transparent 45%, transparent 55%,
            rgba($form-table-font-color, .2) 55%,
            rgba($form-table-font-color, .2) 100%
        );
        transform: rotate(45deg) skew(5deg, 5deg);

        &.asc {
            background-image: linear-gradient(
                to bottom right,
                rgba($form-table-font-color, .2) 0%,
                rgba($form-table-font-color, .2) 45%,
                transparent 45%, transparent 55%,
                $form-table-font-color 55%,
                $form-table-font-color 100%
            );
        }

        &.desc {
            background-image: linear-gradient(
                to bottom right,
                $form-table-font-color 0%,
                $form-table-font-color 45%,
                transparent 45%, transparent 55%,
                rgba($form-table-font-color, .2) 55%,
                rgba($form-table-font-color, .2) 100%
            );
        }
    }
</style>