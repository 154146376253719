<template>
    <vue-modal :visible="isModalOpen" @on-close="closeRecycleModal" id="user-recycle-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Delete User</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you want to delete this user?</p>
            <p>This action is permanent. Consider marking the user as inactive.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeRecycleModal">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="recycleUser" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Delete User' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'RecycleUserModal',
        computed: {
            ...mapState('userManager', {
                activeRecord: state => state.addModifyUser.activeRecord,
                isModalOpen: state => state.recycleUserModal.isModalOpen,
                isModalProcessing: state => state.recycleUserModal.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('userManager', {
                closeModal: 'recycleUserModal/closeModal',
                resetUserModal: 'addModifyUser/resetUserModal',
                recycleRecord: 'recycleUserModal/recycleRecord',
            }),
            closeRecycleModal(){
                this.resetUserModal();
                this.closeModal();
            },
            recycleUser(){
                this.recycleRecord({ ajaxRoute: window.vpanel.users.delete, recordId: this.activeRecord.id, dispatchAction: 'userManager/list/removeRecordFromDataTable'});
                this.resetUserModal();
            }
        }
    }
</script>