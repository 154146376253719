export const footerMixin = {
    props: {
        legalLinks: { type: Array },
        facebook: { type: String },
        twitter: { type: String },
        linkedin: { type: String },
        instagram: { type: String },
        pinterest: { type: String },
        youtube: { type: String },
        vimeo: { type: String },
    },
    computed: {
        areSocialLinks() {
            return [this.facebook, this.twitter, this.linkedin, this.instagram, this.pinterest, this.youtube, this.vimeo].some(s => s);
        },
        footerTemplate() {
            return this.designConfig?.template?.footer_template || 1;
        },
    },
    mounted() {
        document.body.dataset.headerTemplate = this.footerTemplate;
    }
}

export default { footerMixin }