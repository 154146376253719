import { v4 as uuidv4 } from 'uuid';

// All input types
export const general = {
    emits: ['focus-question'],
    props: {
        validateFormInc: {
            type: Number,
            default: 0
        },
        question: {
            type: Object,
            required: true
        },
        questionId: {
            type: [Number, String],
            default: uuidv4()
        },
        focussedQuestion: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            valid: '',
        }
    },
    computed: {
        name() {
            return `inputs[${this.questionId}]`;
        },
        customBinders() {
            return {}
        },
        controlBinder() {
            // Add from attributes to binder
            let attrs    = this.question.attributes,
                res      = {},
                addToRes = (prop, dest = null) => { if (attrs?.[prop]) res[dest || prop] = attrs[prop] };

            // Bound from attributes
            addToRes('autocomplete');
            addToRes('pattern');
            addToRes('data-param');

            if(this.question.type == 'file'){
                addToRes('accept');
            }

            // Conditionally bound
            if (this.required) res['required'] = true;

            // Bound always
            res['name'] = this.name + (this.question.type === 'checkbox' || this.question.type === 'file' ? '[]' : '');

            Object.assign(res, this.customBinders);

            return res;
        },
        title() {
            return this.question.label;
        },
        validClass() {
            let res = false;
            if (this.valid) res = `fb-${this.valid}`;
            return res;
        },
        required() {
            if ('required' in this.question) return this.question.required;
            return 0;
        },
        requiredMessage() {
            if ('required_message' in this.question) return this.question.required_message
            return '';
        }
    },
    methods: {
        checkValue() {
            let res = this.validateFunc(this);
            if (res >= 0) this.valid = res ? 'valid' : 'invalid';
            else this.valid = '';

            this.$emit('validate-question', (res < 0 || res));
        },
        // Default text check - to be overriden by component
        // If question is required validate that a value has been entered
        validateFunc(vm) {
            if (!vm.required) return -1;
            return vm.$refs.control.value.trim().length;
        },
        focusQuestion() {
            this.$emit('focus-question', this.questionId)
        }
    },
    watch: {
        validateFormInc(n) {
            this.checkValue()
        }
    }
}

// Has multiple options to choose from
export const hasOptions = {
    props: {
        options: {
            type: Object,
            required: true
        }
    },
    methods: {
        getOptionTitle(optionId) {
            return this.options[optionId].value;
        }
    }
}

// Handles the triggering and emitting of the focusQuestion() event
export const questionLadder = {
    emits: ['focus-question', 'validate-question'],
    computed: {
        questionEventBinder() {
            return {
                'focus-question': this.focusQuestion,
                'validate-question': this.validateQuestion,
                'file-change': this.fileChange
            }
        }
    },
    methods: {
        focusQuestion(id = 0) {
            this.$emit('focus-question', id)
        },
        validateQuestion(valid) {
            this.$emit('validate-question', valid)
        },
        fileChange(files) {
            this.$emit('file-change', files)
        }
    }
}

// Handles the passing and binding of the question data
export const questionChute = {
    props: {
        focussedQuestion: {
            type: Number,
            default: 0
        },
        validateFormInc: {
            type: Number,
            default: 0
        }
    },
    computed: {
        questionBinder() {
            return {
                focussedQuestion: this.focussedQuestion,
                validateFormInc: this.validateFormInc
            }
        }
    }
}

export default { general, hasOptions, questionLadder, questionChute }