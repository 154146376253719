<template>
    <button class="btn btn-primary" @click="setDetails(id)">{{ viewDetailsButtonText }}</button>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: "ViewLocationDetailsButton",
    props: ["id"],
    computed: {
        ...mapState('location', {
            viewDetailsButtonText: state => state.settings.all.VDBT
        })
    },
    methods: {
        ...mapActions('location', [
            'setDetails'
        ])
    }
}
</script>