<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p @click="sortBy('title')" :class="sortClass('title')">Title &amp; Description</p>
            <p @click="sortBy('active', 'number')" :class="sortClass('active')">Status</p>
            <p @click="sortBy('updated_at', 'date')" :class="sortClass('updated_at')">Last Modified</p>
        </div>
        <div class="simple-table" style="--cols:4;">
            <p class="first-col st-header" @click="sortBy('title')" :class="sortClass('title')">Title &amp; Description</p>
            <p class="st-header" @click="sortBy('active', 'number')" :class="sortClass('active')">Status</p>
            <p class="st-header" @click="sortBy('updated_at', 'date')" :class="sortClass('updated_at')">Last Modified</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <div class="first-col marketing-message-details" :key="row.id+row.mm_uuid+'title_tagline'">
                        <div class="marketing-message-title-tagline">
                            <span class="st-hidden">Title &amp; Description</span>
                            <p class="marketing-message-title"><strong>{{ row.title }}</strong></p>
                            <p class="marketing-message-tagline">{{ row.tagline }}</p>
                        </div>
                    </div>

                    <div :key="row.id+row.mm_uuid+'status'">
                        <div class="d-flex flex-column">
                            <span class="st-hidden">Status</span>
                            <p class="m-0" :class="row.active?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                            <p class="m-0 text-info" v-if="!row.published"><em>(Unpublished)</em></p>
                        </div>
                    </div>

                    <p :key="row.id+row.mm_uuid+'updated_at'">
                        <span class="st-hidden">Last Modified</span>
                        <strong>{{ row.updated_at }}</strong>
                    </p>

                    <div class="last-col" :key="row.id+row.mm_uuid+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <RouterLink exact class="btn btn-primary" :to="{ name: 'Modify Marketing Message',  params: { uuid: row.mm_uuid }}" :title="`Internal link to modify ${row.title}`">Modify</RouterLink>
                            <button type="button" class="btn btn-outline-primary" @click.prevent="openConfirmDeleteModal(row.id)">Delete</button>
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from "vuex";
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelMarketingMessageTable',
        mixins: [ tableMixin ],
        methods: {
            ...mapActions('marketingMessage/list', [
                "openConfirmDeleteModal"
            ]),
            status(row) {
                return row.active ? 'Active' : 'Inactive';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(calc(var(--cols) - 1), minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>