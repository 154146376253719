<template>
    <vue-modal :visible="isModalOpen" @on-close="closeTagAddModifyModal" id="tag-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-tag mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <div class="page-settings-details pb-2 pt-0" v-if="!isActionAdd">
            <p class="psd-published">Created Date: <span>{{ activeRecord.created_at }}</span></p>
            <p class="psd-modified">Last Modified: <span>{{ activeRecord.updated_at }}</span></p>
            <p class="psd-status">Status: <span>{{ parseInt(activeRecord.is_active) ? 'Active' : 'Inactive' }}</span></p>
        </div>

        <!-- Form Include -->
        <VpanelAddModifyTagForm
            @submitForm="submitForm"
            @closeModal="closeModal"
            :is-action-add="isActionAdd"
            :active-record="activeRecord"
            :class="{'d-none': isFormProcessing}"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeTagAddModifyModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="add-modify-tag-form">{{ isFormProcessing ? 'Processing...' : 'Apply' }}</button>
            </div>
        </template>

    </vue-modal>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'VpanelAddModifyTagModal',
        computed: {
            ...mapState('tag/addModify', {
                isModalOpen: state => state.isModalOpen,
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
            getModalHeader() {
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';
                return action + 'Tag';
            }
        },
        methods: {
            ...mapActions('tag/addModify', [
                'submitForm',
                'closeModal',
                'resetForm'
            ]),
            closeTagAddModifyModal() {
                let vm = this;
                vm.closeModal();
                setTimeout(function(){vm.resetForm()}, 200);
            }
        }
    }
</script>