<template>
    <div>
        <p class="h5">General Details</p>
        <div class="row">
            <div class="col-md form-group has-label">
                <!-- Product Name -->
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="product_name">Name</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Give the product a name."
                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                </div>
                <input
                    required
                    type="text"
                    name="name"
                    id="product_name"
                    class="form-control"
                    :value="activeRecord.name"
                    title="Please enter the name of the product"
                    @input="updateProductValue({ prop: 'name', nested: false })"
                    data-invalid-feedback="Please enter a products's name."
                />
                <div class="invalid-feedback">
                    Please enter a product name.
                </div>
            </div>

            <!-- Product Category -->
            <div class="col-md form-group has-label position-relative">
                <div class="loader-wrapper absolute-fill" v-if="categoriesLoading">
                    <VueLoader hide-message :spinner-size="30"/>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="category">Category</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Choose an appropriate category."
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <select
                    required
                    id="category"
                    name="category"
                    class="form-control"
                    :value="activeRecord.category"
                    title="Select the category of this product"
                    data-invalid-feedback="Please select a product's category."
                    @change="updateProductValue({ prop: 'category', nested: false })"
                >
                    <option value="" selected disabled hidden>Category</option>
                    <option
                        v-for="(category, index) in categories"
                        :key="index"
                        :value="category.id"
                    >{{ category.name }}</option>
                </select>
                <div class="invalid-feedback">
                    Please select a category.
                </div>
            </div>

            <!-- Product Manufacturer -->
            <div class="col-md form-group has-label">
                <div class="loader-wrapper absolute-fill" v-if="manufacturersLoading">
                    <VueLoader hide-message :spinner-size="30"/>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="manufacturer">Manufacturer</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Choose an appropriate manufacturer."
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <select
                    required
                    id="manufacturer"
                    name="manufacturer"
                    class="form-control"
                    :value="activeRecord.manufacturer"
                    title="Select the manufacturer of this product"
                    data-invalid-feedback="Please select a product's manufacturer."
                    @change="updateProductValue({ prop: 'manufacturer', nested: false })"
                >
                    <option value="" selected disabled hidden>Manufacturer</option>
                    <option
                        v-for="(manufacturer, index) in manufacturers"
                        :key="index"
                        :value="manufacturer.id"
                        :selected="activeRecord.manufacturer == manufacturer.id"
                    >{{ manufacturer.name }}</option>
                </select>
                <div class="invalid-feedback">
                    Please select a manufacturer.
                </div>
            </div>
        </div>

        <!-- Product CTA Button URL -->
        <div class="form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label for="product_cta_url">Buy Now URL</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="The link to buy this product now. This will navigate the user to this link instead of the product request form."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                type="text"
                name="cta_url"
                id="product_cta_url"
                class="form-control"
                :value="activeRecord.cta_url"
                title="Please enter the buy now url for this product"
                @input="updateProductValue({ prop: 'cta_url', nested: false })"
            />
        </div>

        <!-- Product Description -->
        <div class="form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="wysiwyg">Description</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Provide a description of your product that will help customers understand it better."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                type="text"
                id="wysiwyg"
                class="form-control"
                name="product_description"
                :value="activeRecord.description"
                title="Please provide additional product details"
                data-invalid-feedback="Please enter a description."
            />
            <div class="invalid-feedback">
                Please enter a description.
            </div>
        </div>

        <div class="row align-items-end">
            <!-- Product Tags -->
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="tags">Tags</label>
                    <div class="d-flex align-items-center flex-wrap justify-content-end mx-n1">
                        <span class="text-secondary text-14 text-underline cursor-pointer mx-1 text-right" @click="openTagModal">
                            <span class="fas fa-plus-circle mr-1"></span>Add New
                        </span>
                        <a
                            href="#"
                            class="mx-1"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Tags help customers find your product better. Use Ctrl+Click to select multiple tags."
                        >
                            <span class="fas fa-question-circle text-info cursor-pointer"></span>
                        </a>
                    </div>
                </div>
                <select
                    multiple
                    id="tags"
                    name="tags"
                    ref="productTags"
                    class="form-control"
                    @change="updateTagValues"
                    title="Select multiple tags that describe this product"
                >
                    <option value="" disabled hidden>Tags</option>
                    <option
                        v-for="(tagId, tagName) in tags"
                        :key="tagId"
                        :value="tagId"
                        :selected="activeRecord.tags.includes(parseInt(tagId))"
                    >{{ tagName }}</option>
                </select>
            </div>

            <!-- Product Status -->
            <div class="col-md form-group" style="padding-top:3px">
                <div class="radio-legend d-flex justify-content-between align-items-center">
                    <span class="mr-3">Status<span class="required"></span></span>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Active = visible to customers. Inactive = not visible to customers."
                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                </div>
                <fieldset>
                    <legend class="label sr-only">Status</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="status_yes">
                            <input
                                checked
                                required
                                :value="1"
                                type="radio"
                                name="status"
                                id="status_yes"
                                class="form-check-input"
                                v-model="activeRecord.status"
                                title="Please choose an option"
                                @change="updateProductValue({ prop: 'status', nested: false })"
                                data-invalid-feedback="Please indicate if the product is active or inactive."
                            />
                            <span class="form-check-sign"></span>
                            Active
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="status_no">
                            <input
                                required
                                :value="0"
                                type="radio"
                                name="status"
                                id="status_no"
                                class="form-check-input"
                                v-model="activeRecord.status"
                                title="Please choose an option"
                                @change="updateProductValue({ prop: 'status', nested: false })"
                                data-invalid-feedback="Please indicate if the product is active or inactive."
                            />
                            <span class="form-check-sign"></span>
                            Inactive
                            <div class="invalid-feedback">
                            Please indicate if the product is active or inactive.
                            </div>
                        </label>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelProductGeneralDetailsForm',
        props: [
            'activeRecord',
            'categoriesLoading',
            'manufacturersLoading'
        ],
        mounted(){
            CKEDITOR.replace('wysiwyg', {
                removePlugins: 'maximize,resize'
            });

            if(this.activeRecord.description){
                CKEDITOR.instances['wysiwyg'].setData(this.activeRecord.description);
            }
        },
        computed: {
            ...mapState({
                tags: state => state.productTag.list.allData,
                categories: state => state.productCategory.list.allData,
                manufacturers: state => state.productManufacturer.list.allData,
            })
        },
        methods: {
            ...mapActions({
                openTagModal: 'productTag/addModify/openModal',
                updateProductValue: 'product/addModify/updateProductValue',
            }),
            updateTagValues() {
                let selectedTags = [...this.$refs['productTags'].selectedOptions].map(({value}) => parseInt(value));
                this.updateProductValue({ prop: 'tags', value: selectedTags, nested: false});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .loader-wrapper {
        background-color: rgba(255,255,255,.8);
    }
</style>