<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <p class="h5 m-0">
                <span class="fas fa-cog mr-1"></span>
                Settings
            </p>
        </div>
        <div class="card-body">
            <div :key="index" v-for="(setting, index) in getCheckboxSettings">
                <div class="mx-2">
                    <label class="form-check-label" :for="`${setting.value}_${index}`">
                        <input
                            type="checkbox"
                            :name="setting.value"
                            :value="setting.vmodel"
                            class="form-check-input"
                            :checked="setting.vmodel"
                            :id="`${setting.value}_${index}`"
                            @input="updateSettingValue(setting.value)"
                        />
                        {{ setting.label }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VpanelProductSettingsForm',
        props: [
            'activeRecord',
        ],
        computed: {
            getCheckboxSettings(){
                return [
                    {
                        value: 'is_featured',
                        label: 'Is Featured?',
                        vmodel: this.activeRecord.is_featured
                    },
                    {
                        value: 'is_rx_required',
                        label: 'Requires RX?',
                        vmodel: this.activeRecord.is_rx_required
                    },
                    {
                        value: 'is_insurance_eligible',
                        label: 'Is Insurance Eligible?',
                        vmodel: this.activeRecord.is_insurance_eligible
                    },
                    {
                        label: 'Insurance Only?',
                        value: 'is_insurance_only',
                        vmodel: this.activeRecord.is_insurance_only
                    },
                    {
                        label: 'Free Shipping?',
                        value: 'is_free_shipping',
                        vmodel: this.activeRecord.is_free_shipping
                    }
                ]
            }
        },
        methods:{
            updateSettingValue(setting){
                this.$emit('update', {prop: setting, isCheckBox: true, nested: false});
            }
        }
    }
</script>