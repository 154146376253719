<template>
    <div class="fb-badge-row">
        <div
            class="fb-badge badge bg-wcag-grey text-white"
            :class="{'no-subs': formVersion.total_submissions == 0}"
            @click.prevent="filterSubmissionsByVersionAndStatus({formId: formVersion.id, status: 'all'})"
        >
            <p>{{ allFilterName }}</p>
        </div>
        <div
            class="fb-badge badge bg-warning text-white"
            :class="{'no-subs': formVersion.unread_submissions == 0}"
            @click.prevent="filterSubmissionsByVersionAndStatus({formId: formVersion.id, status: 'unread'})"
        >
            <span class="fas fa-envelope"></span>
            <p class="ml-1">{{ formVersion.unread_submissions }}</p>
            <p class="fb-badge-hidden">Unread</p>
        </div>
        <div
            class="fb-badge badge bg-gray border-0 text-black"
            :class="{'no-subs': currentVersionReadSubmissions == 0}"
            @click.prevent="filterSubmissionsByVersionAndStatus({formId: formVersion.id, status: 'seen'})"
        >
            <span class="fas fa-envelope-open"></span>
            <p class="ml-1">{{ currentVersionReadSubmissions }}</p>
            <p class="fb-badge-hidden">Read</p>
        </div>
        <div
            class="fb-badge badge bg-light-gray border-0 text-black"
            :class="{'no-subs':  formVersion.archived_submissions == 0}"
            @click.prevent="filterSubmissionsByVersionAndStatus({formId: formVersion.id, status: 'archived'})"
        >
            <span class="fas fa-archive"></span>
            <p class="ml-1">{{ formVersion.archived_submissions }}</p>
            <p class="fb-badge-hidden">Archived</p>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'VpanelFormSubmissionFilters',
        props: {
            formVersion: Object,
            allFilterName: {
                type: String,
                default: 'All'
            }
        },
        computed: {
            currentVersionReadSubmissions(){
                return (
					this.formVersion.total_submissions -
					(this.formVersion.unread_submissions + this.formVersion.archived_submissions)
				)
            }
        },
        methods: {
            ...mapActions('forms/submissions/list', [
                'filterSubmissionsByVersionAndStatus'
            ])
        }
    }
</script>

<style lang="scss" scoped>
     #forms-module-section {
        .fb-badge {
            border-width: 0;
            display: flex;
            cursor: pointer;
            margin: 0 .25rem;

            span, p {
                line-height: 1;
                margin: 0;
            }

            .fb-badge-hidden {
                opacity: 1;
                pointer-events: none;
                overflow: hidden;
                transition: 100ms ease-out;
            }

            .fb-badge-hidden { margin-left: .25rem; }
        }

        .fb-badge-row {
            margin: .25rem -.25rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .no-subs {
            opacity: .5;
            cursor: default;
            pointer-events: none;
        }
    }
</style>