<template>
	<div class="glide">
		<div class="glide__track" data-glide-el="track">
			<div class="glide__slides">
                <div
                    class="glide__slide"
                    v-for="(slide, index) in slides"
					:key="slide.mm_uuid"
                >
                    <div class="slide-wrapper">
                        <div class="slide-bg-image" :style="{backgroundImage: `url('${slides[index][bgImageSize]}')`}"></div>
                        <div class="dynamic-container">
                            <div class="slide-content">
                                <p class="h4">{{ slide.title }}</p>
                                <p>{{ slide.tagline }}</p>
                                <vue-link
                                    link-class="btn btn-primary"
                                    :link-path="slide.button_url"
                                    :link-text="slide.button_text"
                                    :link-target="slide.button_target"
                                    v-if="slide.button_url && slide.button_text"
                                    :data-nodisclaimer="!slide.button_disclaimer"
                                ></vue-link>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
        <template v-if="slides.length > 1">
            <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
            </div>
            <div class="glide__bullets" data-glide-el="controls[nav]">
                <button
                    v-for="n in slides.length"
                    :key="'bullet' + (n - 1)"
                    class="glide__bullet"
                    :data-glide-dir="`=${n - 1}`"
                ></button>
            </div>
        </template>
	</div>
</template>

<script>
import { mixin } from '../marketingMessageMixin';

export default {
	name: "MarketingMessageTemplate2",
    mixins: [mixin],
    data() {
        return {
            options: {
                type: 'carousel',
                gap: 20,
                peek: 200,
                animationDuration: 200
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import "../../../../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";

//slider custom theming
.glide {
    opacity: 0;
    transition: 200ms ease-out;

    &.glide--carousel {
        opacity: 1;
    }
}

.slide-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50vh;

    .slide-bg-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,0.5);
        }
    }

    .dynamic-container {
        $padding: 3rem;
        max-width: calc(1110px + #{$padding * 2});
        margin-left: auto;
        margin-right: auto;
        padding-left: $padding;
        padding-right: $padding;
        width: 100%;
    }

    .slide-content {
        position: relative;
        max-width: 60ch;

        p { color: white; }
    }
}

.glide__arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    top: 50%;
    left: 0;
    pointer-events: none;
    transform: translateY(-50%);

    .glide__arrow {
        position: relative;
        width: 1.5rem;
        height: 100%;
        background-color: rgba(0,0,0,.53);
        text-indent: -9999px;
        border: 0;
        pointer-events: all;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            height: .75rem;
            width: .75rem;
            border-color: #fff #fff transparent transparent;
            border-style: solid;
            border-width: 1.5px;
        }

        &--left {
            border-radius: 0 5px 5px 0;
            &:before { transform: translate(math.div(-100%, 3), -50%) rotate(-135deg) }
        }
        &--right {
            border-radius: 5px 0 0 5px;
            &:before { transform: translate(math.div(-200%, 3), -50%) rotate(45deg) }
        }
    }
}

.glide__bullets {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 12px 8px;

    .glide__bullet {
        border: 0;
        outline: 0;
        background-color: transparent;
        border-width: 2px;
        border-style: solid;
        border-color: white;
        height: 12px;
        width: 12px;
        margin: 0 4px;
        border-radius: 50rem;
        padding: 0;
        transition: background-color 200ms ease-out;

        &--active {
            background-color: white;
        }
    }
}
</style>