<template>
    <div class="vpanel-staff-module-wrapper">
        <VpanelStaffSecondaryNav />

        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
    export default {
        name: "VpanelStaffModuleIndex"
    }
</script>

<style lang="scss">
    .vpanel-staff-module-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
</style>