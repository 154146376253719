<template>
    <vue-modal :visible="isModalOpen" @on-close="closeAssetAddModifyModal" id="asset-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-briefcase mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <div class="page-settings-details pb-3 pt-0" v-if="!isActionAdd">
            <p class="psd-published">Added Date: <span>{{ activeRecord.created_at }}</span></p>
            <p class="psd-modified">Last Modified: <span>{{ activeRecord.updated_at }}</span></p>
            <p class="psd-status">Status: <span>{{ activeRecordStatus }}</span></p>
        </div>

        <!-- Form Include -->
        <asset-add-modify-form
            :active-record="activeRecord"
            :is-action-add="isActionAdd"
            :is-form-processing="isFormProcessing"
            @close-asset-modal="closeAssetAddModifyModal"
        />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeAssetAddModifyModal">Close</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="asset-add-modify-form">{{ isFormProcessing ? 'Processing...' : 'Save Changes' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'AddModifyAssetModal',
        computed: {
            ...mapState('assetManager/addModifyAsset', {
                isModalOpen: state => state.isModalOpen,
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
            getModalHeader(){
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';

                return action + this.activeRecord.asset_type_name;
            },
            activeRecordStatus(){
                return this.activeRecord.status ? 'Active' : 'Inactive';
            }
        },
        methods: {
            ...mapActions('assetManager/addModifyAsset', [
                'closeModal',
                'resetAssetModal'
            ]),
            closeAssetAddModifyModal(){
                let vm = this;
                vm.closeModal();

                setTimeout(function(){vm.resetAssetModal()}, 200);
            }
        }
    }
</script>