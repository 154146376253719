<template>
    <div class="ecom-footer-template-1" :data-variant="variant">
        <div class="top-bar">
            <div class="dynamic-container">
                <div class="link-groups">
                    <div class="logo-group" v-if="reversedLogoPath">
                        <div class="logo">
                            <img :src="reversedLogoPath" :alt="`${companyName} Logo`">
                            <a href="/" class="absolute-fill text-throw">{{ companyName }}</a>
                        </div>
                    </div>
                    <div class="link-group">
                        <div class="top">
                            <VueLink link-text="All Products" link-path="/shop" link-class="heading" />
                            <VueLink
                                v-for="(navItem,index) in getShownNav"
                                :key="index"
                                :link-text="navItem.page.nav_title"
                                :link-path="navItem.page.url"
                            ></VueLink>
                        </div>
                    </div>
                    <div class="link-group">
                        <div class="top">
                            <VueLink link-text="Get in Touch" link-path="/contact-us" link-class="heading" />
                            <VueLink link-class="with-icon" :link-text="contactPhone" :link-path="contactPhoneFormatted" link-icon="fas fa-phone" />
                            <VueLink link-class="with-icon" :link-text="contactEmail" :link-path="contactEmailFormatted" link-icon="fas fa-envelope" />
                            <p v-if="contactHours" class="vue-link with-icon"><span class="fas fa-clock mr-1"></span><span v-html="contactHours"></span></p> <!-- if locations exist include link to locations eventually -->
                        </div>
                        <div class="bottom" v-if="areSocialLinks">
                            <VueSocialLinks
                                :facebook="facebook"
                                :twitter="twitter"
                                :linkedin="linkedin"
                                :instagram="instagram"
                                :pinterest="pinterest"
                                :youtube="youtube"
                                :vimeo="vimeo"
                            />
                        </div>
                    </div>
                    <div class="link-group">
                        <div class="top">
                            <p class="heading">Customer Resources</p>
                            <VueLink link-text="My Account" link-path="/profile" />
                            <VueLink link-text="My Cart" link-path="/shop/cart" />
                            <VueLink link-text="Wish List" link-path="/profile/wish-list" />
                            <VueLink link-text="Shipping &amp; Return Policy" link-path="/shipping-return-policy" />
                        </div>
                        <div class="bottom">
                            <div class="credit-cards">
                                <img src="/images/core/site/card-visa.png" alt="" class="card-type">
                                <img src="/images/core/site/card-master.png" alt="" class="card-type">
                                <img src="/images/core/site/card-discover.png" alt="" class="card-type">
                                <img src="/images/core/site/card-ae.png" alt="" class="card-type">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="dynamic-container">
                <div class="copyright-bar">
                    <div class="top">
                        <p class="legal-links" v-if="legalLinks.length">
                            <template v-for="(link,index) in legalLinks">
                                <span v-if="index > 0" :key="`legal-${index}-sep`">|</span>
                                <VueLink :key="`legal-${index}`" :link-obj="link"></VueLink>
                            </template>
                        </p>
                    </div>
                    <div class="bottom">
                        <p class="m-0">&#169; {{ new Date().getFullYear() }} {{ companyName }}. All rights reserved. Ecommerce Web Solutions Provided by <VueLink link-text="VGM Forbin" link-path="https://www.forbin.com/" />.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { navMixin } from '@v/Core/Site/Mixins/navMixin';
import { footerMixin } from '@v/Core/Site/Mixins/footerMixin';
import { headerFooterMixin } from '@v/Core/Site/Mixins/headerFooterMixin';

export default {
    mixins: [
        navMixin,
        footerMixin,
        headerFooterMixin,
    ],
    inheritAttrs: false,
    computed: {
        getShownNav() {
            // Gets first 5, top-level nav items that are marked as shown
            return this.navInfo.filter((navItem, index) => {
                return navItem.page.show_in_nav && index < 5;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../../sass/vue-import';

    .ecom-footer-template-1 {
        color: var(--themeComplementCompliant-hsl) !important;

        &::v-deep a { color: var(--themeComplementCompliant-hsl) !important; }

        &[data-variant="full"] .dynamic-container {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        &:not([data-variant]) .dynamic-container {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        .top-bar {
            padding: 1rem 0;
            background-color: var(--themeComplement-hsl);
            border-top: 1px solid hsla(var(--themeComplementCompliant), .2);

            .link-groups {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: -1rem;

                .link-group {
                    flex: 0 1 auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 1rem;

                    p, a {
                        @include fluid-size(14px, 16px, font-size);
                        padding: .25rem 0;
                        text-decoration: none;
                        margin: 0;
                    }

                    .top {
                        display: flex;
                        flex-direction: column;

                        .with-icon {
                            display: flex;

                            &::v-deep svg { margin-top: .25em; }
                        }
                    }

                    .bottom {
                        margin-top: 1rem;
                    }

                    .credit-cards {
                        display: flex;
                        flex-wrap: wrap;
                        margin: -.25rem;

                        .card-type {
                            height: 30px;
                            margin: .25rem;
                        }
                    }

                    .heading {
                        font-size: 22px;
                        line-height: 1;
                    }
                }
            }

            .logo-group {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 0 1 auto;
                align-self: stretch;
                padding: 1rem;

                @include not-desktop {
                    flex: 1 0 100%;
                    align-items: center;
                }
            }

            .logo {
                position: relative;
                max-height: 100%;
                max-width: 250px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                    object-position: left center;
                }
            }
        }

        .bottom-bar {
            padding: 1rem 0;
            background-color: var(--themeComplement-hsl);
            border-top: 1px solid hsla(var(--themeComplementCompliant), .2);
            @include fluid-size(14px, 16px, font-size);
        }

        .copyright-bar {
            .top,
            .bottom { text-align: center; }
        }

        .legal-links {
            margin: 0;
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            line-height: 1;
            margin: -.5rem -.5rem .25rem;

            .vue-link { padding: .125rem .5rem; }
        }
    }
</style>