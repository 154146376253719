<template>
    <div class="fb-container" :id="`fb_${formId}`">
        <p class="fb-message-intro" v-if="introMessage">{{ introMessage }}</p>

        <vue-loader v-if="isFormLoading"></vue-loader>

        <SiteFormBuilderForm
            v-else
            :data="data"
        />
    </div>
</template>
<script>
    export default {
        name: 'SiteFormBuilderIndex',
        props: ['formId'],
        created() {
            this.fetchForm();
        },
        data() {
            return {
                data: {
                    form: {},
                    questions: {},
                    options: {}
                },
                isFormLoading: true
            }
        },
        computed: {
            introMessage() {
                return this.data?.form?.introduction_message;
            }
        },
        methods: {
            fetchForm(){
                axios.get(`/form-processor/${this.formId}`)
                    .then(res => {
                        Object.assign(this.data, res.data.data);

                        // Set form loading to false
                        this.isFormLoading = false;
                    })
                    .catch(error => {
                        if (error.request && error.request.status == 404) {
                            this.errorMessage = 'An unknown error occured when grabbing this form.';
                        }

                        logApplicationError(error);
                        console.error(error);
                    });
                }
            }
    }
</script>