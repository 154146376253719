<template>
    <form id="form-builder-import-export-form" class="needs-validation" name="form-builder-import-export-form" novalidate @submit.prevent="isActionImport ? submitImportForm() : exportForm()">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="d-flex flex-column align-items-stretch">
               <div class="form-group">
					<label for="form_json">Form JSON</label>
                    <textarea
                        required
                        rows="10"
                        id="form_json"
                        name="form_json"
                        title="Import Form"
                        class="form-control"
                        :disabled="!isActionImport"
                        @input="verifyValueIsJson"
                        :value="activeRecord.form_json"
                        data-invalid-feedback="Please provide JSON to import from."
                    ></textarea>
                    <div class="invalid-feedback">
                        Please provide JSON to import from.
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'VpanelFormBuilderImportExportForm',
        props: [
            'activeRecord',
            'errorMessage',
            'isActionImport',
        ],
        methods: {
            ...mapActions('forms/importExport', [
                'submitImportForm',
                'validateImportJson'
            ]),
            verifyValueIsJson(){
                this.validateImportJson(event.target.value);
            },
            exportForm(){
                let json      = document.querySelector('#form_json');
                json.disabled = false;
                json.select();
                document.execCommand('copy');
                json.disabled = true;

				showNotification('Copied to clipboard!', 'info');
            }
        }
    }
</script>