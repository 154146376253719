<template>
    <div class="staff-section p-3">
        <div class="mb-3">
            <VpanelStaffAddNewButton
                button-text="Add Division"
                namespace="division/addModify"
            />
        </div>

        <div class="d-flex flex-column aligh-items-end">
            <!-- Table Loader -->
            <VueLoader v-if="isTableLoading" />

            <!-- Staff Division Table -->
            <VpanelStaffDivisionTable
                v-else
                respond-at="md"
                :data="tableData"
                :items-per-page="10"
                :searchable-data="['name']"
            />
       </div>

       <VpanelAddModifyDivisionModal />

        <!-- Delete Staff Division Modal -->
        <VueConfirmationModal
            namespace="division/recycle"
            @confirmed-modal="deleteStaffDivision"
            heading-text="Confirm Delete Division"
            modal-id="staff-division-recycle-modal"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelStaffDivisionList',
        computed: {
            ...mapState('division/list', {
                tableData: state => state.tableData,
                isTableLoading: state => state.isTableLoading
            })
        },
        methods: {
            ...mapActions('division/list', [
                'deleteRecord'
            ]),
            deleteStaffDivision(){
                this.deleteRecord({namespace: 'division', routeGroup: 'staff/division'});
            }
        }
    }
</script>