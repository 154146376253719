<template>
    <div class="fb-fieldset fb-dropdown" :class="validClass" role="group" :aria-labelledby="`question-${questionId}-label`" @blur="blurDropdown()">
        <p class="fb-legend" :id="`question-${questionId}-label`" @click="focusDropdown()">{{ question.label }}<span class="fb-required" v-if="required">*</span></p>

        <div class="fb-options" :class="{expanded: expanded}" ref="options">
            <div class="fb-fieldset-dropdown">
                <button
                    type="button"
                    class="fb-dropdown-toggle"
                    :title="`Shows/Hides options for question ${question.label}`"
                    tabindex="-1"
                    @click.prevent="toggleDropdown()"
                    v-html="selectedNames || 'Please Select...'"
                ></button>
                <div class="fb-dropdown-container" ref="dropdown">
                    <label class="fb-option" v-for="optionId in question.options" :key="`option_${optionId}`" >
                        <input
                            :class="`fb-control-${type}`"
                            :type="type"
                            v-bind="controlBinder"
                            :value="optionId"
                            :data-selected-value="options[optionId].value"
                            @change="toggleIfTrigger(optionId)"
                            @focus="focusDropdown()"
                            @blur="checkValue()"
                        />
                        {{ options[optionId].value }}
                    </label>
                </div>
                <SiteFormBuilderAssistiveText
                    :message="requiredMessage || defaultRequiredMessage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { general, hasOptions, questionChute, questionLadder } from '../mixins/SiteFormBuilderControlMixins';

export default {
    mixins: [ general, hasOptions, questionChute, questionLadder ],
    props: {
        type: {
            type: String,
            default: 'checkbox'
        },
        defaultRequiredMessage: {
            type: String,
            default: 'Please select an option'
        },
        customValidationFunc: {
            type: Function,
            default(vm) {
                if (!vm.required) return -1;
                return Array.from(vm.$refs.options.querySelectorAll('input')).some(i => i.checked)
            }
        }
    },
    data() {
        return {
            expanded: false,
            computedInc: 0,
            mounted: false
        }
    },
    computed: {
        selectedOptions() {
            let c = this.computedInc; // fake dep to refresh
            if (!this.mounted) return [];
            let checked = Array.from(this.$refs.dropdown.querySelectorAll('input:checked'));
            return checked.map(i => i.dataset.selectedValue);
        },
        selectedNames() {
            let res = '';
            if (this.selectedOptions.length) {
                let items = this.selectedOptions.map(n => {
                    let span = document.createElement('span');
                    span.textContent = n;
                    return span.outerHTML;
                });

                res = items.join("");
            }
            return res;
        },
    },
    methods: {
        toggleDropdown() {
            if (this.expanded) this.blurDropdown()
            else this.focusDropdown();
        },
        toggleIfTrigger(id) {
            this.checkValue();
            this.toggleSelectedOptions(id);
        },
        focusDropdown() {
            this.expanded = true;
            this.focusQuestion(this.questionId)
        },
        blurDropdown() {
            this.checkValue();
            this.expanded = false;
            this.focusQuestion();
        },
        validateFunc(vm) {
            return this.customValidationFunc(vm)
        },
        toggleSelectedOptions(id) {
            this.computedInc++;
        },
    },
    watch: {
        expanded() {
            let el     = this.$refs.dropdown,
                height = el.scrollHeight,
                capped = height > 200 ? 200 : height;

            el.style.setProperty('--height', capped + 'px');
        },
        focussedQuestion(n) {
            if (n !== this.questionId) this.expanded = false;
        }
    },
    mounted() {
        this.mounted = true;
    }
}
</script>