<template>
	<div v-if="!optionsIsDeleted">
		<div class="d-flex align-items-start">
			<div class="row flex-grow-1">
				<div class="col-md form-group">
					<label class="required" :for="`option_value_${index}`">Option Text</label>
					<input
						required
						type="text"
						title="Label Text"
						class="form-control"
						v-model="option.value"
						:id="`option_value_${index}`"
						:name="`option_value_${index}`"
						data-msg="Please provide option value"
                        data-invalid-feedback="Please provide option value"
					/>
                    <div class="invalid-feedback">
                        Please provide option value.
                    </div>
				</div>

				<!-- 1 is top, else sorted alphabetically -->
				<div class="col-md form-group">
					<label :for="`option_sort_order_${index}`">Sort Order</label>
					<input
						type="number"
						title="Sort Order"
						class="form-control"
						data-rule-number="true"
						v-model="option.sort_order"
						:id="`option_sort_order_${index}`"
						:name="`option_sort_order_${index}`"
                        data-invalid-feedback="Please use a number"
					/>
                    <div class="invalid-feedback">
                        Please use a number.
                    </div>
				</div>

			</div>
			<button
				v-if="options.length > 1"
				@click.prevent="removeOption()"
				class="ml-2 h-100 lh-md w-auto btn btn-danger"
			>Remove Option</button>
		</div>
	</div>
</template>

<script>
	export default {
        props: ["index", "option", "options"],
        data() {
            return {
                optionsIsDeleted: 0
            };
        },
        computed: {
            optionIsDeleted() {
                return false;
            }
        },
        methods: {
            removeOption() {
                if (this.options.length > 1) {
                    this.option.deleted = 1;
					this.optionsIsDeleted = 1;
				}
            }
        }
	};
</script>