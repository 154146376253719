<template>
    <div class="site-config-table">
        <div class="sct-header">
            <p class="sct-name">Name</p>
            <p class="sct-value">Value</p>
            <p class="sct-description">Description</p>
        </div>
        <div class="sct-body">
            <template v-if="configs">
                <div
                    v-for="(config, index) in configs"
                    :key="index"
                    class="sct-row"
                    :data-site-config-id="config.id"
                >
                    <p class="sct-name"><label :for="config.id">{{ config.name }}</label></p>
                    <div class="sct-value pr-2" :class="{'sctv-file': config.type == 'file' }">
                        <template v-if="config.type == 'boolean'">
                            <div class="custom-control custom-switch text-left">
                                <input
                                    type="checkbox"
                                    :checked="config.value == 1"
                                    class="custom-control-input js-boolean-control site-config-input"
                                    :id="config.id"
                                    :name="`config[${config.name}]`"
                                    value="1"
                                />
                                <label
                                    class="custom-control-label"
                                    :for="config.id"
                                ></label>
                            </div>
                        </template>
                        <template v-else-if="config.type == 'textarea'">
                            <textarea
                                class="form-control site-config-input"
                                :id="config.id"
                                :title="`Edit ${config.name}`"
                                :name="`config[${config.name}]`"
                                rows="3"
                                v-model="config.value"
                            ></textarea>
                        </template>
                        <template v-else-if="config.type == 'file'">
                            <input
                                :id="`config_${config.id}`"
                                :title="`Edit ${config.name}`"
                                class="form-control site-config-input"
                                type="text"
                                disabled
                                :name="`config[${config.name}]`"
                                :value="config.value"
                            />

                            <button
                                @click.prevent="openFileManager"
                                class="btn btn-tertiary"
                                :id="`${config.id}_choose`"
                                :data-input="`config_${config.id}`"
                                title="Opens the file manager modal where you can choose to upload a new file or choose from your file library"
                            >Choose File</button>

                            <button
                                @click.prevent="deleteFileValue"
                                class="btn btn-outline-primary"
                                :id="`${config.id}_remove`"
                                :data-input="`config_${config.id}`"
                                title="Clears the current file"
                            >Delete file</button>

                            <a
                                v-if="config.value"
                                class="btn btn-primary"
                                :href="config.value"
                                target="_blank"
                                :title="`Opens ${config.value} in a new window`"
                            >Preview file</a>
                        </template>
                        <template v-else>
                            <input
                                :type="config.type"
                                class="form-control site-config-input"
                                :id="config.id"
                                :title="`Edit ${config.name}`"
                                :name="`config[${config.name}]`"
                                :value="config.value"
                            />
                        </template>
                    </div>
                    <p class="sct-description" v-html="config.description"></p>
                </div>
            </template>
            <template v-else>
                <div class="sct-row">
                    <p class="sct-name">No Site Configs found.</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { openFileManager } from '../../../../../../js/core/vpanel/vpanel-functions';

export default {
    name: 'SiteConfigList',
    props: ["configs"],
    methods: {
        openFileManager() {
            openFileManager(event.target, null);
        },
        deleteFileValue() {
            document.querySelector(`#${event.target.dataset.input}`).value = '';
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .sctv-file {
        display: flex;
        flex-wrap: wrap;
        margin: -.25rem;

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            margin: .25rem;
            width: auto;
        }
    }

    .site-config-table {
        overflow: hidden;

        .sct-body {
            overflow-y: auto;
            @extend %minimal-scrollbar;
        }
    }
</style>