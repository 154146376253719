<template>
    <div class="product-image-slider-wrapper" ref="wrapper">
        <div class="loader-wrapper" v-if="loading">
            <VueLoader />
        </div>
        <div class="product-image-slider">
            <div class="button-wrapper" v-if="showSidebar">
                <div class="buttons">
                    <button
                        v-for="(image, index) in images"
                        :key="'button-' + index"
                        :class="{'active': slider.index == index}"
                        @click="navigateTo(index)"
                    >
                        <img :src="image.thumb" :alt="image.alt">
                    </button>
                    <a
                        v-for="id in videoIds"
                        :key="id"
                        :href="`https://youtube.com/watch?v=${id}`"
                        target="_blank"
                        :title="`Opens product youtube video (${id}) in new tab`"
                    >
                        <span class="video-icon fas fa-play"></span>
                        <img :src="`https://img.youtube.com/vi/${id}/0.jpg`" :alt="`Thumbnail for product youtube video (${id})`">
                    </a>
                </div>
            </div>
            <div
                class="glide__track"
                data-glide-el="track"
                :style="{marginLeft:showSidebar ? 'calc(var(--sidebar-width) + var(--sidebar-gap))' : '0'}"
            >
                <div class="glide__slides" ref="slides">
                    <div
                        class="glide__slide"
                        v-for="(image, index) in images"
                        :key="`product-image-${index}`"
                    >
                        <!-- Load checker, stop loading once all are loaded -->
                        <img class="hidden-image" :src="image.path" :alt="image.alt" style="display:none" @load="loadedImage(image.id)">
                        <InnerImageZoom
                            :src="image.path"
                            :alt="image.alt"
                            fullscreenOnMobile
                            zoomPreload
                            moveType="pan"
                            zoomType="click"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Glide from "@glidejs/glide";
    import InnerImageZoom from 'vue-inner-image-zoom';

    export default {
        props: ['images', 'videoIds'],
        components: {
            InnerImageZoom
        },
        data() {
            return {
                windowWidth: window.innerWidth,
                options: {
                    type: 'carousel',
                    gap: 16,
                    perView: 1,
                    animationDuration: 200,
                    swipeThreshold: false,
                    dragThreshold: false
                },
                initSuccess: false,
                slider: {},
                loadedImages: [],
                showSidebar: true,
            }
        },
        watch: {
            loadedImages: {
                handler(n) {
                    if (n.length === this.images.length) {
                        setTimeout(() => this.handleResize({target: this.$refs.wrapper}), 0);
                    }
                },
                deep: true
            }
        },
        computed: {
            loading() {
                return this.loadedImages.length < this.images.length;
            },
            resizeObserver() {
                return new ResizeObserver((e, o) => e.forEach(this.handleResize));
            }
        },
        methods: {
            // The entries being passed here should be on the main
            handleResize(entry) {
                if (this.loadedImages.length === this.images.length) {
                    let images = entry.target.querySelectorAll('.hidden-image');

                    images.forEach(image => {
                        let nWidth = image.naturalWidth;
                        image.classList.remove('no-zoom');
                        image.classList.remove('no-mobile-zoom');

                        // if image is not greater than screen
                        if (nWidth <= window.innerWidth) image.classList.add('no-mobile-zoom');

                        // if image is greater than the slide
                        if (nWidth <= image.closest('.glide__slide').offsetWidth) image.classList.add('no-zoom');
                    })
                }
            },
            init() {
                this.slider = new Glide('.product-image-slider', this.options);
                this.slider.mount();
                this.updatePropWithBoolIfModified('initSuccess', true);
                this.updatePropWithBoolIfModified('showSidebar', true);
            },
            updatePropWithBoolIfModified(prop, bool) {
                if (this[prop] !== bool) this[prop] = bool;
            },
            initCheck() {
                this.$emit('show-video-card', false);
                let caseId = !!this.images.length + ((this.images.length > 1) * 2) + (!!this.videoIds.length * 4) + (this.initSuccess * 8);

                // validCase |   |   | X |   |   |   | X |   |   |   | X  |    |    |    | X  |    |
                // caseId    | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 |
                // ---------------------------------------------------------------------------------
                // images    | 0 | 1 | 0 | 1 | 0 | 1 | 0 | 1 | 0 | 1 | 0  | 1  | 0  | 1  | 0  | 1  |
                // gt1Images | 0 | 0 | 1 | 1 | 0 | 0 | 1 | 1 | 0 | 0 | 1  | 1  | 0  | 0  | 1  | 1  |
                // videos    | 0 | 0 | 0 | 0 | 1 | 1 | 1 | 1 | 0 | 0 | 0  | 0  | 1  | 1  | 1  | 1  |
                // inited    | 0 | 0 | 0 | 0 | 0 | 0 | 0 | 0 | 1 | 1 | 1  | 1  | 1  | 1  | 1  | 1  |

                switch (caseId) {
                    case 0:  // No images, no videos, not inited; [Do nothing]
                    case 13: // One image, videos, inited; [Init??? - psych, do nothing]
                    case 11: // Multiple images, no videos, inited; [Re-init - psych, do nothing]
                    case 15: // Multiple images, videos, inited; [Re-init - psych, do nothing]
                        break;

                    case 1:  // One image, no videos, not inited; [Do nothing + set sidebar to false]
                        this.updatePropWithBoolIfModified('showSidebar', false); break;

                    case 3:  // Multiple images, no videos, not inited; [Init]
                    case 7:  // Multiple images, videos, not inited; [Init]
                    case 5:  // One image, videos, not inited; [Init???]
                        this.init(); break;

                    case 4:  // No images, videos, not inited; [videoSection to true]
                    case 12: // No images, videos, inited; [videoSection to true]
                        this.$emit('show-video-card', true); break;

                    case 8:  // No images, no videos, inited; [Disable]
                    case 9:  // One image, no videos, inited; [Disable]
                        this.updatePropWithBoolIfModified('showSidebar', false); this.slider.disable(); break;
                }
            },
            navigateTo(index) {
                this.slider.go(`=${index}`);
            },
            loadedImage(id) {
                if (!this.loadedImages.includes(id)) this.loadedImages.push(id);
            },
        },
        updated() {
            this.initCheck();
        },
        mounted() {
            this.resizeObserver.observe(this.$refs.wrapper);
            this.initCheck();
        },
    }
</script>

<style lang="scss">
    @import "../../../../../../../../node_modules/vue-inner-image-zoom/lib/vue-inner-image-zoom.css";

    .iiz {
        vertical-align: bottom;

        &__img {
            vertical-align: bottom;
        }
    }
</style>

<style lang="scss" scoped>
    @import "../../../../../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
    @import "../../../../../../../sass/_vue-import.scss";

    .product-image-slider-wrapper {
        position: relative;

        .loader-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            background-color: rgba(255,255,255,.5);
        }
    }

    .product-image-slider {
        @include fluid-size(4px, 8px, --sidebar-gap);
        @include fluid-size(-4px, -8px, --negative-sidebar-gap);
        --sidebar-width: min(25%, 100px);
        display: flex;
        align-items: flex-start;
        position: relative;

        .button-wrapper {
            @extend %minimal-scrollbar;
            overflow: auto;
            height: 100%;
            width: var(--sidebar-width);
            position: absolute;

            .buttons {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;
                margin: calc(var(--negative-sidebar-gap) / 2) var(--negative-sidebar-gap);
                margin-right: 0;

                button, a {
                    width: 100%;
                    outline: 0;
                    border: 0;
                    padding: calc(var(--sidebar-gap) / 2) var(--sidebar-gap);
                    background-color: transparent;
                    opacity: .5;
                    transition: 200ms ease-out;
                    position: relative;

                    &.active, &:hover {
                        opacity: 1;

                        .video-icon { color: var(--themeColor-hsl); }
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .video-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: white;
                        transition: 200ms ease-out;
                    }
                }
            }
        }

        .glide__track {
            flex: 1 1 auto;
            margin-left: calc(var(--sidebar-width) + var(--sidebar-gap));
        }

        .glide__slides {
            flex: 1 1 auto;
            align-items: stretch;

            .glide__slide {
                height: auto;
                display: flex;
                justify-content: center;
            }
        }

        .hidden-image {
            max-width: 100%;

            &.no-zoom {
                display: block !important;
                align-self: flex-start;
                width: 100%;

                + figure.iiz {
                    display: none;
                }
            }

            @media only screen and (max-width: 640px) {
                &.no-mobile-zoom {
                    display: block !important;
                    align-self: flex-start;
                    width: 100%;

                    + figure.iiz {
                        display: none;
                    }
                }
            }
        }
    }
</style>