<template>
    <div class="product-listing-wrapper">
        <div class="product-listing-sidebar" :class="{expanded: sidebarExpanded}">
            <div class="product-listing-sidebar-toggle" @click="sidebarExpanded = !sidebarExpanded">
                <div v-show="sidebarExpanded">
                    <span class="fas fa-times"></span>
                </div>
                <div v-show="!sidebarExpanded">
                    <span class="fas fa-filter"></span>
                </div>
            </div>

            <div class="product-listing-sidebar-inner">
                <SiteProductListingSidebarCard :expandable="tags.length > 10" v-if="tags.length">
                    <template #header>Tags</template>
                    <button
                        v-for="(tag, id) in tags"
                        :key="`product-list-tag-${id}`"
                        @click="productList.filterBy('tags', tag.id)"
                        :class="{active: productList.isActiveFilter('tags', tag.id)}"
                    >{{ tag.name }}</button>
                </SiteProductListingSidebarCard>

                <SiteProductListingSidebarCard>
                    <template #header>Price Filters</template>
                    <div class="d-sm-flex align-items-center">
                        <VueInput name="$" class="m-0" v-model.number="minimumPrice" />
                        <span class="mx-1">to</span>
                        <VueInput name="$$$" class="m-0" v-model.number="maximumPrice" />
                    </div>
                </SiteProductListingSidebarCard>

                <SiteProductListingSidebarListCard
                    name="Categories"
                    :list="categories"
                    :active-item="category && category.id ? category.id : null"
                />

                <SiteProductListingSidebarListCard
                    name="Brands"
                    :list="manufacturers"
                    :active-item="manufacturer && manufacturer.id ? manufacturer.id : null"
                />
            </div>
        </div>

        <div class="product-listing-main">
            <slot></slot>
            <div class="search-and-settings">
                <form class="search-form" @submit.prevent="setSearchModel()">
                    <VueSearch name="Search" v-model="searchModel" />
                    <button type="submit" class="btn btn-primary"><span class="fas fa-search"></span></button>
                </form>
                <VueSelect name="Sort" :options="sortOptions" v-model="sortModel" />
                <VueSelect name="Items to Show" :options="amountToShowOptions" v-model.number="productList.itemsPerPage"/>
                <button
                    v-if="productList.hasActiveFilters"
                    class="reset-filters btn btn-primary"
                    title="This will reset all filters"
                    @click.prevent="resetListAndModels()"
                ><span class="fas fa-undo-alt"></span> Reset Filters</button>
            </div>
            <div class="product-listing" v-if="!productList.isLoading">
                <SiteProductQuickViewCard
                    v-for="product in productList.displayedList"
                    :key="`product-listing-${product.id}`"
                    :product="product"
                />
            </div>

            <div class="position-relative py-5" v-else>
                <VueLoader />
            </div>

            <div class="bottom-bar">
                <p>{{ productList.rangeText }}</p>

                <Pagination
                    :active-page="productList.activePage"
                    :item-count="productList.itemCount"
                    :items-per-page="productList.itemsPerPage"
                    @on-update="productList.updateActivePage($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import EloquentSSFP from '@j/core/global/EloquentSSFP';

    export default {
        name: 'SiteProductListing',
        props: [ 'tags', 'categories', 'manufacturers', 'category', 'manufacturer'],
        data() {
            return {
                productList: new EloquentSSFP(`/ecommerce-list/${this.category?.id ? `category/${this.category?.id}` : (this.manufacturer?.id ? `manufacturer/${this.manufacturer?.id}` : 'products')}`, {
                    searchableData: ['name'],
                    itemsPerPage: 20,
                    activeSorts: ['name']
                }),
                sortOptions: [
                    {
                        name: "Name (A-Z)",
                        value: "1",
                        sortParams: {
                            activeSort: "name",
                            sortDir: "asc",
                            sortType: "string"
                        }
                    },
                    {
                        name: "Name (Z-A)",
                        value: "2",
                        sortParams: {
                            activeSort: "name",
                            sortDir: "desc",
                            sortType: "string"
                        }
                    },
                    {
                        name: "Price (Low-High)",
                        value: "3",
                        sortParams: {
                            activeSort: "productAttributes:min:lowest_price",
                            sortDir: "asc",
                            sortType: "number"
                        }
                    },
                    {
                        name: "Price (High-Low)",
                        value: "4",
                        sortParams: {
                            activeSort: "productAttributes:min:lowest_price",
                            sortDir: "desc",
                            sortType: "number"
                        }
                    },
                    {
                        name: "Percent Discount",
                        value: "5",
                        sortParams: {
                            activeSort: "productAttributes:min:discount_percent",
                            sortDir: "desc",
                            sortType: "number"
                        }
                    },
                ],
                searchModel: '',
                sortModel: "1",
                minimumPrice: "",
                maximumPrice: "",
                sidebarExpanded: false,
                searchDebounceTimer: null,
            }
        },
        computed: {
            amountToShowOptions() {
                let result = [];

                for (let v = 1; v < 5; v++) {
                    let a = v * 20;
                    result.push({ name: a, value: a })
                }

                return result;
            },
        },
        methods: {
            setSearchModel() {
                let options = {
                    ...this.productList.options,
                    searchModel: this.searchModel,
                    activePage: 1
                };

                this.productList.refreshData(options);
            },
            resetListAndModels() {
                this.sortModel = "1";
                this.searchModel = "";
                this.minimumPrice = "";
                this.maximumPrice = "";
                this.productList.resetAll();
            },
        },
        watch: {
            sortModel(n) {
                let c = this.sortOptions.find(opt => {
                        return opt.value === n;
                    }).sortParams;

                let options = this.productList.options;
                options['activeSorts'] = [c.activeSort];
                options['sortDirs'] = [c.sortDir];
                options['sortTypes'] = [c.sortType];

                this.productList.refreshData(options);
            },
            searchModel(n,o) {
                if (o && !n) {
                    this.setSearchModel();
                }
            },
            minimumPrice(n,o) {
                if (n !== o) this.productList.filterBy('productAttributes.lowest_price', n, ">=", 1);
            },
            maximumPrice(n,o) {
                if (n !== o) this.productList.filterBy('productAttributes.lowest_price', n, "<=", 1);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';

    .product-listing-wrapper {
        display: flex;
        align-items: stretch;
        margin: -1rem;

        .product-listing-sidebar {
            @extend %minimal-scrollbar;
            flex: 0 0 clamp(200px, 25%, 240px);
            align-self: flex-start;
            max-height: 100vh;

            .product-listing-sidebar-inner {
                @extend %minimal-scrollbar;
            }

            @include desktop {
                position: sticky;
                top: 0;
                overflow: auto;

                .product-listing-sidebar-toggle { display: none; }
            }

            @include not-desktop {
                position: fixed;
                top: 0;
                left: calc(-100% + 50px + 1rem);
                width: calc(100% - 1rem);
                transition: 200ms ease-out;
                padding-right: 50px;
                z-index: 5;
                pointer-events: none;

                &:before {
                    z-index: -1;
                    content: "";
                    position: fixed;
                    top: 0;
                    left: 0;
                    background-color: rgba(0,0,0,0);
                    height: 100%;
                    width: 100%;
                    pointer-events: none;
                    transition: 200ms ease-out;
                }

                &.expanded {
                    left: 0;

                    &:before {
                        background-color: rgba(0,0,0,.8);
                        pointer-events: all;
                    }

                    .product-listing-sidebar-toggle {
                        background-color: white;
                        color: hsl(0deg, 0%, 45%);
                    }

                    .product-listing-sidebar-inner {
                        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
                    }
                }

                .product-listing-sidebar-inner {
                    background-color: hsl(0deg, 0%, 90%);
                    overflow: auto;
                    height: 100vh;
                    pointer-events: all;
                    box-shadow: 0 5px 0 rgba(0,0,0,0.1);
                    transition: 200ms ease-out;
                }

                .product-listing-sidebar-toggle {
                    pointer-events: all;
                    position: absolute;
                    right: 0;
                    bottom: 1rem;
                    height: 50px;
                    width: 50px;
                    background-color: var(--themeColor-hsl);
                    color: var(--themeCompliant-hsl);
                    font-size: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0 5px 5px 0;
                    z-index: -1;
                }
            }
        }

        .product-listing-main {
            flex: 1 1 auto;
            padding: 1rem;
        }
    }


    .product-listing {
        @include fluid-size(160px, 200px, --product-min-width);
        @include fluid-size(16px, 32px, --product-grid-gap);

        grid-template-columns: repeat(auto-fill, minmax(var(--product-min-width),1fr));
        grid-auto-rows: auto;
        display: grid;
        grid-auto-flow: dense;
        grid-gap: var(--product-grid-gap);
    }

    .search-and-settings {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem .5rem;

        .search-form {
            display: flex;
            flex-grow: 9999;

            &::v-deep {
                .form-group {
                    margin: 0;
                    padding: 0;
                    flex-grow: auto;
                    flex-basis: none;
                    min-width: none;
                }

                .btn {
                    border-top-right-radius: .25rem;
                    border-bottom-right-radius: .25rem;
                }

                input {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    border-right-width: 0;
                }
            }
        }

        .search-form,
        .form-group {
            margin: 0;
            padding: .5rem;
            flex-grow: 1;
            flex-basis: 150px;
            min-width: max-content;
        }

        .reset-filters {
            margin: .5rem;
            flex-grow: 1;
            min-width: max-content;
        }
    }

    .bottom-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: .75rem -.5rem;

        > * {
          margin: .25rem .5rem;
        }
    }
</style>