<template>
    <form id="service-add-modify-form" class="needs-validation" name="service-add-modify-form" novalidate @submit.prevent="submitServiceForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="d-flex flex-column align-items-stretch">
                <div class="row">
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="name">Name</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The name of the location service"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            id="name"
                            type="text"
                            class="form-control"
                            required name="name"
                            v-model="activeRecord.name"
                            title="Please enter a service name"
                            data-invalid-feedback="Please enter a service name."
                        />
                        <div class="invalid-feedback">
                            Please enter a service name.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group" v-if="!isActionAdd && activeRecord.icon">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>Selected Icon</span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <span :class="`${activeRecord.icon} fa-2x`"></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="icon">Icon Search</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Enter a search term to find an icon"
                            >
                                <span class="fas fa-question-circle text-info cursor-pointer"></span>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between align-items-stretch">
                            <input

                                type="text"
                                id="icon_search"
                                name="icon_search"
                                @blur="iconSearch"
                                class="form-control"
                                title="Search for an icon"
                                v-model="activeRecord.search_term"
                            />

                            <button type="button" class="btn btn-secondary flex-shrink-0 ml-2" @click="iconSearch">Icon Search</button>
                        </div>
                    </div>
                </div>

                <vpanel-loader v-if="isIconSearchProcessing" />

                <div class="row" v-if="allIcons.length && !isIconSearchProcessing">
                    <div
                        :class="`col form-group ${selectedIconClass(iconFromFa)}`"
                        v-for="(iconFromFa, index) in allIcons"
                        :key="index"
                        @click="iconSelected(iconFromFa)"
                    >
                        <span :class="`${iconFromFa} fa-2x cursor-pointer`"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group px-2">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="required mr-3">Status</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="If the service is active it will show on the front end"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_yes">
                                    <input
                                        checked
                                        required
                                        :value="1"
                                        type="radio"
                                        name="status"
                                        id="status_yes"
                                        class="form-check-input"
                                        title="Please choose an option"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the service is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Active
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_no">
                                    <input
                                        required
                                        :value="0"
                                        type="radio"
                                        name="status"
                                        id="status_no"
                                        class="form-check-input"
                                        title="Please choose an option"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the service is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Inactive
                                    <div class="invalid-feedback">
                                        Please indicate if the service is active or inactive.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    export default {
        name: 'AddModifyLocationServiceForm',
        props: [
            'allIcons',
            'isActionAdd',
            'activeRecord',
            'errorMessage',
            'isIconSearchProcessing'
        ],
        methods: {
            submitServiceForm(){
                this.$emit('submit-service-form');
            },
            iconSearch(){
                this.$emit('icon-search');
            },
            iconSelected(icon){
                this.$emit('icon-selected', icon);
            },
            selectedIconClass(icon){
                return this.activeRecord.icon == icon ? 'text-success' : '';
            }
        }
    }
</script>