<template>
    <div class="address">
        <p class="heading">Address</p>
        <div class="group">
            <p>{{ location.address }}<br/>{{ location.city }}, {{ location.state }} {{ location.postal_code }}</p>
            <a
                :href="`https://www.google.com/maps/dir/Current+Location/${destinationURI}`"
                :title="`External link to google maps driving directions to ${location.title}`"
                target="_blank"
            >
                <strong>Get Driving Directions ></strong>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "FrontendLocationAddress",
    props: ["location"],
    computed: {
        destinationURI() {
            let l = this.location;
            // Might be cool to use place-id in the future if google doesn't nuke it,
            // then they could enter that in the VPanel for even more refined results.
            // Tried including the location name in the url and it worked,
            // except if the location name isn't something useful i.e. Cedar Falls Branch.
            return encodeURIComponent(`${l.address}, ${l.city}, ${l.state} ${l.postal_code}`).replaceAll('%20','+');
        }
    }
}
</script>

<style lang="scss" scoped>
    .heading {
        margin-top: 1rem;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: .25rem;
        border-bottom: 1px solid var(--light);

        + .group {
            padding: .5rem 0;

            p { margin: 0; }

            > *:first-child { margin-top: 0; }
            > *:last-child { margin-bottom: 0; }
        }
    }
</style>