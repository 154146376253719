import { render, staticRenderFns } from "./VpanelLocationModuleSettingList.vue?vue&type=template&id=05b6d046&scoped=true&"
import script from "./VpanelLocationModuleSettingList.vue?vue&type=script&lang=js&"
export * from "./VpanelLocationModuleSettingList.vue?vue&type=script&lang=js&"
import style0 from "./VpanelLocationModuleSettingList.vue?vue&type=style&index=0&id=05b6d046&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b6d046",
  null
  
)

export default component.exports