<template>
    <div class="d-flex flex-column p-4 flex-grow-1">
        <div class="border-bottom">
            <p class="h5">
                <span class="fas fa-ticket-alt mr-1 text-tertiary"></span>&nbsp;{{ getAddModifyHeading('Coupon') }}
            </p>
        </div>
        <div class="pt-1">
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                class="pb-3"
                v-if="!isActionAdd"
                :status="getStatusAsString"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <form class="coupon-add-modify-form needs-validation" name="coupon-add-modify-form" id="coupon-add-modify-form" novalidate @submit.prevent="submitCouponForm">
                <!-- Error Messages -->
                <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

                <!-- Coupon Form Include -->
                <VpanelAddModifyCouponForm :active-record="activeRecord" />

                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary my-0 save-button" :disabled="isFormProcessing">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: "VpanelAddModifyCouponPage",
        computed: {
            ...mapState('coupon/addModify', {
                activeRecord: state => state.activeRecord,
                errorMessage: state => state.errorMessage,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
            ...mapGetters('coupon/addModify', [
                'getStatusAsString',
                'getAddModifyHeading'
            ])
        },
        methods: {
            ...mapActions('coupon/addModify', [
                'submitForm'
            ]),
            submitCouponForm(){
                let addDispatch     = this.isActionAdd ? 'coupon/addModify/setActiveRecord' : null;
                let addModifyRoute  = this.isActionAdd ? '/vpanel/modules/ecommerce/coupon/store' : '/vpanel/modules/ecommerce/coupon/update';
                let routerPath      = this.isActionAdd ? 'Modify Coupon' : null;


                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    dataTableId: null,
                    isUsingModal: false,
                    routerPath: routerPath,
                    dispatchPath: addDispatch
                });
            }
        }
    }
</script>