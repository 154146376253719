<template>
    <div class="user-manager-section p-4">
        <div class="mb-3">
            <add-new-user-button />
        </div>
        <div class="um-datatable d-flex flex-column aligh-items-end">
            <user-manager-list />
        </div>

        <recycle-user-modal />
        <add-modify-user-modal />
        <confirm-reset-password-modal />
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    export default {
        name: 'UserManagerIndex',
        created(){
            this.fetchUsers();
            this.setCsrfToken();
        },
        methods: {
            ...mapActions("userManager", {
                setCsrfToken: 'setCsrfToken',
                fetchUsers: 'list/fetchUsers',
            })
        }
    }
</script>