<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p @click="sortBy('title')" :class="sortClass('title')">Title</p>
            <p @click="sortBy('address')" :class="sortClass('address')">Address</p>
            <p @click="sortBy('status', 'number')" :class="sortClass('status')">Status</p>
            <p @click="sortBy('updated_at', 'date')" :class="sortClass('updated_at')">Last Modified</p>
        </div>
        <div class="simple-table" style="--cols:5;">
            <p class="first-col st-header" @click="sortBy('title')" :class="sortClass('title')">Title</p>
            <p class="st-header" @click="sortBy('address')" :class="sortClass('address')" >Address</p>
            <p class="st-header" @click="sortBy('status', 'number')" :class="sortClass('status')">Status</p>
            <p class="st-header" @click="sortBy('updated_at', 'date')" :class="sortClass('updated_at')">Last Modified</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <p class="first-col" :key="row.id+'title'">
                        <span class="st-hidden">Title</span>
                        <strong>{{ row.title }}</strong>
                    </p>

                    <div :key="row.id+'address'">
                        <span class="st-hidden">Address</span>
                        <div class="d-flex flex-column">
                            <p class="m-0 text-truncate" :title="row.address"><strong>{{ row.address }}</strong></p>
                            <p class="m-0 text-truncate" :title="`${row.city}, ${row.state} ${row.postal_code}`">{{ row.city }}, {{ row.state }} {{ row.postal_code }}</p>
                        </div>
                    </div>

                    <div :key="row.id+'status'">
                        <span class="st-hidden">Status</span>
                        <p class="m-0" :class="row.status?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                    </div>

                    <p :key="row.id+'updated_at'">
                        <span class="st-hidden">Last Modified</span>
                        <strong>{{ row.updated_at }}</strong>
                    </p>

                    <div class="last-col" :key="row.id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <RouterLink exact class="btn btn-primary" :to="{ name: 'Modify Location',  params: { id: row.id }}" :title="`Internal link to modify the ${row.title} location`">Modify</RouterLink>
                            <button type="button" class="btn btn-outline-primary" @click="openLocationDeleteModal(row.id)">Delete</button>
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelLocationTable',
        mixins: [ tableMixin ],
        methods: {
            ...mapActions('location/list', [
                'openConfirmDeleteModal'
            ]),
            status(row) {
                return row.status ? 'Active' : 'Inactive';
            },
            openLocationDeleteModal(locationId){
                this.openConfirmDeleteModal({recordId: locationId, namespace: 'location'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(calc(var(--cols) - 1), minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>