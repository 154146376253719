<template>
    <div id="map"></div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
    mounted() {
        let gmApiScript = document.createElement('script'),
            self = this;
        gmApiScript.setAttribute('src', 'https://unpkg.com/@googlemaps/js-api-loader@1.0.0/dist/index.min.js');
        document.body.appendChild(gmApiScript);

        gmApiScript.addEventListener('load', function() {
            if (!self.instanceInitialized) self.initInstance();
            self.initMap();
        })
    },
    computed: {
        ...mapState('location/googleMap', [
            'instanceInitialized'
        ])
    },
    methods: {
        ...mapActions('location/googleMap', [
            'initInstance',
            'initMap',
            'destroyMap'
        ])
    },
    beforeDestroy() {
        this.destroyMap();
    }
}
</script>

<style lang="scss" scoped>
    #map {
        height: 100%;
        width: 100%;
    }
</style>