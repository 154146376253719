<template>
    <div class="vue-site-search-1" :data-expanded="searchExpand">
        <p class="show-site-search" @click="searchClick"><span class="fas fa-search"></span></p>
        <div class="vss-input-group form-group">
            <input
                class="form-control"
                type="search"
                placeholder="Search website"
                ref="siteSearch"
                v-model="siteSearch"
                @blur="focusOutSearch"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "navInfo",
        "designConfig",
        "pageUuid",
        "searchTemplate"
    ],
    data() {
        return {
            siteSearch: '',
            searchExpand: false
        }
    },
    methods: {
        searchClick() {
            this.searchExpand = true;
            this.$refs.siteSearch.focus();
        },
        focusOutSearch(event) {
            this.searchExpand = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../../sass/vue-import';

    .vue-site-search-1 {
        position: relative;
        display: flex;
        align-items: center;
        transition: 200ms ease-out;
        z-index: 3;
    }

    .show-site-search {
        color: var(--themeComplementCompliant-hsl);
        font-size: 18px;
        margin: 0;
        line-height: 1;
        z-index: 1;
        cursor: pointer;
        transition: 200ms ease-out;

        .vue-site-search-1[data-expanded] & {
            pointer-events: none;
        }
    }

    .vss-input-group {
        margin: 0;
        padding: .5rem;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        opacity: 1;
        transition: 200ms ease-out;
        background-color: var(--themeComplement-hsl);
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
        box-shadow: 0 2px 5px rgba(black, .1);
        z-index: 1;

        .vue-site-search-1:not([data-expanded]) & {
            transform: translateY(-100%);
            opacity: 0;
            pointer-events: none;
        }

        input[type="search"] { font-size: 18px; }
    }
</style>