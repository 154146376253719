// Todo
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes
// Particularly value, placeholder, autocomplete, disabled, & pattern
// https://laravel.com/docs/7.x/validation#available-validation-rules
// Steal any potential client side validation rules that can be passed into validate="" as a string/array
// parse the string into an array and loop through each rule and return data 'valid': true if all pass

/**
 * This mixin contains the logic that is shared across all input types among the Vue form components
 */

 export const mixin = {
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            required: true
        },
        width: {
            type: [Number, String],
            validator: (value) => {
                if (parseInt(value)) {
                    return parseInt(value) > 0 && parseInt(value) <= 12;
                }
                console.error(`Property 'width': '${value}' is invalid. Please enter an integer between 1 and 12`);
            }
        },
        required: {
            type: Boolean,
            default: false
        },
        floatLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String
        },
        requiredMessage: {
            type: String
        },
        id: {
            type: String
        },
        groupClass: {
            type: String
        },
        inputClass: {
            type: String
        },
        title: {
            type: String
        },
        value: {
            type: [Object, String, Array, Boolean, Number]
        },
        validate: {
            type: [String, Array]
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            labelFloating: false,
            inputHiddenClass: "",
            notAllowedValues: ['symbol', 'bigint', 'function', 'undefined']
        }
    },
    updated() {
        this.checkLabel();
    },
    computed: {
        inputListener() {
            const vm = this;
            return Object.assign({},
                this.$listeners,
                {
                    input: function (event) {
                        switch ( event.target.type ) {
                            case 'checkbox':
                                if ( typeof vm.value !== 'object' ) {
                                    vm.$emit('input', event.target.checked);
                                }
                                break;
                            case 'color':
                                break;
                            default:
                                vm.$emit('input', event.target.value);
                                break;
                        }
                    },
                    change: function (event) {
                        switch ( event.target.type ) {
                            case 'date':
                            case 'time':
                            case 'color':
                                vm.$emit('input', event.target.value);
                                break;
                        }
                    }
                }
            )
        },
        groupBinder() {
            return {
                class: [this.widthClass, this.groupClass, this.inputHiddenClass]
            }
        },
        inputBinder() {
            return {
                ...this.$attrs,
                class: this.inputClass,
                name: this.inputName,
                id: this.inputId,
                title: this.inputTitle,
                required: this.required,
                readonly: this.readonly,
                'data-msg': this.inputRequiredMessage,
            }
        },
        widthClass() {
            return this.width ? `viw-${this.width}` : false;
        },
        inputRequiredMessage() {
            if (this.required) {
                return this.requiredMessage || `Please enter a ${this.inputLabel.toLowerCase()}`
            } else {
                return false;
            }
        },
        inputTitle() {
            if (this.title) {
                return this.title;
            } else {
                let fallbackTitle = this.inputLabel.toLowerCase();
                let aAn = fallbackTitle.match(/^[aeiou].*/gi) ? 'an' : 'a';
                return `Enter ${aAn} ${fallbackTitle}`;
            }
        },
        inputLabel() {
            return this.label || this.name;
        },
        inputId() {
            return this.id || `${this.inputLabel.toLowerCase().replace(/[\s+\W]+/g, '_').replace(/_+$/g, '')}_${this._uid}`;
        },
        inputName() {
            return this.name || `${this.inputLabel.toLowerCase().replace(/[\s+\W]+/g, '_').replace(/_+$/g, '')}`;
        }
    },
    methods: {
        focus() {
            if (this.floatLabel) {
                this.labelFloating = true;
            }
        },
        unfocus() {
            if (this.floatLabel && !event.target.value) {
                this.labelFloating = false;
            }
        },
        checkLabel() {
            let result = false;

            if (!this.notAllowedValues.includes(typeof this.value) && this.value !== null) {
                switch (typeof this.value) {
                    case 'object':
                        result = Array.isArray(this.value) ? this.value.length !== 0 : Object.keys(this.value).length !== 0;
                        break;
                    case 'boolean':
                        result = this.value;
                        break;
                    case 'number':
                        result = true;
                        break;
                    case 'string':
                        result = this.value.length !== 0;
                        break;
                    default:
                        result = true;
                        break;
                }
            }

            this.labelFloating = result;
        }
    },
    mounted() {
        // Check if input is hidden
        this.inputHiddenClass = document.querySelector(`#${this.inputId}`).getAttribute("hidden") || document.querySelector(`#${this.inputId}`).type == "hidden" ? "d-none" : "";
        this.checkLabel();
    }
}