import { render, staticRenderFns } from "./VpanelMarketingMessageTable.vue?vue&type=template&id=d08f1d62&scoped=true&"
import script from "./VpanelMarketingMessageTable.vue?vue&type=script&lang=js&"
export * from "./VpanelMarketingMessageTable.vue?vue&type=script&lang=js&"
import style0 from "./VpanelMarketingMessageTable.vue?vue&type=style&index=0&id=d08f1d62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d08f1d62",
  null
  
)

export default component.exports