<template>
    <vue-hcaptcha v-if="hcaptchaSiteKey"
        :id="uuid"
        :size="size"
        :theme="theme"
        :tabindex="tabIndex"
        ref="invisibleHcaptcha"
        @error="$emit('error')"
        @verify="$emit('verify')"
        :sitekey="hcaptchaSiteKey"
        @expired="$emit('expired')"
    />
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
    export default {
        name: 'VueRecaptcha',
        props: {
            execute: {
                type: Boolean
            },
            hcaptchaSiteKey: {
                type: String,
                default: ''
            },
            size: {
                type: String,
                default: 'normal'
            },
            theme: {
                type: String,
                default: 'light'
            },
            tabIndex: {
                type: String,
                default: '-1'
            }
        },
        components: { VueHcaptcha },
        data() {
            return {
                executeValue: this.execute
            }
        },
        computed: {
            uuid() {
                return `hcaptcha_${uuidv4()}`;
            }
        },
        methods: {
            executeInvisibleHcaptcha(){
                if(this.size == 'invisible' && this.execute){
                    this.$refs.invisibleHcaptcha.execute();
                }
            }
        },
        watch: {
            execute(){
                this.executeInvisibleHcaptcha();
            }
        }
    }
</script>

<style lang="scss" scoped>
    :deep(iframe) {
        display: block;
    }
</style>