<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div :class="{'float-wrapper': floatLabel, 'float-label': labelFloating}">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <select
                class="form-control"
                v-bind="inputBinder"
                v-on="inputListener"
                @focus="focus"
                @blur="unfocus"
            >
                <option
                    v-for="(option, index) in statesWithExclusions"
                    :key="index"
                    :value="option.value"
                    :hidden="option.hidden"
                    :disabled="option.disabled"
                    :selected="value == option.value"
                >{{ option.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        exclude: {
            type: Array,
        }
    },
    data() {
        return {
            states: [
                // If nothing is passed in to the v-model the input name will act as the placeholder
                { value: "", name: "", hidden: true, disabled: true },
                { value: "AL", name: "Alabama" },
                { value: "AK", name: "Alaska" },
                { value: "AZ", name: "Arizona" },
                { value: "AR", name: "Arkansas" },
                { value: "CA", name: "California" },
                { value: "CO", name: "Colorado" },
                { value: "CT", name: "Connecticut" },
                { value: "DE", name: "Delaware" },
                { value: "DC", name: "DC" },
                { value: "FL", name: "Florida" },
                { value: "GA", name: "Georgia" },
                { value: "HI", name: "Hawaii" },
                { value: "ID", name: "Idaho" },
                { value: "IL", name: "Illinois" },
                { value: "IN", name: "Indiana" },
                { value: "IA", name: "Iowa" },
                { value: "KS", name: "Kansas" },
                { value: "KY", name: "Kentucky" },
                { value: "LA", name: "Louisiana" },
                { value: "ME", name: "Maine" },
                { value: "MD", name: "Maryland" },
                { value: "MA", name: "Massachusetts" },
                { value: "MI", name: "Michigan" },
                { value: "MN", name: "Minnesota" },
                { value: "MS", name: "Mississippi" },
                { value: "MO", name: "Missouri" },
                { value: "MT", name: "Montana" },
                { value: "NE", name: "Nebraska" },
                { value: "NV", name: "Nevada" },
                { value: "NH", name: "New Hampshire" },
                { value: "NJ", name: "New Jersey" },
                { value: "NM", name: "New Mexico" },
                { value: "NY", name: "New York" },
                { value: "NC", name: "North Carolina" },
                { value: "ND", name: "North Dakota" },
                { value: "OH", name: "Ohio" },
                { value: "OK", name: "Oklahoma" },
                { value: "OR", name: "Oregon" },
                { value: "PA", name: "Pennsylvania" },
                { value: "RI", name: "Rhode Island" },
                { value: "SC", name: "South Carolina" },
                { value: "SD", name: "South Dakota" },
                { value: "TN", name: "Tennessee" },
                { value: "TX", name: "Texas" },
                { value: "UT", name: "Utah" },
                { value: "VT", name: "Vermont" },
                { value: "VA", name: "Virginia" },
                { value: "WA", name: "Washington" },
                { value: "WV", name: "West Virginia" },
                { value: "WI", name: "Wisconsin" },
                { value: "WY", name: "Wyoming" }
            ]
        }
    },
    computed: {
        statesWithExclusions() {
            if (this.exclude) {
                return this.states.filter(state => {
                    return this.exclude.every(exclusion => exclusion.toUpperCase() !== state.value );
                });
            } else {
                return this.states;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .float-wrapper { position: relative; }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .float-wrapper & {
            position: absolute;
            left: .5rem;
            top: .875rem;
            transform: translateY(0);
            background-color: transparent;
            padding: 0 .25rem;
            transition: 200ms ease-out;
            pointer-events: none;
            line-height: 1;
            border-radius: 2px;
            opacity: .5;
        }

        .float-wrapper.float-label & {
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
        }
    }
</style>