<template>
    <TableWrapper
        :searchable="false"
        v-on="wrapperEvents"
        v-bind="wrapperBinder"
        :item-count="allTableInfo.itemCount"
        :active-page="allTableInfo.activePage"
        @update-page="allTableInfo.updateActivePage($event);setCurrentPageToSession($event);"
    >
        <div class="st-filters my-2">
            <!--Form Filters-->
            <VpanelFormSubmissionFilters
                v-if="!formHasRevisions"
                :form-version="currentForm"
                class="justify-content-start"
            />

            <div class="fb-input-filters">
                <div
                    class="form-group flex-grow-1 mb-0"
                    :class="{'mr-2': select_index < 2}"
                    :key="`sortableColumn_${select_index}`"
                    v-for="(value, select_index) in sortableColumns"
                >
                    <select class="form-control" @input="updateSortableColumns(select_index)" v-model="sortableColumns[select_index].name">
                        <option
                            v-for="(label, inputId) in currentForm.inputs"
                            :key="inputId"
                            :data-input-id="inputId"
                        >{{ label }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="st-hidden-sorts">
            <p
                v-for="(header, column_header_index) in sortableColumns"
                :key="`currentSortableColumnHeaders_${column_header_index}`"
            >
                {{ header.name }}
            </p>
            <p @click="allTableInfo.sortBy('created_at', null, 'date')" :class="allTableInfo.sortClass('created_at')">Submitted</p>
        </div>
        <div class="simple-table" style="--cols:5;">
            <p
                class="st-header"
                :class="{'first-col': column_header_index == 0}"
                v-for="(header, column_header_index) in sortableColumns"
                :key="`currentSortableColumnHeaders_${column_header_index}`"
            >
                {{ header.name }}
            </p>

            <p class="st-header" @click="allTableInfo.sortBy('created_at', null, 'date')" :class="allTableInfo.sortClass('created_at')">Submitted</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="(submission, submission_index) in displayedList">
                    <p
                        :data-status="submissionStatus(submission)"
                        :key="`${submission.id}_${column_header_index}`"
                        :class="{'first-col': column_header_index == 0}"
                        v-for="(header, column_header_index) in sortableColumns"
                    >
                        <span class="st-hidden">{{ header.name }}</span>
                        <strong v-html="submissionValue({submission_index, column_header_index})"></strong>
                    </p>

                    <p
                        :key="submission.id+'created_at'"
                        :data-status="submissionStatus(submission)"
                    >
                        <span class="st-hidden">Submitted</span>
                        {{ submission.created_at }}
                    </p>

                    <div
                        class="last-col"
                        :key="submission.id+'options'"
                        :data-status="submissionStatus(submission)"
                    >
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <!--View Submission Button-->
                            <VpanelFormBuilderButton
                                namespace=""
                                button-text="View"
                                router-path="View Submission"
                                :router-path-params="{
                                    id: currentForm.id,
                                    submissionId: submission.id
                                }"
                            />

                            <!--Archive Submission Button-->
                            <VpanelFormBuilderButton
                                namespace=""
                                button-color="secondary-blue"
                                @button-event="updateArchiveStatus(submission)"
                                :button-text="submission.archived ? 'Unarchive': 'Archive'"
                                :title="`Internal link to view the submissions for the ${submission.name} form`"
                            />
                        </div>
                    </div>
                </template>
            </template>

            <div v-else style="grid-column: 1 / -1;">
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelFormSubmissionsTable',
        mixins: [ tableMixin ],
        props: [
            'currentForm',
            'allTableInfo',
            'formHasRevisions'
        ],
        computed: {
            ...mapGetters('forms', {
                sortableColumns: 'versions/list/getSortableColumns',
                submissionValue: 'submissions/list/getSubmissionValue',
            })
        },
        methods: {
            ...mapActions('forms', {
                setSortableColumns: 'versions/list/setSortableColumns',
                updateArchiveStatus: 'submissions/modify/updateArchiveStatus',
                setCurrentPageToSession: 'submissions/list/setCurrentPageToSession',
                updateTableDataAfterSearch: 'submissions/list/updateTableDataAfterSearch',
            }),
            updateSortableColumns(sortableColumnIndex){
                let selectedValue   = event.target.value;
                let selectedInputId = event.target.selectedOptions[0].getAttribute('data-input-id');

                this.setSortableColumns({selectedValue, selectedInputId, sortableColumnIndex});
            },
            submissionStatus(submission) {
				if (submission.archived) {
					return "archived";
				} else if (submission.viewed == 0) {
					return "unseen";
				} else {
					return "seen";
				}
			}
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/vue-import';
    #forms-module-section {
        .st-filters {
            gap: 1rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .fb-badge-row { flex-grow: 99; }

            .fb-input-filters {
                display: flex;
                flex-grow: 1;
            }
        }

        .simple-table {
            // Custom col structure to make the last col only be as wide as it needs to be
            --custom-col-structure: repeat(calc(var(--cols) - 1), minmax(min-content, auto)) minmax(min-content, max-content);

            .first-col {
                @include fluid-size(4px, 16px, padding-left);

                &[data-status="unseen"] {
                    border-left: 3px solid var(--warning);
                }
            }

            p[data-status="archived"],
            div[data-status="archived"]{
                background-color: var(--light);
            }
        }
    }
</style>