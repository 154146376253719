<template>
    <div>
        <div class="staff-filter" v-if="showFilters">
            <vue-select
                class="m-1 flex-grow-1"
                v-model="divisionFilter"
                name="Filter by Division"
                :options="divisionOptions"
                v-if="parseInt(configs.ED) && divisionOptions.length > 2"
            ></vue-select>
            <vue-select
                v-model="locationFilter"
                class="m-1 flex-grow-1"
                name="Filter by Location"
                :options="locationOptions"
                v-if="parseInt(configs.EL) && locationOptions.length > 2"
            ></vue-select>
            <button
                class="btn btn-secondary m-1"
                :disabled="!divisionFilter && !locationFilter"
                @click.prevent="divisionFilter=0;locationFilter=0;"
            ><span class="fas fa-filter mr-1"></span>Reset Filter</button>
        </div>

        <div class="staff-list">
            <div
                class="staff-list-item"
                v-for="(staffMember, index) in getFilteredStaff"
                :key="`staff-${index}`"
            >
                <div class="staff-content">
                    <div class="staff-image-wrapper" v-if="parseInt(configs.EI) == 1">
                        <img
                            class="staff-image"
                            style="display: none"
                            @load="useImageFallback"
                            :src="staffMember.image_path"
                            :alt="`Image of ${staffMember.full_name}`"
                        />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="fallback-icon"
                            viewBox="0 0 100 100"
                        >
                            <circle fill="white" cx="50" cy="100" r="35" />
                            <circle fill="white" cx="50" cy="40" r="20" />
                        </svg>
                    </div>
                    <p class="staff-name">{{ staffMember.full_name }}</p>
                    <p class="staff-position" v-if="configs.EP==1">{{ staffMember.position }}</p>
                    <p class="staff-email" v-if="(configs.EE == 1 && configs.DSE == 1) && staffMember.email"><span class="fas fa-envelope mr-1"></span> {{ staffMember.email.replace(/([^\w\s])/g, "&#8203;$&") }}</p>
                    <p class="staff-phone" v-if="configs.EPH==1 && staffMember.phone"><span class="fas fa-phone mr-1"></span> {{ staffMember.phone }}</p>
                    <p class="staff-nmls" v-if="configs.NMLS==1 && staffMember.nmls"><strong>NMLS</strong> {{ staffMember.nmls }}</p>
                </div>
                <div class="staff-buttons">
                    <button
                        class="btn btn-primary"
                        @click="modalShow('contactModal', staffMember.id)"
                        v-if="configs.EE==1 && staffMember.email"
                    >Contact Me</button>
                    <button
                        class="btn btn-secondary"
                        @click="modalShow('bioModal', staffMember.id)"
                        v-if="configs.EB==1 && staffMember.bio"
                    >View Details</button>
                </div>
            </div>
        </div>

        <vue-modal :visible="bioModalVisible" @on-close="modalHide('bioModal')">
            <template #header>
                <h4 class="m-0">{{ currentStaffGetProperty("full_name") }}'s Bio</h4>
            </template>

            <div class="staff-division-location-wrapper" v-if="parseInt(configs.EL) || parseInt(configs.ED)">
                <SiteStaffDivisionsOrLocationsDisplay
                    type="locations"
                    :locations="locations"
                    v-if="parseInt(configs.EL) && shouldDisplayDivisionsOrLocations('locations')"
                />

                <SiteStaffDivisionsOrLocationsDisplay
                    type="divisions"
                    :divisions="divisions"
                    v-if="parseInt(configs.ED) && shouldDisplayDivisionsOrLocations('divisions')"
                />
            </div>
            <div class="m-0" v-html="currentStaffGetProperty('bio')"></div>
        </vue-modal>

        <vue-modal :visible="contactModalVisible" @on-close="modalHide('contactModal')">
            <template #header>
                <h4 class="m-0">Contact {{ currentStaffGetProperty("full_name") }}</h4>
                <p class="alert alert-success m-2" v-if="showSuccess"><strong>Success!</strong> Your message was sent.</p>
                <p class="alert alert-danger m-2" v-if="showError"><strong>Error!</strong> Your message was not sent. Please try again later.</p>
            </template>

            <form id="staff-contact-form" @submit.prevent="submitContactForm" ref="staffContactForm">
                <vue-input name="Your Name" required v-model="contactForm.name" />
                <vue-input name="Email" type="email" required v-model="contactForm.email" />
                <vue-input name="Phone" required v-model="contactForm.phone" />
                <vue-textarea name="Message" rows="4" v-model="contactForm.message" required />
                <vue-checkbox group-class="mb-0" name="Agreement" required label="I agree not to submit sensitive information through this form." />
            </form>

            <template #footer>
                <div class="d-flex justify-content-end">
                    <button
                        class="btn btn-primary"
                        type="submit"
                        form="staff-contact-form"
                    >Submit</button>
                </div>
            </template>
        </vue-modal>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "StaffListingTemplate1",
    data() {
        return {
            contactModalVisible: false,
            bioModalVisible: false,
            showSuccess: false,
            showError: false,
            contactForm: {
                name: "",
                email: "",
                phone: "",
                message: "",
            },
        };
    },
    computed: {
        divisionFilter: {
            get() {
                return this.$store.state.staff.divisionFilter;
            },
            set(value) {
                this.$store.commit('staff/SET', ['divisionFilter', Number(value)]);
            }
        },
        locationFilter: {
            get() {
                return this.$store.state.staff.locationFilter;
            },
            set(value) {
                this.$store.commit('staff/SET', ['locationFilter', Number(value)]);
            }
        },
        ...mapState("staff", [
            "currentStaff",
            "divisions",
            "locations",
            "configs",
            "staff",
        ]),
        ...mapGetters("staff", [
            "currentStaffGetProperty",
            "locationsWithStaff",
            "getFilteredStaff",
            "getDivisions",
        ]),
        divisionOptions() {
            return this.getOptions('divisions','name');
        },
        locationOptions() {
            return this.getOptions('locationsWithStaff','title');
        },
        showFilters() {
            return (parseInt(this.configs.ED) && this.divisionOptions?.length > 2) || (parseInt(this.configs.EL) && this.locationOptions?.length > 2);
        }
    },
    methods: {
        ...mapActions("staff", [
            "currentStaffReset",
            "currentStaffSet",
        ]),
        ...mapMutations("staff", [
            "SET",
        ]),
        getOptions(state,name) {
            let arr = [{ name: "", value: "", hidden: true }];
            if (this[state]) Object.entries(this[state]).forEach(([key,val]) => arr.push({ name: val[name], value: key }));
            return arr;
        },
        useImageFallback(e) {
            e.target.nextElementSibling.style.display = "none";
            e.target.style.display = "block";
        },
        contactFormReset() {
            this.$refs.staffContactForm.reset();
            this.contactForm = {
                name: "",
                email: "",
                phone: "",
                message: "",
            };

            this.showError = false;
            this.showSuccess = false;
        },
        modalShow(modalName, currentStaffId) {
            this.currentStaffSet(currentStaffId);
            this[`${modalName}Visible`] = true;
        },
        modalHide(modalName) {
            let vm = this;
            this[`${modalName}Visible`] = false;
            setTimeout(function () {
                vm.currentStaffReset();

                if (modalName == "contactModal") {
                    vm.contactFormReset();
                }
            }, 200);
        },
        shouldDisplayDivisionsOrLocations(divisionsOrLocations){
            let records = this.currentStaffGetProperty(divisionsOrLocations);
            return records && records.length;
        },
        submitContactForm() {
            // create FormData from reactive data
            let formData = new FormData();
            formData.append("staff_id", this.currentStaff);
            Object.entries(this.contactForm).forEach((entry) => {
                formData.append(entry[0], entry[1]);
            });

            // create data object from FormData
            let obj = {};
            for (var p of formData.entries()) {
                let name = p[0];
                let value = p[1];
                obj[name] = value;
            }

            axios
                .post("/staff/email-customer", obj)
                .then((response) => {
                    this.showError = false;
                    this.showSuccess = true;
                })
                .catch((error) => {
                    this.showError = true;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.staff-filter {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.5rem;
    flex-wrap: wrap;
    background-color: hsl(0deg, 0%, 95%);
}

.staff-list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem -1rem;
}

.staff-list-item {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    flex-grow: 1;
    flex-shrink: 0;

    @include desktop {
        flex-basis: 25%;
        &:nth-last-child(-n + 4) {
            flex-grow: 0;
        }
    }
    @include tablet {
        flex-basis: 50%;
        &:nth-last-child(-n + 2) {
            flex-grow: 0;
        }
    }

    @include mobile {
        flex-basis: 100%;
    }

    .staff-content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    p {
        line-height: 1.1;
        text-align: center;
        margin-top: 0;
        margin-bottom: 1rem !important;
    }

    .staff-buttons {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        .btn {
            flex-grow: 1;
            margin: 0.25rem;
        }
    }
}

.staff-image-wrapper {
    position: relative;
    padding-top: 50%;
    width: 50%;
    height: 0;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 1rem;

    img {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: hsl(0deg, 0%, 80%);
    }
}

.staff-name {
    @include fluid-size(18px, 24px, font-size);
    font-weight: bold;
    color: var(--themeColor-hsl);
}

.staff-position {
    @include fluid-size(14px, 18px, font-size);
}

.staff-email,
.staff-phone {
    @include fluid-size(12px, 14px, font-size);
}

.staff-division-location-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 1rem;
}
</style>
