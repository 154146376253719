import { render, staticRenderFns } from "./VpanelLocationTable.vue?vue&type=template&id=5138a84e&scoped=true&"
import script from "./VpanelLocationTable.vue?vue&type=script&lang=js&"
export * from "./VpanelLocationTable.vue?vue&type=script&lang=js&"
import style0 from "./VpanelLocationTable.vue?vue&type=style&index=0&id=5138a84e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5138a84e",
  null
  
)

export default component.exports