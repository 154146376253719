<template>
    <div class="p-3">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h5 class="m-0">Submission Details</h5>

            <!--Back to Submissions Button-->
            <VpanelFormBuilderButton
                namespace=""
                button-color="outline-primary"
                button-text="Back to Submissions"
                router-path="Form Submission List"
                :router-path-params="{ id: currentSubmission.form_id }"
            />
        </div>
        <div class="vp-status-bar-list">
            <p>Status: <span>{{ submissionStatus }}</span></p>
            <p>Submitted: <span>{{ currentSubmission.created_at }}</span></p>
        </div>
        <div class="flex-table">
            <div class="ft-row ft-header">
                <p class="ft-cell">Input Name</p>
                <p class="ft-cell d-block">Submitted Value</p>
            </div>
            <div class="ft-row" v-for="(value, index) in currentSubmission.formatted_submission" :key="index">
                <p class="ft-cell">{{ value.label }}</p>
                <p class="ft-cell d-block" v-html="value.value"></p>
            </div>
        </div>

        <VpanelFormSubmissionAdminForm
            :submission="currentSubmission"
        />
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'VpanelFormSubmissionViewDetailsPage',
        computed: {
            ...mapState('forms/submissions/modify', {
                currentSubmission: 'activeRecord',
            }),
            submissionStatus() {
                return this.currentSubmission.archived ? 'Archived' : (this.currentSubmission.viewed ? 'Seen' : 'Unread');
            }
        }
    }
</script>