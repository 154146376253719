<template>
    <div class="product-section">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <VpanelEcommAddNewRecordButton namespace="product/addModify" button-text="Add a New Product" router-path="Add Product" />
            <VpanelEcommAddNewRecordButton namespace="product/importation" button-icon="fas fa-file-import" button-color="outline-secondary-blue" title="Import Products" />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Product Table -->
        <VpanelProductTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />

        <!-- Import Modal -->
        <VpanelEcommAddModifyModal
            custom-header="Import Products"
            modal-icon="fas fa-file-import"
            namespace="product/importation"
            form-id="product-importation-form"
            form-name="vpanel-product-importation-form"
        />

        <!-- Delete Product Modal -->
        <VpanelEcommConfirmRecycleModal
            namespace="product"
            modal-id="product-recycle-modal"
            heading-text="Confirm Delete Product"
            ajax-route="/vpanel/modules/ecommerce/product/recycle"
            after-recycle-dispatch-action="product/list/refreshTableData"
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "VpanelProductList",
        computed: {
            ...mapGetters('product/list', {
                tableData: 'getTableData'
            })
        }
    }
</script>