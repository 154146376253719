<template>
    <div class="location-section">
        <AddNewRecordButton namespace="location/addModify" record-type="Add a New Location" router-path="Add Location" />

        <!-- Table Loader -->
        <VueLoader v-if="isTableLoading" />

        <!-- Location Table -->
        <VpanelLocationTable
            v-else
            respond-at="md"
            :data="tableData"
            :items-per-page="10"
            :searchable-data="['title', 'address', 'state', 'city', 'postal_code']"
        />

        <!-- Delete Location Modal -->
        <VueConfirmationModal
            namespace="location/recycle"
            heading-text="Confirm Delete"
            @confirmed-modal="deleteLocation"
            modal-id="location-recycle-modal"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelLocationList',
        computed: {
            ...mapState('location/list', {
                tableData: state => state.tableData,
                isTableLoading: state => state.isTableLoading
            })
        },
        methods: {
            ...mapActions('location/list', [
                'deleteRecord'
            ]),
            deleteLocation(){
                this.deleteRecord({namespace: 'location', routeGroup: 'location'});
            }
        }
    }
</script>