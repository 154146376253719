<template>
    <div class="vm-wrapper" :class="{'vm-hidden': !visible}" @click.self="emitClose">
        <div class="vm-object">
            <div class="vm-header">
                <slot name="header">
                    <h3 class="m-0">Example Header</h3>
                </slot>
            </div>
            <div class="vm-close" @click="emitClose"></div>
            <div class="vm-body">
                <slot>
                    <p class="py-4 m-0">Example Body Content</p>
                </slot>
            </div>
            <div class="vm-footer" v-if="hasFooter">
                <slot name="footer">
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasFooter() {
            return this.$slots.hasOwnProperty('footer');
        }
    },
    methods: {
        emitClose() {
            this.$emit('on-close');
        }
    },
    watch: {
        visible: function(n,o) {
            if (n) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.removeProperty('overflow');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../sass/_vue-import.scss';
    $closeSize: 12px;
    $borders: 1px solid #ddd;

    .vm-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        z-index: 200;
        opacity: 1;
        pointer-events: all;
        transition: 200ms ease-out;

        &.vm-hidden {
            opacity: 0;
            pointer-events: none;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(black, .53);
        }
    }

    .vm-object {
        position: relative;
        background-color: white;
        border-radius: $button-border-radius;
        display: grid;
        max-height: 100%;
        overflow: hidden;
        grid-template:
            "h c" max-content
            "b b" 1fr
            "f f" max-content
            / 1fr ($closeSize + 32px);

        @include fluid-size(
            468px,
            720px,
            width,
            $min-vw:500px,
            $max-vw:1200px
        );

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    .vm-header {
        grid-area: h;
        padding: 1rem;
    }
    .vm-footer {
        grid-area: f;
        padding: 1rem;
    }
    .vm-close {
        grid-area: c;
        padding: 1rem;
        position: relative;
        border-left: $borders;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            height: 3px;
            width: 3px;
            position: absolute;
            background-color: mix(white, black, 50%);
            top: 50%;
            left: 50%;
            transform-origin: 50% 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:before { width: $closeSize; }
        &:after { height: $closeSize; }
    }

    .vm-body {
        grid-area: b;
        padding: 1rem;
        border-top: $borders;
        border-bottom: $borders;
        height: 100%;
        overflow-y: auto;
    }
</style>