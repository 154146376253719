<template>
    <div class="ecommerce-card">
        <div class="ec-image" :class="{'no-image': !bgImageBinder}" :style="bgImageBinder">
            <p class="product-count" v-if="data.product_count">{{ data.product_count }} Item{{ data.product_count > 1 ? 's' : '' }}</p>
        </div>
        <div class="ec-content">
            <div class="ec-content-top"><p class="ec-name">{{ data.name }}</p></div>
            <VueLink
                link-text="View More"
                link-class="btn btn-primary"
                :link-path="data.url"
                :link-title-name="data.name"
                class="mt-2"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    computed: {
        bgImageBinder() {
            let result = false;
            if (this.data.image) result = {
                backgroundImage: `url('${this.data.image}')`
            }
            return result;
        },
    }
}
</script>

<style lang="scss">
    @import '../../../../../../../sass/_vue-import.scss';

    .ecommerce-card {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
    }

    .ec-image {
        background-size: cover;
        padding-top: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: hsl(0deg, 0%, 95%);
        position: relative;

        &.no-image:before {
            content: "";
            position: absolute;
            height: 25%;
            width: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: hsla(0deg,0%,0%,5%);
            clip-path: polygon(0% 100%, 40% 0%, 65% 50%, 77.5% 25%, 100% 100%);
        }

        .product-count {
            position: absolute;
            top: 0;
            right: 0;
            padding: 6px;
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);
            border-bottom-left-radius: 5px;
            line-height: 1;
            background-color: var(--success);
            color: white;
            font-weight: bold;
            font-size: 12px;
        }
    }

    .ec-content {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    .ec-name {
        @include fluid-size(16px, 20px, --ttmfs);
        --ttmlh: 1.1;
        --ttmlc: 2;
        display: block;
        display: -webkit-box;
        font-family: var(--display-font-family, Arial,sans-serif);
        font-weight: 700;
        font-size: var(--ttmfs);
        color: var(--optimumOnWhite-hsl);
        line-height: var(--ttmlh);
        max-width: 2000px; // A max-width is needed, the value just needs to be above the width of the text box, so I made it huge
        max-height: calc( var(--ttmfs) * var(--ttmlh) * var(--ttmlc) );
        margin: 0 auto;
        -webkit-line-clamp: var(--ttmlc);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>