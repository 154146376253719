<template>
    <div class="services group">
        <p v-if="!location.services.length">No Services Listed</p>
        <template v-else>
            <p class="service" v-for="id in location.services" :key="id + '_sl'">
                <span :class="services[id].icon_class"></span>
                {{ services[id].name }}
            </p>
        </template>
    </div>
</template>

<script>
export default {
    props: ["location", "id", "services"]
}
</script>

<style lang="scss" scoped>
    .services {
        display: flex;
        flex-wrap: wrap;
        margin: -.25rem;

        .service {
            text-align: center;
            font-size: 12px;
            flex: 1 0 calc(50% - .5rem);
            margin: .25rem !important;
            padding: .25rem;
            line-height: 1;
            border-radius: 5px;
            background-color: hsl(0deg,0%,95%);
            font-weight: bold;
            min-width: max-content;

            svg {
                margin-right: .25rem;
            }
        }
    }
</style>