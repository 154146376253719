<template>
    <div class="customer-section">
        <!-- Table Loader -->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Customer Table -->
        <VpanelCustomerTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "VpanelCustomerList",
        computed: {
            ...mapGetters('customer/list', {
                tableData: 'getTableData'
            })
        }
    }
</script>