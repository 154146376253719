<template>
    <VueModal :visible="isModalOpen" @on-close="closeFormBuilderModal" id="form-builder-modal">
        <template #header>
            <h5 class="modal-title"><span :class="`${modalIcon} mr-1 text-tertiary`"></span>&nbsp;{{ customHeader }}</h5>
        </template>

        <!-- Form Include -->
        <component
            :is="getCurrentForm"
            v-bind="getFormProps"
            :class="{'d-none': isFormProcessing}"
        />

        <VpanelLoader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeFormBuilderModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" :form="formId">{{ isFormProcessing ? 'Processing...' : buttonText }}</button>
            </div>
        </template>
    </VueModal>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelFormBuilderImportExportModal',
        props: [
            'formId',
            'formName',
            'modalIcon',
            'namespace',
            'customHeader',
            'isActionImport'
        ],
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.computed = {
                ...mapState(storeNamespace, {
                    isModalOpen: state => state.isModalOpen,
                    activeRecord: state => state.activeRecord,
                    errorMessage: state => state.errorMessage,
                    isFormProcessing: state => state.isFormProcessing,
                }),
                buttonText(){
                    return this.isActionImport ? 'Save' : 'Copy';
                },
                getCurrentForm(){
                    return this.formName;
                },
                getFormProps(){
                    return {
                        activeRecord: this.activeRecord,
                        errorMessage: this.errorMessage,
                        isActionImport: this.isActionImport,
                    }
                }
            }

            this.$options.methods = {
                ...mapActions(storeNamespace, [
                    'closeModal',
                    'resetModal'
                ]),
                closeFormBuilderModal(){
                    let vm                       = this;
                    const resetModalActionExists = Object.keys(vm.$store._actions).findIndex(key => key === `${vm.namespace}/resetModal`) !== -1;

                    vm.closeModal();

                    if(resetModalActionExists){
                        setTimeout(function(){vm.resetModal()}, 200);
                    }
                }
            }
        }
    }
</script>