import { mapState, mapMutations, mapActions } from "vuex";
import Glide from "@glidejs/glide";

export const mixin = {
    data() {
        return {
            windowWidth: window.innerWidth,
            initSuccess: false,
            slider: {},
        }
    },
    computed: {
		...mapState("marketingMessage", [
            "slides"
        ]),
        slideCount() {
            return this.slides.length;
        },
        bgImageSize() {
            let result;
            if (this.windowWidth < 768) result = 'mobile_path';
            else if (this.windowWidth < 1024) result = 'tablet_path';
            else result = 'desktop_path';

            return result;
        }
	},
	methods: {
        onResize() {
            setTimeout(() => {
                this.windowWidth = window.innerWidth;
                this.slider.update();
                if (typeof this.playing !== undefined) {
                    this.playing = true;
                }
            }, 200);
        },
        init() {
            if (!this.initSuccess) {
                this.slider = new Glide('.glide', this.options);
                this.slider.mount();
                this.initSuccess = true;

                //disable the slider if only one slide
                if (!(this.slides.length > 1)) {
                    this.slider.disable();
                }
            }
        }
	},
    updated() {
        this.init();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
	mounted() {
        // this.fetchData();
        this.init();
        window.addEventListener('resize', this.onResize);
	}
}