// TODO
// Refactor the props to move any default logic there
// Figure out what needs to be required and what doesn't, right now nothing is??
// Potentially integrate slots??

<template>
    <component
        :is="elementType"
        v-bind="linkBinder"
        v-html="getLinkText"
        v-on="linkListener"
    ></component>
</template>

<script>
export default {
    props: {
        linkObj: Object,
        linkText: String,
        linkPath: String,
        linkTitle: String,
        linkTarget: String,
        linkClass: String,
        linkIcon: String,
        linkTitleName: String,
        linkAttributes: Object,
    },
    data() {
        return {
            obj: this.linkObj,
            path: this.setData(this.linkPath, "link"),
            title: this.setData(this.linkTitle, "title"),
            titleName: this.setData(this.linkTitleName, "titleName"),
            target: this.setData(this.linkTarget, "target"),
            classes: this.setData(this.linkClass, "class"),
            attributes: this.setData(this.linkAttributes, "attributes"),
        }
    },
    methods: {
        setData(prop, objProp) {
            if (prop) {
                return prop;
            } else if (this.linkObj) {
                return this.linkObj[objProp];
            }
        }
    },
    computed: {
        linkBinder() {
            return {
                ...this.attributes,
                class: ['vue-link', this.classes],
                href: this.path,
                target: this.getLinkTarget,
                title: this.getLinkTitle
            }
        },
        linkListener() {
            return Object.assign({},
                this.$listeners,
            )
        },
        elementType() {
            return !this.path || this.path == '#' ? 'p' : 'a';
        },
        getLinkURLObj() {
            return new URL(this.path, location.origin);
        },
        isInternalLink() {
            let url = this.getLinkURLObj;
            return location.hostname === url.hostname || !url.hostname;
        },
        getLinkTarget() {
            if ( this.target ) {
                return this.target;
            }

            let url = this.getLinkURLObj;

            if (url.protocol == 'tel:' || url.protocol == "mailto:" || this.elementType !== 'a') {
                return false;
            }

            return this.isInternalLink ? '_self' : '_blank';
        },
        getLinkTitle() {
            if ( this.title ) return this.title;
            if ( this.elementType == 'p' ) return false;

            let protocol = this.getLinkURLObj.protocol;
            let locationText = this.isInternalLink ? 'Internal' : 'External';
            let targetText = this.getLinkTarget == '_self' ? 'the same' : 'a new';
            let titleName = this.titleName || this.text;

            if ( protocol == "tel:") {
                return 'Opens ' + titleName + ' in your default calling application';
            } else if ( protocol == "mailto:" ) {
                return 'Opens ' + titleName + ' in your default email application';
            } else {
                return locationText + ' link to ' + titleName + ' in ' + targetText + ' window';
            }
        },
        icon() {
            return this.setData(this.linkIcon, "icon");
        },
        text() {
            return this.setData(this.linkText, "text");
        },
        getLinkText() {
            return (this.icon ? '<span class="' + this.icon + ' ' + (this.text ? 'mr-1' : '') + '"></span>' : '') + (this.text || '');
        }
    }
}
</script>

<style lang="scss" scoped>
    .vue-link:not([href]) {
        margin: 0;
        cursor: pointer;
        text-decoration: underline; // PRF

        &:hover { text-decoration: none; }
    }

    p.vue-link {
        margin: 0;
    }
</style>