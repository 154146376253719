<template>
    <div class="location-section">
        <AddNewRecordButton namespace="locationService/addModify" record-type="Add a New Location Service" />

        <!-- Table Loader -->
        <VueLoader v-if="isTableLoading" />

        <!-- Location Service Table -->
        <VpanelLocationServiceTable
            v-else
            respond-at="md"
            :data="tableData"
            :items-per-page="10"
            :searchable-data="['name']"
        />

        <!--Add/Modify Modal-->
        <AddModifyLocationServiceModal />

        <!-- Delete Location Modal -->
        <VueConfirmationModal
            heading-text="Confirm Delete"
            namespace="locationService/recycle"
            @confirmed-modal="deleteLocationService"
            modal-id="location-service-recycle-modal"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelLocationServiceList',
        computed: {
            ...mapState('locationService/list', {
                tableData: state => state.tableData,
                isTableLoading: state => state.isTableLoading
            })
        },
        methods: {
            ...mapActions('locationService/list', [
                'deleteRecord'
            ]),
            deleteLocationService(){
                this.deleteRecord({namespace: 'locationService', routeGroup: 'location/service'});
            }
        }
    }
</script>