import { render, staticRenderFns } from "./VpanelFormBuilderAddModifyForm.vue?vue&type=template&id=40023410&scoped=true&"
import script from "./VpanelFormBuilderAddModifyForm.vue?vue&type=script&lang=js&"
export * from "./VpanelFormBuilderAddModifyForm.vue?vue&type=script&lang=js&"
import style0 from "./VpanelFormBuilderAddModifyForm.vue?vue&type=style&index=0&id=40023410&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40023410",
  null
  
)

export default component.exports