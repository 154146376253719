<template>
    <div class="ecommerce-module-section">
        <VpanelEcommerceSecondaryNav />

        <RouterView :key="$route.fullPath" />
    </div>
</template>

<script>
    export default {
        name: "VpanelEcommerceModuleIndex"
    }
</script>