<template>
    <div class="footer-template-1" :data-variant="variant">
        <div class="top-bar">
            <div class="dynamic-container">
                <div class="top-links">
                    <vue-link
                        v-for="(navItem,index) in getShownNav"
                        :key="index"
                        :link-text="navItem.page.nav_title"
                        :link-path="navItem.page.url"
                    ></vue-link>
                </div>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="dynamic-container">
                <div class="copyright-bar">
                    <div class="left">
                        <vue-social-links
                            :facebook="facebook"
                            :twitter="twitter"
                            :linkedin="linkedin"
                            :instagram="instagram"
                            :pinterest="pinterest"
                            :youtube="youtube"
                            :vimeo="vimeo"
                        />
                        <p class="m-0">&#169; {{ new Date().getFullYear() }} {{ companyName }}. All rights reserved.</p>
                        <p class="legal-links" v-if="legalLinks.length">
                            <template v-for="(link,index) in legalLinks">
                                <span v-if="index > 0" :key="`legal-${index}-sep`">|</span>
                                <vue-link :key="`legal-${index}`" :link-obj="link"></vue-link>
                            </template>
                        </p>
                    </div>
                    <div class="right">
                        <p class="m-0">Web solutions provided by <vue-link link-text="VGM Forbin" link-path="https://www.forbin.com/" link-title-name=""></vue-link>.</p>
                        <p class="m-0">VGM&nbsp;Forbin is a division of The VGM&nbsp;Group.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { navMixin } from '@v/Core/Site/Mixins/navMixin';
import { footerMixin } from '@v/Core/Site/Mixins/footerMixin';
import { headerFooterMixin } from '@v/Core/Site/Mixins/headerFooterMixin';

export default {
    mixins: [
        navMixin,
        footerMixin,
        headerFooterMixin,
    ],
    inheritAttrs: false,
    computed: {
        getShownNav() {
            // Gets first 5, top-level nav items that are marked as shown
            return this.navInfo.filter((navItem, index) => {
                return navItem.page.show_in_nav && index < 5;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';

    .footer-template-1 {
        color: var(--optimumOnWhite-hsl) !important;

        &::v-deep a { color: var(--optimumOnWhite-hsl) !important; }

        &[data-variant="full"] .dynamic-container {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        &:not([data-variant]) .dynamic-container {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        .top-bar {
            background-color: white;
            padding: 1rem 0;
            border-top: 1px solid hsla(var(--optimumOnWhite), .2);

            .top-links {
                display: flex;
                justify-content: center; // fallback
                justify-content: space-evenly;
                flex-wrap: wrap;
                align-items: center;
                margin: -.5rem;
            }

            .vue-link {
                @include fluid-size(14px, 18px, font-size);
                color: var(--optimumOnWhite-hsl);
                padding: .5rem;
                font-weight: bold;
                text-decoration: none;

                &:hover { text-decoration: underline; }
            }
        }

        .bottom-bar {
            background-color: white;
            padding: 1rem 0;
            border-top: 1px solid hsla(var(--optimumOnWhite), .2);
            @include fluid-size(14px, 16px, font-size);
        }

        .copyright-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
                justify-content: center;
            }

            .left,
            .right { text-align: center; }

            @include not-mobile {
                .left { text-align: left; }
                .right { text-align: right; }
            }
        }

        .legal-links {
            margin: 0;
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin: -.5rem;

            .vue-link { margin: .5rem; }
        }
    }
</style>