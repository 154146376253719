import { headerMixin } from '@v/Core/Site/Mixins/headerMixin';
import { themingMixin } from '@v/Core/Site/Mixins/themingMixin';
import { headerFooterMixin } from '@v/Core/Site/Mixins/headerFooterMixin';

export const SiteDesignPreviewMixin = {
    mixins: [
        headerMixin,
        themingMixin,
        headerFooterMixin,
    ],
    props: [
        "designConfig"
    ],
    computed: {
        logoBinder() {
            let c = this.designConfig?.theme;
            return {
                src: !c.invert_logo && c.dedicated_footer_logo ? c.dedicated_footer_logo : c.logo,
                class: c.invert_logo ? "logo-invert" : ""
            }
        }
    }
}

export default { SiteDesignPreviewMixin }