<template>
    <label class="fb-group" :class="validClass">
        <span class="fb-label">{{ question.label }}<span class="fb-required" v-if="required">*</span></span>
        <select
            ref="control"
            class="fb-control"
            v-bind="[controlBinder]"
            @input="checkValue()"
            @focus="focusQuestion(questionId)"
            @blur="focusQuestion(); checkValue()"
        >
            <option value="" disabled selected hidden>Please select...</option>
            <option
                v-for="optionId in question.options"
                :key="`option_${optionId}`"
                :value="optionId"
            >{{ options[optionId].value }}</option>
        </select>
        <SiteFormBuilderAssistiveText
            :message="requiredMessage || 'Please select an option'"
        />
    </label>
</template>

<script>
import { general, hasOptions, questionChute, questionLadder } from './mixins/SiteFormBuilderControlMixins';

export default {
    name: "FRQuestionSelect",
    mixins: [ general, hasOptions, questionChute, questionLadder ],
    methods: {
        validateFunc(vm) {
            if (!vm.required) return -1;
            return Array.from(vm.$refs.control.options).some(o => o.selected && !o.disabled && o.value);
        }
    }
}
</script>