<template>
    <component
        v-if="searchTemplateName != 'hidden'"
        :is="templateComponent"
        v-bind="{...$props, ...$attrs}"
    ></component>
</template>

<script>
export default {
    props: [
        "searchTemplate",
        "navInfo",
        "designConfig",
        "pageUuid"
    ],
    data() {
        return {
            searchTemplateName: this.searchTemplate,
        }
    },
    computed: {
        template() {
            return this.searchTemplateName || 'Template1';
        },
        templateComponent() {
            let prefix = 'VueSiteSearch',
                check  = Object.keys(Object.getPrototypeOf(this.$options.components)).includes(prefix + this.template);

            return prefix + ( check ? this.template : 'Template1');
        },
    },
    methods: {
        closeMobileMenu(event) {
            this.$emit('close-mobile-menu');
        }
    },
    mounted() {
        document.body.dataset.searchTemplate = this.searchTemplateName || '1';
    }
}
</script>

<style lang="scss">

</style>