<template>
    <div class="overview-listing-wrapper">
        <div class="search-and-settings">
            <form class="search-form" @submit.prevent="setSearchModel()">
                <VueSearch name="Search" v-model="searchModel" />
                <button type="submit" class="btn btn-primary"><span class="fas fa-search"></span></button>
            </form>
            <VueSelect name="Sort" :options="sortOptions" v-model="sortModel" />
            <VueSelect name="Items to Show" :options="amountToShowOptions" v-model.number="list.itemsPerPage"/>
            <button
                v-if="list.hasActiveFilters"
                class="reset-filters btn btn-primary"
                title="This will reset all filters"
                @click.prevent="resetListAndModels()"
            ><span class="fas fa-undo-alt"></span> Reset Filters</button>
        </div>
        <div class="overview-listing">
            <SiteCategoryBrandQuickViewCard
                v-for="category in list.displayedList"
                :key="`category-${category.id}`"
                :data="category"
            />
        </div>
        <div class="bottom-bar">
            <p>{{ list.rangeText }}</p>

            <Pagination
                :active-page="list.activePage"
                :item-count="list.itemCount"
                :items-per-page="list.itemsPerPage"
                @on-update="list.updateActivePage($event)"
            />
        </div>
    </div>
</template>

<script>
    import EloquentSSFP from '@j/core/global/EloquentSSFP';

    export default {
        name: 'SiteProductCategoryOverview',
        props: ['pageType'],
        data() {
            return {
                list: new EloquentSSFP(`/ecommerce-list/${this.pageType}/all`, {
                    searchableData: ['name'],
                    itemsPerPage: 20,
                    activeSorts: ['name']
                }),
                sortOptions: [
                    {
                        name: "Name (A-Z)",
                        value: "1",
                        sortParams: {
                            activeSort: "name",
                            sortDir: "asc",
                            sortType: "string"
                        }
                    },
                    {
                        name: "Name (Z-A)",
                        value: "2",
                        sortParams: {
                            activeSort: "name",
                            sortDir: "desc",
                            sortType: "string"
                        }
                    },
                    {
                        name: "Product Count (Low-High)",
                        value: "3",
                        sortParams: {
                            activeSort: "products:count",
                            sortDir: "asc",
                            sortType: "number"
                        }
                    },
                    {
                        name: "Product Count (High-Low)",
                        value: "4",
                        sortParams: {
                            activeSort: "products:count",
                            sortDir: "desc",
                            sortType: "number"
                        }
                    }
                ],
                sortModel: "1",
                searchModel: '',
                searchDebounceTimer: null,
            }
        },
        computed: {
            amountToShowOptions() {
                let result = [];

                for (let v = 1; v < 4; v++) {
                    let a = v * 20;
                    result.push({ name: a, value: a })
                }

                result.push({ name: "All", value: this.list.itemCount });
                return result;
            },
        },
        methods: {
            setSearchModel() {
                this.list.searchModel = this.searchModel;
            },
            resetListAndModels() {
                this.sortModel = "1";
                this.searchModel = "";
                this.list.resetAll();
            },
        },
        watch: {
            sortModel(n) {
                let c = this.sortOptions.find(opt => {
                        return opt.value === n;
                    }).sortParams;

                let options = this.list.options;
                options['activeSorts'] = [c.activeSort];
                options['sortDirs'] = [c.sortDir];
                options['sortTypes'] = [c.sortType];

                this.list.refreshData(options);
            },
            searchModel(n,o) {
                if (o && !n) {
                    this.setSearchModel();
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';

    .overview-listing {
        @include fluid-size(160px, 200px, --product-min-width);
        @include fluid-size(16px, 32px, --product-grid-gap);

        grid-template-columns: repeat(auto-fill, minmax(var(--product-min-width),1fr));
        grid-auto-rows: auto;
        display: grid;
        grid-auto-flow: dense;
        grid-gap: var(--product-grid-gap);
    }

    .search-and-settings {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem .5rem;

        .search-form {
            display: flex;
            flex-grow: 9999;

            &::v-deep {
                .form-group {
                    margin: 0;
                    padding: 0;
                    flex-grow: auto;
                    flex-basis: none;
                    min-width: none;
                }

                .btn {
                    border-top-right-radius: .25rem;
                    border-bottom-right-radius: .25rem;
                }

                input {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    border-right-width: 0;
                }
            }
        }

        .search-form,
        .form-group {
            margin: 0;
            padding: .5rem;
            flex-grow: 1;
            flex-basis: 150px;
            min-width: max-content;
        }

        .reset-filters {
            margin: .5rem;
            flex-grow: 1;
            min-width: max-content;
        }
    }

    .bottom-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: .75rem -.5rem;

        > * {
          margin: .25rem .5rem;
        }
    }
</style>