<template>
    <div class="site-config-tables">
        <div class="site-config-table">
            <div class="sct-header">
                <p class="sct-name">Name</p>
                <p class="sct-value">Value</p>
                <p class="sct-description">Description</p>
            </div>
            <div class="sct-body">
                <!-- Template -->
                <div class="sct-row">
                    <p class="sct-name"><label for="template">Template</label></p>
                    <div class="sct-value pr-2 sctv-select">
                        <select
                            id="template"
                            name="Template"
                            title="Edit Your Template"
                            @change="updateValue('selectedTemplateId')"
                            class="form-control site-config-input"
                        >
                            <option
                                v-for="template in templateOptions"
                                :key="template.id"
                                :value="template.id"
                                :selected="currentTemplate == template.id"
                            >{{ template.name }}</option>
                        </select>
                    </div>
                    <p class="sct-description">Your Site's Template</p>
                </div>
                <!-- Primary Color -->
                <div class="sct-row">
                    <p class="sct-name"><label for="primary_color">Primary Color</label></p>
                    <SctColorPicker name="Primary Color" property="primary_color" />
                    <p class="sct-description">Your Primary Color</p>
                </div>
                <!-- Secondary Color -->
                <div class="sct-row">
                    <p class="sct-name"><label for="secondary_color">Secondary Color</label></p>
                    <SctColorPicker name="Secondary Color" property="secondary_color" />
                    <p class="sct-description">Restricted by contrast ratio. If the ratio between the primary and secondary color does not surpass 4.5, the secondary color will instead be the highest contrast option between black and white.</p>
                </div>
                <!-- Display Font -->
                <div class="sct-row">
                    <p class="sct-name"><label for="display_font_config">Display Font</label></p>
                    <div class="sct-value pr-2 sctv-select">
                        <select
                            class="form-control site-config-input"
                            title="Edit Display Font"
                            id="display_font_config"
                            name="Display Font"
                            @change="updateValue('display_font')"
                        >
                            <option :selected="designOptions.display_font == `` || !designOptions.display_font" value="" disabled hidden></option>
                            <option v-for="(value, key, index) in fontPairLogic" :key="'displayFont' + index"
                                :selected="designOptions.display_font == key"
                                :value="key"
                            >{{ value.name }}</option>
                        </select>
                    </div>
                    <p class="sct-description">Your display/heading font</p>
                </div>
                <!-- Body Font -->
                <div class="sct-row">
                    <p class="sct-name"><label for="body_font_config">Body Font</label></p>
                    <div class="sct-value pr-2 sctv-select">
                        <select
                            class="form-control site-config-input"
                            title="Choose from suggested Body Font pairings"
                            id="body_font_config"
                            name="Body Font"
                            @change="updateValue('body_font')"
                        >
                            <option :selected="designOptions.body_font == `` || !designOptions.body_font" value="" disabled hidden></option>
                            <template v-for="(value, key) in fontPairLogic">
                                <template v-if="designOptions.display_font == key">
                                    <option v-for="(option, index) in value.options" :key="index"
                                        :selected="designOptions.body_font == option.value"
                                        :value="option.value"
                                    >{{ option.name }}</option>
                                </template>
                            </template>
                        </select>
                    </div>
                    <p class="sct-description">Popular font pairings based on your chosen display font</p>
                </div>
                <!-- Logo -->
                <div class="sct-row">
                    <p class="sct-name"><label for="logo">Logo</label></p>
                    <SctFileUploader property="logo" />
                    <p class="sct-description">Your logo</p>
                </div>
                <template v-if="currentTemplate != 4">
                    <!-- Invert Logo -->
                    <div class="sct-row">
                        <p class="sct-name"><label for="invert_logo">Automatically Adapt Footer Logo</label></p>
                        <input class="sct-value" type="checkbox" name="invert_logo" :checked="designOptions.invert_logo" @change="updateValue('invert_logo', 'checked')">
                        <p class="sct-description">We will automatically adapt your logo so it appears like a watermark in the footer. If you would prefer your original, unedited logo, uncheck this field. If you would prefer to choose your own footer logo, please upload a dedicated footer logo.</p>
                    </div>
                    <!-- Dedicated Footer Logo -->
                    <div class="sct-row" :class="{disabled: designOptions.invert_logo}">
                        <p class="sct-name"><label for="dedicated_footer_logo">Dedicated Footer Logo</label></p>
                        <SctFileUploader property="dedicated_footer_logo" />
                        <p class="sct-description">If you don't wish to automatically convert your logo to monochrome for your footer, you can choose to upload your own</p>
                    </div>
                </template>
            </div>
        </div>

        <!-- Header links -->
        <template v-for="n in linkCount">
            <h4 :key="`link-row-${n}-header`">Header Link {{n}}</h4>

            <div class="site-config-table" :key="`link-row-${n}-table`">
                <div class="sct-header">
                    <p class="sct-name">Property</p>
                    <p class="sct-value">Value</p>
                    <p class="sct-description">Description</p>
                </div>
                <div class="sct-body">
                    <div
                        class="sct-row"
                        v-for="property in linkProperties"
                        :key="`link-${n}-${property.value}`"
                    >
                        <p class="sct-name"><label :for="`link_${n}_${property.value}`">{{ property.name }}</label></p>
                        <div class="sct-value pr-2 sctv-input">
                            <div class="d-flex flex-wrap m-n1">
                                <input
                                    :id="`link_${n}_${property.value}`"
                                    :name="`link_${n}_${property.value}`"
                                    :title="`Edit Link ${n}'s ${property.name}`"
                                    @input="updateValue(`link_${n}_${property.value}`)"
                                    :value="designOptions[`link_${n}_${property.value}`]"
                                    class="form-control site-config-input"
                                />
                            </div>
                        </div>
                        <p class="sct-description">Edit Header Link {{ n }}'s {{ property.name }}</p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'SiteDesignList',
        data() {
            return {
                fontPairLogic: {
                    "'Roboto', sans-serif": {
                        name: 'Roboto',
                        options: [
                            {name:"Open Sans",    value:"'Open Sans', sans-serif"},
                            {name:"Noto Sans JP", value:"'Noto Sans JP', sans-serif"},
                            {name:"Lato",         value:"'Lato', sans-serif"},
                        ]
                    },
                    "'Poppins', sans-serif": {
                        name: 'Poppins',
                        options: [
                            {name:"Open Sans",    value:"'Open Sans', sans-serif"},
                            {name:"Roboto",       value:"'Roboto', sans-serif"},
                            {name:"Lora",         value:"'Lora', serif"},
                        ]
                    },
                    "'Playfair Display', serif": {
                        name: 'Playfair Display',
                        options: [
                            {name:"Roboto",       value:"'Roboto', sans-serif"},
                            {name:"Open Sans",    value:"'Open Sans', sans-serif"},
                            {name:"Montserrat",   value:"'Montserrat', sans-serif"},
                        ]
                    },
                    "'Quicksand', sans-serif": {
                        name: 'Quicksand',
                        options: [
                            {name:"Roboto",       value:"'Roboto', sans-serif"},
                            {name:"Open Sans",    value:"'Open Sans', sans-serif"},
                            {name:"Montserrat",   value:"'Montserrat', sans-serif"},
                        ]
                    },
                    "'Josefin Slab', serif": {
                        name: 'Josefin Slab',
                        options: [
                            {name:"Roboto",       value:"'Roboto', sans-serif"},
                            {name:"Open Sans",    value:"'Open Sans', sans-serif"},
                            {name:"Montserrat",   value:"'Montserrat', sans-serif"},
                        ]
                    },
                },
                linkProperties: [
                    {name: "Display Text",   value: "text"},
                    {name: "URL",            value: "url"},
                    {name: "Advisory Title", value: "title"},
                ],
                linkCount: 4
            }
        },
        computed: {
            ...mapState('siteConfig/design', {
                designOptions:   state => state.designOptions.theme,
                templateOptions: state => state.designOptions.templates,
                currentTemplate: state => state.designOptions.selectedTemplateId
            })
        },
        methods: {
            ...mapMutations('siteConfig/design', [
                'SET_VALUE_IN_DESIGN_OPTIONS'
            ]),
            updateValue(prop, att = 'value') {
                this.SET_VALUE_IN_DESIGN_OPTIONS([prop, event.target[att]]);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .disabled {
        opacity: .25;
        pointer-events: none;
    }
</style>