<template>
    <div class="site-config-nav vp-tab-nav">

        <router-link :to="{name:'Site Config'}" id="site-config-tab" class="nav-link" title="Internal link that opens site config" exact>Site Config</router-link>
        <router-link :to="{name:'Design'}" id="design-config-tab" v-if="isDesignConfigEnabled" class="nav-link" title="Internal link that opens site design config" exact>Design</router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('siteConfig', {
            isDesignConfigEnabled:   state => state.isDesignConfigEnabled
        })
    }
}
</script>