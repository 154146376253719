<template>
    <button type="button" class="btn btn-primary m-0 align-self-start mb-2" @click.prevent="showForm">
        {{ recordType }}
    </button>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'AddNewRecordButton',
        props:{
            namespace: String,
            recordType: String,
            routerPath : {
                type: String,
                default: null
            }
        },
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.methods = {
                ...mapActions(storeNamespace, [
                    'openModal',
                    'showAddForm',
                ]),
                showForm(){
                    if(this.routerPath){
                        this.showAddForm(this.routerPath);
                    } else {
                        this.openModal();
                    }
                }
            }
        }
    }
</script>