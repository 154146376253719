<template>
    <SiteProductListingSidebarCard :expandable="list.length > 10">
        <template #header>{{ name }}</template>
        <VueLink
            v-for="(listItem, id) in list"
            :key="`${name}-list-${id}`"
            :link-text="listItem.name"
            :link-path="isActive(listItem) ? undefined : listItem.url"
            :class="{'active cursor-default text-decoration-none':isActive(listItem)}"
        />
    </SiteProductListingSidebarCard>
</template>

<script>
export default {
    props: ["name", "list", "activeItem"],
    methods: {
        isActive(listItem) {
            return listItem.id == this.activeItem
        }
    }
}
</script>