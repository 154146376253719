export const headerFooterMixin = {
    props: {
        designConfig: {
            type: Object
        },
        // This we'll probably want moved to the design options at some point
        // It's for variants of the headers (or potentially other templates)
        // i.e. "full" for a full width header
        variant: {
            type: [String, Boolean],
            default: false
        }
    },
    computed: {
        logoPath() {
            return this.designConfig?.theme?.logo || '/images/custom/site/logo.png';
        },
        reversedLogoPath() {
            let logo = this.logoPath; // If reversed logo is not set or the background where it is to be used is too light, use original logo
            if (tinycolor(this.themeComplementCompliant).isLight()) {
                logo = null; // if background is dark but reversed logo is not set, set null
                if (this.designConfig?.theme?.reverse_logo) logo = this.designConfig.theme.reverse_logo; // if background is dark and reversed logo is set, use reversed logo
            }
            return logo;
        },
    }
}

export default { headerFooterMixin }