<template>
    <div class="p-3 flex-grow-1">
        <div class="border-bottom">
            <p class="h5">
                <span class="fas fa-tags mr-1 text-tertiary"></span>&nbsp;{{ getAddModifyHeading('Product Category') }}
            </p>
        </div>
        <div>
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                class="py-1"
                v-if="!isActionAdd"
                :status="getStatusAsString"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <form class="product-category-add-modify-form needs-validation" name="product_category_add_modify_form" id="product-category-add-modify-form" novalidate @submit.prevent="submitProductCategoryForm">
                <!-- Error Messages -->
                <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

                <!-- Product Category Form Tabs Nav -->
                <VpanelEcommAddModifyFormNav
                    namespace="productCategory"
                    section-name="product category"
                    :nav-items="[{
                        name: 'General Details',
                        href: 'product-category-add-modify-general-details',
                        id: 'product-category-add-modify-general-details-tab',
                        is_active_tab: getActiveFormTab == 'product-category-add-modify-general-details-tab' ? true : false
                    },
                    {
                        name: 'Seo',
                        href: 'product-category-add-modify-seo',
                        id: 'product-category-add-modify-seo-tab',
                        is_active_tab: getActiveFormTab == 'product-category-add-modify-seo-tab' ? true : false
                    }]"
                />

                <!-- Tabbed Content -->
                <div class="tab-content" id="add-modify-tab-content">
                    <!-- Product Category General Details Form -->
                    <div class="tab-pane fade" :class="{'show active': getActiveFormTab == 'product-category-add-modify-general-details-tab'}" id="product-category-add-modify-general-details" role="tab-panel" aria-labelledby="product-category-add-modify-general-details-tab">
                        <div class="row">
                            <div class="col-md-7 col-lg-8">
                                <VpanelProductCategoryGeneralDetailsForm
                                    :active-record="activeRecord"
                                    @update="updateProductCategoryValue"
                                />
                            </div>
                            <div class="col-md-5 col-lg-4 mt-3 mt-md-0">
                                <VpanelEcommAddModifyImageForm
                                    :active-record="activeRecord"
                                    @update="updateProductCategoryValue"
                                />
                            </div>
                        </div>

                    </div>

                    <!-- Product Category Seo Form -->
                    <div class="tab-pane fade" :class="{'show active': getActiveFormTab == 'product-category-add-modify-seo-tab'}" id="product-category-add-modify-seo" role="tab-panel" aria-labelledby="product-category-add-modify-seo-tab">
                        <VpanelEcommSeoSettingsForm
                            section-name="category"
                            :active-record="activeRecord"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary my-0 save-button">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: "VpanelAddModifyProductCategoryPage",
        computed: {
            ...mapGetters('productCategory/addModify', [
                'getActiveFormTab',
                'getStatusAsString',
                'getAddModifyHeading'
            ]),
            ...mapState('productCategory/addModify', {
                formId: state => state.formId,
                activeRecord: state => state.activeRecord,
                errorMessage: state => state.errorMessage,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing
            }),
        },
        methods: {
            ...mapActions('productCategory/addModify', [
                'submitForm',
                'setFormIsValid',
                'checkIfPageIsValid',
                'setValueInActiveRecord'
            ]),
            updateProductCategoryValue(payload){
                let inputValue = 'value' in payload ? payload.value : payload.parseAsInt ? parseInt(event.target.value) : event.target.value;

                this.setValueInActiveRecord({ prop: payload.prop, value: inputValue });
            },
            submitProductCategoryForm(){
                let addDispatch     = this.isActionAdd ? 'productCategory/addModify/setActiveRecord' : null;
                let addModifyRoute  = this.isActionAdd ? '/vpanel/modules/ecommerce/product/category/store' : '/vpanel/modules/ecommerce/product/category/update';
                let routerPath      = this.isActionAdd ? 'Modify Product Category' : 'Product Category List';

                this.checkIfPageIsValid();

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    isUsingModal: false,
                    routerPath: routerPath,
                    dispatchPath: addDispatch
                });
            }
        }
    }
</script>