<template>
    <table id="datatable" class="table table-striped table-bordered dataTable dtr-inline w-100" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="cspNonce">
        <thead>
            <tr>
                <th class="table-head" tabindex="0" aria-controls="datatable">Name & Description</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Shortcut Key</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Type</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Status</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Used On</th>
                <th class="table-head" tabindex="0" aria-controls="datatable">Options</th>
            </tr>
        </thead>
    </table>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'AssetManagerList',
        props: [
            'cspNonce'
        ],
        created(){
            this.addClickEventsToListButtons();
        },
        methods: {
            ...mapActions('assetManager/list', [
                'addClickEventsToListButtons'
            ])
        }
    }
</script>