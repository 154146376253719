<template>
    <div class="audit-logs-section">
        <div class="al-header">
            <div>
                <export-as-csv-button />
                <export-as-xlsx-button />
            </div>
        </div>

        <audit-log-list />
    </div>
</template>
<script>
    export default {
        name: 'AuditLogIndex'
    }
</script>