// Accepts ["name", "id", "required"]

<template>
<div v-bind="groupBinder">
    <div
        class="form-group"
        role="group"
        :aria-labelledby="inputId"
    >
        <p :id="inputId" class="fieldset-label">{{ inputLabel }}<span v-if="required" class="required">*</span></p>

        <div class="inputs">
            <div
                v-for="(option, index) in options"
                :key="option.name"
                class="input-wrapper"
                :class="option.groupClass"
            >
                <input
                    type="radio"
                    v-on="inputListener"
                    :id="`${inputId}_${index}`"
                    :name="`${inputId}_options`"
                    :value="option.value"
                    :checked="localValue == option.value"
                    @change="changed(option.value)"
                >
                <label class="form-label" :for="`${inputId}_${index}`">{{ option.name }}</label>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        options: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            localValue: this.value
        }
    },
    methods: {
        changed(val) {
            this.localValue = val;
        }
    },
    watch: {
        value: function (val) {
            this.localValue = val;
        }
    }
}
</script>

<style lang="scss" scoped>
    .inputs { padding: .25rem 0; }

    .input-wrapper {
        display: flex;
        align-items: flex-start;
        position: relative;
        padding: .25rem 0;
    }

    .form-group {
        display: block;
        width: 100%;
        min-height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-family: Arial,sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        position: relative;
    }

    .fieldset-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: .25rem;
        opacity: 1;
        background-color: white;
        transform: translateY(-50%);
        padding: 0 .25rem;
        line-height: 1;
        border-radius: 2px;
    }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        user-select: none;
        opacity: 1;
        padding-left: .5rem;
        line-height: 1;
    }
</style>