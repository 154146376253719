<template>
    <form id="product-importation-form" class="needs-validation" name="product-importation-form" novalidate @submit.prevent="submitImportForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <div class="alert bg-secondary-blue">
            <p class="mb-1">When using the import tool, please keep these things in mind:</p>
            <ul class="mb-0">
                <li>The import only works <span class="text-underline cursor-pointer" @click="downloadSampleImport">with this spreadsheet</span></li>
                <li><strong>Macros must be enabled</strong> when you first open the file</li>
                <li>The larger your file, the longer it takes to import</li>
                <li>The import processes in the background</li>
                <li>Once completed, you’ll receive an email notification</li>
                <li>If any errors occur, you’ll receive a separate email with details on how to adjust your file and import again</li>
                <li>If you have any questions, please reach out to <a href="mailto:customercare@forbin.com" class="text-white text-underline" title="Link opens computers default mailing application">customercare@forbin.com</a></li>
            </ul>
        </div>

        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="d-flex flex-column align-items-stretch">
                <div class="row">
                    <div class="col-12 form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="product_import">File</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Upload the template spreadsheet after populating it with your products."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            required
                            type="file"
                            id="product_import"
                            class="form-control"
                            name="product_import"
                            title="Please provide a file to import products from"
                            data-invalid-feedback="Please provide a file to import products from."
                        />
                        <div class="invalid-feedback">
                            Please provide a file to import products from.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: 'VpanelProductImportationForm',
        props: [
            'isActionAdd',
            'activeRecord',
            'errorMessage',
        ],
        computed: {
            ...mapGetters([
                'getModuleConfigs'
            ])
        },
        methods: {
            ...mapActions('product/importation', [
                'submitImportForm'
            ]),
            closeModal(){
                this.$emit('close-ecomm-modal');
            },
            downloadSampleImport(){
                window.open(this.getModuleConfigs['Forbin CDN'] + this.getModuleConfigs['Product Import Template File Path'])
            }
        }
    }
</script>