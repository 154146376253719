<template>
	<div class="glide">
		<div class="glide__track" data-glide-el="track">
			<div class="glide__slides">
                <div
                    class="glide__slide"
                    v-for="(slide, index) in slides"
					:key="slide.mm_uuid"
                >
                    <div class="slide-wrapper">
                        <div class="slide-bg-image" :style="{backgroundImage: `url('${slides[index][bgImageSize]}')`}"></div>
                        <div class="dynamic-container">
                            <div class="slide-content">
                                <h1 class="slide-title">{{ slide.title }}</h1>
                                <p class="slide-subtitle">{{ slide.tagline }}</p>
                                <vue-link
                                    v-if="slide.button_url && slide.button_text"
                                    :link-text="slide.button_text"
                                    :link-target="slide.button_target || '_self'"
                                    :link-path="slide.button_url"
                                    link-class="btn btn-primary"
                                    :data-nodisclaimer="!slide.button_disclaimer || false"
                                ></vue-link>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
        <template v-if="slides.length > 1">
            <div class="mm-bullets">
                <button
                    v-for="n in slides.length"
                    :key="'bullet' + (n - 1)"
                    class="mm-bullet"
                    :class="{'active': slider._i == (n - 1)}"
                    @click.stop.prevent="goToIndex(n)"
                ></button>
            </div>
            <div class="mm-arrows">
                <button class="mm-arrow mm-arrow--left" @click.stop.prevent="prev">prev</button>
                <button class="mm-arrow mm-arrow--right" @click.stop.prevent="next">next</button>
            </div>
            <div class="mm-playpause">
                <button class="mm-play" @click.stop.prevent="play" v-show="!playing">▶</button>
                <button class="mm-pause" @click.stop.prevent="pause" v-show="playing">❚❚</button>
            </div>
        </template>
	</div>
</template>

<script>
import { mixin } from '../marketingMessageMixin';

export default {
	name: "MarketingMessageTemplateHeartland",
    mixins: [mixin],
    data() {
        return {
            options: {
                type: 'carousel',
                gap: 0,
                animationDuration: 200,
                autoplay: 7000,
                hoverpause: false
            },
            playing: true
        }
    },
    methods: {
        play() {
            this.slider.play(this.options.autoplay);
            this.playing = true;
        },
        pause() {
            this.slider.update({autoplay:false});
            this.playing = false;
        },
        next() {
            this.slider.go('>');
            this.slider.update({autoplay:false});
            this.playing = false;
        },
        prev() {
            this.slider.go('<');
            this.slider.update({autoplay:false});
            this.playing = false;
        },
        goToIndex(i) {
            this.slider.go(`=${i - 1}`);
            this.slider.update({autoplay:false});
            this.playing = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import "../../../../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
@import "../../../../../../sass/_vue-import.scss";

//slider custom theming
.glide {
    height: 100%;
    opacity: 0;
    transition: 200ms ease-out;

    &.glide--carousel {
        opacity: 1;
    }

    .glide__track {
        height: 100%;
    }

    .glide__slides {
        height: 100%;
    }
}

.slide-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    .slide-bg-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,0.53);
        }
    }

    .dynamic-container {
        $padding: 3rem;
        max-width: calc(1110px + #{$padding * 2});
        margin-left: auto;
        margin-right: auto;
        padding-left: $padding;
        padding-right: $padding;
        width: 100%;
    }

    .slide-content {
        position: relative;
        padding: 5rem 0;

        .slide-title {
            @include fluid-size(24px, 56px, font-size);
            font-family: benton-sans, sans-serif;
            font-weight: 700;
            line-height: 1.2;
            color: #fff;
            margin: 0;
            text-transform: uppercase;
        }

        .slide-subtitle {
            @include fluid-size(16px, 36px, font-size);
            font-family: benton-sans, sans-serif;
            font-weight: 400;
            line-height: 1.2;
            color: #fff;
            margin: .75em 0 1em;
        }

        p { max-width: 60ch; }
    }
}

.mm-arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    top: 50%;
    left: 0;
    pointer-events: none;
    transform: translateY(-50%);

    .mm-arrow {
        position: relative;
        width: 1.5rem;
        height: 100%;
        background-color: rgba(0,0,0,.53);
        text-indent: -9999px;
        border: 0;
        pointer-events: all;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            height: .75rem;
            width: .75rem;
            border-color: #fff #fff transparent transparent;
            border-style: solid;
            border-width: 1.5px;
        }

        &--left {
            &:before { transform: translate(math.div(-100%, 3), -50%) rotate(-135deg) }
        }
        &--right {
            &:before { transform: translate(math.div(-200%, 3), -50%) rotate(45deg) }
        }
    }
}

.mm-bullets {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 1.5rem;

    .mm-bullet {
        border: 0;
        outline: 0;
        background-color: rgba(white, .8);
        height: 1rem;
        width: 1rem;
        margin: .5rem;
        border-radius: 50rem;
        padding: 0;
        transition: background-color 200ms ease-out;

        &.active,
        &:hover, &:focus {
            background-color: var(--primary-yellow, var(--secondary));
        }
    }
}

.mm-playpause {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 1.5rem;

    .mm-play,
    .mm-pause {
        color: white;
        opacity: .8;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        outline: 0;
        padding: .5rem;

        &:hover {
            opacity: 1;
        }
    }
}
</style>