<template>
    <form id="user-add-modify-form" class="user-form needs-validation" name="user-add-modify-form" novalidate @submit.prevent="submitUserForm">
        <div class="form-errors"></div>

        <input type="hidden" name="_token" :value="activeRecord._token" />
        <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />

        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="document-form-fields d-flex flex-column align-items-stretch">
                <div class="row">
                    <div class="col">
                        <h5>Basic Information</h5>
                        <div class="row">
                            <div class="col form-group has-label">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="required" for="user_name">Name</label>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        title="The name of the user"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    required name="name"
                                    id="user_name"
                                    title="Please enter your name"
                                    autocomplete="off"
                                    data-invalid-feedback="Please enter a user's name."
                                    v-model="activeRecord.name"
                                />
                                <div class="invalid-feedback">
                                    Please enter a user's name.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group has-label">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="required" for="user_email">Email</label>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        title="The email of the user"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <input
                                    type="email"
                                    required
                                    class="form-control"
                                    name="email"
                                    id="user_email"
                                    title="Please enter email"
                                    autocomplete="off"
                                    v-model="activeRecord.email"
                                    data-invalid-feedback="Please enter a user's email."
                                />
                                <div class="invalid-feedback">
                                    Please enter a user's email.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group has-label">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="required" for="user_username">Username</label>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        title="Username for the user"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <input
                                    type="text"
                                    required
                                    class="form-control"
                                    name="username"
                                    id="user_username"
                                    title="Please enter your username"
                                    autocomplete="off"
                                    v-model="activeRecord.username"
                                    data-invalid-feedback="Please enter a user's username."
                                />
                                <div class="invalid-feedback">
                                    Please enter a username.
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-grow-1 flex-wrap mx-n2">
                            <div class="form-group flex-grow-1 px-2">
                                <div class="radio-legend d-flex justify-content-between align-items-center">
                                    <span class="required mr-3">Master Admin?</span>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        title="A master admin will have all permissions"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <fieldset>
                                    <legend class="label sr-only">Master Admin?</legend>
                                    <div class="form-check form-check-inline form-check-radio">
                                        <label class="form-check-label" for="user_master_admin_yes">
                                            <input
                                                class="form-check-input"
                                                required type="radio"
                                                title="Master Admin?"
                                                name="master_admin"
                                                id="user_master_admin_yes"
                                                value="1"
                                                v-model.number="activeRecord.master_admin"
                                                data-invalid-feedback="Please indicate if the user is a master admin."
                                            />
                                            <span class="form-check-sign"></span>
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline form-check-radio">
                                        <label class="form-check-label" for="user_master_admin_no">
                                            <input
                                                class="form-check-input"
                                                required
                                                checked
                                                type="radio"
                                                title="Master Admin?"
                                                name="master_admin"
                                                id="user_master_admin_no"
                                                value="0"
                                                v-model.number="activeRecord.master_admin"
                                                data-invalid-feedback="Please indicate if the user is a master admin."
                                            />
                                            <span class="form-check-sign"></span>
                                            No
                                            <div class="invalid-feedback">
                                                Please indicate if the user is a master admin.
                                            </div>
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="form-group flex-grow-1 px-2">
                                <div class="radio-legend d-flex justify-content-between align-items-center">
                                    <span class="required mr-3">Status</span>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        title="Select a status for the user"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <fieldset>
                                    <legend class="label sr-only">Status</legend>
                                    <div
                                        class="form-check form-check-inline form-check-radio"
                                        v-for="(status, index) in userStatuses"
                                        :key="index"
                                    >
                                        <label class="form-check-label" :for="`user_status_${status}`">
                                            <input
                                                class="form-check-input"
                                                checked
                                                required
                                                type="radio"
                                                :title="status"
                                                name="status"
                                                :id="`user_status_${status}`"
                                                :value="status"
                                                v-model="activeRecord.status"
                                                data-invalid-feedback="Please select a status for this user."
                                            />
                                            <span class="form-check-sign"></span>
                                            {{ status }}
                                            <div class="invalid-feedback">
                                                Please select a status for this user.
                                            </div>
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="form-group flex-grow-1 px-2" v-if="activeRecord.require_password_change != null">
                                <div class="radio-legend d-flex justify-content-between align-items-center">
                                    <span class="required mr-3">Require Password Change?</span>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        title="The user will be required to change their password the next time they login."
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <fieldset>
                                    <legend class="label sr-only">Require Password Change?</legend>
                                    <div class="form-check form-check-inline form-check-radio">
                                        <label class="form-check-label" for="user_password_change_yes">
                                            <input
                                                class="form-check-input"
                                                required
                                                type="radio"
                                                title="Require Password Change?"
                                                name="require_password_change" id="user_password_change_yes"
                                                value="1"
                                                v-model="activeRecord.require_password_change"
                                                data-invalid-feedback="Please indicate if the user needs to change their password."
                                            />
                                            <span class="form-check-sign"></span>
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline form-check-radio">
                                        <label class="form-check-label" for="user_password_change_no">
                                            <input
                                                class="form-check-input"
                                                checked
                                                required
                                                type="radio"
                                                title="Require Password Change?"
                                                name="require_password_change"
                                                id="user_password_change_no"
                                                value="0"
                                                v-model="activeRecord.require_password_change"
                                                data-invalid-feedback="Please indicate if the user needs to change their password."
                                            />
                                            <span class="form-check-sign"></span>
                                            No
                                            <div class="invalid-feedback">
                                                Please indicate if the user needs to change their password.
                                            </div>
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="form-group flex-grow-1 px-2">
                                <div class="radio-legend d-flex justify-content-between align-items-center">
                                    <span class="mr-3">Roles/Permissions</span>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        title="Specific areas and actions a user has permission to access and do"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <fieldset class="user-permissions" :disabled="isMasterAdmin" :style="{opacity: isMasterAdmin ? '.6' : '1'}">
                                    <legend class="label sr-only">Roles</legend>
                                    <div
                                        class="form-check form-check-inline form-check-radio"
                                        v-for="(role, index) in sortedUserRoles"
                                        :key="index"
                                    >
                                        <label class="form-check-label" :for="`user_roles_${getRoleInputId(role.name)}`">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                :title="role.name"
                                                name="roles[]"
                                                :id="`user_roles_${getRoleInputId(role.name)}`"
                                                :value="role.id"
                                                v-model="activeRecord.roles"
                                            />
                                            <span class="form-check-sign"></span>
                                            {{ role.name }}
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AddModifyUserForm',
        computed: {
            ...mapState('userManager/addModifyUser', {
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
                userRoles: state => state.userRoles,
                userStatuses: state => state.userStatuses,
            }),
            isMasterAdmin() {
                return this.activeRecord.master_admin === 1
            },
            sortedUserRoles() {
                return this.userRoles.toSorted((a,b) => {
                    if (a.name > b.name) return 1
                    if (a.name < b.name) return -1
                    return 0
                })
            }
        },
        watch: {
            isMasterAdmin(n,o) {
                if (n) {
                    this.activeRecord.roles.splice(0, Infinity, ...this.userRoles.map(role => role.id))
                }
            }
        },
        methods: {
            getRoleInputId(roleName){
                return roleName.toLowerCase().replace(/ /g,"_");
            },
            submitUserForm(){
                this.$emit('submit-user-form');
            },
            closeModal(){
                this.$emit('close-user-modal');
            }
        }
    }
</script>

<style lang="scss">
    @import '../../../../../../../sass/vue-import';

    .user-permissions {
        position: relative;
        columns: 3 200px;

        > * {
            @include fluid-size(4px, 6px, padding-top);
            @include fluid-size(4px, 6px, padding-bottom);
            display: block;
            margin: 0;
            word-break: break-word;
        }
    }
</style>