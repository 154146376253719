<template>
    <input
        type="search"
        class="form-control"
        :placeholder="`Search ${itemName}`"
        @input="$emit('input', $event.target.value)"
    >
</template>

<script>
export default {
    props: {
        tableObj: {
            type: Object,
            required: true
        }
    },
    computed: {
        itemName() {
            return this.tableObj.options.itemName || 'results';
        }
    }
}
</script>