<template>
    <div class="row">
        <!-- Coupon Code -->
        <div class="col-6 form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label for="coupon_code">Coupon Code</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Coupon Codes must be unique"
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                name="code"
                id="coupon_code"
                class="form-control"
                :value="activeRecord.code"
                @input="updateCouponInput('code', false)"
                title="Please enter the code for this coupon"
                data-invalid-feedback="Please enter a coupon code."
            />
            <div class="invalid-feedback">
                Please enter a coupon code.
            </div>
        </div>
        <!-- Coupon Type -->
        <div class="col-6 form-group px-2">
            <div class="radio-legend d-flex justify-content-between align-items-center">
                <span class="mr-3">Coupon Type</span>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="What type of coupon this is"
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <fieldset>
                <legend class="label sr-only">Coupon Type</legend>
                <div class="form-check form-check-inline form-check-radio" v-for="(type, index) in couponTypes" :key="index">
                    <label class="form-check-label" :for="`coupon_type_${index}`">
                        <input
                            checked
                            type="radio"
                            :value="index"
                            class="form-check-input"
                            :id="`coupon_type_${index}`"
                            :name="`coupon_type_${index}`"
                            title="Please choose an option"
                            v-model.number="activeRecord.type"
                            @change="updateCouponInput('type', false)"
                        />
                        <span class="form-check-sign"></span>
                        {{ type.name }}
                    </label>
                </div>
            </fieldset>
        </div>
        <!-- Amount Off -->
        <div class="col-6 form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label for="amount_off">Amount Off</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Example: 10% off anything over a $100 purchase."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                id="amount_off"
                name="amountOff"
                placeholder="0.00"
                class="form-control"
                :value="activeRecord.amount_off"
                @input="updateCouponInput('amount_off', false)"
                title="Please enter an amount off for this coupon"
                data-invalid-feedback="Amount off is required."
            />
            <div class="invalid-feedback">
                Please enter an amount off for this coupon
            </div>
        </div>
        <!-- Minimum Balance Required -->
        <div class="col-6 form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label for="minimum_balance_required">Minimum Balance Required</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Example: 10% off anything over a $100 purchase."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                name="minBal"
                placeholder="0.00"
                class="form-control"
                :value="activeRecord.min_bal"
                id="minimum_balance_required"
                @input="updateCouponInput('min_bal', false)"
                data-invalid-feedback="Minimum balance is required."
                title="Please enter the minimum balance for this coupon"
            />
            <div class="invalid-feedback">
                Please enter a minimum balance for this coupon
            </div>
        </div>
        <!-- Available At Date -->
        <div class="col-6 form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label for="available_at">Available At Date</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="The date the coupon should start being available for use."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                id="available_at"
                name="availableAt"
                v-mask="'##/##/####'"
                placeholder="dd/mm/yyyy"
                class="form-control datepicker"
                :value="activeRecord.available_at"
                @input="updateCouponInput('available_at', false)"
                data-invalid-feedback="Available at date is required."
                title="Please enter a date in which this coupon is available at"
            />
            <div class="invalid-feedback">
                Available at date is required.
            </div>
        </div>
        <!-- Expires At Date -->
        <div class="col-6 form-group has-label">
            <div class="d-flex justify-content-between align-items-center">
                <label for="expires_at">Expires At Date</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="The date the coupon should no longer be available for use."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                id="expires_at"
                name="expiresAt"
                v-mask="'##/##/####'"
                placeholder="dd/mm/yyyy"
                class="form-control datepicker"
                :value="activeRecord.expires_at"
                @input="updateCouponInput('expires_at', false)"
                data-invalid-feedback="An expires at date is required."
                title="Please enter a date in which this coupon expires at"
            />
            <div class="invalid-feedback">
                An expires at date is required.
            </div>
        </div>
         <!-- Coupon Status -->
        <div class="col-4 form-group px-2">
            <div class="radio-legend d-flex justify-content-between align-items-center">
                <span class="mr-3">Status<span class="required"></span></span>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="If coupon is active it will show on the front end"
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <fieldset>
                <legend class="label sr-only">Status</legend>
                <div class="form-check form-check-inline form-check-radio">
                    <label class="form-check-label" for="status_yes">
                        <input
                            checked
                            required
                            :value="1"
                            type="radio"
                            name="status"
                            id="status_yes"
                            class="form-check-input"
                            v-model="activeRecord.status"
                            title="Please choose an option"
                            @change="updateCouponInput('status', false)"
                            data-invalid-feedback="Please indicate if the coupon is active or inactive."
                        />
                        <span class="form-check-sign"></span>
                        Active
                    </label>
                </div>
                <div class="form-check form-check-inline form-check-radio">
                    <label class="form-check-label" for="status_no">
                        <input
                            required
                            :value="0"
                            type="radio"
                            name="status"
                            id="status_no"
                            class="form-check-input"
                            v-model="activeRecord.status"
                            title="Please choose an option"
                            @change="updateCouponInput('status', false)"
                            data-invalid-feedback="Please indicate if the coupon is active or inactive."
                        />
                        <span class="form-check-sign"></span>
                        Inactive
                        <div class="invalid-feedback">
                            Please indicate if the coupon is active or inactive.
                        </div>
                    </label>
                </div>
            </fieldset>
        </div>
        <!-- One Time Use -->
        <div class="col-4 form-group has-label">
            <label class="form-check-label" for="is_one_time_use">
                <input
                    name="oneTime"
                    type="checkbox"
                    id="is_one_time_use"
                    class="form-check-input"
                    :value="activeRecord.one_time_use"
                    :checked="activeRecord.one_time_use"
                    @change="updateCouponInput('one_time_use', true)"
                />
                Customer can use coupon only one time.
            </label>
        </div>
        <!-- Apply Restrictions -->
        <div class="col-4 form-group has-label">
            <label class="form-check-label" for="coupon_restrictions">
                <input
                    type="checkbox"
                    id="coupon_restrictions"
                    class="form-check-input"
                    name="coupon_restrictions"
                    :value="activeRecord.has_restrictions"
                    :checked="activeRecord.has_restrictions"
                    @change="updateCouponInput('has_restrictions', true)"
                />
                Apply Restrictions
            </label>
        </div>
        <div class="col-6 form-group has-label" v-if="activeRecord.has_restrictions">
            <div class="d-flex justify-content-between align-items-center">
                <label for="expires_at">Exclude Products By Manufacturer</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="This coupon can not be applied to the following products by this manufacturer. Use Ctrl + Click to select multiple."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <select
                multiple
                id="manufacturer"
                name="manufacturer"
                class="form-control"
                ref="couponManufacturers"
                v-if="manufacturers.length"
                v-model="activeRecord.manufacturers"
                @change="updateCouponInput('manufacturers', false)"
            >
                <option value="" selected disabled hidden>Select Option</option>
                <option
                    v-for="(manufacturer, index) in manufacturers"
                    :key="index"
                    :value="manufacturer.id"
                    :selected="activeRecord.manufacturers.includes(parseInt(index))"
                >{{ manufacturer.name }}</option>
            </select>
            <div class="alert alert-info" v-else>
                Please add manufacturers first.
            </div>
        </div>
        <div class="col-6 form-group has-label" v-if="activeRecord.manufacturers.length">
            <div class="d-flex justify-content-between align-items-center">
                <label for="products">Exclude Products</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="This coupon can not be applied to the following products. Use Ctrl + Click to select multiple."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <select
                multiple
                id="products"
                name="products"
                class="form-control"
                ref="couponProducts"
                v-model="activeRecord.products"
                @change="updateCouponInput('products', false)"
            >
                <option value="" selected disabled hidden>Select Option</option>
                <option
                    v-for="(product, index) in getProductsByManufacturer"
                    :key="index"
                    :value="product.id"
                    :selected="activeRecord.products.includes(parseInt(index))"
                >{{ product.name }}</option>
            </select>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: 'VpanelAddModifyCouponForm',
        props: [
            'activeRecord'
        ],
        computed: {
            ...mapGetters('productManufacturer/list', {
                manufacturers: 'getManufacturersThatHaveProducts'
            }),
            ...mapState({
                couponTypes: state => state.coupon.addModify.types
            }),
            getProductsByManufacturer(){
                let selectedManufacturers = this.activeRecord.manufacturers;
                let productsToShow        = {};

                selectedManufacturers.forEach(selectedManufacturerId => {
                    let manufacturer = this.manufacturers.find(x => x.id == selectedManufacturerId);
                    Object.assign(productsToShow, manufacturer?.products);

                });

                return productsToShow;
            }
        },
        methods: {
            ...mapActions('coupon/addModify', [
                'setValueInActiveRecord'
            ]),
            updateCouponInput(prop, inputIsCheckbox){
                let inputValue;

                if(prop == 'manufacturers' || prop == 'products'){
                    let inputRef = prop == 'products' ? 'couponProducts' : 'couponManufacturers';
                    inputValue = [...this.$refs[inputRef].selectedOptions].map(({value}) => parseInt(value));
                } else {
                    inputValue = inputIsCheckbox ? event.target.checked : event.target.value;
                }

                this.setValueInActiveRecord({prop: prop, value: inputValue});
            }
        }
    }
</script>