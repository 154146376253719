<template>
    <div class="location-section">
        <AddNewRecordButton namespace="locationType/addModify" record-type="Add a New Location Type" />

        <!-- Table Loader -->
        <VueLoader v-if="isTableLoading" />

        <!-- Location Type Table -->
        <VpanelLocationTypeTable
            v-else
            respond-at="md"
            :data="tableData"
            :items-per-page="10"
            :searchable-data="['name']"
        />

        <!--Add/Modify Modal-->
        <AddModifyLocationTypeModal />

        <!-- Delete Location Type Modal -->
        <VueConfirmationModal
            heading-text="Confirm Delete"
            namespace="locationType/recycle"
            @confirmed-modal="deleteLocationType"
            modal-id="location-type-recycle-modal"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelLocationTypeList',
        computed: {
            ...mapState('locationType/list', {
                tableData: state => state.tableData,
                isTableLoading: state => state.isTableLoading
            })
        },
        methods: {
            ...mapActions('locationType/list', [
                'deleteRecord'
            ]),
            deleteLocationType(){
                this.deleteRecord({namespace: 'locationType', routeGroup: 'location/type'});
            }
        }
    }
</script>