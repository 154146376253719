<template>
    <form id="division-add-modify-form" class="staff-form needs-validation" name="division-add-modify-form" novalidate @submit.prevent="submitForm">
        <input type="hidden" name="_token" :value="activeRecord._token" />
        <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />
        <div class="document-form-fields">
            <div class="form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="name">Name</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="The name of the division"
                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                </div>
                <input
                    required
                    id="name"
                    name="name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    @input="updateName()"
                    :value="activeRecord.name"
                    title="Please enter the division name"
                    data-invalid-feedback="Please enter a division name."
                />
                <div class="invalid-feedback">
                    Please enter a division name.
                </div>
            </div>

            <div class="form-group has-label">
                <div class="radio-legend d-flex justify-content-between align-items-center">
                    <span class="required mr-3">Status</span>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Please select a status for this division."
                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                </div>
                <fieldset>
                    <legend class="label sr-only">Status</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="status-active">
                            <input
                                value="1"
                                type="radio"
                                name="status"
                                id="status-active"
                                @change="updateStatus()"
                                class="form-check-input"
                                title="Mark this division as active"
                                :checked="activeRecord.is_active == 1"
                                data-invalid-feedback="Please select a status for this division."
                            />
                            <span class="form-check-sign"></span>
                            Active
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="status-inactive">
                            <input
                                value="0"
                                type="radio"
                                name="status"
                                id="status-inactive"
                                class="form-check-input"
                                @change="updateStatus()"
                                title="Mark this division as inactive"
                                :checked="activeRecord.is_active == 0"
                                data-invalid-feedback="Please select a status for this division."
                            />
                            <span class="form-check-sign"></span>
                            Inactive
                        </label>
                    </div>
                    <div class="invalid-feedback">
                        Please select a status for this division.
                    </div>
                </fieldset>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'VpanelAddModifyStaffDivisionForm',
        props: [ 'isActionAdd' ],
        computed: {
            ...mapState('division/addModify', {
                activeRecord: state => state.activeRecord
            })
        },
        methods: {
            ...mapMutations('division/addModify', [
                'SET_VALUE_IN_ACTIVE_RECORD'
            ]),
            submitForm(){
                this.$emit('submitForm');
            },
            closeModal(){
                this.$emit('close-division-modal');
            },
            updateStatus() {
                this.SET_VALUE_IN_ACTIVE_RECORD(['is_active', event.target.value]);
            },
            updateName() {
                this.SET_VALUE_IN_ACTIVE_RECORD(['name', event.target.value]);
            }
        },
    }
</script>