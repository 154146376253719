<template>
    <button :type="buttonType" :class="`btn btn-${buttonColor}`" @click.prevent="buttonAction" :disabled="buttonProcessing">
        <span v-if="buttonIcon" :class="`${buttonIcon}`"></span>
        <template v-if="buttonText">{{ buttonText }}</template>
    </button>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'VpanelEcommAddNewRecordButton',
        props:{
            namespace: {
                type: String,
                default: null
            },
            buttonType: {
                type: String,
                default: 'button'
            },
            buttonText: {
                type: String,
                default: null
            },
            buttonColor: {
                type: String,
                default: 'primary'
            },
            routerPath : {
                type: String,
                default: null
            },
            routerPathParams: [Array, Object],
            buttonIcon: {
                type: String,
                default: null
            },
            buttonProcessing: {
                type: Boolean,
                default: false
            }
        },
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.methods = {
                ...mapActions(storeNamespace, [
                    'openModal'
                ]),
                buttonAction(){
                    if(this.routerPath){
                        this.$router.push({ name: this.routerPath, params: this.routerPathParams });
                    } else if(this.namespace){
                        this.openModal();
                    } else {
                        this.$emit('button-event');
                    }
                }
            }
        }
    }
</script>