<template>
    <button
        type="button"
        class="btn btn-primary m-0"
        @click.prevent="bulkAddProducts"
        :disabled="isBulkAddButtonProcessing"
    >{{ isBulkAddButtonProcessing ? 'Processing...' : buttonText }}</button>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelBulkAddMasterCatalogProductButton',
        props: [
            "selectedLength"
        ],
        computed: {
            ...mapState('masterCatalog/add', {
                isBulkAddButtonProcessing: state => state.isBulkAddButtonProcessing
            }),
            buttonText() {
                let text;
                if (this.selectedLength) text = ` ${this.selectedLength} Product${this.selectedLength > 1 ? 's' : ''}`;
                return text ? `Bulk Add${text}` : 'Bulk Add';
            }
        },
        methods: {
            ...mapActions('masterCatalog/add', [
                'bulkAddProducts',
            ])
        }
    }
</script>