import dayjs from 'dayjs'

export default {
    props: {
        data: {
            type: [Array, Object],
            required: true
        },
        searchableData: {
            type: Array,
            default: []
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        respondAt: {
            type: String,
            default: "sm"
        }
    },
    data() {
        return {
            searchModel: '',      // what is being searched
            activeSort: null,     // what prop is being sorted
            sortDir: 'asc',       // which direction the prop is being sorted
            sortType: 'string',   // the type of the prop
            filteredProp: null,   // what prop that value should be found in
            filteredValue: null,  // what value is being searched to be filtered
            selectedRows: [],     // which rows are selected
            activePage: 1,        // what page you are on
            activeDropdown: null, // which dropdown is currently active
        }
    },
    methods: {
        resetAll() {
            let mixinData = ['searchModel','activeSort','sortDir','sortType','filteredProp','filteredValue','selectedRows','activePage','activeDropdown'];
            mixinData.forEach(d=>this[d]=this.$options.data()[d]);
        },
        // Search Methods
        updateSearch(value) {
            this.searchModel = value;
        },
        // Sorting Methods
        sortBy(value, type = 'string') {
            this.sortDir = this.activeSort == value ? (this.sortDir == 'asc' ? 'desc' : 'asc') : 'asc' ;
            this.sortType = type;
            this.activeSort = value;
        },
        sortClass(value) {
            return `sort${value == this.activeSort ? ' ' + this.sortDir : ''}`;
        },
        // Filter Methods
        filterBy(prop, value) {
            if (this.filteredProp == prop && this.filteredValue == value) {
                [this.filteredProp, this.filteredValue] = [null, null];
            } else {
                this.filteredProp = prop;
                this.filteredValue = value;
            }
        },
        isActiveFilter(prop, value) {
            return this.filteredProp == prop && this.filteredValue == value;
        },
        // Pagination Methods
        updateActivePage(page) {
            this.activePage = page;
        },
        // Dropdown Methods
        setActiveDropdown(uid) {
            this.activeDropdown = this.isDropdownActive(uid) ? null : uid;
        },
        isDropdownActive(uid) {
            return this.activeDropdown == uid;
        },
        dropdownBinder(row, uid) {
            return {
                isActive: this.isDropdownActive(uid),
                row: row
            }
        },
        // Row Selection Methods
        selectedStatus(uidName) {
            if (this.selectedRows.length) {
                let vm = this;
                if (this.searchedAndFilteredList.every(r=>vm.selectedRows.includes(r[uidName]))) return 1;
                if (this.selectedRows.some(r=>r)) return 0;
            }
            return -1;
        },
        isRowSelected(uid) {
            return this.selectedRows.includes(uid);
        },
        selectRow(uid) {
            if (this.selectedRows.includes(uid)) this.selectedRows.splice(this.selectedRows.indexOf(uid),1);
            else this.selectedRows.push(uid);
        },
        selectAll(uidName) {
            let vm = this;
            if (this.selectedRows.length) {
                this.selectedRows.splice(0);
            } else {
                this.searchedAndFilteredList.forEach(r => {
                    vm.selectedRows.push(r[uidName]);
                })
            }
        }
    },
    watch: {
        // Page handling when filtering / searching to make sure you aren't left on a non-existent page
        itemCount(n) {
            let maxPage = Math.ceil(n/this.itemsPerPage);
            // if above maxPage range (on page 10 but only 6 pages) move to new maxPage
            if ((maxPage < this.activePage)) this.updateActivePage(maxPage);
            // if coming out of search / filter with no results and under page range (on page 0 while maxPage is 1) move to page 1
            else if ((maxPage && !this.activePage)) this.updateActivePage(1);
        },
        // If searching / sorting / filtering make all dropdowns inactive;
        displayedList: {
            handler() {
                this.activeDropdown = null;
            },
            deep: true
        }
    },
    computed: {
        // Handle keyed objects and convert to array
        dataFormatted() {
            if (Array.isArray(this.data)) return this.data;
            return Object.entries(this.data).map(([key, value]) => {
                return {key: key, ...value}
            });
        },
        // <TableWrapper v-bind="wrapperBinder">
        wrapperBinder() {
            return {
                itemCount: this.itemCount,
                selectedCount: this.selectedRows.length,
                activePage: this.activePage,
                itemsPerPage: this.itemsPerPage,
                searchable: !!this.searchableData.length,
                searchModel: this.searchModel,
                class: `simple-table-wrapper simple-table-wrapper-${this.respondAt}`
            }
        },
        // <TableWrapper v-on="wrapperEvents">
        wrapperEvents() {
            return {
                'update-page': this.updateActivePage,
                'on-search': this.updateSearch
            }
        },
        // Search & Filter Logic
        searchedAndFilteredList() {
            let canBeSearched = !!(this.searchModel && this.searchableData.length),
                canBeFiltered = !!(this.filteredValue && this.filteredProp);

            if (!canBeSearched && !canBeFiltered) return this.dataFormatted;

            return this.dataFormatted.filter(d => {
                let [searchResult,filterResult] = [true,true];
                if (canBeSearched) searchResult = this.searchableData.some(sd => {
                    try {
                        return String(d[sd]).toLowerCase().includes(String(this.searchModel).toLowerCase())
                    } catch (searchError) {
                        console.error(searchError);
                        return false;
                    }
                });
                if (canBeFiltered) {
                    filterResult = d[this.filteredProp] == this.filteredValue;
                    if (Array.isArray(this.filteredValue)) filterResult = this.filteredValue.includes(d[this.filteredProp]);
                }
                return searchResult && filterResult;
            })
        },
        // Sort Logic
        sortedList() {
            if (!this.activeSort) return this.searchedAndFilteredList;
            let searchedAndFilteredClone = [...this.searchedAndFilteredList]

            searchedAndFilteredClone.sort((a,b) => {
                let asc = this.sortDir == 'asc';
                a = a[this.activeSort];
                b = b[this.activeSort];

                const ascDesc = (a,b) => asc?a-b:b-a;

                switch (this.sortType) {
                    case 'number':
                        return ascDesc(Number(a), Number(b))
                    case 'date':
                        return ascDesc(dayjs(a).format('YYYYMMDDHHmm'), dayjs(b).format('YYYYMMDDHHmm'));
                    default: // string
                        return asc ? (a.toLowerCase()).localeCompare(b.toLowerCase()) : (b.toLowerCase()).localeCompare(a.toLowerCase());
                }
            });

            return searchedAndFilteredClone;
        },
        // Pagination Logic
        // To be used as the looped data, e.g. v-for="(row,index) in displayedList"
        displayedList() { // handles pagination
            return this.sortedList.reduce((a,c,i) => {
                if (i >= (this.activePage - 1) * this.itemsPerPage && i < this.activePage * this.itemsPerPage) a.push({...c});
                return a;
            }, []);
        },
        itemCount() {
            return this.sortedList.length;
        }
    }
}