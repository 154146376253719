export const headerMixin = {
    props: {
        navInfo: {
            type: Array,
        },
    },
    computed: {
        currentPageId() {
            return this.pageUuid;
        },
        headerTemplate() {
            return this.designConfig?.template?.header_template || 1;
        },
        headerLinks() {
            let c = this.designConfig?.theme;
            if (!c) return [];

            let result = [];
            for (let i=1; i<5; i++) {
                let linkObj = false;
                if (['text','url','title'].every(p => c[`link_${i}_${p}`])) {
                    linkObj = {
                        text: c[`link_${i}_text`],
                        link: c[`link_${i}_url`],
                        title: c[`link_${i}_title`]
                    }
                }
                result.push(linkObj)
            }
            return result;
        }
    },
    methods: {
        addVars() {
            document.body.style.setProperty('--hd-height', this.$el.offsetHeight + 'px');
        }
    },
    mounted() {
        document.body.dataset.headerTemplate = this.headerTemplate;
        document.body.dataset.navTemplate = this.navTemplate;
        document.body.dataset.searchTemplate = this.searchTemplate;

        let vm = this;
        this.$nextTick(() => vm.addVars());
        window.addEventListener('resize', () => vm.addVars());
    },
    updated() {
        this.addVars();
    }
}

export default { headerMixin }