<template>
    <router-view :key="$route.fullPath" :is-forbin-admin="isForbinAdmin" />
</template>
<script>
    import { mapMutations } from 'vuex';

    export default {
        name: "VpanelFormsModuleIndex",
        props: [
            'isForbinAdmin',
            'isFormManager'
        ],
        methods: {
            ...mapMutations('forms', [
                'SET'
            ])
        },
        mounted(){
            this.SET(['isFormManager', this.isFormManager]);
        }
    }
</script>