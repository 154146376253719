<template>
    <div class="d-flex flex-column p-3 flex-grow-1">
        <div class="border-bottom">
            <p class="h5">
                <span class="fas fa-tshirt mr-1 text-tertiary"></span>&nbsp;{{ getAddModifyHeading('Product', 'name') }}
            </p>
        </div>
        <div>
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                class="py-1"
                v-if="!isActionAdd"
                :status="getStatusAsString"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <form class="product-add-modify-form needs-validation" name="product_add_modify_form" id="product-add-modify-form" novalidate @submit.prevent="submitProductForm">
                <!-- Error Messages -->
                <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

                <!-- Product Form Tabs Nav -->
                <VpanelEcommAddModifyFormNav
                    namespace="product"
                    section-name="product "
                    :nav-items="navItems"
                />

                <!-- Tabbed Content -->
                <div class="tab-content" id="add-modify-tab-content">
                    <!-- Product General Details Form -->
                    <div :class="{'show active': activeNavTab == 'add-modify-general-details-tab'}" class="tab-pane fade" id="add-modify-general-details" role="tab-panel" aria-labelledby="add-modify-general-details-tab">
                        <div class="row">
                            <VpanelProductGeneralDetailsForm
                                class="col-lg-8"
                                :active-record="activeRecord"
                                :categories-loading="categoriesLoading"
                                :manufacturers-loading="manufacturersLoading"
                            />
                            <div class="col-lg-4">
                                <!-- Product Price and Weight -->
                                <VpanelProductPriceAndWeightForm
                                    @update="updateProductValue"
                                    :has-variants="activeRecord.has_variants"
                                    :product-attribute="activeRecord.attributes[0]"
                                />

                                <!-- Product SKUs and Qty -->
                                <VpanelProductSkuAndQtyForm
                                    @update="updateProductValue"
                                    :has-variants="activeRecord.has_variants"
                                    :product-attribute="activeRecord.attributes[0]"
                                />

                                <!-- Product Settings -->
                                <VpanelProductSettingsForm
                                    @update="updateProductValue"
                                    :active-record="activeRecord"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Product Variants Form -->
                    <div :class="activeNavTab == 'add-modify-variants-tab' ? 'show active' : ''" class="tab-pane fade" id="add-modify-variants" role="tab-panel" aria-labelledby="add-modify-variants-tab">
                        <VpanelProductVariantForm
                            @reset-variants="openVariantResetModal"
                            :product-options="activeRecord.options"
                            :has-variants="activeRecord.has_variants"
                        />

                        <VpanelProductVariantPreviewForm
                            v-if="activeRecord.has_variants"
                            :product-options="activeRecord.options"
                            :product-attributes="activeRecord.attributes"
                        />
                    </div>

                    <!-- Product Features and Specifications Forms -->
                    <div :class="activeNavTab == 'add-modify-features-specs-tab' ? 'show active' : ''" class="tab-pane fade" id="add-modify-features-specs" role="tab-panel" aria-labelledby="add-modify-features-specs-tab">
                        <div class="row">
                            <!-- Product Features Form -->
                            <div class="col-md-6">
                                <VpanelProductFeatureForm
                                    :product-features="activeRecord.features"
                                />
                            </div>

                            <!-- Product Specifications Form -->
                            <div class="col-md-6">
                                <VpanelProductSpecificationForm
                                    :product-specs="activeRecord.specs"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Product Documents, Videos Images (AKA Media) Forms -->
                    <div :class="activeNavTab == 'add-modify-media-tab' ? 'show active' : ''" class="tab-pane fade" id="add-modify-media" role="tab-panel" aria-labelledby="add-modify-media-tab">
                        <!-- Product Images Form -->
                        <VpanelEcommMultiImageManagerCard
                            @update="updateProductValue"
                            @remove-item="removeAdditionalItem"
                            :product-images="activeRecord.images"
                            :show-variant-message="activeRecord.has_variants"
                        />

                        <div class="row">
                            <!-- Product Documents Form -->
                            <div class="col-md-6">
                                <VpanelProductDocumentForm
                                    :product-documents="activeRecord.documents"
                                />
                            </div>

                            <!-- Product Videos Form -->
                            <div class="col-md-6">
                                <VpanelProductVideoForm
                                    :product-videos="activeRecord.videos"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Product Seo Form -->
                    <div :class="activeNavTab == 'add-modify-seo-tab' ? 'show active' : ''" class="tab-pane fade" id="add-modify-seo" role="tab-panel" aria-labelledby="add-modify-seo-tab">
                        <VpanelEcommSeoSettingsForm
                            section-name="product"
                            :active-record="activeRecord"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary my-0 save-button" :disabled="categoriesLoading || manufacturersLoading">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
                </div>
            </form>

            <!-- Delete Product Variant Modal -->
            <VpanelEcommConfirmRecycleModal
                v-if="!isActionAdd"
                namespace="productVariant"
                modal-id="product-variant-recycle-modal"
                heading-text="Confirm Delete Product Variant"
                ajax-route="/vpanel/modules/ecommerce/product/variant/recycle"
                after-recycle-dispatch-action="product/addModify/removeAttributeFromProduct"
            >
                <template #modalContent>
                    <p class="h4 font-weight-bold">Are you sure you want to delete this variant?</p>
                    <p class="text-center m-0">This action is permanent. You will not be able to restore this product's variant. </p>
                </template>
            </VpanelEcommConfirmRecycleModal>

            <!-- Confirm Variant Reset Modal -->
            <VpanelConfirmResetProductVariantModal />

            <!-- Add Tag Modal -->
            <VpanelEcommAddModifyModal
                section-name="Tag"
                modal-icon="fas fa-tags"
                form-id="product-tag-add-form"
                namespace="productTag/addModify"
                form-name="vpanel-add-product-tag-form"
            />

            <!-- Add HCPCS Code Modal -->
            <VpanelAddProductHcpcsCodeModal />

            <!-- Add Unit of Measure Modal -->
            <VpanelAddProductUomModal />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: "VpanelAddModifyProductPage",
        computed: {
            ...mapGetters('product/addModify', {
                activeNavTab: 'getActiveFormTab',
                getStatusAsString: 'getStatusAsString',
                getAddModifyHeading: 'getAddModifyHeading'
            }),
            ...mapState({
                formId: state => state.product.addModify.formId,
                activeRecord: state => state.product.addModify.activeRecord,
                errorMessage: state => state.product.addModify.errorMessage,
                isActionAdd: state => state.product.addModify.formActionIsAdd,
                isFormProcessing: state => state.product.addModify.isFormProcessing,
                categoriesLoading: state => state.productCategory.list.isAllDataLoading,
                manufacturersLoading: state => state.productManufacturer.list.isAllDataLoading,
            }),
            navItems(){
                return [{
                        name: 'Details',
                        href: 'add-modify-general-details',
                        id: 'add-modify-general-details-tab',
                        is_active_tab: this.activeNavTab == 'add-modify-general-details-tab' ? true : false
                    },
                    {
                        name: 'Variants',
                        href: 'add-modify-variants',
                        id: 'add-modify-variants-tab',
                        is_active_tab: this.activeNavTab == 'add-modify-variants-tab' ? true : false
                    },
                    {
                        name: 'Features & Specs',
                        href: 'add-modify-features-specs',
                        id: 'add-modify-features-specs-tab',
                        is_active_tab: this.activeNavTab == 'add-modify-features-specs-tab' ? true : false
                    },
                    {
                        name: 'Media',
                        href: 'add-modify-media',
                        id: 'add-modify-media-tab',
                        is_active_tab: this.activeNavTab == 'add-modify-media-tab' ? true : false
                    },
                    {
                        name: 'Seo',
                        href: 'add-modify-seo',
                        id: 'add-modify-seo-tab',
                        is_active_tab: this.activeNavTab == 'add-modify-seo-tab' ? true : false
                    }];
            }
        },
        methods: {
            ...mapActions('product/addModify', {
                submitForm: 'submitForm',
                setFormIsValid: 'setFormIsValid',
                setActiveFormTab: 'setActiveFormTab',
                checkIfPageIsValid: 'checkIfPageIsValid',
                updateProductValue: 'updateProductValue',
                removeAdditionalItem: 'removeAdditionalItem',
                openVariantResetModal: 'confirmVariantResetModal/openModal',
            }),
             submitProductForm(){
                let addDispatch     = this.isActionAdd ? 'product/addModify/setActiveRecord' : null;
                let addModifyRoute  = this.isActionAdd ? '/vpanel/modules/ecommerce/product/store' : '/vpanel/modules/ecommerce/product/update';
                let routerPath      = this.isActionAdd ? 'Modify Product' : 'Product List';

                this.checkIfPageIsValid();

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    isUsingModal: false,
                    routerPath: routerPath,
                    dispatchPath: addDispatch
                });
            }
        }
    }
</script>