<template>
    <div class="preview-body" :style="themeProperties">
        <div class="header">
            <div class="top" v-if="headerLinks.length && headerLinks.some(l => l)">
                <div>
                    <template v-for="(link, index) in headerLinks">
                        <div
                            v-if="link"
                            :key="`header-link-preview-${index}`"
                            :class="`fake-link fake-link-${index}`"
                        ></div>
                    </template>
                </div>
            </div>
            <div class="bottom">
                <div class="logo">
                    <img :src="logoPath" alt="preview logo">
                </div>
                <div class="fake-links"></div>
            </div>
        </div>
        <div class="page-title">
            <div class="title"></div>
            <div class="breadcrumbs"></div>
        </div>
        <div class="main">
            <p class="heading">Heading 1</p>
            <p class="heading">Heading 2</p>
            <p class="heading">Heading 3</p>
            <p class="heading">Heading 4</p>
            <p class="heading">Heading 5</p>
            <p class="heading">Heading 6</p>
            <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <button class="btn btn-sm">Button</button>
            <button class="btn btn-sm">Button</button>
        </div>
        <div class="footer">
            <div class="top">
                <div class="fake-links"></div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="logo">
                        <img v-bind="logoBinder" alt="preview logo">
                    </div>
                </div>
                <div class="center">
                    <div class="fake-links"></div>
                </div>
                <div class="right">
                    <div class="fake-links"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteDesignPreviewMixin } from '@v/Modules/SiteConfig/Core/Vpanel/components/SiteDesignPreviewTemplates/SiteDesignPreviewMixin';

    export default {
        mixins: [ SiteDesignPreviewMixin ],
        name: "SiteDesignPreviewPowerwebTemplate1"
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';
    // Josefin Slab, Lato, Lora, Montserrat, Noto Sans JP, Open Sans, Playfair Display, Poppins, Quicksand, Roboto
    @import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Lora:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans+JP:wght@400;700&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&family=Quicksand:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

    $border: 1px solid;

    @mixin fake-links($color: var(--themeComplement-hsl), $width: 20px, $gap: 8px) {
        width: (($width * 3) + ($gap * 2));
        background-image: linear-gradient(
            to right,
            $color 0,
            $color $width,
            transparent ($width),
            transparent ($width + $gap),
            $color ($width + $gap),
            $color (($width * 2) + $gap),
            transparent (($width * 2) + $gap),
            transparent (($width + $gap) * 2),
            $color (($width + $gap) * 2),
            $color (($width * 3) + ($gap * 2))
        );
    }

    .preview-body {
        width: 100%;
        border: $border;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .header {
        .top {
            background-color: var(--themeColor-hsl);
            padding: 4px 10%;

            > div {
                display: flex;
                justify-content: flex-end;
                margin: -1px -3px;
            }

            .fake-link {
                height: 5px;
                width: 20px;
                border: 2px solid;
                margin: 1px 3px;

                &-0 {
                    border-color: transparent;
                    background-color: var(--themeComplement-hsl)
                }

                &-1, &-3 {
                    border-color: var(--themeCompliant-hsl);
                    background-color: var(--themeColor-hsl);
                }

                &-2 {
                    border-color: var(--themeComplementCompliant-hsl);
                    background-color: var(--themeComplement-hsl);
                }
            }
        }

        .bottom {
            padding: 4px 10%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;

            .fake-links {
                @include fake-links($color: black);
            }

            .logo {
                margin-right: 8px;
                max-height: 24px;
                max-width: 48px;

                img {
                    max-width: 100%;
                    max-height: 24px;
                    object-fit: contain;
                }
            }
        }
    }

    .page-title {
        $options: (
            '--bg-h': (0deg, var(--themeComplementCompliant-h)),
            '--bg-s': (0%,   var(--themeComplementCompliant-s)),
            '--bg-l': (0%,   var(--themeComplementCompliant-l)),
            '--bg-a': (.54,  var(--themeComplement-minOpacityOnCompliant)),
            '--tx-h': (0deg, var(--themeComplement-h)),
            '--tx-s': (0%,   var(--themeComplement-s)),
            '--tx-l': (100%, var(--themeComplement-l)),
        );

        @include pureCssIf(var(--themeComplement-minOpacityOnCompliant), ">=", .75, $options);

        --bg-hsl: hsl(var(--bg-h), var(--bg-s), var(--bg-l));
        --bg-hsla: hsla(var(--bg-h), var(--bg-s), var(--bg-l), var(--bg-a));
        --tx-hsl: hsl(var(--tx-h), var(--tx-s), var(--tx-l));
        position: relative;
        padding: 8px 10%;
        background-image:
            linear-gradient(to bottom, white, transparent),
            repeating-linear-gradient(-45deg, black, black 10px, white 10px, white 20px);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: var(--bg-hsla);
        }

        .title {
            position: relative;
            height: 8px;
            width: 76px;
            background-color: var(--tx-hsl);
            margin-bottom: 4px;
        }

        .breadcrumbs {
            position: relative;
            height: 4px;
            width: 38px;
            background-image: linear-gradient(
                to right,
                var(--tx-hsl) 0,
                var(--tx-hsl) 15px,
                transparent 15px,
                transparent 23px,
                var(--tx-hsl) 23px,
                var(--tx-hsl) 38px
            );

            &:before {
                position: absolute;
                font-size: 6px;
                color: var(--tx-hsl);
                content: "/";
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .main {
        flex-grow: 1;
        padding: 10px 10%;

        p {
            margin: 0 0 .25em;
            line-height: 1;
            color: black;
        }

        .heading {
            font-family: var(--display-font-family);
            font-weight: bold;
            color: var(--optimumOnWhite-hsl);

            &:nth-child(1) { font-size: 36px; }
            &:nth-child(2) { font-size: 32px; }
            &:nth-child(3) { font-size: 28px; }
            &:nth-child(4) { font-size: 24px; }
            &:nth-child(5) { font-size: 20px; }
            &:nth-child(6) { font-size: 16px; }
        }

        .body {
            margin-top: 1em;
            font-size: 12px;
            line-height: 1.25;
            font-family: var(--body-font-family);
        }

        button:nth-of-type(1) {
            background-color: var(--themeColor-hsl);
            color: var(--themeCompliant-hsl);
            font-family: var(--body-font-family);
        }

        button:nth-of-type(2) {
            background-color: var(--themeComplement-hsl);
            color: var(--themeComplementCompliant-hsl);
            font-family: var(--body-font-family);
        }
    }

    .footer {
        padding: 0;
        background-color: white;

        .fake-links {
            @include fake-links($color: var(--themeColor-hsl));
        }

        .top {
            padding: 4px 10%;
            background-color: var(--themeColor-hsl);
            display: flex;
            justify-content: center;

            .fake-links {
                @include fake-links($color: var(--themeCompliant-hsl));
            }
        }

        .bottom {
            padding: 4px 10%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo {
                margin-right: 8px;
                max-height: 24px;
                max-width: 48px;

                img {
                    max-width: 100%;
                    max-height: 24px;
                    object-fit: contain;

                    &.logo-invert {
                        filter: saturate(0) opacity(.25);
                    }
                }
            }

            .center {
                .fake-links {
                    @include fake-links($color: black);
                }
            }

            .right {
                .fake-links {
                    @include fake-links($color: black, $width: 6px, $gap: 6px);
                    height: 6px;
                }
            }
        }
    }

    .fake-links {
        @include fake-links();
        height: 5px;
    }
</style>