<template>
    <div class="preview-body" :style="themeProperties">
        <div class="header">
            <div class="top">
                <div class="fake-links"></div>
                <div class="fake-links"></div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="logo">
                        <img :src="logoPath" alt="preview logo">
                    </div>
                    <div class="fake-links"></div>
                </div>
            </div>
        </div>
        <div class="page-title">
            <div class="title"></div>
            <div class="breadcrumbs"></div>
        </div>
        <div class="main">
            <p class="heading">Heading 1</p>
            <p class="heading">Heading 2</p>
            <p class="heading">Heading 3</p>
            <p class="heading">Heading 4</p>
            <p class="heading">Heading 5</p>
            <p class="heading">Heading 6</p>
            <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <button class="btn btn-sm">Button</button>
            <button class="btn btn-sm">Button</button>
        </div>
        <div class="footer">
            <div class="top">
                <div class="fake-links"></div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="fake-links"></div>
                </div>
                <div class="right">
                    <div class="fake-links"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteDesignPreviewMixin } from './SiteDesignPreviewMixin';

    export default {
        mixins: [ SiteDesignPreviewMixin ],
        name: "SiteDesignPreviewTemplate1",
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';
    // Josefin Slab, Lato, Lora, Montserrat, Noto Sans JP, Open Sans, Playfair Display, Poppins, Quicksand, Roboto
    @import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Lora:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans+JP:wght@400;700&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&family=Quicksand:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

    $border: 1px solid;

    .preview-body {
        width: 100%;
        border: $border;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .header {
        background-color: white;
        padding: 0 10%;

        .top {
            padding: 4px 0;
            display: flex;
            justify-content: space-between;

            + .bottom { border-top: $border }
        }

        .bottom {
            padding: 4px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                display: flex;
                align-items: center;

                .logo {
                    margin-right: 8px;

                    img {
                        height: 24px;
                        width: 24px;
                        object-fit: contain;
                        vertical-align: bottom;
                    }
                }
            }
        }
    }

    .page-title {
        $options: (
            '--bg-h': (0deg, var(--themeComplementCompliant-h)),
            '--bg-s': (0%,   var(--themeComplementCompliant-s)),
            '--bg-l': (0%,   var(--themeComplementCompliant-l)),
            '--bg-a': (.54,  var(--themeComplement-minOpacityOnCompliant)),
            '--tx-h': (0deg, var(--themeComplement-h)),
            '--tx-s': (0%,   var(--themeComplement-s)),
            '--tx-l': (100%, var(--themeComplement-l)),
        );

        @include pureCssIf(var(--themeComplement-minOpacityOnCompliant), ">=", .75, $options);

        --bg-hsl: hsl(var(--bg-h), var(--bg-s), var(--bg-l));
        --bg-hsla: hsla(var(--bg-h), var(--bg-s), var(--bg-l), var(--bg-a));
        --tx-hsl: hsl(var(--tx-h), var(--tx-s), var(--tx-l));
        position: relative;
        padding: 8px 10%;
        background-image:
            linear-gradient(to bottom, white, transparent),
            repeating-linear-gradient(-45deg, black, black 10px, white 10px, white 20px);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: var(--bg-hsla);
        }

        .title {
            position: relative;
            height: 8px;
            width: 76px;
            background-color: var(--tx-hsl);
            margin-bottom: 4px;
        }

        .breadcrumbs {
            position: relative;
            height: 4px;
            width: 38px;
            background-image: linear-gradient(
                to right,
                var(--tx-hsl) 0,
                var(--tx-hsl) 15px,
                transparent 15px,
                transparent 23px,
                var(--tx-hsl) 23px,
                var(--tx-hsl) 38px
            );

            &:before {
                position: absolute;
                font-size: 6px;
                color: var(--tx-hsl);
                content: "/";
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .main {
        flex-grow: 1;
        padding: 10px 10%;

        p {
            margin: 0 0 .25em;
            line-height: 1;
            color: black;
        }

        .heading {
            font-family: var(--display-font-family);
            font-weight: bold;
            color: var(--optimumOnWhite-hsl);

            &:nth-child(1) { font-size: 36px; }
            &:nth-child(2) { font-size: 32px; }
            &:nth-child(3) { font-size: 28px; }
            &:nth-child(4) { font-size: 24px; }
            &:nth-child(5) { font-size: 20px; }
            &:nth-child(6) { font-size: 16px; }
        }

        .body {
            margin-top: 1em;
            font-size: 12px;
            line-height: 1.25;
            font-family: var(--body-font-family);
        }

        button:nth-of-type(1) {
            background-color: var(--themeColor-hsl);
            color: var(--themeCompliant-hsl);
            font-family: var(--body-font-family);
        }

        button:nth-of-type(2) {
            background-color: var(--themeComplement-hsl);
            color: var(--themeComplementCompliant-hsl);
            font-family: var(--body-font-family);
        }
    }

    .footer {
        padding: 0 10%;
        background-color: white;

        .top {
            padding: 4px 0;
            display: flex;
            justify-content: center;

            + .bottom { border-top: $border var(--themeColor-hsl) }
        }

        .bottom {
            padding: 4px 0;
            display: flex;
            justify-content: space-between;
        }
    }

    .fake-links {
        height: 5px;
        width: 76px;
        background-image: linear-gradient(
            to right,
            var(--optimumOnWhite-hsl) 0,
            var(--optimumOnWhite-hsl) 20px,
            transparent 20px,
            transparent 28px,
            var(--optimumOnWhite-hsl) 28px,
            var(--optimumOnWhite-hsl) 48px,
            transparent 48px,
            transparent 56px,
            var(--optimumOnWhite-hsl) 56px,
            var(--optimumOnWhite-hsl) 76px
        )
    }
</style>