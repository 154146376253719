<template>
    <div class="recycle-bin-section">
        <div class="rbs-add-new mb-3">
            <h3 class="mb-1">Recycled Pages</h3>
            <p>From this screen, pages can be permanently purged from the system, or restored to its original location on your website.<br><strong>You will not be able to edit these pages or assets until they have been restored.</strong></p>
        </div>

        <!-- Recycled Pages Table -->
        <table id="datatable" class="table table-striped table-bordered dataTable dtr-inline w-100 dt-pages" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="cspNonce">
            <thead>
                <tr>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Name</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Date Recycled</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Options</th>
                </tr>
            </thead>
        </table>

        <!-- Recycle Bin Delete Modal -->
        <recycle-bin-confirm-delete-modal
            :is-modal-open="isModalOpen"
            :is-modal-processing="isModalProcessing"
            modal-id="recycled-pages-confirm-delete-modal"
            @close-delete-modal="closeModal"
            @permanently-delete-event="deletePermanently"
        >
        </recycle-bin-confirm-delete-modal>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'RecycledPagesSection',
        created(){
            this.fetchPages();
            this.addClickEventsToListButtons();
        },
        computed: {
            ...mapState('recycleBin/pages', {
                cspNonce: state => state.list.cspNonce,
                activeRecord: state => state.list.activeRecord,
                isModalOpen: state => state.deletePage.isModalOpen,
                isModalProcessing: state => state.deletePage.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('recycleBin/pages', {
                fetchPages: 'list/fetchPages',
                closeModal: 'deletePage/closeModal',
                recycleRecord: 'deletePage/recycleRecord',
                addClickEventsToListButtons: 'list/addClickEventsToListButtons'
            }),
            deletePermanently(){
                this.recycleRecord({ ajaxRoute: window.vpanel.recycle_bin.delete.page, recordId: this.activeRecord.uuid, dispatchAction: 'recycleBin/pages/list/removeRecordFromDataTable'});
            },
        }
    }
</script>