<template>
    <div class="d-flex flex-column p-3 flex-grow-1">
        <div class="border-bottom">
            <p class="h5">
                <span :class="`fas ${getHeadingIcon} mr-1 text-tertiary`"></span>&nbsp;
                {{ getAddModifyHeading('Staff', 'full_name') }}
            </p>
        </div>
        <div class="pt-2">
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                v-if="!isActionAdd"
                :status="getStatusAsString"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <!-- Form Include -->
            <VpanelAddModifyStaffForm :is-action-add="isActionAdd" :active-record="activeRecord" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    export default {
        name: "VpanelAddModifyStaffPage",
        computed: {
            ...mapGetters('staff/addModify', [
                'getStatusAsString',
                'getAddModifyHeading'
            ]),
            ...mapState('staff/addModify', {
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
            }),
            getHeadingIcon(){
                return this.isActionAdd ? 'fa-user-plus' : 'fa-user-cog';
            }
        }
    }
</script>