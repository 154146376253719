<template>
    <div class="module-config-section-wrapper">
        <div class="mb-1 d-flex flex-row justify-content-between">
            <div class="module-config-section-header d-flex flex-column">
                <p>From this screen you can modify the module configuration options for your module.</p>
            </div>
            <div class="module-config-section-button d-flex flex-row justify-content-end mb-1">
                <button type="submit" class="btn btn-primary" :form="formId">Update Module Settings</button>
            </div>
        </div>
        <div class="module-config-table">
            <div class="mct-header">
                <p class="mct-name">Name</p>
                <p class="mct-value">Value</p>
                <p class="mct-description">Description</p>
            </div>
            <div class="mct-body">
                <form :id="formId" class="needs-validation" name="update-staff-module-setting" novalidate @submit.prevent="$emit('submit-form')">
                    <template v-for="(setting, index) in moduleConfigs">
                        <div class="mct-row" :key="index">
                            <p class="mct-name"><label :for="setting.id">{{ setting.name }}</label></p>
                            <div class="mct-value pr-2">
                                <div
                                    v-if="setting.field_type == 'checkbox'"
                                    class="custom-control custom-switch text-left"
                                >
                                    <input
                                        type="checkbox"
                                        :id="setting.id"
                                        :name="`config[${setting.id}]`"
                                        :value="parseInt(setting.value)"
                                        :checked="parseInt(setting.value)"
                                        @input="updateModuleSetting(setting)"
                                        class="custom-control-input js-boolean-control module-config-input"
                                    >
                                    <label class="custom-control-label" :for="setting.id"></label>
                                </div>
                                <div
                                    class="mct-image-dimensions-container"
                                    v-else-if="setting.field_type == 'image-dimensions'"
                                >
                                    <div class="image-dimensions-input-wrapper">
                                        <input
                                            type="text"
                                            :id="setting.id"
                                            :title="`Edit ${setting.name} Width`"
                                            :name="`config[${setting.id}][width]`"
                                            class="form-control module-config-input"
                                            :value="JSON.parse(setting.value).width"
                                        >
                                        <span>Width</span>
                                    </div>
                                    <div class="image-dimensions-input-wrapper">
                                        <input
                                            type="text"
                                            :id="setting.id"
                                            :title="`Edit ${setting.name} Height`"
                                            :name="`config[${setting.id}][height]`"
                                            class="form-control module-config-input"
                                            :value="JSON.parse(setting.value).height"
                                        >
                                        <span>Height</span>
                                    </div>
                                </div>

                                <input
                                    v-else
                                    :id="setting.id"
                                    :value="setting.value"
                                    :type="setting.field_type"
                                    :name="`config[${setting.id}]`"
                                    :title="`Edit ${setting.name}`"
                                    @input="updateModuleSetting(setting)"
                                    class="form-control module-config-input"
                                >

                                </div>
                            <p class="mct-description">{{ setting.description }}</p>
                        </div>
                    </template>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "VpanelModuleConfigList",
        props: {
            formId: {
                type: String,
                default: 'update-module-config-settings'
            },
            moduleConfigs: Array, Object
        },
        methods: {
            updateModuleSetting(config){
                let inputValue = config.field_type == 'checkbox' ? event.target.checked : event.target.value;
                this.$emit('update-config-value', {config: config, value: inputValue});
            }
        }
    }
</script>
<style lang="scss" scoped>
    .module-config-section-wrapper {
        padding: 1.5rem;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
</style>