<template>
    <VueModal :visible="isModalOpen" @on-close="cancelProductVaraintReset" id="product-variant-reset-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Product Variants Removal</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you want to remove these product variants?</p>
            <p class="text-center m-0">This action is permanent. You will not be able to restore these product variants. </p>
            <p class="text-center">Make sure to save after performing this action.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="cancelProductVaraintReset">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="resetProductVariants" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Confirm' }}</button>
            </div>
        </template>
    </VueModal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelConfirmResetProductVariantModal',
        computed: {
            ...mapState('product/addModify/confirmVariantResetModal', {
                isModalOpen: state => state.isModalOpen,
                isModalProcessing: state => state.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('product/addModify', {
                updateInput: 'setValueInActiveRecord',
                resetProductAttributes: 'resetProductAttributes',
                closeModal: 'confirmVariantResetModal/closeModal'
            }),
            cancelProductVaraintReset(){
                //set has variants back to true
                this.updateInput({prop: 'has_variants', value: true});

                //close modal
                this.closeModal();
            },
            resetProductVariants(){
                //reset attributes
                this.resetProductAttributes();

                //will show user a message
                showNotification('Remember to save these changes.', 'info');

                //close modal
                this.closeModal();
            }
        }
    }
</script>