<template>
    <div class="comments-form">
        <form id="add-comment-form" name="add-comment-form" @submit.prevent="onSubmit">
            <span class="text-muted text-14 mb-1 d-block">All fields marked with * are required</span>

            <vue-textarea
                rows="6"
                required
                name="Leave a comment"
                @keyup="updateCharCount"
                v-model="activeRecord.comment"
                :maxlength="commentMaxCharacters"
            ></vue-textarea>
            <p class="mt-n2 text-info text-14">
                <span>{{ charactersRemaining }}</span> Characters Remaining
            </p>

            <div class="row">
                <div class="col-md-6">
                    <vue-input
                        name="Name"
                        v-model="activeRecord.name"
                        required
                    ></vue-input>
                </div>

                <div class="col-md-6">
                    <vue-input
                        name="Email"
                        v-model="activeRecord.email"
                        required
                    ></vue-input>
                </div>
            </div>

            <vue-hcaptcha v-if="hcaptchaSiteKey"
                :sitekey="hcaptchaSiteKey"
                @verify="onVerify"
                @expired="onExpire"
                @error="onError"
            />

            <vue-button
                @click.prevent="onSubmit"
                :class="{disabled:isDisabled}"
                :disabled="isDisabled"
            >Submit Comment</vue-button>
        </form>

        <vue-modal :visible="successModalVisible" @on-close="closeModal">
            <template #header>
                <p class="h2 m-0 text-success" v-if="response == 'Success'"><span class="fas fa-check mr-1"></span>{{ response }}</p>
                <p class="h2 m-0" v-else>{{ response }}</p>
            </template>
            <p class="m-0">{{ message }}</p>
            <div id="form-errors" class="form-errors mb-0"></div>
        </vue-modal>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

    export default {
        name: 'SiteBlogCommentForm',
        props: [
            "commentMaxCharacters"
        ],
        components: { VueHcaptcha },
        data() {
            return {
                eKey: null,
                error: null,
                token: null,
                expired: false,
                verified: false,
                charactersRemaining: this.commentMaxCharacters
            }
        },
        computed: {
            ...mapState('blog', {
                activeBlog: 'activeRecord',
                hcaptchaSiteKey: 'hcaptchaSiteKey',
            }),
            ...mapState('comment', [
                'message',
                'response',
                'activeRecord',
                'successModalVisible',
            ]),
            isDisabled: function(){
                return !this.verified;
            }
        },
        methods: {
            ...mapActions('comment', [
                'submitForm',
                'closeModal'
            ]),
            onVerify(token, ekey) {
                this.verified = true;
                this.token = token;
                this.activeRecord.hcaptchaToken=token;
                this.activeRecord.hcaptchaEkey=ekey;
                this.eKey = ekey;
            },
            onExpire() {
                this.verified = false;
                this.token = null;
                this.eKey = null;
                this.expired = true;
            },
            onError(err) {
                this.token = null;
                this.eKey = null;
                this.error = err;
            },
            onSubmit() {
                this.submitForm(this.activeBlog.id);
            },
            updateCharCount(){
                let input     = event.target;
                let maxlength = this.commentMaxCharacters;
                let newCount  = maxlength - input.value.length;

                this.charactersRemaining = newCount;
            }
        }
    }
</script>