<template>
    <div class="product-manufacturer-section">
         <div class="mb-2">
            <VpanelEcommAddNewRecordButton namespace="productManufacturer/addModify" button-text="Add a New Manufacturer" router-path="Add Product Manufacturer" />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Product Manufacturer Table -->
        <VpanelProductManufacturerTable
            v-else
            respond-at="md"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />

        <!-- Delete Manufacturer Modal -->
        <VpanelEcommConfirmRecycleModal
            namespace="productManufacturer"
            heading-text="Confirm Delete Manufacturer"
            modal-id="product-manufacturer-recycle-modal"
            ajax-route="/vpanel/modules/ecommerce/product/manufacturer/recycle"
            after-recycle-dispatch-action="productManufacturer/list/refreshTableData"
        />
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "VpanelProductManufacturerList",
        computed: {
            ...mapGetters('productManufacturer/list', {
                tableData: 'getTableData'
            })
        }
    }
</script>