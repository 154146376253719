<template>
    <div class="flex-grow-1 d-flex flex-md-row flex-column">
        <!--Form Versions List-->
        <VpanelFormVersionList
            v-if="formHasRevisions"
            :form-name="currentFormVersion.name"
            :created-date="currentFormVersion.created_at"
        />

        <div class="p-3 flex-grow-1" style="max-width:100%;">
            <div class="fb-submissions-header flex-column flex-md-row">
                <!--Header-->
                <VpanelFormSubmissionListingHeader
                    :show-badges="true"
                    :form-name="getFormName"
                    :show-date="!formHasRevisions"
                    :created-date="currentFormVersion.created_at"
                />

                <div class="fb-submission-header-buttons">
                    <!--Export Button-->
                    <VpanelFormBuilderButton
                        namespace=""
                        @button-event="exportSubmissions"
                        button-color="outline-secondary-blue"
                        :button-processing="isExportProcessing"
                        :button-text="isExportProcessing ? 'Processing...' : 'Export Submissions'"
                    />

                    <!--Back to Forms Button-->
                    <VpanelFormBuilderButton
                        namespace=""
                        router-path="Forms List"
                        button-text="Back to Forms"
                        button-color="outline-primary"
                    />
                </div>
            </div>

            <!--Table Loader-->
            <VueLoader v-if="tableData.isLoading" />

            <!-- Forms Table -->
            <VpanelFormSubmissionsTable
                v-else
                respond-at="md"
                :items-per-page="10"
                :searchable-data="[]"
                :all-table-info="tableData"
                :data="tableData.displayedList"
                :current-form="currentFormVersion"
                :form-has-revisions="formHasRevisions"
            />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';

	export default {
        name: "VpanelFormSubmissionList",
        computed: {
            ...mapGetters('forms/versions/list', {
                formHasRevisions: 'getFormHasRevisions',
                currentFormVersion: 'getCurrentFormVersion'
            }),
            ...mapState('forms/submissions', {
                tableData: state => state.list.tableData,
                isExportProcessing: state => state.exportation.isFormProcessing
            }),
            getFormName(){
                let formRevisionName = `v${this.currentFormVersion.revision_name}` || 'v1';
                let formName         = !this.formHasRevisions ? this.currentFormVersion.name : '';
                return `${formName}  ${formRevisionName}`;
            }
        },
        methods: {
            ...mapActions('forms/submissions/exportation', [
                'exportSubmissions'
            ])
        }
	};
</script>

<style lang="scss" scoped>
    #forms-module-section .fb-submissions-header {
        gap: 1rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .fb-submission-header-buttons{
            display: flex;
            gap: 1rem;
        }
    }
</style>