<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <p class="h5 m-0">
                <span class="fas fa-glasses mr-1"></span>
                Specifications
            </p>

            <!-- Add Additional Specs Button -->
            <VpanelAddAdditionalInputButton
                @add-new-array-item="addSpec"
            />
        </div>

        <div class="card-body">
            <div :class="{'border-top pt-2 mt-2': index !== 0}" :key="index" v-for="(specs, index) in productSpecs">
                <div class="d-flex align-items-start m-n1">
                    <!-- Spec Group Title -->
                    <div class="form-group has-label m-1 flex-grow-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label :for="`product_spec_title_${index}`">Group Title</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Give the specification group a title/name."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            :value="specs.title"
                            name="product_spec_title"
                            :id="`product_spec_title_${index}`"
                            @input="updateProductValue({index: index, prop: 'specs', nestedIndex: 'title', nested: true})"
                        />
                    </div>

                    <!-- Remove Additional Spec Button -->
                    <div>
                        <VpanelRemoveAdditionalItemButton
                            @add-new-array-item="addSpec"
                            v-if="Object.keys(productSpecs).length > 1"
                            :array-count="Object.keys(productSpecs).length"
                            @remove-additional-item="removeAdditionalItem([index, 'specs'])"
                        />
                    </div>
                </div>
                <div class="name-value-grid">
                    <template v-for="(specGroup, specGroupIndex) in specs.group">
                        <!-- Spec Group Name -->
                        <div class="form-group has-label mb-0" :key="`name_${specGroupIndex}`">
                            <div class="d-flex justify-content-between align-items-center">
                                <label :for="`product_spec_name_${specGroupIndex}`">Name</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="Provide a specification name."
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                :value="specGroup.name"
                                name="product_spec_name"
                                :id="`product_spec_name_${specGroupIndex}`"
                                @input="updateProductSpecGroup([index, specGroupIndex, 'name'])"
                            />
                        </div>
                        <!-- Spec Group Value -->
                        <div class="form-group has-label mb-0" :key="`value_${specGroupIndex}`">
                            <div class="d-flex justify-content-between align-items-center">
                                <label :for="`product_spec_value_${specGroupIndex}`">Value</label>
                                <a
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="Provide a specification value."
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                :value="specGroup.value"
                                name="product_spec_value"
                                :id="`product_spec_value_${specGroupIndex}`"
                                @input="updateProductSpecGroup([index, specGroupIndex, 'value'])"
                            />
                        </div>
                        <div class="buttons m-n1" :key="`buttons_${specGroupIndex}`">
                            <!-- Remove Additional Spec Group Button -->
                            <VpanelRemoveAdditionalItemButton
                                @add-new-array-item="addSpecGroup"
                                v-if="Object.keys(specs.group).length > 1"
                                :array-count="Object.keys(specs.group).length"
                                @remove-additional-item="removeProductSpecGroup([index, specGroupIndex])"
                            />
                            <!-- Add Additional Spec Group Button -->
                            <VpanelAddAdditionalInputButton
                                v-if="specGroupIndex == 0"
                                @add-new-array-item="addSpecGroup(index)"
                                class="m-1"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'VpanelProductSpecificationForm',
        props: [
            'productSpecs'
        ],
        created(){
            if(this.productSpecs.length == 0){
                this.addSpec();
            }
        },
        methods:{
            ...mapActions('product/addModify', [
                'updateProductValue',
                'removeAdditionalItem',
                'removeProductSpecGroup',
                'updateProductSpecGroup',
            ]),
            checkSpecGroupForEmptyValues(groups){
                return Object.values(groups).every(group => group.name && group.value);
            },
            addSpec(){
                let specs = this.productSpecs;
                let shouldAddNewSpec = Object.values(specs).every(spec => spec.title && this.checkSpecGroupForEmptyValues(spec.group));

                if(shouldAddNewSpec){
                    specs.push({
                        title: '',
                        group: [{
                            name: '',
                            value: ''
                        }]
                    });
                } else {
                    //will show user a message if theres a spec that doesn't have a value
                    showNotification('Make sure all specification inputs are filled in, before adding a new one.', 'error');
                }
            },
            addSpecGroup(index){
                let specGroup = this.productSpecs[index].group;

                if(this.checkSpecGroupForEmptyValues(specGroup)){
                    specGroup.push({
                        name: '',
                        value: ''
                    });
                } else {
                    //will show user a message if theres a spec that doesn't have a value
                    showNotification('Make sure all specification name and value inputs are filled in.', 'error');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../../../../sass/_vue-import.scss';

    .name-value-grid {
        display: grid;
        grid-template-columns: 1fr 1fr max-content;
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
        grid-gap: .5rem 1rem;
        margin: 1rem 0;

        @include bp-up($bp-laptop + 1) { grid-template-columns: 1fr 1fr max-content; }
        @include bp-tablet { grid-template-columns: 1fr 1fr max-content; }

        @include bp-laptop {
            grid-template-columns: 100%;

            .buttons { margin-bottom: 1rem !important; }
        }

        @include mobile {
            grid-template-columns: 100%;

            .buttons { margin-bottom: 1rem !important; }
        }
    }
</style>