<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div class="input-wrapper">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <input
                class="form-control"
                type="time"
                :value="value || undefined"
                v-bind="inputBinder"
                v-on="inputListener"
            >
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        min: {
            type: [String],
            validator: (value) => { return value.match(/([01][1-9]|2[0-3]):(0[0-9]|[12345][0-9])/g); },
            default: null
        },
        max: {
            type: [String],
            validator: (value) => { return value.match(/([01][1-9]|2[0-3]):(0[0-9]|[12345][0-9])/g); },
            default: null
        },
        step: {
            type: [Number, String],
            validator: (value) => { return parseInt(value); },
            default: null
        }
    },
    computed: {
        modifiedBinder() {
            return {
                ...this.inputBinder,
                min: this.min,
                max: this.max,
                step: this.step,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-wrapper { position: relative; }

    .form-control::-webkit-calendar-picker-indicator { cursor: pointer; }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .input-wrapper & {
            position: absolute;
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
            padding: 0 .25rem;
            line-height: 1;
            border-radius: 2px;
        }
    }
</style>