<template>
    <div class="top-bar">
        <!-- Search -->
        <input
            type="search"
            v-model="postalModel"
            v-if="isSearchEnabled"
            class="location-search form-control"
            placeholder="Find a nearby location by zip code..."
        >

        <div class="top-buttons">
            <div class="filters">
                <button
                    @click="nearMeClick()"
                    class="top-bar-button"
                    v-if="isFindLocationEnabled && activeView == 'map'"
                    :class="{active: nearMed}"
                ><span class="fas fa-search-location"></span>Near Me</button>

                <button
                    class="top-bar-button"
                    v-if="isFiltersEnabled && (typeOptions.length > 1 || serviceOptions.length > 1 || stateOptions.length > 2)"
                    @click="setFiltersVisible(!filtersVisible)"
                ><span class="fas fa-filter"></span>Filters</button>
            </div>

            <div class="views">
                <button
                    v-for="view in views"
                    class="top-bar-button"
                    v-show="view.isEnabled"
                    @click="setNewView(view.name)"
                    :key="`${view.name}-view-button`"
                    :class="{active: activeView == view.name}"
                ><span :class="`fas fa-${view.icon}`"></span>{{ view.name }}</button>
            </div>

            <div class="filters-panel" :class="{active: filtersVisible}" v-if="isFiltersEnabled">
                <!-- Types -->
                <vue-checkboxes
                    v-if="typeOptions.length > 1"
                    name="Types"
                    v-model="typeModel"
                    :options="typeOptions"
                ></vue-checkboxes>

                <!-- Services -->
                <vue-checkboxes
                    v-if="isServicesEnabled && serviceOptions.length > 1"
                    name="Services"
                    v-model="serviceModel"
                    :options="serviceOptions"
                ></vue-checkboxes>

                <!-- States -->
                <vue-select
                    v-if="stateOptions.length > 2"
                    name="State"
                    :options="stateOptions"
                    v-model="stateModel"
                ></vue-select>

                <div class="buttons">
                    <button class="btn btn-primary" @click="applyFilters();setFiltersVisible(false)">Apply</button>
                    <button class="btn btn-light" @click="resetFilters">Reset</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    props: [
        'isGridViewEnabled',
        'isListViewEnabled',
        'isServicesEnabled',
        'isMapViewEnabled',
        'filtersVisible',
        'serviceFilter',
        'postalFilter',
        'stateFilter',
        'activeView',
        'typeFilter',
        'services',
        'types',
    ],
    data() {
        return {
            views: [
                { name: 'map', icon: 'map', isEnabled: this.isMapViewEnabled },
                { name: 'list', icon: 'th-list', isEnabled: this.isListViewEnabled },
                { name: 'grid', icon: 'th-large', isEnabled: this.isGridViewEnabled }
            ],
            typeOptions: [],
            serviceOptions: [],
            stateOptions: [{name:"",value:"",hidden:true,selected:true}],
            postalModel: "",
            typeModel: [],
            serviceModel: [],
            stateModel: ""
        }
    },
    computed: {
        ...mapState('location', [
            'locations'
        ]),
        ...mapState('location/googleMap', [
            'nearMed'
        ]),
        ...mapState('location/settings', [
            'isFindLocationEnabled',
            'isFiltersEnabled',
            'isSearchEnabled',
        ]),
        ...mapGetters('location', [
            'locationIds',
        ]),
    },
    watch: {
        // Keeping this one dynamic because there's no 'Apply' button for it
        postalModel(n) {
            this.setPostalFilter(n)
            this.settleFilters();
        }
    },
    methods: {
        ...mapActions('location', [
            'setSidebarDetails',
            'setFiltersVisible',
            'setServiceFilter',
            'setPostalFilter',
            'setStateFilter',
            'setTypeFilter',
            'setView',
        ]),
        ...mapActions('location/googleMap', [
            'fitNewBounds',
            'resetNearMe',
            'initMap',
            'nearMe',
        ]),
        nearMeClick() {
            if (this.nearMed) this.resetNearMe();
            else this.nearMe()
        },
        formatForCheckboxes(indexedObj) {
            return Object.entries(indexedObj).map(([key, obj]) => {
                return { name: obj.name, value: key }
            });
        },
        applyFilters() {
            this.setStateFilter(this.stateModel.slice());
            this.setTypeFilter([...this.typeModel]);
            this.setServiceFilter([...this.serviceModel]);
            this.settleFilters();
        },
        resetFilters() {
            this.typeModel.length = 0;
            this.serviceModel.length = 0;
            this.postalModel = "";
            // if only the checkboxes have values the reactivity lags behind
            // because the models are linked to arrays not their entries,
            // changing the state selector value for just a millisecond then
            // resetting it makes it update even if the state value doesn't change
            this.stateModel = "1";
            this.stateModel = "";
            this.applyFilters();
        },
        settleFilters() {
            if (this.activeView == 'map') {
                this.fitNewBounds();
                this.setFiltersVisible(false);
                this.setSidebarDetails(null);
            }
        },
        setNewView(view) {
            this.setView(view);
            setTimeout(this.settleFilters, 0);
        }
    },
    mounted() {
        // format types and services data for checkbox components
        this.typeOptions = this.formatForCheckboxes(this.types);
        this.serviceOptions = this.formatForCheckboxes(this.services);
        [...new Set(this.locationIds.map(i => this.locations[i].state))].sort().forEach(s => {
            this.stateOptions.push({
                name: s,
                value: s
            })
        });
    },
}
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../../sass/_vue-import.scss';

    // eventually move this to the base, maybe clean it up a bit
    @mixin border-widths($t, $r: null, $b: null, $l: null) {
        // conditionally handle shorthand
        @if not $r {$r:$t;$b:$t;$l:$t;}
        @else if not $b {$b:$t;$l:$r;}
        @else if not $l {$l:$r;}

        border-style: solid;
        border-color: var(--light);
        border-width: $t $r $b $l;
    }

    .top-bar {
        background-color: white;
        position: relative;
        z-index: 1; // to put above the overlay when the filter is open

        @include desktop {
            display: flex;

            .location-search {
                flex: 1 1 math.div(100%, 3);
                min-width: 300px;
            }

            .top-buttons {
                flex: 1 1 math.div(200%, 3);
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: white;
        }
    }

    .location-search {
        border-radius: 0;
        background-color: var(--lighter);
        font-size: 14px;
        position: relative;
        min-height: 3em;
        z-index: 1;

        @include desktop {
            height: 100%;
            @include border-widths(1px, 0, 1px, 1px);
        }

        @include not-desktop {
            @include border-widths(1px, 0);
        }
    }

    .top-buttons {
        display: flex;
        align-items: stretch;
        position: relative;

        @include desktop {
            @include border-widths(1px, 1px, 1px, 0);
        }

        @include not-desktop {
            @include border-widths(0, 0, 1px);
        }

        @include not-mobile {
            justify-content: space-between;
        }

        @include mobile {
            flex-grow: 1;
            flex-wrap: wrap;
        }

        .filters-panel {
            pointer-events: none;
            position: absolute;
            top: 100%;
            width: 100%;
            background-color: white;
            padding: 1rem .75rem;
            opacity: 0;
            transform: translateY(-100%);
            transition: var(--transition);
            z-index: -1;

            &.active {
                transform: translateY(0);
                opacity: 1;
                pointer-events: all;
            }

            &::v-deep {
                .fieldset-label + .inputs .input-wrapper {
                    display: inline-flex;
                    padding-left: .5rem;
                    padding-right: .5rem;
                }
            }

            .buttons {
                display: flex;
                align-items: stretch;
                justify-content: flex-end;
                flex-wrap: wrap;
                margin: -.25rem;

                button {
                    margin: .25rem;
                    flex-grow: 1;
                }
            }
        }
    }

    .filters, .views {
        display: flex;
        align-items: stretch;

        @include mobile {
            flex-grow: 1;
        }
    }

    .views {
        justify-content: flex-end;
    }

    .top-bar-button {
        display: flex;
        border: 0;
        outline: 0;
        align-items: center;
        justify-content: center;
        padding-top: .75rem;
        padding-bottom: .75rem;
        @include fluid-size(8px, 16px, padding-left);
        @include fluid-size(8px, 16px, padding-right);
        margin: 0;
        background-color: white;
        transition: var(--transition);
        font-size: 14px;
        color: var(--dark);
        text-transform: capitalize;
        line-height: 1.1;

        @include not-desktop {
            flex-grow: 1;
        }

        &:hover {
            color: var(--dark);
            background-color: var(--light);
        }

        &.active {
            background-color: var(--themeColor-hsl);
            color: var(--themeCompliant-hsl);
        }

        &.disabled {
            pointer-events: none;
            opacity: .5;
        }

        svg {
            margin-right: .5em;
        }
    }
</style>