<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div :class="{'float-wrapper': floatLabel, 'float-label': labelFloating}">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <select
                :name="inputLabel"
                class="form-control"
                v-bind="inputBinder"
                v-on="inputListener"
                @focus="focus"
                @blur="unfocus"
            >
                <option
                    v-for="(option, index) in options"
                    :key="`${index}-${option.name}`"
                    :value="option.value"
                    :hidden="option.hidden"
                    :disabled="option.disabled"
                    :selected="value == option.value"
                >{{ option.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        options: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../sass/_vue-import.scss';

    .float-wrapper {
        position: relative;

        &:before {
            @include safari {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                background-color: #000;
                height: 12px;
                width: 12px;
                transform: translate(-50%, -50%);
                clip-path: polygon(20% 30%,50% 60%,80% 30%,90% 40%,50% 80%,10% 40%);
            }
        }
    }

    select { @include safari { -webkit-appearance: none; } }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .float-wrapper & {
            position: absolute;
            left: .5rem;
            top: .875rem;
            transform: translateY(0);
            background-color: transparent;
            padding: 0 .25rem;
            transition: 200ms ease-out;
            pointer-events: none;
            line-height: 1;
            border-radius: 2px;
            opacity: .5;
        }

        .float-wrapper.float-label & {
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
        }
    }
</style>