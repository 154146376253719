<template>
    <form id="add-modify-comment-form" class="comment-form needs-validation" name="add-modify-comment-form" novalidate @submit.prevent="submitForm">
        <div class="form-errors"></div>
        <input type="hidden" name="_token" :value="activeRecord._token" />
        <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />
        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
        <div class="document-form-fields d-flex flex-column align-items-stretch">
            <h5>Basic Information</h5>
                <div class="form-group has-label" v-if="isActionAdd">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="full_name">Blog Post</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Blog Post"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>

                    <select
                        class="form-select-input"
                        v-model="activeRecord.post_id"
                        name="post"
                    >
                        <option
                            v-for="(post, index) in posts"  :key="index"
                            :id="`post${post.title}`"
                            :value="post.id"
                        >
                            {{post.title}}
                        </option>
                    </select>
                    <div class="invalid-feedback">
                        Please select a blog post.
                    </div>
                </div>

                <div class="form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="full_name">Name</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="The  name of the commentor"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="text"
                        class="form-control"
                        required
                        name="name"
                        id="name"
                        title="Please enter commentor  name"
                        autocomplete="off"
                        data-invalid-feedback="Please enter a comment name."
                        v-model="activeRecord.name"
                    />
                    <div class="invalid-feedback">
                        Please enter a name.
                    </div>
                </div>

                <div class="form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="user_email">Email</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="The email of the user"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="email"
                        required
                        class="form-control"
                        name="email"
                        id="email"
                        title="Please enter email"
                        autocomplete="off"
                        v-model="activeRecord.email"
                        data-invalid-feedback="Please enter commentoremail."
                    />
                    <div class="invalid-feedback">
                        Please enter commentor's email.
                    </div>
                </div>

                <div class="form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="comment">Comment</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Comment"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <textarea
                        required
                        id="comment"
                        name="comment"
                        maxlength="500"
                        autocomplete="off"
                        class="form-control"
                        title="Please enter comment"
                        v-model="activeRecord.comment"
                        data-invalid-feedback="Add a comment of the commentor"
                    />
                    <div class="invalid-feedback">
                            Add a comment.
                    </div>
                </div>

                <div class="form-group flex-grow-1">
                    <div class="radio-legend d-flex justify-content-between align-items-center">
                        <span class="required mr-3">Status</span>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Please select a status for this comment."
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <fieldset>
                        <legend class="label sr-only">Status</legend>
                        <div class="form-check form-check-inline form-check-radio">
                            <label class="form-check-label" for="status-approved">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    title="status"
                                    required
                                    name="status"
                                    id="status-approved"
                                    value="Approved"
                                    v-model="activeRecord.status"
                                    data-invalid-feedback="Please select a status for this comment."
                                />
                                <span class="form-check-sign"></span>
                                Approved
                            </label>
                        </div>

                        <div class="form-check form-check-inline form-check-radio">
                            <label class="form-check-label" for="status-pending">
                                <input
                                    class="form-check-input"
                                    title="status"
                                    type="radio"
                                    name="status"
                                    id="status-pending"
                                    value="Pending"
                                    v-model="activeRecord.status"
                                    data-invalid-feedback="Please select a status for this comment."
                                />
                                <span class="form-check-sign"></span>
                                Pending
                                <div class="invalid-feedback">
                                Please select a status for this comment.
                                </div>
                            </label>
                        </div>

                        <div class="form-check form-check-inline form-check-radio">
                            <label class="form-check-label" for="status-declined">
                                <input
                                    class="form-check-input"
                                    title="status"
                                    type="radio"
                                    name="status"
                                    id="status-declined"
                                    value="Declined"
                                    v-model="activeRecord.status"
                                    data-invalid-feedback="Please select a status for this comment."
                                />
                                <span class="form-check-sign"></span>
                                Declined
                                <div class="invalid-feedback">
                                Please select a status for this comment.
                                </div>
                            </label>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        name: 'VpanelAddModifyCommentForm',
        props: [
            'id',
            'isActionAdd',
            'activeRecord'
        ],
        data() {
            return {
                image: '',
                post: '',
                show: true
            }
        },
        created() {
            if (this.id > 0) {
                this.show = false;
                this.activeRecord.post_id = this.id;
            }
        },
        computed: {
            ...mapState('comment/list', {
                cspNonce: state => state.cspNonce,
                comments: state => state.tableData,
                moduleConfig: state => state.moduleConfig,
            }),
            ...mapState('blog/list', {
                posts: state => state.tableData
            })
        },
        methods: {
            submitForm() {
                this.$emit('submitForm');
            },
            closeModal() {
                this.$emit('close-comment-modal');
            }
        }
    }
</script>