<template>
    <div class="ecommerce-nav">
        <template v-for="(link, index) in navLinks">
            <RouterLink
                :exact="link.exact"
                :key="index"
                class="nav-link"
                :title="link.title"
                :to="{ name: link.to}"
                active-class="current"
                v-if="link.is_section_enabled"
            >{{ link.name }}</RouterLink>
        </template>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'VpanelEcommerceSecondaryNav',
        computed: {
            ...mapGetters([
                'getModuleConfigs'
            ]),
            navLinks(){
                return [
                    {
                        'name': 'Products',
                        'to': 'Product List',
                        'exact': true,
                        'is_section_enabled': true,
                        'title': 'Internal link that opens a list of catalog products',
                    },
                    {
                        'name': 'Master Catalog',
                        'to': 'Master Catalog List',
                        'exact': true,
                        'title': 'Internal link that opens a list of master catalog products',
                        'is_section_enabled': parseInt(this.getModuleConfigs['Enable Master Catalog Section']),
                    },
                    {
                        'name': 'Categories',
                        'is_section_enabled': true,
                        'to': 'Product Category List',
                        'exact': false,
                        'title': 'Internal link that opens a list of product categories'
                    },
                    {
                        'name': 'Options',
                        'is_section_enabled': true,
                        'to': 'Product Option List',
                        'exact': true,
                        'title': 'Internal link that opens a list of product options'
                    },
                    {
                        'name': 'Manufacturers',
                        'is_section_enabled': true,
                        'to': 'Product Manufacturer List',
                        'exact': true,
                        'title': 'Internal link that opens a list of product manufacturers'
                    },
                    {
                        'name': 'Customers',
                        'to': 'Customer List',
                        'exact': true,
                        'title': 'Internal link that opens a list of customers',
                        'is_section_enabled': parseInt(this.getModuleConfigs['Enable Customers Section'])
                    },
                    {
                        'name': 'Orders',
                        'to': 'Order List',
                        'exact': true,
                        'title': 'Internal link that opens a list of orders',
                        'is_section_enabled': parseInt(this.getModuleConfigs['Enable Orders Section'])
                    },
                    {
                        'name': 'Coupons',
                        'to': 'Coupon List',
                        'exact': true,
                        'title': 'Internal link that opens a list of coupons',
                        'is_section_enabled': parseInt(this.getModuleConfigs['Enable Coupons Section'])
                    },
                    {
                        'name': 'Reports',
                        'to': 'Report List',
                        'exact': true,
                        'title': 'Internal link that opens a list of reports',
                        'is_section_enabled': parseInt(this.getModuleConfigs['Enable Reports Section'])
                    },
                    {
                        'name': 'Settings',
                        'to': 'Module Setting List',
                        'exact': true,
                        'title': 'Internal link that opens a list of settings',
                        'is_section_enabled': parseInt(this.getModuleConfigs['Enable Settings Section']),
                    },
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .ecommerce-nav {
        @extend %minimal-scrollbar;
        overflow-x: auto;
        flex-shrink: 0;

        &::v-deep .nav-link {
            flex-shrink: 0;
        }
    }
</style>