<template>
    <div class="al-body">
        <table id="al-datatable" class="table table-bordered dataTable dtr-inline" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="cspNonce">
            <thead>
                <tr role="row">
                    <th class="table-head" tabindex="0" aria-controls="datatable">Event</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Status</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Username</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable">Log Date</th>
                    <th class="table-head" tabindex="0" aria-controls="datatable"></th>
                </tr>
            </thead>
        </table>
    </div>
</template>
<script>
    import { mapState, mapActions } from "vuex";
    export default {
        name: 'AuditLogList',
        created(){
            this.fetchLogs();
            this.addClickEventsToListButtons();
        },
        computed: {
            ...mapState("auditLog/list", {
                cspNonce: state => state.cspNonce,
            })
        },
        methods: {
            ...mapActions("auditLog/list", [
                'fetchLogs',
                'addClickEventsToListButtons'
            ])
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep #al-show-details {
        .fa-chevron-up { display: none; }

        &.active {
            .fa-chevron-up { display: inline-block; }
            .fa-chevron-down { display: none; }
        }
    }
</style>