<template>
    <label class="fb-group" :class="[validClass, notices.length ? 'fb-warning' : '']">
        <span class="fb-label">{{ question.label }}<span class="fb-required" v-if="required" aria-label="required">*</span></span>

        <div class="fb-file-wrapper">
            <input
                hidden
                multiple
                type="file"
                ref="control"
                class="fb-control"
                v-bind="[controlBinder]"
                @input="checkValue()"
                @focus="focusQuestion(questionId)"
                @blur="focusQuestion(); checkValue()"
                v-on="questionEventBinder"
            />
            <div class="fb-file-list">
                <p class="file" v-for="(file, i) in files" :key="i+'file'">
                    {{ file.name }}
                    <FontAwesomeIcon :icon="icons.trashAlt" @click.prevent="removeFile(i)" style="color: var(--red)" />
                </p>
            </div>
            <div class="fb-file-buttons">
                <button class="btn btn-primary btn-sm" @click.prevent="chooseFiles()">Add Files</button>
            </div>
        </div>

        <ul class="fb-file-notices" v-if="notices.length">
            <li v-for="(notice, index) in notices" :key="`notice_${index}`">
                {{ notice }}
                <button class="fb-file-notice-close-timer" type="button" @click.prevent="removeNotice(notice)">
                    <FontAwesomeIcon :icon="icons.times" style="position:relative" />
                </button>
            </li>
        </ul>

        <SiteFormBuilderAssistiveText
            v-if="formatText"
            format
            :message="formatText"
        />
        <SiteFormBuilderAssistiveText :message="requiredMessage" />
    </label>
</template>

<script>
import { questionChute, questionLadder, general } from './mixins/SiteFormBuilderControlMixins';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import mime from './plugins/Mime';

export default {
    name: 'SiteFormBuilderControlFile',
    inheritAttrs: false,
    mixins: [ questionChute, questionLadder, general ],
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            icons: {
                trashAlt: faTrashAlt,
                times: faTimes
            },
            files: [],
            invalidTypes: new Set(),
            notices: []
        }
    },
    watch: {
        files: {
            deep: true,
            handler(n) {
                if (!n.length) this.checkValue();
                this.$emit('file-change', n)
            }
        }
    },
    computed: {
        formatText() {
            let acceptString = this.question.attributes.accept;
            if (!acceptString) return '';
            let acceptArray = this.question.attributes.accept.split(',').map(accept => accept.trim());
            let formattedArray = acceptArray.map(accept => {
                if (accept[0] === '.') return accept;
                if (accept.split('/').length > 1) {
                    return Object.entries(mime).filter(([key, value]) => {
                        if (accept.split('/')[1] === "*") return this.findMimesThatStartWith(value, accept);
                        return value.includes(accept);
                    }).map(([key, value]) => key);
                }
            })
            return `Accepted files: ${formattedArray.flat().map(k=>'.'+k).join(', ')}`;
        }
    },
    methods: {
        removeNotice(notice) {
            let index = this.notices.indexOf(notice);
            if (index > -1) this.notices.splice(index, 1);
        },
        logFalseReturn(res, toLog) {
            if (!res) this.invalidTypes.add(toLog);
            return res;
        },
        findMimesThatStartWith(mimeValue, accept) {
            const typeStartsWith = (type) => type.startsWith(accept.split("/")[0]);
            if (Array.isArray(mimeValue)) return mimeValue.some(type => typeStartsWith(type))
            return typeStartsWith(mimeValue);
        },
        compareValue(type, accept) {
            // Check image/jpeg format or image/* format
            if (accept.split('/').length > 1) {
                // Get all mimes in the primary accept type [image, application, text]/...
                let mimes = Object.values(mime).filter(mimeType => this.findMimesThatStartWith(mimeType, accept));

                // If using the type/* format, determine if any of the mimes match the checked type
                if (accept.split('/')[1] === '*') return mimes.includes(type);

                // Else accept is full mime type, check for equality
                return type.includes(accept);
            }

            // Check .jpg format
            if (accept[0] === '.') return a.slice(1) in mime && mime[a.slice(1)].includes(type);
            return false;
        },
        compareValues(types, acceptString) {
            if (!acceptString) return true;
            let acceptArray = acceptString.split(',').map(accept => accept.trim());

            // Go over every type and accept combo and add false returns to the invalidTypes set
            return types.every(type => this.logFalseReturn(acceptArray.some(accept => this.compareValue(type, accept)), type));
        },
        // This validateFunc has one side effect in addition to returning -1, 0, or 1
        // It will push invalid file types to an array
        validateFunc(vm) {
            let addedFiles = vm.$refs.control.files,
                fileTypes = [...new Set(Array.from(addedFiles).map(file => file.type))];

            // Run compare function as it generates the invalidTypes array, we don't need the return
            vm.compareValues(fileTypes, vm.$refs.control.accept);

            vm.addFiles();

            if (!vm.required) return -1;
            return vm.files.length;
        },
        addFiles() {
            let vm = this;
            [...vm.$refs.control.files].forEach(file => {
                const pushAndRemoveNoticeAfterWait = (notice) => {
                    vm.notices.push(notice);
                    setTimeout(() => vm.removeNotice(notice), 7000);
                }
                let added = this.files.some(f => ['name','type','size'].every(prop => file[prop] === f[prop]));
                let invalid = this.invalidTypes.has(file.type);
                let filesFull = vm.files.length >= vm.question.attributes?.maxFiles;

                // Add file if not added or invalid
                if (!added && !invalid && !filesFull) this.files.push(file);

                if (filesFull) pushAndRemoveNoticeAfterWait(`Max file count is ${vm.question.attributes?.maxFiles}. Please remove a file before adding another.`);
                if (invalid) pushAndRemoveNoticeAfterWait(`${file.name} is not an accepted file type and wasn't added.`);
                if (added) pushAndRemoveNoticeAfterWait(`${file.name} has already been added and won't be added a second time.`);
            })
            this.invalidTypes.clear();
            event.target.value = "";
        },
        removeFile(i) {
            this.files.splice(i, 1);
        },
        chooseFiles() {
            this.$refs.control.click();
        },
    }
}
</script>