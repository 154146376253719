<template>
    <div ref="formWrapper">
        <div v-if="showErrorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <p v-if="showSuccessMessage" class="alert alert-success">{{ successMessage }}</p>

        <form class="fb-form" novalidate @submit.prevent="submitForm" ref="form">
            <div class="fb-questions" @click.self="focusQuestion()">
                <input type="hidden" name="form_id" :value="data.form.id">
                <SiteFormBuilderQuestion
                    v-for="questionId in data.form.questions"
                    :key="`question_${questionId}`"
                    v-bind="questionBinder(questionId)"
                    :validate-form-inc="validateFormInc"
                    @focus-question="focusQuestion($event)"
                    @validate-question="validateQuestion(questionId, $event)"
                    @file-change="fileChange(questionId, $event)"
                />
            </div>

            <VueRecaptcha
                :size="captchaSize"
                @verify="captchaVerified"
                @expired="captchaExpired"
                :execute="executeInvisibleHcaptcha"
                :hcaptcha-site-key="data.form.hcaptcha_site_key"
            />

            <button class="btn btn-primary" type="submit" :disabled="isFormProcessing">{{ isFormProcessing ? 'Processing...' : 'Submit' }}</button>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'SiteFormBuilderForm',
        props: [ 'data' ],
        data() {
            return {
                isFormValid: null,
                errorMessage: null,
                validQuestions: {},
                validateFormInc: 0,
                focussedQuestion: 0,
                successMessage: null,
                isCaptchaValid: false,
                showErrorMessage: false,
                isFormProcessing: false,
                showSuccessMessage: false,
                executeInvisibleHcaptcha: false,
                captchaSize: this.data.form.recaptcha_type,
                fileInputs: {},
            }
        },
        mounted(){
            this.parseParams();
        },
        methods: {
            validateQuestion(questionId, valid) {
                this.validQuestions[questionId] = valid
            },
            focusQuestion(id = 0) {
                this.focussedQuestion = id;
            },
            captchaVerified() {
                this.isCaptchaValid = true;

                if (this.showErrorMessage) {
                    this.showErrorMessage = false;
                    this.errorMessage     = null;
                }

                if(this.captchaSize == 'invisible'){
                    this.submitForm();
                }
            },
            captchaExpired() {
                if (!this.showSuccessMessage) {
                    this.isCaptchaValid   = false;
                    this.showErrorMessage = true;
                    this.errorMessage     = 'Captcha has expired please do the challenge again.';
                }

                this.executeInvisibleHcaptcha = false;
            },
            questionBinder(id) {
                let question = this.data.questions[id];
                let res = {
                    question: question,
                    questionId: parseInt(id),
                    focussedQuestion: this.focussedQuestion
                };

                if (question.options) res['options'] = question.options.reduce((a,c) => {
                    a[c] = this.data.options[c];
                    return a;
                }, {});

                return res
            },
            parseParams() {
                let   form   = this.$refs.form;
                const params = new URLSearchParams(window.location.search);

                if (window.location.search.length) {
                    // Support for URL Param Interception for text, email, tel inputs, and selects
                    let paramInterceptors = form.querySelectorAll('[data-param]');

                    paramInterceptors.forEach(interceptor => {
                        let param          = params.get(interceptor.dataset.param),
                            tag            = interceptor.tagName,
                            isTextEmailTel = ['text','email','tel'].some(q => interceptor.type == q);

                        if (!param) return false;
                        if (tag == 'INPUT' && isTextEmailTel) interceptor.value = decodeURI(param);
                        if (tag == 'SELECT') {
                            // Basing the param value from the TEXT rather than the VALUE as the value in formbuilder form selects is just an index
                            let option = Array.from(interceptor.options).find(option => option.text == param);

                            if (option) option.selected = true;
                        }
                    })
                }
            },
            fileChange(questionId, files) {
                this.fileInputs[questionId] = files;
            },
            submitForm() {
                let vm = this;
                this.validateFormInc++;
                this.isFormProcessing = true;

                setTimeout(function() {
                    if (!Object.values(vm.validQuestions).every(q => q)) {
                        vm.showErrorMessage = true;
                        vm.errorMessage     = "Please complete all required fields";
                        vm.isFormProcessing = false;
                    } else {
                        vm.showErrorMessage   = false;
                        vm.errorMessage       = "";

                        if (!vm.isCaptchaValid) {
                            vm.executeInvisibleHcaptcha = true;
                        }

                        let form        = vm.$refs.form;
                        let formWrapper = form.closest('.form-builder-renderer');
                        let formData    = new FormData(form);

                        // Update file input FormData with saved file list
                        if (Object.keys(vm.fileInputs).length) {
                            Object.entries(vm.fileInputs).forEach(([id, files]) => {
                                let key = `inputs[${id}][]`;
                                formData.delete(key);
                                files.forEach(file => formData.append(key, file));
                            })
                        }

                        if (vm.isCaptchaValid) {
                            //set the form processing variable
                            axios.post('/form-processor/process', formData)
                                .then(response => {
                                    //If successful
                                    vm.isFormProcessing   = false;
                                    vm.isFormValid        = null;
                                    vm.successMessage     = response.data.message;
                                    vm.showSuccessMessage = true;

                                    formWrapper.scrollIntoView({
                                        block: "start",
                                        behavior: "smooth"
                                    });

                                    form.reset();
                                }).catch(error => {
                                    //set the form processing variable
                                    vm.isFormProcessing   = false;
                                    vm.showSuccessMessage = false;
                                    let errors          = error.response.data.message;
                                    let formErrors      = vm.data.form.error_message ? vm.data.form.error_message : '';

                                    if (typeof errors === 'string') formErrors = errors;
                                    else if (typeof errors === 'object' && errors !== null && !Array.isArray(errors)) {
                                        Object.values(errors).forEach(err => formErrors += `<p>${err}<p>`)
                                    }

                                    vm.errorMessage     = formErrors;
                                    vm.showErrorMessage = true;

                                    logApplicationError(error);

                                    formWrapper.scrollIntoView({
                                        block: "start",
                                        behavior: "smooth"
                                    });

                                    console.error(error);
                                });
                        } else {
                            if (vm.captchaSize != 'invisible') {
                                vm.showErrorMessage = true;
                                vm.errorMessage     = 'Please check the recaptcha box';
                            }
                        }
                    }
                }, 500)
            }
        }
    }
</script>

<style lang="scss">
    @import './SCSS/_form-styles.scss';
</style>