<template>
    <component
        :is="templateComponent"
    ></component>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	name: "StaffListing",
    props: ["template", "staffData", "divisionData", "moduleConfig", "locationData"],
    data() {
        return {
            templateName: this.template
        }
    },
    computed: {
        templateComponent() {
            return `staff-listing-template-${this.templateName || 1}`;
        }
    },
    methods: {
        ...mapMutations("staff", ["SET"]),
    },
    mounted() {
        this.SET(['staff', this.staffData]);
        this.SET(['divisions', this.divisionData]);
        this.SET(['configs', this.moduleConfig]);
        this.SET(['locations', this.locationData]);
    }
};
</script>