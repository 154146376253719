<template>
    <div>
        <template v-for="(number, index) in {'Phone':location.phone,'Fax':location.fax,'Toll Free':location.toll_free}">
            <p v-if="number" :key="index">
                <strong>{{ index }}: </strong>
                <a
                    :href="`tel:${number.replace(/\D/g,'')}`"
                    :title="`Opens the ${location.title} phone number in your default calling application`"
                >{{ number }}</a>
            </p>
        </template>
        <p v-if="location.email">
            <strong>Email: </strong>
            {{ location.email }}
        </p>
    </div>
</template>

<script>
export default {
    props: ["location", "sidebarDetails"]
}
</script>

<style lang="scss" scoped>
    p {
        margin-bottom: 0;
    }
</style>