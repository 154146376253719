// TODO
// Acts a little glitchy when no v-model is applied to the component

<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div class="input-wrapper">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <input
                type="range"
                :value="inputValue"
                v-bind="modifiedBinder"
                v-on="inputListener"
                @input="updateTooltip"
                @mousedown="clicking = true"
                @mouseup="clicking = false"
            >
            <div class="range-value" :style="rangeValueLocation" v-if="inputValue">{{inputValue}}</div>
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        min: {
            type: [Number, String],
            validator: (value) => { return parseInt(value) || parseInt(value) === 0; },
            default: '0'
        },
        max: {
            type: [Number, String],
            validator: (value) => { return parseInt(value); },
            default: 100
        },
        step: {
            type: [Number, String],
            validator: (value) => { return parseInt(value); },
            default: null
        }
    },
    data() {
        return {
            inputValue: this.value,
            clicking: false
        }
    },
    computed: {
        rangeValueLocation() {
            return {
                color: this.inputValue,
                left: `${((this.inputValue - this.min) / (this.max - this.min) * 100)}%`,
                transform: `translate(${((this.inputValue - this.min) / (this.max - this.min) * 100) * -1}%, -50%)`
            }
        },
        modifiedBinder() {
            return {
                ...this.inputBinder,
                min: this.min,
                max: this.max,
                step: this.step,
                class: ['form-control form-control-range', this.clicking ? 'clicking' : '']

            }
        }
    },
    methods: {
        updateTooltip() {
            this.inputValue = event.target.value;
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-wrapper { position: relative; }
    .form-control {
        border-color: transparent;
        padding: 0;

        &::-webkit-slider-thumb { cursor: grab; }
        &.clicking::-webkit-slider-thumb { cursor: grabbing; }
    }

    .range-value {
        position: absolute;
        top: 100%;
        min-width: 18px;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--blue);
        color: white;
        font-weight: bold;
        font-size: 12px;
        border-radius: 2px;
        padding: 0;
    }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .input-wrapper & {
            position: absolute;
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
            padding: 0 .25rem;
            line-height: 1;
            border-radius: 2px;
        }
    }
</style>