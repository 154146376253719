<template>
    <TableWrapper
        v-on="wrapperEvents"
        v-bind="wrapperBinder"
        :item-count="allTableInfo.itemCount"
        :active-page="allTableInfo.activePage"
        @update-page="allTableInfo.updateActivePage($event);setCurrentPageToSession($event);"
    >
        <!--Custom Table Search-->
        <VpanelEcommerceTableSearch namespace="productManufacturer/list" @searched="updateTableDataAfterSearch" :search-model="allTableInfo.searchModel" />

        <div class="st-hidden-sorts">
            <p @click="allTableInfo.sortBy('name')" :class="allTableInfo.sortClass('name')">Name &amp; Description</p>
            <p @click="allTableInfo.sortBy('is_active', null, 'number')" :class="allTableInfo.sortClass('is_active')">Status</p>
            <p class="st-header" @click="allTableInfo.sortBy('updated_at', null, 'date')" :class="allTableInfo.sortClass('updated_at')">Last Modified</p>
        </div>
        <div class="simple-table" style="--cols:5;">
            <p class="first-col st-header" @click="allTableInfo.sortBy('name')" :class="allTableInfo.sortClass('name')">Name &amp; Description</p>
            <p class="st-header">Logo</p>
            <p class="st-header" @click="allTableInfo.sortBy('is_active', null, 'number')" :class="allTableInfo.sortClass('is_active')">Status</p>
            <p class="st-header" @click="allTableInfo.sortBy('updated_at', null, 'date')" :class="allTableInfo.sortClass('updated_at')">Last Modified</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <div class="d-flex justify-content-center align-items-start flex-column" :key="row.id+'name'">
                        <span class="st-hidden">Name &amp; Description</span>
                        <p class="font-weight-bold mb-1 text-16">{{ row.name }}</p>
                        <div class="manufacturer-description" :class="{'with-gradient': row.description && row.description.length > 350}" v-html="row.description"></div>
                    </div>

                    <p :key="row.id+'image'">
                        <span class="st-hidden">Logo</span>
                        <VpanelEcommImageDiv :image="row.image" image-wrapper-width="width:150px;" />
                    </p>

                    <div :key="row.id+'status'">
                        <span class="st-hidden">Status</span>
                        <p class="m-0" :class="row.status?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                    </div>

                    <p :key="row.id+'updated_at'">
                        <span class="st-hidden">Last Modified</span>
                        <strong>{{ row.updated_at }}</strong>
                    </p>

                    <div class="last-col" :key="row.id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <RouterLink
                                exact
                                class="btn btn-primary"
                                :title="`Internal link to modify the ${row.name} manufacturer`"
                                :to="{ name: 'Modify Product Manufacturer',  params: { id: row.id }}"
                            >Modify</RouterLink>

                            <button
                                type="button"
                                class="btn btn-outline-primary"
                                @click="recycleManufacturer(row.id)"
                                :disabled="Object.keys(row.products).length > 0"
                                :title="Object.keys(row.products).length > 0 ? 'Please unassociate products before deleting manufacturer' : false"
                            >Delete</button>
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelProductManufacturerTable',
        mixins: [ tableMixin ],
        props: [
            'allTableInfo',
        ],
        methods: {
            ...mapActions('productManufacturer/list', [
                'recycleButtonAction',
                'setCurrentPageToSession',
                'updateTableDataAfterSearch'
            ]),
            status(row) {
                return row.status ? 'Active' : 'Inactive';
            },
            recycleManufacturer(manufacturerId){
                this.recycleButtonAction({
                    recordId: manufacturerId,
                    storeNamespace: 'productManufacturer'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(4, minmax(min-content, auto)) minmax(min-content, max-content);
    }

    .manufacturer-description {

        &::v-deep *:last-child {
            margin-bottom: 0;
        }

        &.with-gradient {
            position: relative;
            max-height: 100px;
            overflow: hidden;

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50px;
                background-image: linear-gradient(to top, white, transparent);
            }
        }
    }
</style>