<template>
    <div class="details-wrapper">
        <p class="detail-type">{{ getUppercaseType }}</p>
        <div class="detail-type-value-wrapper">
            <div class="detail-type-value" v-for="(id, index) in currentStaffGetProperty(type)" :key="index">
                <span>{{ getRecordName(id)  }}</span>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";

    export default {
        name: 'SiteStaffDivisionsOrLocationsDisplay',
        props: [
            'type',
            'locations',
            'divisions'
        ],
        computed: {
            ...mapGetters("staff", [
                "currentStaffGetProperty",
            ]),
            getUppercaseType(){
                return this.type.charAt(0).toUpperCase() + this.type.slice(1);
            }
        },
        methods: {
            getRecordName(id){
                let returnValue;
                if (this.type == 'locations') returnValue = this.locations[id].title
                else if (this.type == 'divisions') returnValue = this.divisions[id].name

                return returnValue;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .details-wrapper {
        display: flex;
        align-items: stretch;
    }

    .detail-type {
        margin: 0;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.1;
        padding: 5px 12px;
        border: solid hsl(0deg, 0%, 90%);
        border-width: 1px 0 1px 1px;
        border-radius: 5px 0 0 5px;
    }

    .detail-type-value-wrapper {
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 6px;
        flex-wrap: wrap;
        border-radius: 0 5px 5px 0;
        background-color: hsl(0deg, 0%, 90%);
    }

    .detail-type-value {
        border-radius: 5px;
        font-size: 14px;
        line-height: 1.0;
        padding: 4px 12px;
        background-color: white;
    }
</style>