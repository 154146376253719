<template>
    <form id="type-add-modify-form" class="needs-validation" name="type-add-modify-form" novalidate @submit.prevent="submitTypeForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />

        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="d-flex flex-column align-items-stretch">
                <div class="row">
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="name">Name</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The name of the location type"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            id="name"
                            type="text"
                            class="form-control"
                            required name="name"
                            v-model="activeRecord.name"
                            title="Please enter a type name"
                            data-invalid-feedback="Please enter a type name."
                        />
                        <div class="invalid-feedback">
                            Please enter a type name.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group px-2">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="required mr-3">Status</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="If the type is active it will show on the front end"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_yes">
                                    <input
                                        checked
                                        required
                                        :value="1"
                                        type="radio"
                                        name="status"
                                        id="status_yes"
                                        class="form-check-input"
                                        title="Please choose an option"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the type is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Active
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_no">
                                    <input
                                        required
                                        :value="0"
                                        type="radio"
                                        name="status"
                                        id="status_no"
                                        class="form-check-input"
                                        title="Please choose an option"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the type is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Inactive
                                    <div class="invalid-feedback">
                                        Please indicate if the type is active or inactive.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    export default {
        name: 'AddModifyLocationtypeForm',
        props: [
            'isActionAdd',
            'activeRecord',
            'errorMessage',
        ],
        methods: {
            submitTypeForm(){
                this.$emit('submit-type-form');
            },
            closeModal(){
                this.$emit('close-type-modal');
            }
        }
    }
</script>