<template>
    <div class="group">
        <p class="title">{{ hours.title }}</p>
        <p
            v-for="(day, index) in daysStartingToday"
            :key="index"
            class="hours-row"
            :class="{today: !index}"
        >
            {{ day }}:
            <span v-if="hours[`${day.toLowerCase()}_closed`]">Closed</span>
            <span v-else>{{ formatTime(hours[`${day.toLowerCase()}_open`]) }} to {{ formatTime(hours[`${day.toLowerCase()}_close`]) }}</span>
        </p>
    </div>
</template>

<script>
export default {
    props: ["hours"],
    data() {
        return {
            now: new Date(),
            daysOfWeek: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        }
    },
    computed: {
        daysStartingToday() {
            let index = this.now.getDay();
            return this.daysOfWeek
                       // Cuts off days before today
                       .slice(index)
                       // and appends them to the end of the array
                       .concat(this.daysOfWeek.slice(0, index))
        },
    },
    methods: {
        formatTime(time) {
            // if time is null, return dash
            if (!time) return '-';
            let timeArr = time.split(":");
            if (timeArr[0] > 12) return `${timeArr[0] - 12}:${timeArr[1]} PM`;
            return `${timeArr[0].replace(/^0/, '')}:${timeArr[1]} AM`;
        }
    }
}
</script>

<style lang="scss" scoped>
    .title {
        font-size: 16px;
        font-weight: bold;
    }

    .group {
        padding: .5rem 0;

        p {
            margin: 0;
            padding: 0 .5rem;
        }

        > *:first-child { margin-top: 0; }
        > *:last-child { margin-bottom: 0; }
    }

    .hours-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.today { font-weight: bold; }
    }
</style>