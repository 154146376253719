export const dataTableMixin = {
    template: `<table :id="tableId" :class="customClasses" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="csp_nonce">
                    <thead>
                        <tr>
                            <th class="table-head" tabindex="0" aria-controls="datatable" v-for="(columnName, index) in columnNames" :key="index">
                            {{ columnName }}
                            </th>
                        </tr>
                    </thead>
                </table>`,
    props: [
        'tableId',
        'columnNames',
        'ajaxRoute',
        'customDatatableOptions',
        'classList'
    ],
    data() {
        return {
            csp_nonce: window.csp_nonce
        }
    },
    computed: {
        datatableOptions() {

            const componentOptions = this.$options;

            let defaultDataTableOptions = {
                ajax: {
                    url: this.ajaxRoute,
                    error: function (xhr, error, code) {
                        const err = {
                            message: `There was an error with ajax call in ${componentOptions.name} component: ${code}`,
                            name: 'DataTablesError'
                        };

                        openApplicationErrorModal(err, '');
                    }
                },
                processing: true,
                pagingType: "full_numbers",
                lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
                responsive: true,
                autoWidth: true,
                rowId: 'id',
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search By Keyword",
                    processing: 'Loading...'
                },

            };

            let customDatatableOptions = this.customDatatableOptions || {};
            let moduleDatatableOptions = this.moduleDatatableOptions || {};

            return { ...defaultDataTableOptions, ...moduleDatatableOptions, ...customDatatableOptions };
        },
        customClasses() {
            return this.classList || 'table table-striped table-bordered dataTable dtr-inline w-100';
        }
    },
    mounted() {
        const datatableOptions = this.datatableOptions;
        const componentOptions = this.$options;

        $(`#${this.tableId}`).on('error.dt', function (e, settings, techNote, message) {
            const error = {
                message: `There was an error in component ${componentOptions.name}: ${message}`,
                name: 'DataTablesError'
            }
            openApplicationErrorModal(error, '');

        }).DataTable(datatableOptions);
    }
}