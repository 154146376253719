<template>
    <div class="header-template-ecom-1" :data-variant="variant">
        <div class="top-bar" v-if="primaryLinks">
            <div class="dynamic-container">
                <VueSiteSearch
                    :search-template="2"
                    v-bind="{...$props}"
                ></VueSiteSearch>
                <div class="logo">
                    <img :src="logoPath" alt="Company logo">
                    <VueLink link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></VueLink>
                </div>
                <div class="ecommerce-links">
                    <div class="link-wrapper">
                        <VueLink
                            v-if="contactPhone"
                            :link-path="contactPhoneFormatted"
                            :link-text="contactPhone"
                            link-icon="fas fa-phone"
                        />

                        <VueLink
                            link-text="Cart"
                            link-icon="fas fa-shopping-cart"
                        />

                        <!-- v-if="!loggedIn" -->
                        <VueLink
                            link-text="Log In/New Account"
                            link-icon="fas fa-user"
                        />

                        <!-- v-if="loggedIn" -->
                        <VueLink
                            link-class="d-none"
                            link-text="Account"
                            link-icon="fas fa-user-circle"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="dynamic-container">
                <VueNav
                    :mobile-expanded="mobileNavExpanded"
                    v-bind="{...$props}"
                    @close-mobile-menu="mobileNavExpanded = !mobileNavExpanded"
                ></VueNav>

                <div class="mobile-controls">
                    <p class="open-mobile-menu" @click="mobileNavExpanded = !mobileNavExpanded">
                        <span class="fas fa-bars"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { navMixin } from '@v/Core/Site/Mixins/navMixin';
import { headerMixin } from '@v/Core/Site/Mixins/headerMixin';
import { headerFooterMixin } from '@v/Core/Site/Mixins/headerFooterMixin';

export default {
    mixins: [
        navMixin,
        headerMixin,
        headerFooterMixin,
    ],
    props: [
        "primaryLinks",
        "secondaryLinks",
        "primaryCta",
        "secondaryCta",
    ],
    data() {
        return {
            mobileNavExpanded: false
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../../sass/_vue-import.scss';

    .header-template-ecom-1 {
        &::v-deep {
            .top-bar {
                padding: .5rem 0;
                background-color: var(--themeColor-hsl);

                .dynamic-container {
                    display: grid;

                    @include desktop {
                        grid-template:
                            "s l e" max-content
                            / 1fr auto 1fr;
                    }

                    @include tablet {
                        grid-template:
                            "s s" max-content
                            "l e" max-content
                            / max-content 1fr;
                    }

                    @include mobile {
                        grid-template:
                            "s" max-content
                            "e" max-content
                            "l" max-content
                            / 100%;
                    }

                    .vue-site-search-2 {
                        grid-area: s;
                        margin: .5rem 0;

                        .vss-input-group {
                            height: auto;
                            width: 100%;

                            input {
                                border: 2px solid var(--themeComplement-hsl);
                                color: var(--optimumOnWhite-hsl);
                                border-radius: 0;
                                background-color: white;
                                box-shadow: 0 5px 10px -5px rgb(0 0 0 / 20%);
                                font-weight: bold;
                                font-size: 14px;
                                padding: .65rem 1.5rem;

                                &::placeholder {
                                    color: hsla(var(--optimumOnWhite), .5);
                                    text-transform: uppercase;
                                }
                            }

                            a {
                                background-color: var(--themeComplement-hsl);
                                color: var(--themeComplementCompliant-hsl);
                            }
                        }
                    }

                    .logo {
                        grid-area: l;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 100%;
                        max-height: 100px;

                        @include desktop { margin: .5rem min(6vw,75px); }
                        @include tablet { margin: .5rem min(6vw,75px) .5rem 0; }
                        @include mobile { margin: .5rem 0; }

                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }

                    .ecommerce-links {
                        grid-area: e;
                        display: flex;
                        align-items: center;
                        margin: .5rem 0;

                        @include mobile { margin: 1rem 0; }

                        .link-wrapper {
                            display: flex;
                            justify-content: flex-end;
                            align-content: center;
                            width: calc(100% + 1rem);
                            flex-wrap: wrap;
                            margin: -.5rem;

                            @include mobile { justify-content: center; }
                        }

                        .vue-link {
                            margin: .25rem .5rem;
                            font-weight: bold;
                            color: var(--themeComplement-hsl);
                            text-decoration: none;
                            flex-shrink: 0;
                        }
                    }
                }
            }

            .bottom-bar {
                background-color: var(--themeComplement-hsl);
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dynamic-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    > .mobile-controls {
                        width: 100%;

                        .open-mobile-menu {
                            margin: 0;
                            color: var(--themeComplementCompliant-hsl);
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: .5rem;
                        }
                    }
                }

                // Nav styles
                .vue-nav-1 {
                    width: 100%;
                    margin: 0;

                    @include tablet { padding-left: 50%; }
                    @include mobile { padding-left: 0; }

                    &:before, &:after {
                        z-index: -1;
                        transition: 200ms ease-out;

                        @include tablet { width: 50%; }
                        @include not-desktop {
                            content: "";
                            position: absolute;
                            top: 0;
                            height: 100%;
                        }
                    }

                    &:before {
                        right: 0;
                        background-color: var(--themeColor-hsl);

                        @include mobile { width: 100%; }
                    }

                    &:after {
                        left: 0;
                        background-color: rgba(black, .8);

                        @include mobile { width: 0; }
                    }

                    .vn-item { text-transform: none; }

                    [data-depth="0"] {
                        &.vn-family {
                            --hc-circle-size: 30px;
                            --hc-arrow-width: 2px;
                            @include desktop { flex: 1 0 auto; }
                            &:before { background-color: var(--themeComplementCompliant-hsl); }
                            &:hover .vn-item[data-depth="0"] { color: var(--themeComplement-hsl); }
                        }

                        &.vn-item {
                            @include desktop {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                color: var(--themeComplementCompliant-hsl);
                                &:before { content: none; }
                            }
                        }
                    }

                    [data-depth="1"] {
                        &.vn-family {
                            --hc-circle-size: 20px;
                            --hc-arrow-width: 1px;
                        }
                    }
                }
            }


            .mobile-controls {
                @include desktop { display: none; }

                .open-mobile-menu {
                    font-size: 24px;
                    margin: 0 0 0 1rem;
                }
            }

            // Default container variant
            &:not([data-variant]) .dynamic-container {
                max-width: var(--container-width, 1140px);
                margin-left: auto;
                margin-right: auto;
                padding-left: calc((var(--grid-gutter-width, 30px) / 2));
                padding-right: calc((var(--grid-gutter-width, 30px) / 2));
            }

            // Full width variant
            &[data-variant="full"] .dynamic-container {
                padding-left: calc((var(--grid-gutter-width, 30px) / 2));
                padding-right: calc((var(--grid-gutter-width, 30px) / 2));
            }

            .primary-links,
            .secondary-links {
                display: flex;

                a {
                    color: var(--themeComplement-hsl);
                    padding: .25rem 1rem;
                }
            }
        }
    }
</style>