<template>
    <button v-bind="buttonBinder" v-on="buttonListeners">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        buttonType: String,
    },
    computed: {
        themeColor() {
            return this.buttonType ? this.buttonType.toLowerCase() : 'primary';
        },
        buttonListeners() {
            let ifClick = 'click' in this.$listeners ? {} : { click: this.defaultClick };

            return Object.assign(ifClick,
                this.$listeners
            )
        },
        themeClass() {
            return `btn btn-${this.themeColor}`
        },
        buttonBinder() {
            return {
                ...this.$attrs,
                class: this.themeClass
            }
        }
    },
    methods: {
        defaultClick() {
            console.error(`This button currently does not do anything.\nCan be found in <${this.$parent.$options.name}>`);
        }
    }
}
</script>