<template>
    <div class="product-category-section">
        <div class="mb-2">
            <VpanelEcommAddNewRecordButton namespace="productCategory/addModify" button-text="Add a New Category" router-path="Add Product Category" />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Product Category Table -->
        <VpanelProductCategoryTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />

        <!-- Delete Category Modal -->
        <VpanelEcommConfirmRecycleModal
            namespace="productCategory"
            heading-text="Confirm Delete Category"
            modal-id="product-category-recycle-modal"
            ajax-route="/vpanel/modules/ecommerce/product/category/recycle"
            after-recycle-dispatch-action="productCategory/list/refreshTableData"
        />
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "VpanelProductCategoryList",
        computed: {
            ...mapGetters('productCategory/list', {
                tableData: 'getTableData',
            })
        }
    }
</script>