<template>
    <router-view :key="$route.fullPath" />
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: "VpanelBlogListIndex",
        created() {
            this.fetchBlog();
        },
        methods: {
            ...mapActions("blog", {
                fetchBlog: "list/fetchBlog",
            }),
        }
    };
</script>