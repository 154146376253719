<template>
    <form id="product-tag-add-form" class="needs-validation" name="product-tag-add-form" novalidate @submit.prevent="submitProductTagForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <div class="form-group has-label m-0">
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="product_tag_name">Name</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="The name of the product tag"
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                name="name"
                class="form-control"
                id="product_tag_name"
                v-model="activeRecord.name"
                title="Please enter an tag name"
                data-invalid-feedback="Please enter an tag name."
            />
            <div class="invalid-feedback">
                Please enter a tag name.
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: 'VpanelAddProductTagForm',
        props: [
            'activeRecord',
            'errorMessage',
        ],
        methods: {
            submitProductTagForm(){
                let addCommit = 'productTag/list/ADD_RECORD_TO_TAG_LIST';
                let addRoute  = '/vpanel/modules/ecommerce/product/tag/store';

                this.$emit('submit-ecomm-form', {addModifyRoute: addRoute, addModifyCommit: addCommit, dataTableId: null  });
            },
            closeModal(){
                this.$emit('close-ecomm-modal');
            }
        }
    }
</script>