<template>
    <div class="category-details">
        <div class="category-img" v-if="category.image">
            <img :src="category.image" :alt="category.image_alt" />
        </div>
        <div class="category-content">
            <h2 class="category-title">{{ category.name }}</h2>
            <div v-html="category.description"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'SiteProductCategoryDetails',
        props: [
            'category'
        ]
    }
</script>

<style lang="scss" scoped>
    .category-details {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: -1rem -1rem 1rem;

        .category-img {
            padding: 1rem;
            flex: 1 1 max(100px, 25%);
            border-radius: 5px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                vertical-align: bottom;
            }
        }

        .category-content {
            padding: 1rem;
            flex: 1 1 75%;
        }
    }
</style>