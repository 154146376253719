<template>
    <div class="row">
        <div class="col">
            <p class="h5">Hours</p>
            <div class="row">
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="hours_title">Title</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="The title/name of the location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        type="text"
                        id="hours_title"
                        name="hours_title"
                        class="form-control"
                        v-model="currentLocation.hours[0].title"
                        title="Please enter the title for this set of hours"
                        
                    />
                </div>
            </div>
            <div v-for="(day, index) in daysOfWeek" :key="index">
                <div class="row">
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label :class="isClosedAllDayChecked(day) ? 'required' : ''" :for="`${day}_open`">{{ day }} Open</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                :title="`Choose an open time for ${day}`"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="time"
                            :id="`${day}_open`"
                            :name="`${day}_open`"
                            class="form-control"
                            :required="isClosedAllDayChecked(day)"
                            :title="`Please choose an open time for ${day}`"
                            v-model="currentLocation.hours[0][day.toLowerCase() + '_open']"
                            :data-invalid-feedback="`Please choose an open time for ${day}`"
                        />
                        <div class="invalid-feedback">
                            Please choose an open time for {{ day }}
                        </div>
                    </div>
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label :class="isClosedAllDayChecked(day) ? 'required' : ''" :for="`${day}_close`">{{ day }} Close</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                :title="`Choose a time that this location closes on ${day}`"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="time"
                            :id="`${day}_close`"
                            class="form-control"
                            :name="`${day}_close`"
                            :required="isClosedAllDayChecked(day)"
                            :title="`Please choose a close time for ${day}`"
                            v-model="currentLocation.hours[0][day.toLowerCase() + '_close']"
                            :data-invalid-feedback="`Please choose a close time for ${day}`"
                        />
                        <div class="invalid-feedback">
                            Please choose a close time for {{ day }}
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <div class="input-wrapper form-control">
                                <input
                                    :value="1"
                                    type="checkbox"
                                    :id="`${day}_closed`"
                                    :name="`${day}_closed`"
                                    :title="`Click if this location is closed all day on ${day}`"
                                    v-model="currentLocation.hours[0][day.toLowerCase() + '_closed']"
                                >
                                <label :for="`${day}_closed`" class="form-label">
                                    Closed all day
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'LocationHoursForm',
        props: [
            'activeRecord'
        ],
        data() {
			return {
                currentLocation: this.activeRecord,
			}
        },
        computed: {
            daysOfWeek(){
                return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            }
        },
        methods: {
            isClosedAllDayChecked(day){
                return this.currentLocation.hours[0][day.toLowerCase() + '_closed'] ? false : true;
            }
        }
    }
</script>