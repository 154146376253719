<template>
    <VueModal :visible="isModalOpen" @on-close="closeModal" :id="modalId">
        <template #header>
            <h5 class="modal-title"><span :class="`${headerIcon} mr-1 ${headerIconColor}`"></span>{{ headingText }}</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span :class="`${bodyIcon} ${bodyIconColor} ${bodyIconSize}`"></span>
            <slot name="modalContent">
                <p class="h4 font-weight-bold">Are you sure you want to delete this?</p>
                <p class="text-center m-0">This action is permanent. You will not be able to restore this.</p>
                <p class="text-center">Consider marking as inactive instead.</p>
            </slot>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeModal">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="$emit('confirmed-modal')" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Confirm' }}</button>
            </div>
        </template>
    </VueModal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VueConfirmationModal',
        props: {
            modalId: String,
            namespace: String,
            headingText: String,
            headerIcon: {
                type: String,
                default: 'fas fa-trash'
            },
            headerIconColor: {
                type: String,
                default: 'text-tertiary'
            },
            bodyIcon: {
                type: String,
                default: 'fas fa-exclamation-triangle'
            },
            bodyIconColor: {
                type: String,
                default: 'text-danger'
            },
            bodyIconSize: {
                type: String,
                default: 'fa-7x'
            }
        },
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.computed = {
                ...mapState(storeNamespace, {
                    isModalOpen: state => state.isModalOpen,
                    isModalProcessing: state => state.isModalProcessing,
                })
            }

            this.$options.methods = {
                ...mapActions(storeNamespace, [
                    'closeModal'
                ])
            }
        }
    }
</script>