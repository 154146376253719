<template>
    <div class="card">
        <div class="card-header">
            <p class="h5 m-0">
                <span class="fas fa-barcode mr-1"></span>
                SKUs &amp; Qty
            </p>
        </div>
        <div class="card-body" v-if="!hasVariants">
            <div class="row align-items-start">
                <!-- SKU -->
                <VpanelProductSkuInput
                    :input-index="0"
                    :input-value="productAttribute.sku"
                    @update="updateProductAttributeInput('sku', false)"
                />

                <!-- Manufacturer SKU -->
                <div class="col-md-6 form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="manufacturer_sku">Manufacturer SKU</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide a manufacturer SKU."
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="text"
                        class="form-control"
                        id="manufacturer_sku"
                        name="manufacturer_sku"
                        :value="productAttribute.manufacturer_sku"
                        title="Please enter the manufacturer SKU of this product"
                        @input="updateProductAttributeInput('manufacturer_sku', false)"
                    />
                </div>
            </div>
            <div class="row align-items-start">
                <!-- QTY -->
                <div class="col-md-6 form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="product_qty">Qty</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the quantity available."
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="number"
                        id="product_qty"
                        name="product_qty"
                        class="form-control"
                        :value="productAttribute.qty"
                        title="Please enter the qty of this product"
                        @input="updateProductAttributeInput('qty', false)"
                    />
                </div>
                <!-- Product HCPCS Code -->
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-start">
                        <label for="product_hcpcs_code">HCPCS Code</label>
                        <div class="d-flex align-items-center justify-content-end flex-wrap mx-n1">
                            <span class="text-secondary text-14 text-underline cursor-pointer mx-1 text-right" @click="openHcpcsCodeModal">
                                <span class="fas fa-plus-circle mr-1"></span>Add New
                            </span>
                            <a
                                href="#"
                                class="mx-1"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Select an HCPCS code for this product or provide a new one (add new.)"
                            >
                                <span class="fas fa-question-circle text-info cursor-pointer"></span>
                            </a>
                        </div>
                    </div>
                    <select
                        name="hcpcs_code"
                        class="form-control"
                        id="product_hcpcs_code"
                        :value="productAttribute.hcpcs_code"
                        title="Select a hcpcs codes for this product"
                        @change="updateProductAttributeInput('hcpcs_code', false)"
                    >
                        <option value="" selected disabled>Select HCPCS Code</option>
                        <option
                            v-for="(codeId, code) in hcpcsCodes"
                            :key="codeId"
                            :value="codeId"
                            :selected="productAttribute.hcpcs_code == codeId"
                        >{{ code }}</option>
                    </select>
                </div>
            </div>
            <div class="row" v-if="showStockStatus">
                <!-- In Stock -->
                <div class="col-md-6 form-group mx-2">
                    <label class="form-check-label" for="is_in_stock">
                        <input
                            id="is_in_stock"
                            type="checkbox"
                            name="is_in_stock"
                            class="form-check-input"
                            :value="productAttribute.is_in_stock"
                            :checked="productAttribute.is_in_stock"
                            @input="updateProductAttributeInput('is_in_stock', true)"
                        />
                        In Stock?
                    </label>
                </div>
            </div>
        </div>
        <div class="card-body" v-else>
            <div class="alert alert-info m-0">
                <p class="mb-0">These settings are now managed from the variant section.</p>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelProductSkuAndQtyForm',
        props: [
            'hasVariants',
            'productAttribute'
        ],
        computed: {
            ...mapState({
                hcpcsCodes: state => state.productHcpcsCode.list.allData,
                showStockStatus: state => parseInt(state.moduleConfigs['Display Stock Status'])
            })
        },
        methods: {
            ...mapActions('productHcpcsCode/addModify', {
                openHcpcsCodeModal: 'openModal',
            }),
            updateProductAttributeInput(nestedIndex, inputIsCheckbox){
                this.$emit('update', { prop: 'attributes', nestedIndex: nestedIndex, index: 0, isCheckBox: inputIsCheckbox, nested: true });
            }
        }
    }
</script>