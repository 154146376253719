<template>
    <div class="marketing-message-button-settings-form">
        <div class="d-md-flex align-items-start wrap-gap" style="--gap:30px;--gap-y:15px;">
            <div class="flex-grow-1 pb-2">
                <div class="wrap-gap">
                    <!-- Marketing Message Button URL -->
                    <div class="form-group has-label flex-grow-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="button_url">Button URL</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Enter a page from your site or an external site."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            id="button_url"
                            name="button_url"
                            class="form-control"
                            :value="activeRecord.button_url"
                            @input="$emit('update', { prop: 'button_url' })"
                            title="Enter a page from your site or an external site"
                        />
                    </div>

                    <!-- Marketing Message Button Text -->
                    <div class="form-group has-label flex-grow-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="button_text">Button Text</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="This text will display on the button."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            id="button_text"
                            name="button_text"
                            class="form-control"
                            :value="activeRecord.button_text"
                            title="Enter the text to display on the button"
                            @input="$emit('update', { prop: 'button_text' })"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="d-md-flex align-items-start wrap-gap" style="--gap:30px;--gap-y:15px;">
            <div class="flex-grow-1 py-2">
                <div class="wrap-gap">
                    <!-- Marketing Message Button Target -->
                    <div class="form-group flex-grow-1">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="mr-3">Button Target</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="This determines if the button will open the provided url in a new window or stay in the same window."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Button Target</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="button_target_self">
                                    <input
                                        type="radio"
                                        value="_self"
                                        name="button_target"
                                        id="button_target_self"
                                        class="form-check-input"
                                        title="Show Page in Same Window"
                                        v-model="activeRecord.button_target"
                                        @change="$emit('update', { prop: 'button_target', value: '_self' })"
                                    />
                                    <span class="form-check-sign"></span>
                                    Same Window
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="button_target_blank">
                                    <input
                                        type="radio"
                                        value="_blank"
                                        name="button_target"
                                        id="button_target_blank"
                                        class="form-check-input"
                                        title="Show Page in New Window"
                                        v-model="activeRecord.button_target"
                                        @change="$emit('update', { prop: 'button_target', value: '_blank' })"
                                    />
                                    <span class="form-check-sign"></span>
                                    New Window
                                </label>
                            </div>
                        </fieldset>
                    </div>

                    <!-- Marketing Message Button Disclaimer -->
                    <div class="form-group flex-grow-1" v-if="disclaimerEnabled">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="mr-3">Button Disclaimer</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="This determines if the button will take the user to a disclaimer page first."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Button Disclaimer</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="button_disclaimer_yes">
                                    <input
                                        :value="1"
                                        type="radio"
                                        name="button_disclaimer"
                                        class="form-check-input"
                                        id="button_disclaimer_yes"
                                        v-model="activeRecord.button_disclaimer"
                                        @change="$emit('update', { prop: 'button_disclaimer', parseInt: true })"
                                        title="Show disclaimer first before going to the value entered into the button url field"
                                    />
                                    <span class="form-check-sign"></span>
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="button_disclaimer_no">
                                    <input
                                        :value="0"
                                        type="radio"
                                        name="button_disclaimer"
                                        class="form-check-input"
                                        id="button_disclaimer_no"
                                        v-model="activeRecord.button_disclaimer"
                                        @change="$emit('update', { prop: 'button_disclaimer', parseInt: true })"
                                        title="Don\'t show disclaimer first before going to the value entered into the button url field"
                                    />
                                    <span class="form-check-sign"></span>
                                    No
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VpanelMarketingMessageButtonSettingsForm',
        props: [
            'activeRecord',
            'disclaimerEnabled'
        ]
    }
</script>