<template>
    <div class="row">
        <div class="col">
            <p class="h5">General Details</p>
            <div class="row">
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="address">Address</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the address of the location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        type="text"
                        id="address"
                        name="address"
                        class="form-control"
                        title="Please enter the address"
                        v-model="currentLocation.address"
                        data-invalid-feedback="Please provide an address"
                    />
                    <div class="invalid-feedback">
                        Please provide an address
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="city">City</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide in what city the location is located in"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        id="city"
                        name="city"
                        type="text"
                        class="form-control"
                        title="Please enter the city"
                        v-model="currentLocation.city"
                        data-invalid-feedback="Please provide a city"
                    />
                    <div class="invalid-feedback">
                        Please provide a city
                    </div>
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="state">State/Province</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide what state or province this location is in"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                     <select
                        required
                        id="state"
                        name="state"
                        type="text"
                        class="form-control"
                        v-model="currentLocation.state"
                        title="Please enter the state/province"
                        data-invalid-feedback="Please provide a state/province"
                    >
                        <optgroup label="United States">
                            <option
                                :key="index"
                                :value="option.value"
                                v-for="(option, index) in states"
                            >
                                {{ option.name }}
                            </option>
                        </optgroup>
                        <optgroup label="Canadian Provinces">
                            <option
                                :key="index"
                                :value="option.value"
                                v-for="(option, index) in provinces"
                            >
                                {{ option.name }}
                            </option>
                        </optgroup>
                    </select>
                    <div class="invalid-feedback">
                        Please provide a state/province
                    </div>
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="postal_code">Postal Code</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the postal code for this location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        type="text"
                        id="postal_code"
                        name="postal_code"
                        class="form-control"
                        title="Please enter the postal code"
                        v-model="currentLocation.postal_code"
                        data-invalid-feedback="Please provide a postal code"
                    />
                    <div class="invalid-feedback">
                        Please provide a postal code
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="latitude">Latitude</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Click the fetch geo data button to populate this field"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        readonly
                        type="text"
                        id="latitude"
                        name="latitude"
                        class="form-control"
                        v-model="currentLocation.latitude"
                        title="Please click the fetch geo data button to populate this field"
                        data-invalid-feedback="Please click the fetch geo data button to populate this field"
                    />
                    <div class="invalid-feedback">
                        Please click the fetch geo data button to populate this field
                    </div>
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="longitude">Longitude</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Click the fetch geo data button to populate this field"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        readonly
                        type="text"
                        id="longitude"
                        name="longitude"
                        class="form-control"
                        v-model="currentLocation.longitude"
                        title="Please click the fetch geo data button to populate this field"
                        data-invalid-feedback="Please click the fetch geo data button to populate this field"
                    />
                    <div class="invalid-feedback">
                        Please click the fetch geo data button to populate this field
                    </div>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-outline-secondary-blue" @click.prevent="fetchGeoLocation">{{ geoLocationProcessing ? 'Processing...' : 'Fetch Geo Data' }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="phone">Phone</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the main phone number for this location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        id="phone"
                        name="phone"
                        type="tel"
                        class="form-control"
                        v-mask="'(###) ###-####'"
                        title="Please enter the phone number"
                        v-model="currentLocation.phone"
                        data-invalid-feedback="Please provide a phone number"
                    />
                    <div class="invalid-feedback">
                        Please provide a phone number
                    </div>
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="toll_free">Toll Free</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the toll free phone number for this location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="tel"
                        id="toll_free"
                        name="toll_free"
                        class="form-control"
                        v-mask="'1 (###) ###-####'"
                        v-model="currentLocation.toll_free"
                        title="Please enter the toll free number"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="fax_number">Fax</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the fax number for this location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="tel"
                        id="fax_number"
                        name="fax_number"
                        class="form-control"
                        v-mask="'(###) ###-####'"
                        title="Please enter a fax number"
                        v-model="currentLocation.fax"
                    />
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="email">Contact Email</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="When this field is provided, people will be able to contact you via the front end"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        class="form-control"
                        v-model="currentLocation.email"
                        title="Please enter an email address"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'LocationContactInfoForm',
        props: [
            'activeRecord'
        ],
        data() {
			return {
                currentLocation: this.activeRecord,
			}
        },
        computed: {
            ...mapState('location/addModify', {
                states:  state => state.states,
                provinces:  state => state.provinces,
                geoLocationProcessing: state => state.geoLocationProcessing
            })
        },
        methods: {
            ...mapActions('location/addModify', [
                'fetchGeoLocation'
            ])
        }
    }
</script>