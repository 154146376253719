<template>
    <div class="coupon-section">
        <div class="mb-2">
            <VpanelEcommAddNewRecordButton namespace="coupon/addModify" button-text="Add a New Coupon" router-path="Add Coupon" />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Coupon Table -->
        <VpanelCouponTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />

        <!-- Delete Coupon Modal -->
        <VpanelEcommConfirmRecycleModal
            namespace="coupon"
            modal-id="coupon-recycle-modal"
            heading-text="Confirm Delete Coupon"
            ajax-route="/vpanel/modules/ecommerce/coupon/recycle"
            after-recycle-dispatch-action="coupon/list/refreshTableData"
        />
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "VpanelCouponList",
        computed: {
            ...mapGetters('coupon/list', {
                tableData: 'getTableData'
            })
        }
    }
</script>