<template>
    <div class="row">
        <div class="col-md form-group has-label" v-for="(option, index) in productOptions" :key="index">
            <template v-if="option != ''">
                <div class="d-flex justify-content-between align-items-center">
                    <label :for="`product_option_values_${attributeIndex}_${index}`" class="required">{{ option }} values</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Select a value for this variant."
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <select
                    required
                    name="product_option_values[]"
                    class="form-control flex-grow-1"
                    title="Select product option values"
                    :value="productAttribute.option_values[index]"
                    :id="`product_option_values_${attributeIndex}_${index}`"
                    :data-invalid-feedback="`Please select a value for ${option}`"
                    @change="$emit('update', {prop: 'option_values', attributeIndex: attributeIndex, index: parseInt(index)})"
                >
                    <option
                        :key="optionValueIndex"
                        :value="optionValueIndex"
                        v-for="(optionValue, optionValueIndex) in getOptionValues(index)"
                        :selected="productAttribute.option_values[index] == optionValueIndex"
                    >
                        {{ optionValue }}
                    </option>
                </select>
                <div class="invalid-feedback">
                    Please select a value for {{ option }}.
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'VpanelProductOptionValuesFormSection',
        props: [
            'productOptions',
            'attributeIndex',
            'productAttribute'
        ],
        computed: {
            ...mapGetters({
                getOptionsThatHaveValues: 'productOption/list/getOptionsThatHaveValues',
                getOptionValueNameById: 'productOptionValue/list/getOptionValueNameById'
            })
        },
        methods: {
            getOptionValues(optionId){
                let optionValues    = {};
                //finds an the select options values
                let selectedOptions = this.getOptionsThatHaveValues.find(o => o.id === parseInt(optionId));

                if(selectedOptions?.values.length){
                    //go through and get all the names of the options values
                    selectedOptions?.values.forEach(x => {
                        let optionValue = this.getOptionValueNameById(x);
                        if (optionValue) Vue.set(optionValues, x, optionValue);
                    });
                }

                return optionValues;
            }
        }
    }
</script>