<template>
    <div class="vn-family"
        :data-navid="pageUuid"
        :data-current="isCurrentPage"
        :data-has-children="navItem.children.length > 0"
        :data-depth="currentDepth"
        :data-expanded="expanded"
        @click.self="expandToggle()"
    >
        <vue-link
            v-bind="linkBinder"
        ></vue-link>
        <div class="vn-children" v-if="navItem.children.length" :data-depth="currentDepth">
            <template v-for="ni in navItem.children">
                <vue-nav-item
                    :nav-item="ni"
                    :depth="childDepth"
                    :key="ni.page.uuid"
                    v-if="ni.page.show_in_nav"
                    :current-page-id="currentPageId"
                ></vue-nav-item>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "navItem",
        "currentPageId",
        "depth"
    ],
    data() {
        return {
            pageUuid: this.navItem.page.uuid,
            pageName: this.navItem.page.nav_title,
            currentDepth: this.depth,
            childDepth: this.depth + 1,
            expanded: this.navItem.expanded // this may need to be 'expanded' 😉 upon once we run into more complex nav structures
        }
    },
    computed: {
        linkBinder() {
            // Set up default bound params
            let res = {
                'link-class': "vn-item",
                'link-attributes': {'data-depth': this.currentDepth},
                'link-path': this.pageLink,
                'link-text': this.pageName
            };

            // Link specific params
            if (this.navItem.page_type === 'url')  {
                // Change title text so it reveals the url they are going to
                res['link-title-name'] = this.pageLink;

                // Add correct target based on window_target property
                let target = this.navItem.page.window_target;
                switch (target) {
                    case "Same": res['link-target'] = '_self'; break;
                    case "New": res['link-target'] = '_blank'; break;
                }
            }

            return res;
        },
        isCurrentPage() {
            return this.pageUuid === this.currentPageId;
        },
        pageLink() {
            if (this.navItem.page_type == 'heading') return '#';
            return this.navItem.page.url;
        }
    },
    methods: {
        expandToggle() {
            this.expanded = !this.expanded;
        }
    }
}
</script>