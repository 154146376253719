<template>
    <div id="site-config-panel">
        <site-config-nav></site-config-nav>

        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
export default {
    name: 'SiteConfigIndex'
}
</script>

<style lang="scss" scoped>
    button.nav-link {
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
    }
</style>