<template>
    <div class="card">
        <div class="card-header pb-2 d-flex justify-content-between align-items-center">
            <p class="h5 m-0">
                <span class="far fa-file-alt mr-1"></span>
                Documents
            </p>

            <!-- Add Additional Documents Button -->
            <VpanelAddAdditionalInputButton
                @add-new-array-item="addDocument"
            />
        </div>
        <div class="card-body">
            <div :key="index" v-for="(document, index) in productDocuments" class="border-bottom mb-2">
                <div class="form-group has-label flex-grow-1">
                    <!-- Document Name -->
                    <div class="d-flex justify-content-between align-items-center">
                        <label :class="document.path ? 'required' : ''" :for="`display_name_${index}`">Document Display Name</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide a document name to display in the Documents section of the product detail view."
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="text"
                        class="form-control"
                        :value="document.name"
                        :id="`display_name_${index}`"
                        :name="`display_name_${index}`"
                        :required="document.path ? true : false"
                        title="Please enter the display name of this document"
                        data-invalid-feedback="Please enter a display name for this document."
                        @change="updateProductValue({prop: 'documents', index: index, nestedIndex: 'name', nested: true})"
                    />
                    <div class="invalid-feedback">
                        Please enter a display name for this document.
                    </div>
                </div>
                <div class="d-flex align-items-start mx-n1 mb-1">
                    <div class="form-group has-label m-1 flex-grow-1">
                        <div class="d-flex justify-content-between align-items-start mx-n1">
                            <label class="mx-1" :for="`product_document_path_${index}`">Choose Product Document</label>
                            <div class="text-right d-flex flex-wrap justify-content-end align-items-center">
                                <label class="mx-1" :for="`is_external_${index}`">
                                    <input
                                        type="checkbox"
                                        name="is_external"
                                        :id="`is_external_${index}`"
                                        :value="document.is_external"
                                        :checked="document.is_external"
                                        @input="updateProductValue({prop: 'documents', index: index, nestedIndex: 'is_external', nested: true, isCheckBox: true})"
                                    />
                                    Is External?
                                </label>
                                <a
                                    href="#"
                                    class="mx-1"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-boundary="window"
                                    title="Choose or upload a document."
                                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                            </div>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            :value="document.path"
                            name="product_document_path"
                            :disabled="!document.is_external"
                            :id="`product_document_path_${index}`"
                            @input="updateProductValue({prop: 'documents', index: index, nestedIndex: 'path', nested: true})"
                        />
                    </div>
                    <div class="d-flex align-items-center">
                        <!-- Choose Document Button -->
                        <button
                            type="button"
                            class="btn btn-tertiary m-1"
                            v-if="!document.is_external"
                            :id="`choose_image_${index}`"
                            @click.prevent="selectDocument(index)"
                            :data-input="`product_document_path_${index}`"
                            title="Opens the file manager modal where you can choose to upload a new document or choose from your the library"
                        >
                            <span class="fas fa-file-upload"></span>
                        </button>

                        <!-- Remove Additional Documents Button -->
                        <VpanelRemoveAdditionalItemButton
                            @add-new-array-item="addDocument"
                            v-if="Object.keys(productDocuments).length > 1"
                            :array-count="Object.keys(productDocuments).length"
                            @remove-additional-item="removeAdditionalItem([index, 'documents'])"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
	import { openFileManager } from "@j/core/vpanel/vpanel-functions";

    export default {
        name: 'VpanelProductDocumentForm',
        props: [
            'productDocuments'
        ],
        created(){
            if(Object.keys(this.productDocuments).length == 0){
                this.addDocument();
            }
        },
        methods:{
            ...mapActions('product/addModify', [
                'updateProductValue',
                'removeAdditionalItem'
            ]),
            selectDocument(index){
                let chooseImageButton = document.querySelector(`#choose_image_${index}`);

                openFileManager(chooseImageButton, (fmObject) => {
                    this.updateProductValue({prop: 'documents', index: index, value: fmObject[0].url, nestedIndex: 'path', nested: true});
                });
            },
            addDocument(){
                let documents = this.productDocuments;
                //will check if there are any empty product document values
                let shouldAddNewValue = Object.values(documents).every(document => document.path && document.name);

                if(shouldAddNewValue){
                    let length = Object.keys(documents).length;
                    //will add new object to array
                    this.updateProductValue({prop: 'documents', index: 'add' + length, value: {name: '', path: '', is_external: 0}});
                } else {
                    //will show user a message if theres a document that doesn't have a value
                    showNotification('Make sure all document inputs are filled in, before adding a new one.', 'error');
                }
            },
        }
    }
</script>