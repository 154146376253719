<template>
    <vue-modal :visible="isModalOpen" @on-close="closeCategoryAddModifyModal" id="category-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-box mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <div class="page-settings-details pb-2 pt-0" v-if="!isActionAdd">
            <p class="psd-published">Created Date: <span>{{ activeRecord.created_at }}</span></p>
            <p class="psd-modified">Last Modified: <span>{{ activeRecord.updated_at }}</span></p>
            <p class="psd-status">Status: <span>{{ parseInt(activeRecord.is_active) ? 'Active' : 'Inactive' }}</span></p>
        </div>

        <VpanelAddModifyCategoryForm
            :class="{'d-none': isFormProcessing}"
            :is-action-add="isActionAdd"
            :active-record="activeRecord"
            @submitForm="submitForm"
            @closeModal="closeModal"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeCategoryAddModifyModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="add-modify-category-form">{{ isFormProcessing ? 'Processing...' : 'Apply' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelAddModifyCategoryModal',
        computed: {
            ...mapState('category/addModify', {
                isModalOpen: state => state.isModalOpen,
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
            getModalHeader(){
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';

                return action + 'Category';
            }
        },
        methods: {
            ...mapActions('category/addModify', [
                'submitForm',
                'closeModal',
               'resetForm',
            ]),
            closeCategoryAddModifyModal(){
                let vm = this;
                vm.closeModal();

                setTimeout(function(){vm.resetForm()}, 200);
            }
        }
    }
</script>