<template>
  <div class="header-template-1" :data-variant="variant">
    <div class="top-bar" v-if="headerLinks.length && headerLinks.some(l => l)">
      <div class="dynamic-container">
        <div class="top-bar-links" v-if="headerLinks.length && headerLinks.some(o => o)">
          <template v-for="(link, index) in headerLinks">
            <VueLink
              v-if="link"
              :class="[`top-bar-link-${index}`, getLinkClassFromIndex(index)]"
              :key="link.text"
              :link-obj="link"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="dynamic-container">
        <div class="logo">
          <img :src="logoPath" alt="Company logo">
          <VueLink link-path="/" :link-text="`${companyName} home`" link-class="absolute-fill text-throw" />
        </div>
        <div class="nav-and-search">
          <!-- The search template will eventually need to be dynamic, currently it's not set in the db -->
          <!--<VueSiteSearch
            search-template="PowerwebTemplate1"
          />-->
          <VueNav
            nav-template="PowerwebTemplate1"
            :header-links="headerLinks"
            :current-page-id="currentPageId"
            :mobile-expanded="mobileNavExpanded"
            :nav-info="navInfo"
            @open-mobile-menu="openMobileMenu()"
            @close-mobile-menu="closeMobileMenu()"
          />
          <div class="mobile-controls">
            <p class="open-mobile-menu" @click="openMobileMenu()">
              <span class="fas fa-bars"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navMixin } from '@v/Core/Site/Mixins/navMixin';
import { headerMixin } from '@v/Core/Site/Mixins/headerMixin';
import { headerFooterMixin } from '@v/Core/Site/Mixins/headerFooterMixin';

export default {
  mixins: [
    navMixin,
    headerMixin,
    headerFooterMixin,
  ],
  methods: {
    getLinkClassFromIndex(index) {
      switch(index) {
        case 0: return 'text-primary-compliant' ;
        case 1: return 'btn btn-primary-compliant';
        case 2: return 'btn btn-secondary-compliant';
        case 3: return 'btn btn-primary-compliant';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../../../../sass/vue-import';

  .logo {
    position: relative;
    max-height: 100px;
    max-width: min(200px, 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      max-width: 100%;
      max-height: 100px;
      vertical-align: bottom;
    }
  }

  .header-template-1 { background-color: white; }

  .mobile-controls {
    @include desktop { display: none; }

    .open-mobile-menu {
      font-size: 24px;
      margin: 0 0 0 1rem;
      color: black;
    }
  }

  .top-bar {
    @include not-desktop { display: none; }

    padding: .25rem 0;
    background-color: var(--themeColor-hsl);
    color: var(--themeComplement-hsl);

    .dynamic-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    + .bottom-bar {
      position: relative;
      margin-top: 1px;
      // border-top: 1px solid hsla(var(--themeComplement), .2);
    }

    .top-bar-links {
      display: flex;
      align-items: center;
      margin: 0 -.5rem;

      [class*="top-bar-link-"] {
        margin: .25rem .5rem;
      }
    }
  }

  .bottom-bar {
    padding: 1rem 0;
    background-color: white;
    color: var(--optimumOnWhite-hsl);

    .dynamic-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .nav-and-search {
      @include desktop {
        @include fluid-size(-8px, -16px, margin-right, $min-vw: $lg-screen, $max-vw: 1400px);
      }

      display: flex;
      align-items: center;
    }
  }

  .dynamic-container {
    // Default container variant
    .header-template-1:not([data-variant]) & {
      max-width: var(--container-width, 1140px);
      margin-left: auto;
      margin-right: auto;
      padding-left: calc((var(--grid-gutter-width, 30px) / 2));
      padding-right: calc((var(--grid-gutter-width, 30px) / 2));
    }

    // Full width variant
    .header-template-1[data-variant="full"] & {
      padding-left: calc((var(--grid-gutter-width, 30px) / 2));
      padding-right: calc((var(--grid-gutter-width, 30px) / 2));
    }
  }

  .primary-links,
  .secondary-links {
    display: flex;

    a {
      color: var(--themeComplement-hsl);
      padding: .25rem 1rem;
    }
  }
</style>