<template>
	<div class="datatable-section p-2">
		<div class="mb-2">
            <VpanelBlogAddNewRecordButton namespace="category/addModify" button-text="Add a New Category" />
		</div>

        <VpanelBlogCategoryList />

		<VpanelAddModifyCategoryModal />

		<!-- Delete Category Modal -->
        <VpanelBlogConfirmRecycleModal
            namespace="category"
            modal-id="blog-category-recycle-modal"
            heading-text="Confirm Delete Blog Category"
            ajax-route="/vpanel/modules/blog/category/destroy"
            after-recycle-dispatch-action="category/list/removeRecordFromDataTable"
        />
	</div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: "VpanelBlogCategoryIndex",
        created() {
            this.fetchCategories();
        },
        methods: {
            ...mapActions("category", {
                fetchCategories: "list/fetchCategories",
            })
        }
    };
</script>