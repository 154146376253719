<template>
    <button
        type="button"
        class="btn btn-primary"
        @click.prevent="addMasterCatalogProduct"
        v-if="!selectedRecord.already_in_catalog"
    >Add</button>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'VpanelSingleAddMasterCatalogProductButton',
        props: [
            "selectedRecord"
        ],
        methods: {
            ...mapActions('masterCatalog/list', [
                'tableButtonAction',
            ]),
            addMasterCatalogProduct(){
                this.$emit('button-click');
                this.tableButtonAction({activeRecord: this.selectedRecord, actionType: 'add'})
            }
        }
    }
</script>