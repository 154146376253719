<template>
	<div class="p-3 flex-grow-1">
		<div class="d-flex justify-content-between align-items-center mb-2">
			<h5 class="m-0">Form Builder</h5>

            <div class="d-flex justify-content-between align-items-center wrap-gap">
                <!--Export Form Button-->
                <VpanelFormBuilderButton
                    namespace=""
                    v-if="isForbinAdmin"
                    button-text="Export Form"
                    @button-event="openExportModal"
                    button-color="outline-secondary-blue"
                />

                <!--Back to Forms Button-->
                <VpanelFormBuilderButton
                    namespace=""
                    router-path="Forms List"
                    button-text="Back to Forms"
                    button-color="outline-primary"
                />
            </div>
		</div>

        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

    	<form class="needs-validation" id="form-builder-add-modify-form" name="form_builder_add_modify_form" novalidate @submit.prevent="saveFormBuilderForm">
			<div class="row">
				<div class="col-md form-group">
					<label class="required" for="form_name">Form Name</label>
					<input
						required
						type="text"
						id="form_name"
						name="form_name"
						title="Form Name"
						class="form-control"
						:disabled="!isActionAdd"
						v-model="formDetails.name"
						data-msg="Please provide a form name"
					/>
				</div>

				<!-- Emails need to be separated with ; -->
				<div class="col-md form-group">
					<label for="notification_emails">Notification Email(s)</label>
					<input
						type="text"
						class="form-control"
						id="notification_emails"
						name="notification_emails"
						title="Notification Email(s)"
						v-model="formDetails.notification_emails"
					/>
				</div>
			</div>

			<div class="row">
				<!-- Description to show in the VPanel, mostly just for client use. -->
				<div class="col-md form-group">
					<label for="description">Description</label>
					<textarea
						type="text"
						id="description"
						name="description"
						title="Description"
						class="form-control"
						v-model="formDetails.description"
					></textarea>
				</div>

				<!-- Message to be displayed after successful submission. If null, then will use default in front end component-->
				<div class="col-md form-group">
					<label for="introduction_message">Introduction Message</label>
					<textarea
						type="text"
						class="form-control"
						id="introduction_message"
						name="introduction_message"
						title="Introduction Message"
						v-model="formDetails.introduction_message"
					></textarea>
				</div>
			</div>
			<div class="row">
				<!-- Message to be displayed after successful submission. If null, then will use default in front end component-->
				<div class="col-md form-group">
					<label for="success_message">Success Message</label>
					<textarea
						type="text"
						class="form-control"
						id="success_message"
						name="success_message"
						title="Success Message"
						v-model="formDetails.success_message"
					></textarea>
				</div>

				<!-- Message to be displayed after error. If null, then will use default in front end component-->
				<div class="col-md form-group">
					<label for="error_message">Error Message</label>
					<textarea
						type="text"
						id="error_message"
						name="error_message"
						class="form-control"
						title="Success Message"
						v-model="formDetails.error_message"
					></textarea>
				</div>
			</div>
			<div class="row"  v-if="isForbinAdmin">
				<!-- ; separated list of js FILES -->
				<div class="col-md form-group">
					<label for="custom_scripts">Custom Scripts</label>
					<textarea
						type="text"
						id="custom_scripts"
						class="form-control"
						name="custom_scripts"
						title="Success Message"
						v-model="formDetails.custom_scripts"
					></textarea>
				</div>
			</div>

			<template v-if="isForbinAdmin" >
                <div>
                    <h5 class="font-weight-bold">Inputs</h5>
                    <vpanel-form-builder-input
                        v-for="(input, index) in formInputs"
                        :key="index"
                        :index="index"
                        :input="input"
                        :form-inputs="formInputs"
                        :oddInput="index % 2 === 0"
                    ></vpanel-form-builder-input>
                    <button class="btn btn-secondary mt-2" @click.prevent="addNewInput">Add Input</button>
                </div>
            </template>

            <div class="d-flex justify-content-end">
                <!--Save Button-->
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isFormProcessing"
                >{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
            </div>
		</form>

        <!-- Export Modal -->
        <VpanelFormBuilderImportExportModal
            v-if="isForbinAdmin"
            :is-action-import="false"
            namespace="forms/importExport"
            modal-icon="fas fa-file-import"
            custom-header="Export Form JSON"
            form-id="form-builder-import-export-form"
            form-name="vpanel-form-builder-import-export-form"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'VpanelFormBuilderAddModifyForm',
        props: [
            "isForbinAdmin"
        ],
        computed: {
            ...mapState('forms/addModify', {
                errorMessage: state => state.errorMessage,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
                formInputs: state => state.activeRecord.form_inputs,
                formDetails: state => state.activeRecord.form_details,
            }),
        },
        methods: {
            ...mapActions('forms', {
                submitForm: 'addModify/submitForm',
                openExportModal: 'importExport/openExportModal'
            }),
            addNewInput(){
                let currentInputs = this.formInputs;

                currentInputs.push({
					label: "",
					options: [],
					type: "text",
					required: false,
					attributes: null,
					required_message: ""
				});
            },
            saveFormBuilderForm(){
                let addModifyRoute = this.isActionAdd ? '/vpanel/modules/forms/store' : '/vpanel/modules/forms/update';

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    routerPath: 'Forms List',
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-check .form-check-sign:after {
        color: white;
    }
</style>