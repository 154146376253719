<template>
    <div class="map-view" :data-details="!!sidebarDetails">

        <div class="sidebar-list">
            <frontend-location-sidebar-list-item
                v-for="id in locationIds"
                :location="locations[id]"
                :id="id"
                :key="id"
            />
        </div>

        <frontend-location-sidebar-details
            :sidebar-details="sidebarDetails"
            :location="locations[sidebarDetails]"
            :services="services"
            :is-services-enabled="isServicesEnabled"
        />

        <div class="map">
            <google-map-loader />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    props:[
        'isServicesEnabled',
        'services',
        'sidebarDetails',
    ],
    computed: {
        ...mapState('location', [
            'locations'
        ]),
        ...mapGetters('location', [
            'locationIds'
        ])
    },
    methods: {
        ...mapActions('location', [
            'setSidebarDetails',
        ]),
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';

    // eventually move this to the base, maybe clean it up a bit
    @mixin border-widths($t, $r: null, $b: null, $l: null) {
        // conditionally handle shorthand
        @if not $r {$r:$t;$b:$t;$l:$t;}
        @else if not $b {$b:$t;$l:$r;}
        @else if not $l {$l:$r;}

        border-style: solid;
        border-color: var(--light);
        border-width: $t $r $b $l;
    }

    .map-view {
        display: grid;
        overflow: hidden;

        @include desktop {
            grid-template: "sidebar map" minmax(400px, 500px) / minmax(300px, 1fr) 2fr;
        }

        @include not-desktop {
            height: 75vh;
            grid-template: "map" 2fr "sidebar" 1fr / 100%;

            &[data-details] {
                grid-template: "map" 1fr "sidebar" 2fr / 100%;
            }
        }

        &::v-deep {
            .icon-button {
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--themeColor-hsl);
                border: 0;
                outline: 0;
                padding: .25rem;
                margin: 0;
                background: none;
                line-height: 1;
            }
        }
    }

    .sidebar-list {
        grid-area: sidebar;
        background-color: white;
        overflow-y: auto;
        position: relative;
        @extend %minimal-scrollbar;

        @include desktop {
            @include border-widths(0, 0, 1px, 1px)
        }

        @include not-desktop {
            @include border-widths(1px, 0)
        }
    }

    .map {
        grid-area: map;
        background-color: var(--light);
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>