var render, staticRenderFns
import script from "./VpanelLoader.vue?vue&type=script&lang=js&"
export * from "./VpanelLoader.vue?vue&type=script&lang=js&"
import style0 from "./VpanelLoader.vue?vue&type=style&index=0&id=027dda11&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027dda11",
  null
  
)

export default component.exports