<template>
    <div class="fb-question" :class="widthClass">
        <component
            :is="componentType"
            v-bind="questionComponentBinder"
            v-on="questionComponentEventBinder"
        />
    </div>
</template>

<script>
import { questionChute, questionLadder } from './Controls/mixins/SiteFormBuilderControlMixins';

export default {
    name: "SiteFormBuilderQuestion",
    mixins: [ questionChute, questionLadder ],
    props: {
        question: {
            type: Object,
            required: true
        },
        questionId: {
            type: Number,
            required: true
        },
        options: {
            type: [Object, undefined],
            default: undefined
        }
    },
    computed: {
        componentType() {
            let [type, options] = [this.question.type, this.question.options];
            switch (type) {
                case 'email':                    return 'SiteFormBuilderControlEmail';
                case 'tel':                      return 'SiteFormBuilderControlTel';
                case 'file':                     return 'SiteFormBuilderControlFile';
                case 'paragraph':                return 'SiteFormBuilderParagraph';
                case 'textarea':                 return 'SiteFormBuilderControlTextarea';
                case 'date':                     return 'SiteFormBuilderControlDate';
                case 'time':                     return 'SiteFormBuilderControlTime';
                case 'checkbox':
                    if (options.length > 7)      return 'SiteFormBuilderControlFieldsetDropdownCheckbox';
                    else                         return 'SiteFormBuilderControlChipSelectorCheckbox';
                case 'radio':
                    if (options.length > 7)      return 'SiteFormBuilderControlFieldsetDropdownRadio';
                    else                         return 'SiteFormBuilderControlChipSelectorRadio';
                case 'select':                   return 'SiteFormBuilderControlSelect';
                default:                         return 'SiteFormBuilderControlText';
            }
        },
        widthClass() {
            let attrWidth = this.question.attributes?.width;
            if (!attrWidth) return false;
            return `fbw-${attrWidth}`;
        },
        questionComponentBinder() {
            let res = {
                question: this.question,
                questionId: this.questionId,
                focussedQuestion: this.focussedQuestion,
                validateFormInc: this.validateFormInc,
            };
            if (this.options) res['options'] = this.options;
            return res;
        },
        questionComponentEventBinder() {
            return this.questionEventBinder;
        }
    }
}
</script>