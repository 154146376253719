var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3 flex-grow-1"},[_c('div',{staticClass:"border-bottom"},[_c('p',{staticClass:"h5"},[_c('span',{staticClass:"fas fa-tags mr-1 text-tertiary"}),_vm._v(" "+_vm._s(_vm.getAddModifyHeading('Product Category'))+"\n        ")])]),_vm._v(" "),_c('div',[(!_vm.isActionAdd)?_c('VueModifyFormDetailsSection',{staticClass:"py-1",attrs:{"status":_vm.getStatusAsString,"added-date":_vm.activeRecord.created_at,"last-modified-date":_vm.activeRecord.updated_at}}):_vm._e(),_vm._v(" "),_c('form',{staticClass:"product-category-add-modify-form needs-validation",attrs:{"name":"product_category_add_modify_form","id":"product-category-add-modify-form","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitProductCategoryForm.apply(null, arguments)}}},[(_vm.errorMessage)?_c('div',{staticClass:"alert alert-danger form-errors",domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}):_vm._e(),_vm._v(" "),_c('VpanelEcommAddModifyFormNav',{attrs:{"namespace":"productCategory","section-name":"product category","nav-items":[{
                    name: 'General Details',
                    href: 'product-category-add-modify-general-details',
                    id: 'product-category-add-modify-general-details-tab',
                    is_active_tab: _vm.getActiveFormTab == 'product-category-add-modify-general-details-tab' ? true : false
                },
                {
                    name: 'Seo',
                    href: 'product-category-add-modify-seo',
                    id: 'product-category-add-modify-seo-tab',
                    is_active_tab: _vm.getActiveFormTab == 'product-category-add-modify-seo-tab' ? true : false
                }]}}),_vm._v(" "),_c('div',{staticClass:"tab-content",attrs:{"id":"add-modify-tab-content"}},[_c('div',{staticClass:"tab-pane fade",class:{'show active': _vm.getActiveFormTab == 'product-category-add-modify-general-details-tab'},attrs:{"id":"product-category-add-modify-general-details","role":"tab-panel","aria-labelledby":"product-category-add-modify-general-details-tab"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 col-lg-8"},[_c('VpanelProductCategoryGeneralDetailsForm',{attrs:{"active-record":_vm.activeRecord},on:{"update":_vm.updateProductCategoryValue}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-5 col-lg-4 mt-3 mt-md-0"},[_c('VpanelEcommAddModifyImageForm',{attrs:{"active-record":_vm.activeRecord},on:{"update":_vm.updateProductCategoryValue}})],1)])]),_vm._v(" "),_c('div',{staticClass:"tab-pane fade",class:{'show active': _vm.getActiveFormTab == 'product-category-add-modify-seo-tab'},attrs:{"id":"product-category-add-modify-seo","role":"tab-panel","aria-labelledby":"product-category-add-modify-seo-tab"}},[_c('VpanelEcommSeoSettingsForm',{attrs:{"section-name":"category","active-record":_vm.activeRecord}})],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary my-0 save-button",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.isFormProcessing ? 'Processing...' : 'Save'))])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }