<template>
    <div>
        <SiteBlogSearch />

        <div class="filter-bar" v-if="getCurrentFilterName">
            <p class="filter-title">{{ getCurrentFilterName }}</p>
        </div>

        <SiteBlogListItem
            :featured="true"
            v-if="showFirstFeatured"
            :id="firstFeaturedBlog.id"
            :blog="firstFeaturedBlog"
        ></SiteBlogListItem>

        <!-- All blogs ordered by date I think -->
        <SiteBlogListItem
            :id="blog.id"
            :blog="blog"
            :key="'post_' + blog.id"
            v-for="blog in blogs.displayedList"
        ></SiteBlogListItem>

        <div class="py-5" v-if="!blogs.displayedList.length && !showFirstFeatured">
            <p class="text-14 text-center">Sorry, we can't find any blogs with those filters.<br/>Try refining your search or <a href="#" @click.prevent="blogs.resetAll()" title="Reset your Filters">clearing your filters</a>.</p>
        </div>

        <pagination
            :active-page="blogs.activePage"
            :item-count="blogs.itemCount"
            :items-per-page="blogs.itemsPerPage"
            @on-update="blogs.updateActivePage($event);repositionScroll();"
        ></pagination>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name:'SiteBlogList',
    computed: {
        ...mapState('blog', [
            'blogs',
            'pageType'
        ]),
        ...mapGetters('blog', [
            'firstFeaturedBlog',
            'getCurrentFilterName'
        ]),
        showFirstFeatured() {
            return this.firstFeaturedBlog && (!this.pageType && !this.blogs.hasActiveFilters);
        }
    },
    methods: {
        repositionScroll() {
            // Scroll to the top of the component accounting for the header's height
            let yPos = document.querySelector('.blog-layout').getBoundingClientRect().top + window.pageYOffset - parseInt(document.body.style.getPropertyValue('--hd-height'), 10);
            window.scrollTo({top:yPos})
        }
    }
};
</script>
<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .filter-bar {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 1rem;
    }

    .filter-title {
        @include fluid-size(18px, 32px, font-size);
        font-weight: bold;
        margin: 0;
    }
</style>