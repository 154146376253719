<template>
     <div>
        <p class="h5">General Details</p>
        <div class="row">
            <div class="col-md-7">
                <div class="form-group">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="type">Location Type</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Select the type of location this is"
                        >
                            <span class="fas fa-question-circle text-info cursor-pointer"></span>
                        </a>
                    </div>
                    <select
                        required
                        id="type"
                        name="type"
                        class="form-control"
                        v-model="currentLocation.type"
                        title="Select the type of location this is"
                        data-invalid-feedback="Please select a locations's type."
                    >
                        <option value="" selected disabled hidden>Location Type</option>
                        <option
                            v-for="(type, index) in allLocationTypes"
                            :key="index"
                            :value="type.id"
                        >{{ type.name }}</option>
                    </select>
                    <div class="invalid-feedback">
                        Please select a location type.
                    </div>
                </div>
                <div class="form-group">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="required" for="location_title">Title</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="The title/name of the location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        required
                        type="text"
                        name="title"
                        id="location_title"
                        class="form-control"
                        v-model="currentLocation.title"
                        title="Please enter the title/name of the location"
                        data-invalid-feedback="Please enter a locations's title/name."
                    />
                    <div class="invalid-feedback">
                        Please enter a location title/name.
                    </div>
                </div>
                <div class="form-group">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="description">Description</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide additional information about your location here"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="text"
                        id="description"
                        class="form-control"
                        name="location_description"
                        v-model="description"
                        title="Please provide additional location details"
                    />
                </div>
            </div>
            <div class="col-md-5">
                <location-image-form :active-record="activeRecord" />
            </div>
        </div>
        <div class="row">
            <div class="col-md form-group" v-if="allLocationServices.length && areServicesEnabled">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="location_services">Services</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Use Ctrl + Click to select multiple services"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <select
                    multiple
                    id="location_services"
                    title="Select a Service"
                    name="location_services[]"
                    v-model="currentLocation.services"
                    class="form-control flex-grow-1"
                >
                    <option
                        :key="index"
                        :value="service.id"
                        v-for="(service, index) in allLocationServices"
                    >
                        {{ service.name }}
                    </option>
                </select>
            </div>
            <div class="col-md form-group">
                <div class="radio-legend d-flex justify-content-between align-items-center">
                    <span class="required mr-3">Status</span>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="If location is active it will show on the front end"
                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                </div>
                <fieldset>
                    <legend class="label sr-only">Status</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="status_yes">
                            <input
                                checked
                                required
                                :value="1"
                                type="radio"
                                name="status"
                                id="status_yes"
                                class="form-check-input"
                                v-model="currentLocation.status"
                                title="Please choose an option"
                                data-invalid-feedback="Please indicate if the location is active or inactive."
                            />
                            <span class="form-check-sign"></span>
                            Active
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="status_no">
                            <input
                                required
                                :value="0"
                                type="radio"
                                name="status"
                                id="status_no"
                                class="form-check-input"
                                title="Please choose an option"
                                v-model="currentLocation.status"
                                data-invalid-feedback="Please indicate if the location is active or inactive."
                            />
                            <span class="form-check-sign"></span>
                            Inactive
                            <div class="invalid-feedback">
                                Please indicate if the location is active or inactive.
                            </div>
                        </label>
                    </div>
                </fieldset>
            </div>
            <div class="col-md form-group">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="sort_order">Sort</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="The order in which location should be displayed in on the frontend listing."
                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                </div>
                <input
                    type="text"
                    id="sort_order"
                    v-mask="'######'"
                    name="sort_order"
                    class="form-control"
                    v-model="currentLocation.sort_order"
                    title="Please enter the order in which location should be displayed in on the frontend listing"
                />
                <div class="invalid-feedback">
                    Please enter a location order.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'LocationGeneralDetailsForm',
        props: [
            'activeRecord',
            'allLocationTypes',
            'areServicesEnabled',
            'allLocationServices'
        ],
        data() {
			return {
                currentLocation: this.activeRecord,
                description: this.activeRecord.description
			}
        },
        mounted(){
            CKEDITOR.replace('description', {
                removePlugins: 'maximize,resize'
            });

            if(this.currentLocation.description){
                CKEDITOR.instances['description'].setData(this.currentLocation.description);
            }
        }
    }
</script>