<template>
    <nav class="modal-nav pb-3 pt-2" :aria-label="`Ecommerce ${sectionName} Form Tabs`">
        <div class="nav nav-pills wrap-gap" :id="`${navIdPrefix}-add-modify-tab`" role="tablist">
            <a
                role="tab"
                :key="index"
                :id="navItem.id"
                data-toggle="pill"
                class="nav-link btn"
                :href="`#${navItem.href}`"
                :aria-controls="navItem.href"
                v-for="(navItem, index) in navItems"
                @click="setActiveFormTab(navItem.id)"
                :aria-selected="navItem.is_active_tab"
                :class="navItem.is_active_tab ? 'active' : ''"
            >{{ navItem.name }}</a>
        </div>
    </nav>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        name: "VpanelEcommAddModifyFormNav",
        props: [
            'navItems',
            'namespace',
            'sectionName'
        ],
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.computed = {
                navIdPrefix(){
                    return this.sectionName.replace(' ', '-');
                }
            }

            this.$options.methods = {
                ...mapActions(storeNamespace + '/addModify', [
                    'setActiveFormTab'
                ])
            }
        }
    }
</script>