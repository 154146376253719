<template>
    <div class="page-settings-details">
        <p class="psd-published" v-if="showAddDate && addedDate">Added Date: <span>{{ addedDate }}</span></p>
        <p class="psd-modified" v-if="showLastModifiedDate && lastModifiedDate">Last Modified: <span>{{ lastModifiedDate }}</span></p>
        <p class="psd-status" v-if="showStatus && status">Status: <span>{{ status }}</span></p>

        <slot name="additionalModifyDetails"></slot>
    </div>
</template>
<script>
    export default {
        name: 'VueModifyFormDetailsSection',
        props:{
            status: String,
            addedDate: String,
            lastModifiedDate: String,
            showAddDate : {
                type: Boolean,
                default: true
            },
            showLastModifiedDate : {
                type: Boolean,
                default: true
            },
            showStatus: {
                type: Boolean,
                default: true
            }
        }
    }
</script>