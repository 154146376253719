<template>
    <div class="p-3 flex-grow-1">
        <div class="d-flex justify-content-between align-items-center mb-2">
			<h5 class="m-0">Forms List</h5>
            <div class="d-flex justify-content-between align-items-center wrap-gap">
                <!--Import Form Button-->
                <VpanelFormBuilderButton
                    v-if="isForbinAdmin"
                    button-text="Import Form"
                    namespace="forms/importExport"
                    button-color="outline-secondary-blue"
                />

                <!--Create New Button-->
                <VpanelFormBuilderButton
                    namespace=""
                    v-if="isForbinAdmin"
                    router-path="Add Form"
                    button-text="Create New"
                />
            </div>
		</div>

        <!--Table Loader-->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Forms Table -->
        <VpanelFormsTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />

        <!-- Import Modal -->
        <VpanelFormBuilderImportExportModal
            v-if="isForbinAdmin"
            :is-action-import="true"
            namespace="forms/importExport"
            modal-icon="fas fa-file-import"
            custom-header="Import Form JSON"
            form-id="form-builder-import-export-form"
            form-name="vpanel-form-builder-import-export-form"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

	export default {
        name: "VpanelFormsList",
        props: [
            'isForbinAdmin',
        ],
        computed: {
            ...mapState('forms/list', [
                'tableData'
            ])
        }
	};
</script>