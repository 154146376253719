<template>
    <label class="fb-group" :class="validClass">
        <span class="fb-label">{{ question.label }}<span class="fb-required" v-if="required" aria-label="required">*</span></span>
        <!-- No way around the duplicate elements with that peksy v-mask plus conditional wrappers  -->
        <div class="fb-icon-wrapper" v-if="icon">
            <div class="fb-icon" :class="[iconPosition, iconType]">
                <FontAwesomeIcon :icon="iconData" />
            </div>
            <component
                ref="control"
                v-if="boundMask"
                :is="component"
                class="fb-control"
                v-bind="[controlBinder, shellBinder]"
                v-mask="boundMask"
                @input="checkValue()"
                @change="checkValue()"
                @focus="focusQuestion(questionId)"
                @blur="focusQuestion(); checkValue()"
            />
            <component
                ref="control"
                v-else
                :is="component"
                class="fb-control"
                v-bind="[controlBinder, shellBinder]"
                @input="checkValue()"
                @change="checkValue()"
                @focus="focusQuestion(questionId)"
                @blur="focusQuestion(); checkValue()"
            />
        </div>
        <template v-else>
            <component
                ref="control"
                v-if="boundMask"
                :is="component"
                class="fb-control"
                v-bind="[controlBinder, shellBinder]"
                v-mask="boundMask"
                @input="checkValue()"
                @change="checkValue()"
                @focus="focusQuestion(questionId)"
                @blur="focusQuestion(); checkValue()"
            />
            <component
                ref="control"
                v-else
                :is="component"
                class="fb-control"
                v-bind="[controlBinder, shellBinder]"
                @input="checkValue()"
                @change="checkValue()"
                @focus="focusQuestion(questionId)"
                @blur="focusQuestion(); checkValue()"
            />
        </template>
        <SiteFormBuilderAssistiveText
            format
            :message="formatText"
            :format-description="formatDescription"
        />
        <SiteFormBuilderAssistiveText
            :message="requiredMessage || defaultRequiredMessage"
        />
    </label>
</template>

<script>
import { general, questionChute, questionLadder } from '../mixins/SiteFormBuilderControlMixins';
import { mask } from 'vue-the-mask';
import * as FontAwesomeIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    inheritAttrs: false,
    mixins: [ general, questionChute, questionLadder ],
    components: { FontAwesomeIcon },
    directives: { mask },
    props: {
        type: {
            type: [String, Boolean],
            default: false
        },
        component: {
            type: String,
            default: 'input'
        },
        defaultRequiredMessage: {
            type: String,
            default: 'Please enter a value'
        },
        icon: { type: String },
        // Supports left and right
        iconPosition: {
            type: String,
            default: "left"
        },
        // Supports text and button
        iconType: {
            type: String,
            default: 'text'
        },
        customMask: { type: String },
        defaultPattern: { type: String },
        formatText: { type: String },
        formatDescription: { type: String },
        customValidationFunc: {
            type: Function,
            default(vm) {
                if (!vm.required) return -1;
                return vm.$refs.control.value.trim().length;
            }
        }
    },
    data() {
        return {
            iconData: FontAwesomeIcons[this.icon]
        }
    },
    computed: {
        shellBinder() {
            return {
                type: this.type,
                pattern: this.boundPattern
            }
        },
        boundPattern() {
            if (!('pattern' in this.controlBinder) && !this.defaultPattern) return false;
            if (!('pattern' in this.controlBinder)) return this.defaultPattern;
            else return this.controlBinder.pattern;
        },
        boundMask() {
            return this.question.attributes?.mask || this.customMask || false;
        }
    },
    methods: {
        validateFunc(vm) {
            return this.customValidationFunc(vm);
        },
    }
}
</script>