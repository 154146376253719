<template>
    <TableWrapper
        :searchable="false"
        v-on="wrapperEvents"
        v-bind="wrapperBinder"
        :item-count="allTableInfo.itemCount"
        :active-page="allTableInfo.activePage"
        @update-page="allTableInfo.updateActivePage($event);setCurrentPageToSession($event);"
    >
        <!--Custom Table Search-->
        <VpanelFormBuilderTableSearch namespace="forms/list" @searched="updateTableDataAfterSearch" :search-model="allTableInfo.searchModel" />

        <div class="st-hidden-sorts">
            <p @click="allTableInfo.sortBy('name')" :class="allTableInfo.sortClass('name')">Form</p>
            <p @click="allTableInfo.sortBy('active', 'number')" :class="allTableInfo.sortClass('active')">Status</p>
            <p>Submissions</p>
        </div>
        <div class="simple-table" style="--cols:4;">
            <p class="first-col st-header" @click="allTableInfo.sortBy('name')" :class="allTableInfo.sortClass('name')">Form</p>
            <p class="st-header" @click="allTableInfo.sortBy('active', null, 'number')" :class="allTableInfo.sortClass('active')">Status</p>
            <p class="st-header">Submissions</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <div class="first-col d-flex flex-column flex-md-row justify-content-between" :key="row.id+'name'">
                        <span class="st-hidden">Form</span>
                        <div class="d-flex justify-content-between w-100">
                            <strong>{{ row.name }}</strong>
                            <div @click="setActiveDropdown(row.id)" v-if="row.description">
                                <span class="fas fa-info-circle text-info cursor-pointer"></span>
                            </div>
                        </div>
                    </div>

                    <div :key="row.id+'status'">
                        <span class="st-hidden">Status</span>
                        <p class="m-0" :class="row.active?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                    </div>

                    <div :key="row.id+'submissions'">
                        <span class="st-hidden">Submissions</span>
			            <div class="d-flex">
                            <p
                                v-if="row.unread_submissions"
                                class="text-14 font-weight-bold m-1 cursor-help text-warning"
                                :title="row.unread_submissions + ' unread submissions'"
                            >
                                <span class="fas fa-exclamation-circle"></span>
                                {{ row.unread_submissions }}
                            </p>
                            <p
                                v-if="formReadSubmissions(row)"
                                class="text-14 font-weight-bold m-1 cursor-help text-muted"
                                :title="formReadSubmissions(row) + ' read submissions'"
                            >
                                <span class="fas fa-envelope-open"></span>
                                {{ formReadSubmissions(row) }}
                            </p>
                            <p
                                v-if="row.archived_submissions"
                                class="text-14 font-weight-bold m-1 cursor-help text-gray"
                                :title="row.archived_submissions + ' archived submissions'"
                            >
                                <span class="fas fa-archive"></span>
                                {{ row.archived_submissions }}
                            </p>
                            <p v-if="row.total_submissions == 0" class="text-14 font-weight-bold m-1">
                                <span class="fas fa-envelope"></span> 0
                            </p>
                        </div>
                    </div>

                    <div class="last-col" :key="row.id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <!--Submissions Button-->
                            <VpanelFormBuilderButton
                                namespace=""
                                button-text="Submissions"
                                router-path="Form Submission List"
                                :router-path-params="{ id: row.id }"
                            />

                            <!--Modify Button-->
                            <VpanelFormBuilderButton
                                namespace=""
                                v-if="isFormManager"
                                button-text="Modify"
                                router-path="Modify Form"
                                button-color="secondary-blue"
                                :router-path-params="{ id: row.id }"
                            />
                        </div>
                    </div>

                    <TableDropdownWrapper
                        v-if="row.description"
                        :key="row.id+'dropdown'"
                        v-bind="dropdownBinder(row,row.id)"
                    >
                        <p class="m-0">{{ row.description }}</p>
                    </TableDropdownWrapper>
                </template>
            </template>

            <div v-else style="grid-column: 1 / -1;">
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelFormsTable',
        mixins: [ tableMixin ],
        props: [
            'allTableInfo'
        ],
        computed: {
            ...mapState('forms', [
                'isFormManager'
            ])
        },
        methods: {
            ...mapActions('forms/list', [
                'setCurrentPageToSession',
                'updateTableDataAfterSearch'
            ]),
            status(row) {
                return row.active ? 'Active' : 'Inactive';
            },
            formReadSubmissions(row) {
				return (
					row.total_submissions -
					(row.unread_submissions + row.archived_submissions)
				);
			}
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(calc(var(--cols) - 1), minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>