<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div :class="{'float-wrapper': floatLabel, 'float-label': labelFloating}">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <input
                class="form-control"
                type="text"
                :value="value || undefined"
                v-bind="inputBinder"
                v-on="inputListener"
                v-mask="['#####', '#####-####']"
                @focus="focus"
                @blur="unfocus"
            >
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
import { mask } from 'vue-the-mask';
export default {
    mixins: [mixin],
    directives: {mask}
}
</script>

<style lang="scss" scoped>
    .float-wrapper { position: relative; }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .float-wrapper & {
            position: absolute;
            left: .5rem;
            top: .875rem;
            transform: translateY(0);
            background-color: transparent;
            padding: 0 .25rem;
            transition: 200ms ease-out;
            pointer-events: none;
            line-height: 1;
            border-radius: 2px;
            opacity: .5;
        }

        .float-wrapper.float-label & {
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
        }
    }
</style>