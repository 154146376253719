<template>
    <div class="form-group has-label">
        <div class="d-flex justify-content-between align-items-center">
            <label :class="{ 'required': input.isRequired }" :for="`${input.label}_${inputIndex}`">{{ input.displayName }}</label>
            <a
                href="#"
                data-toggle="tooltip"
                data-placement="right"
                data-boundary="window"
                :title="input.infoTitle"
            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
        </div>
        <input
            type="text"
            :name="input.label"
            class="form-control"
            :required="input.isRequired"
            :key="`${input.label}_${keyId}`"
            :id="`${input.label}_${inputIndex}`"
            @change="updateProductPriceInput(input.label)"
            :data-invalid-feedback="`Please enter a ${input.displayName}.`"
            :title="`Please enter the ${input.displayName} of this product`"
            :value="input.label == 'weight' ? formatWeight(inputValue) : formatPrice(inputValue)"
        />
        <div class="invalid-feedback">
            Please add a {{ input.displayName }}.
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VpanelProductPriceAndWeightInput',
        props: [
            'input',
            'inputValue',
            'inputIndex'
        ],
        data() {
            return {
                keyId: 0
            }
        },
        methods: {
            formatWeight(weight){
                return new Intl.NumberFormat('en-US', {
                    style: "unit",
                    unit: "pound",
                    maximumFractionDigits: 1
                }).format(weight);
            },
            formatPrice(price) {
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(price);
            },
            updateProductPriceInput(prop){
                let resultValue = null;

                let targetValue = event.target.value.replace(/[^\d\.]/g, "");
                if (!targetValue.length || targetValue.search(/\d/g) === -1) resultValue = 0;
                else resultValue = parseFloat(targetValue) || 0;

                // Updating the keys on elements forces vue to reevaluate any dependencies
                // Without this if the value "." => 0 and we change it to ".0" => 0 the input won't update because they both eval to 0
                this.keyId++;

                this.$emit('update', prop, resultValue, this.inputIndex);
            }
        }
    }
</script>
