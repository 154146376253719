<template>
    <div class="mc-product-section">
        <!-- Master Catalog Bulk Add Button -->
        <div class="mb-2">
            <VpanelBulkAddMasterCatalogProductButton
                :selected-length="selectedLength"
            />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="isTableLoading" />

        <!-- Master Catalog Table -->
        <VpanelMasterCatalogProductTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :data="tableData.data"
            :total-items="tableData.total"
            :current-page="currentTablePage('masterCatalog')"
        />

        <!-- Confirm Add Product Modal -->
        <VpanelConfirmAddMasterCatalogProductModal />

        <!-- View Product Details Modal -->
        <VpanelViewMasterCatalogProductDetailsModal />

    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        name: "VpanelMasterCatalogProductList",
        computed: {
            ...mapState('masterCatalog/list', [
                'isTableLoading'
            ]),
            ...mapGetters('masterCatalog/list', {
                tableData: 'getTableData',
                selectedLength: 'getSelectedTableRowsLength',
                currentTablePage: 'getCurrentTablePageByTable'
            })
        }
    }
</script>