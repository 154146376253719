<template>
    <div class="nav-icon" >
        <a
            class="nav-icon-link"
            :href="module.url"
            :title="`Manage ${module.name} Module`"
        >
            <span :class="`fas fa-${module.icon}`"></span>
            <p class="nav-icon-label">{{ module.name }}</p>
        </a>
    </div>
</template>
<script>
    export default {
        props: [
            'module'
        ]
    }
</script>