<template>
    <div class="product">
        <div class="product-image" :class="{'no-image': !bgImageBinder}" :style="bgImageBinder">
            <p class="percent-off" v-if="product.discount_percent">{{ notDiscountedVariant ? '' : 'From ' }} {{ product.discount_percent }}% Off</p>
        </div>
        <div class="product-content">
            <div class="product-content-top">
                <p class="product-name">{{ product.name }}</p>
                <p class="product-price" :class="{'product-price-strike': notDiscountedVariant}" v-if="product.store_price !== '0.00'" v-html="formatPriceHtml('store_price')"></p>
                <p class="product-price product-sale-price" v-if="notDiscountedVariant" v-html="formatPriceHtml('sale_price')"></p>
            </div>
            <!-- Eventually this will be updated to be dynamic with buy now, once we implement a cart -->
            <!-- <VueLink
                link-text="Buy Now"
                link-class="btn btn-primary"
                :link-path="product.permalink"
                :link-title-name="product.name"
                class="mt-2"
            /> -->
        </div>
        <VueLink
          link-class="absolute-fill"
          :link-path="product.permalink"
          :link-title-name="product.name"
        />
    </div>
</template>

<script>
export default {
    props: {
        product: Object,
    },
    computed: {
        bgImageBinder() {
            let result = false;
            if (this.product.image?.thumb) result = {
                backgroundImage: `url('${this.product.image?.thumb}')`
            }
            return result;
        },
        notDiscountedVariant() {
            return this.product.discount_percent && this.product.sale_price !== '0.00';
        }
    },
    methods: {
        formatPriceHtml(price_prop) {
            let html = '';
            if (this.product.has_variants) html += '<span style="margin-right:.25em;">From</span>';
            html += `$${this.product[price_prop]}`;
            return html;
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';

    .product {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
        position: relative;
        transition: 80ms ease-out;

        &:hover {
          box-shadow: 0 0 0 2px rgba(0,0,0,0.2);
        }
    }

    .product-image {
        background-size: cover;
        padding-top: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: hsl(0deg, 0%, 95%);
        position: relative;

        &.no-image:before {
            content: "";
            position: absolute;
            height: 25%;
            width: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: hsla(0deg,0%,0%,5%);
            clip-path: polygon(0% 100%, 40% 0%, 65% 50%, 77.5% 25%, 100% 100%);
        }

        .percent-off {
            position: absolute;
            top: 0;
            right: 0;
            padding: 6px;
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);
            border-bottom-left-radius: 5px;
            line-height: 1;
            background-color: var(--success);
            color: white;
            font-weight: bold;
            font-size: 12px;
        }
    }

    .product-content {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    .product-name {
        @include fluid-size(16px, 20px, --ttmfs);
        --ttmlh: 1.1;
        --ttmlc: 2;
        display: block;
        display: -webkit-box;
        font-family: var(--display-font-family, Arial,sans-serif);
        font-weight: 700;
        font-size: var(--ttmfs);
        color: var(--optimumOnWhite-hsl);
        line-height: var(--ttmlh);
        max-width: 2000px; // A max-width is needed, the value just needs to be above the width of the text box, so I made it huge
        max-height: calc( var(--ttmfs) * var(--ttmlh) * var(--ttmlc) );
        margin: 0 auto;
        -webkit-line-clamp: var(--ttmlc);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-price {
        margin: 0;

        &.product-price-strike {
            font-size: 14px;
            position: relative;
            display: inline-block;
            color: rgba(0,0,0,0.53);

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-2deg);
                height: 2px;
                width: calc(100% + 8px);
                background-color: var(--themeColor-hsl);
            }

            + .product-sale-price {
                display: flex;
                align-items: flex-end;
                font-weight: bold;
                font-size: 20px;
                color: var(--optimumOnWhite-hsl);
            }
        }
    }
</style>