// Todo
// File is an attribute that can be passed, but the prop validation would need to make sure it's formatted correctly (google FileList)

<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div class="form-control" :class="{'float-wrapper': floatLabel, 'float-label': labelFloating}">
            <p class="form-label">{{ inputLabel }}<span v-if="required" class="required">*</span></p>
            <label class="vue-file-input" :for="inputId" :title="[selectedFiles || inputTitle]">
                <span class="text-truncate">{{ selectedFiles }}</span>
                <span class="fas fa-upload"></span>
            </label>
            <input
                class="file-input"
                type="file"
                :accept="accept"
                :capture="capture"
                :multiple="multiple"
                v-bind="inputBinder"
                v-on="inputListener"
                @change="checkForFiles"
            >
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        accept: String,
        capture: String,
    },
    data() {
        return {
            selectedFiles: ''
        }
    },
    methods: {
        checkForFiles() {
            if (this.floatLabel) {
                this.labelFloating = event.target.files.length > 0;
            }
            this.selectedFiles = [...event.target.files].map(file => file.name).join(', ');
        }
    }
}
</script>

<style lang="scss" scoped>
    .float-wrapper {
        position: relative;
        padding: 0;
    }

    .vue-file-input {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .375rem .75rem;
        cursor: pointer;
    }

    .file-input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
    }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .float-wrapper & {
            position: absolute;
            left: .5rem;
            top: .875rem;
            transform: translateY(0);
            background-color: transparent;
            padding: 0 .25rem;
            transition: 200ms ease-out;
            pointer-events: none;
            line-height: 1;
            border-radius: 2px;
            opacity: .5;
        }

        .float-wrapper.float-label & {
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
        }
    }
</style>