<template>
    <div id="recycle-bin-panel">
        <recycle-bin-secondary-nav />

        <router-view :key="$route.fullPath" />
    </div>
</template>
<script>
    export default {
        name: 'RecycleBinIndex'
    }
</script>