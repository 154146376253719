<template>
    <VueModal :visible="isModalOpen" @on-close="closeEcommAddModifyModal" id="product-option-value-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span :class="`${modalIcon} mr-1 text-tertiary`"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <!-- Modify Details Section -->
        <VueModifyFormDetailsSection
            v-if="!isActionAdd"
            :status="getStatusAsString"
            :added-date="activeRecord.created_at"
            :last-modified-date="activeRecord.updated_at"
        />

        <!-- Form Include -->
        <component
            :is="getCurrentForm"
            v-bind="getFormProps"
            :class="{'d-none': isFormProcessing}"
            @submit-ecomm-form="submitEcommForm"
            @close-ecomm-modal="closeEcommAddModifyModal"
        />

        <VpanelLoader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeEcommAddModifyModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" :form="formId">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
            </div>
        </template>
    </VueModal>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: 'VpanelEcommAddModifyModal',
        props: [
            'formId',
            'formName',
            'modalIcon',
            'namespace',
            'sectionName',
            'customHeader'
        ],
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.computed = {
                ...mapState(storeNamespace, {
                    isModalOpen: state => state.isModalOpen,
                    activeRecord: state => state.activeRecord,
                    errorMessage: state => state.errorMessage,
                    isActionAdd: state => state.formActionIsAdd,
                    isFormProcessing: state => state.isFormProcessing,
                }),
                ...mapGetters(storeNamespace, [
                    'getStatusAsString',
                    'getAddModifyHeading'
                ]),
                getModalHeader(){
                    return this.customHeader ? this.customHeader : this.getAddModifyHeading(this.sectionName);
                },
                getCurrentForm(){
                    return this.formName;
                },
                getFormProps(){
                    return {
                        isActionAdd: this.isActionAdd,
                        activeRecord: this.activeRecord,
                        errorMessage: this.errorMessage,
                    }
                }
            }

            this.$options.methods = {
                ...mapActions(storeNamespace, [
                    'submitForm',
                    'closeModal',
                    'resetModal'
                ]),
                submitEcommForm(payload){
                    this.submitForm({ ajaxRoute: payload.addModifyRoute, commitPath: payload.addModifyCommit, dispatchPath: payload.dispatchPath});
                },
                closeEcommAddModifyModal(){
                    let vm                       = this;
                    const resetModalActionExists = Object.keys(vm.$store._actions).findIndex(key => key === `${vm.namespace}/resetModal`) !== -1;

                    vm.closeModal();

                    if(resetModalActionExists){
                        setTimeout(function(){vm.resetModal()}, 200);
                    }
                }
            }
        }
    }
</script>