<template>
    <vue-modal :visible="isModalOpen" @on-close="closeDivisionAddModifyModal" id="division-add-modify-modal">
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-box mr-1 text-tertiary"></span>&nbsp;
                {{ getAddModifyHeading('Division', 'name') }}
            </h5>
        </template>
        <template>
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                v-if="!formActionIsAdd"
                :status="getStatusAsString"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <!-- Error Messages -->
            <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

            <!-- Form Include -->
            <VpanelAddModifyStaffDivisionForm
                @closeModal="closeModal"
                @submitForm="submitDivisionForm"
                :is-action-add="formActionIsAdd"
            />
        </template>

        <template #footer v-if="!isFormProcessing">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeDivisionAddModifyModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="division-add-modify-form">{{ isFormProcessing ? 'Processing...' : 'Apply' }}</button>
            </div>
        </template>
    </vue-modal>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';

    export default {
        name: 'VpanelAddModifyDivisionModal',
        computed: {
            ...mapGetters('division/addModify', [
                'getStatusAsString',
                'getAddModifyHeading'
            ]),
            ...mapState('division/addModify', [
                'isModalOpen',
                'activeRecord',
                'errorMessage',
                'formActionIsAdd',
                'isFormProcessing',
            ])
        },
        methods: {
            ...mapActions('division/addModify', [
                'submitForm',
                'closeModal',
                'resetModal'
            ]),
            closeDivisionAddModifyModal(){
                let vm = this;
                this.closeModal();
                setTimeout(vm.resetModal, 200);
            },
            submitDivisionForm(){
                let addModifyCommit = this.formActionIsAdd ? 'division/list/ADD_RECORD_TO_TABLE_DATA' : 'division/list/UPDATE_RECORD_IN_TABLE_DATA';
                let addModifyRoute    = this.formActionIsAdd ? '/vpanel/modules/staff/division/store' : '/vpanel/modules/staff/division/update';

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    commitPath: addModifyCommit
                });
            }
        }
    }
</script>