<template>
    <div>
        <!-- Modify Details Section -->
        <VueModifyFormDetailsSection
            :show-status="false"
            :added-date="activeRecord.created_at"
            :last-modified-date="activeRecord.updated_at"
        />

        <form class="product-variant-add-modify-form needs-validation" name="product-variant-add-modify-form" id="product-variant-add-modify-form" novalidate @submit.prevent="submitProductVariantForm">
            <!-- Error Messages -->
            <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

            <!-- Product Option Values Form -->
            <VpanelProductVariantOptionForm
                :attribute-index="0"
                @update="updateAttributeValue"
                :product-attribute="activeRecord"
            />

            <!-- Product Images Form -->
            <VpanelEcommMultiImageManagerCard
                :show-variant-message="false"
                @update="updateAttributeValue"
                @remove-item="removeAdditionalItem"
                :product-images="activeRecord.images"
            />

            <!-- Product Price and Weight Form -->
            <VpanelProductPriceAndWeightForm
                :has-variants="false"
                @update="updateAttributeValue"
                :product-attribute="activeRecord"
            />

            <!-- Product SKUs and Qty Form -->
            <VpanelProductSkuAndQtyForm
                :has-variants="false"
                @update="updateAttributeValue"
                :product-attribute="activeRecord"
            />

            <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-primary my-0 save-button">{{ isFormProcessing ? 'Processing...' : 'Update' }}</button>
            </div>
        </form>

        <!-- Add HCPCS Code Modal -->
        <VpanelAddProductHcpcsCodeModal />

        <!-- Add Unit of Measure Modal -->
        <VpanelAddProductUomModal />
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: "VpanelAddModifyProductVariantsPage",
        props: [
            'id'
        ],
        computed: {
            ...mapState('productVariant/addModify', {
                activeRecord: state => state.activeRecord,
                errorMessage: state => state.errorMessage,
                isFormProcessing: state => state.isFormProcessing
            })
        },
        methods: {
            ...mapActions('productVariant/addModify', [
                'submitForm',
                'removeAdditionalItem',
                'setValueInActiveRecord'
            ]),
            updateAttributeValue(payload){
                let inputValue;
                let prop  = payload.prop != 'attributes' ? payload.prop : payload.nestedIndex;
                let index = payload.prop != 'attributes' ? payload.index : null;

                if('value' in payload){
                    inputValue = payload.value;
                } else {
                    let value  = payload.prop == 'option_values' ? parseInt(event.target.value) : event.target.value;
                    inputValue = payload.isCheckBox ? event.target.checked : value;
                }

                this.setValueInActiveRecord({prop: prop, value: inputValue, index: index});
            },
            submitProductVariantForm(){
                let modifyDispatch = 'productVariant/addModify/updateActiveRecordAndTableData';
                let modifyRoute    = '/vpanel/modules/ecommerce/product/variant/update';

                //ensure productid is on active record
                this.setValueInActiveRecord({prop: 'product_id', value: this.id});

                this.submitForm({
                    ajaxRoute: modifyRoute,
                    dataTableId: null,
                    isUsingModal: false,
                    dispatchPath: modifyDispatch
                });
            }
        }
    }
</script>