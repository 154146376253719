<template>
    <div>
        <p class="h6">Upload Image</p>
        <div class="mt-2">
            <div class="alert alert-info" v-if="showResizeImageMessage">
                <p class="m-0">Image will be resized after saving.</p>
            </div>
            <!-- Image Preview -->
            <VpanelEcommImageDiv
                :image="activeRecord.image"
                image-wrapper-width="width:100%;"
            />

            <div class="form-group">
                <input
                    disabled
                    type="text"
                    class="form-control"
                    id="category_image_path"
                    name="category_image_path"
                    :value="activeRecord.image"
                />

                <div class="my-1 wrap-gap">
                    <!-- Choose Image Button -->
                    <button
                        type="button"
                        id="choose_category_image"
                        @click.prevent="selectImage"
                        class="btn btn-tertiary"
                        data-input="category_image_path"
                        data-preview="image_preview"
                        title="Opens the file manager modal where you can choose to upload a new image or choose from your image library"
                    >Choose Image</button>

                    <!-- Remove Image Button -->
                    <button
                        type="button"
                        id="remove_image"
                        @click.prevent="removeImage"
                        class="btn btn-outline-primary"
                        title="Clears the current background image"
                    >Delete Image</button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="d-flex justify-content-between align-items-center">
                <label for="image_alt" :class="activeRecord.image ? 'required' : ''">Alternative Text</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Provide a description that explains what the image looks like to someone that can’t see it. Try to keep it short and succinct – less than 100 characters is ideal."
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                type="text"
                id="image_alt"
                name="image_alt"
                class="form-control"
                :value="activeRecord.image_alt"
                :required="activeRecord.image ? true : false"
                @input="$emit('update', { prop: 'image_alt' })"
                title="Please enter the alternative text for the image"
            />
            <div class="invalid-feedback">
                Please add alternative text that describes this image.
            </div>
        </div>
    </div>
</template>
<script>
	import { openFileManager } from "@j/core/vpanel/vpanel-functions";

    export default {
        name: 'VpanelEcommAddModifyImageForm',
        props: [
            'activeRecord'
        ],
        data(){
            return {
                showResizeImageMessage: false
            }
        },
        methods: {
            selectImage(){
                let chooseImageButton = document.querySelector('#choose_category_image');

                openFileManager(chooseImageButton, (fmObject) => {
                    this.$emit('update', {prop: 'image', value: fmObject[0].url});
                    this.showResizeImageMessage = true;
                });
            },
			removeImage(){
                this.$emit('update', {prop: 'image', value: ''});
            }
        }
    }
</script>