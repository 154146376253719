export const navMixin = {
    props: {
        navInfo: {
            type: Array,
        },
    },
    data() {
        return {
            mobileNavExpanded: false
        }
    },
    computed: {
        currentPageId() {
            return this.pageUuid;
        },
        companyName() {
            return this.designConfig?.company_name || 'Company Name';
        },
        contactPhone() {
            return this.designConfig?.contact_phone || '(123) 456-7890';
        },
        contactEmail() {
            return this.designConfig?.contact_email || 'email@email.com';
        },
        contactHours() {
            return this.designConfig?.contact_hours;
        },
        contactPhoneFormatted() {
            return `tel:${this.contactPhone.replace(/\D/g,'')}`;
        },
        contactEmailFormatted() {
            return `mailto:${this.contactEmail}`;
        }
    },
    methods: {
        openMobileMenu() {
            this.mobileNavExpanded = true;
            document.body.style.setProperty('overflow', 'hidden');
        },
        closeMobileMenu() {
            this.mobileNavExpanded = false;
            document.body.style.removeProperty('overflow');
        }
    }
}

export default { navMixin }