<template>
    <TableDropdownWrapper
        v-bind="wrapperBinder"
    >
        <!-- Put whatever the heck you want in here you have full access to the row object 🤙 -->
        <p class="m-0">{{ row.id }}</p>
    </TableDropdownWrapper>
</template>

<script>
    import tableDropdownMixin from './mixins/tableDropdownMixin'
    export default {
        mixins: [tableDropdownMixin]
    }
</script>
