<template>
    <component
        v-if="navTemplateName != 'hidden'"
        :is="templateComponent"
        :template="template"
        :nav-template="navTemplateName"
        :nav-info="navInfo"
        :mobile-expanded="mobileExpanded"
        :current-page-id="currentPageId"
        :header-links="headerLinks"
        @open-mobile-menu="openMobileMenu"
        @close-mobile-menu="closeMobileMenu"
    ></component>
</template>

<script>
export default {
    props: [
        "mobileExpanded",
        "navInfo",
        "currentPageId",
        "navTemplate",
        "template",
        "headerLinks"
    ],
    computed: {
        navTemplateName() {
            return this.navTemplate || 'Template1';
        },
        templateComponent() {
            let prefix = 'VueNav',
                check  = Object.keys(Object.getPrototypeOf(this.$options.components)).includes(prefix + this.navTemplateName);

            return prefix + ( check ? this.navTemplateName : 'Template1');
        },
    },
    methods: {
        openMobileMenu() {
            this.$emit('open-mobile-menu');
        },
        closeMobileMenu() {
            this.$emit('close-mobile-menu');
        }
    }
}
</script>