<template>
    <div class="vp-tab-nav d-flex">
        <router-link
            exact
            class="nav-link"
            active-class="current"
            :to="{name: 'Staff List'}"
            :title="`Internal link that opens a list of ${moduleConfig['MN']}`"
        >Staff</router-link>

        <router-link
            exact
            class="nav-link"
            active-class="current"
            v-if="parseInt(moduleConfig['ED'])"
            :to="{name: 'Staff Division List'}"
            :title="`Internal link that opens a list of ${moduleConfig['MN']} divisions`"
        >Divisions</router-link>

        <router-link
            exact
            class="nav-link"
            active-class="current"
            :to="{ name: 'Staff Module Setting List' }"
            title="Internal link that opens a list of settings for this module"
        >Settings</router-link>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'VpanelStaffSecondaryNav',
        computed: {
            ...mapGetters({
                moduleConfig: 'getModuleConfigs'
            })
        }
    }
</script>