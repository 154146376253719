<template>
    <div class="gmap-info-window">
        <div class="window-wrapper">
            <div class="image-wrapper" v-if="location.image_path">
                <img :src="location.image_path" :alt="location.image_alt" />
            </div>
            <div class="content">
                <p class="title">{{ location.title }}</p>
                <frontend-location-short-hours
                    v-for="(hours, index) in location.hours"
                    :key="index + '_lv'"
                    :hours="hours"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoWindowContent',
    props: ["location"]
}
</script>

<style lang="scss" scoped>
    .gmap-info-window {
        .window-wrapper {
            display: flex;
            align-items: stretch;
        }

        .content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: .25rem;
        }

        .hour-overview {
            font-size: 12px;
            margin-bottom: 0;
        }

        .image-wrapper {
            position: relative;
            height: 60px;
            width: 60px;
            flex: 0 0 auto;
            overflow: hidden;
            border-radius: 50rem;
            border: hsl(0deg,0%,95%);
            margin-right: 12px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                vertical-align: bottom;
            }
        }
    }
</style>