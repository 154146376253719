<template>
    <VpanelModuleConfigList
        :module-configs="moduleSettings"
        @submit-form="submitSettingsForm"
        form-id="update-ecommerce-module-setting"
        @update-config-value="updateModuleSetting"
    />
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: "VpanelEcommerceModuleSettingList",
        computed: {
            ...mapState('moduleSetting/list', {
                moduleSettings: state => state.data,
            })
        },
        methods: {
            ...mapActions({
                submitForm: 'moduleSetting/modify/submitForm',
                fetchModuleConfigData: 'fetchModuleConfigData',
                setValueInActiveRecord: 'moduleSetting/modify/setValueInActiveRecord'
            }),
            updateModuleSetting(payload){
                this.setValueInActiveRecord({ prop: 'config', value: payload.value, index: payload.config.id });
            },
            submitSettingsForm(){
                this.submitForm({ ajaxRoute: '/vpanel/module-config/update'}).then(record => {
                    this.fetchModuleConfigData();
                });
            }
        }
    }
</script>