<template>
    <div class="fb-sidebar p-2">
        <VpanelFormSubmissionListingHeader
            :show-date="true"
            :show-badges="false"
            :form-name="formName"
            :created-date="createdDate"
        />

        <template v-for="(version, index) in formVersionList">
            <VpanelFormSubmissionFilters
                :form-version="version"
                :key="`formVersionList_${index}`"
                :all-filter-name="`v ${version.revision_name || (index + 1)}`"
            />
        </template>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'VpanelFormVersionList',
        props: [
            'formName',
            'createdDate'
        ],
        computed: {
            ...mapState('forms/versions/list', {
                formVersionList: 'allData'
            })
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../../../../../../../sass/vue-import';

     #forms-module-section {
        .fb-sidebar {
            @include desktop {
                border-right: 1px solid black;
            }

            @include not-desktop {
                border-bottom: 1px solid black;
            }
        }
    }
</style>