<template>
    <div class="filters">
        <div class="tabs">
            <button
                class="tab"
                :class="{active:activeFilterTab=='categories'}"
                @click="setFilterTab('categories')"
            >Categories</button>
            <button
                class="tab"
                :class="{active:activeFilterTab=='recent'}"
                @click="setFilterTab('recent')"
            >Recent</button>
            <button
                class="tab"
                :class="{active:activeFilterTab=='archives'}"
                @click="setFilterTab('archives')"
            >Archives</button>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab=='categories'}"
        >
            <a
                class="category"
                :key="'catFilter_' + id"
                :href="category.permalink"
                v-for="(category, id) in categories"
                :title="`Internal link to the ${category.name} category in the same window.`"
                :class="{active: activeFilterTab == 'categories' && pageType == 'category' && activeRecord.id == category.id}"
            >{{ category.name }}</a>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab=='recent'}"
        >
            <a
                class="recent"
                v-for="blog in recent"
                :href="blog.permalink"
                :key="'recent_' + blog.id"
                :title="`Internal link to the ${blog.title} post in the same window.`"
                :class="{active: activeFilterTab == 'recent' && pageType == 'post' && activeRecord.id == blog.id}"
            >{{ blog.title }}</a>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab=='archives'}"
        >
            <a
                class="archive"
                :href="archivePermalink"
                :key="'archived_' + archiveName"
                v-for="(archivePermalink, archiveName) in archives"
                :title="`Internal link to the posts by ${archiveName} archive in the same window.`"
                :class="{active: activeFilterTab == 'archives' && pageType == 'archive' && activeRecord.name == archiveName}"
            >{{ archiveName }}</a>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab===null}"
        >
            <p class="mt-2 text-gray text-14">Choose a tab above to view blog categories, recent, or archived blogs.</p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SiteBlogSidebarFilters',
    computed: {
        ...mapState('blog', [
            'recent',
            'archives',
            'pageType',
            'categories',
            'activeRecord',
            'activeFilterTab'
        ]),
    },
    methods: {
        ...mapActions('blog', [
            'setFilterTab'
        ])
    }
}
</script>

<style lang="scss" scoped>
    .filters {
        margin-top: 1.5rem;

        .tabs {
            display: flex;
            flex-wrap: wrap;

            .tab {
                flex: 1 1 auto;
                background-color: hsl(0deg,0%,95%);
                border: 0;
                border-bottom: 2px solid hsl(0deg,0%,90%);
                outline: 0;
                padding: .25rem .5rem;
                transition: 200ms ease-out;

                &:hover:not(.active) {
                    background-color: hsl(0deg,0%,85%);
                    border-bottom: 2px solid hsl(0deg,0%,80%);
                }

                &.active {
                    border-bottom: 2px solid var(--bg-hsl);
                    background-color: var(--bg-hsla);
                    color: var(--text-hsl);
                }
            }
        }

        .filter-section {
            display: none;

            &.active {
                display: block;
            }

            button, a {
                display: block;
                width: 100%;
                text-align: left;
                background: white;
                border: solid hsl(0deg,0%,95%);
                border-width: 0 0 1px 0;
                outline: 0;
                padding: .5rem;
                font-size: 14px;
                font-weight: bold;
                transition: 200ms ease-out;

                &:hover {
                    background: hsl(0deg,0%,95%);
                }

                &.active {
                    background: hsl(0deg,0%,85%);
                    border-width: 0;
                }
            }
        }
    }
</style>