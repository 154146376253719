<template>
    <div class="product-listing-sidebar-card" :class="{'hide-content': expandable && hiddenContent}">
        <p class="heading"><slot name="header"></slot></p>
        <div class="content">
            <slot></slot>
        </div>
        <button
            v-if="expandable"
            class="mt-2 btn btn-primary"
            @click="hiddenContent = !hiddenContent"
        >Show {{ hiddenContent ? 'More' : 'Less' }}</button>
    </div>
</template>

<script>
export default {
    props: {
        expandable: Boolean
    },
    data() {
        return {
            hiddenContent: true
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import.scss';

    .product-listing-sidebar-card {
        background-color: white;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        padding: 1rem;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin: 1rem;

        &.hide-content {
            max-height: 240px;

            .content:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 25px;
                width: 100%;
                background-image: linear-gradient(to top, white, transparent);
            }
        }

        .heading {
            line-height: 1;
            font-size: 18px;
            font-weight: bold;
            font-family: var(--display-font-family, Arial,sans-serif);
            margin-bottom: .25rem;
        }

        .content {
            position: relative;
            overflow: hidden;
            padding: .5rem .25rem;
        }

        button,
        .vue-link,
        a {
            margin: 0;
            font-size: 14px;
            margin: .25rem 0;
            padding: 6px 12px;
            cursor: pointer;
            border: 0;
            outline: 0;
            background-color: hsl(0deg, 0%, 98%);
            display: block;
            width: 100%;
            text-align: left;
            line-height: 1;

            &.active {
                background-color: var(--themeColor-hsl);
                color: var(--themeCompliant-hsl);
            }

            &:hover {
                text-decoration: underline;
            }

            &.cursor-default {
                cursor: default;
            }
        }
    }
</style>