<template>
    <nav id="location-form-nav" class="modal-nav mt-1 mb-3" aria-label="Location Form Tabs">
        <div class="nav nav-pills" id="location-add-modify-tab" role="tablist">
            <a
                role="tab"
                :key="index"
                :id="navItem.id"
                data-toggle="pill"
                class="nav-link btn"
                :href="`#${navItem.href}`"
                :aria-controls="navItem.href"
                v-for="(navItem, index) in navItems"
                :aria-selected="navItem.is_active_tab"
                @click="SET(['activeFormTab', navItem.id])"
                :class="navItem.is_active_tab ? 'active' : ''"
            >{{ navItem.name }}</a>
        </div>
    </nav>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: "VpanelAddModifyLocationFormNav",
        computed: {
            ...mapState('location/addModify', [
                'activeFormTab'
            ]),
            navItems(){
                return [
                    {
                        name: 'Details',
                        href: 'add-modify-general-details',
                        id: 'add-modify-general-details-tab',
                        is_active_tab: this.activeFormTab === 'add-modify-general-details-tab'
                    },
                    {
                        name: 'Contact',
                        href: 'add-modify-contact-info',
                        id: 'add-modify-contact-info-tab',
                        is_active_tab: this.activeFormTab === 'add-modify-contact-info-tab'
                    },
                    {
                        name: 'Hours',
                        href: 'add-modify-hours',
                        id: 'add-modify-hours-tab',
                        is_active_tab: this.activeFormTab === 'add-modify-hours-tab'
                    }
                ]
            }
        },
        methods: {
            ...mapMutations('location/addModify', [
                'SET'
            ])
        }
    }
</script>

<style lang="scss" scoped>
    .nav.nav-pills {
        margin: -.25rem;

        .nav-link {
            margin: .25rem;
        }
    }
</style>