<template>
    <div class="secondary-nav">
        <template v-for="(link, index) in navLinks">
            <RouterLink
                :exact="link.exact"
                :key="index"
                class="nav-link"
                :title="link.title"
                :to="{ name: link.to}"
                active-class="current"
            >{{ link.name }}</RouterLink>
        </template>
    </div>
</template>
<script>
    export default {
        name: 'VpanelMarketingMessageSecondaryNav',
        computed: {
            navLinks(){
                return [
                    {
                        'name': 'Marketing Messages',
                        'to': 'Marketing Message List',
                        'exact': true,
                        'title': 'Internal link that opens a list of marketing messages',
                    },
                    {
                        'name': 'Settings',
                        'to': 'Marketing Message Setting List',
                        'exact': true,
                        'title': 'Internal link that opens a list of settings'
                    },
                ]
            }
        }
    }
</script>