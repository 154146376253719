<template>
    <button type="button" class="btn btn-secondary text-white" @click="add">
        <span class="fas fa-plus"></span>
    </button>
</template>
<script>
    export default {
        name: 'VpanelAddAdditionalInputButton',
        methods:{
            add(){
                this.$emit('add-new-array-item');
            }
        }
    }
</script>