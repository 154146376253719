<template>
    <div class="marketing-message-settings-form">
        <div class="d-md-flex align-items-start wrap-gap" style="--gap:30px;--gap-y:15px;" v-if="parseInt(moduleConfigs['Scheduler'])">
            <div class="flex-grow-1 pb-2">
                <div class="wrap-gap">
                    <!-- Marketing Message Publish Date -->
                    <div class="form-group has-label flex-grow-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="scheduled_start_date">Publish Date</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                :title="`Schedule when this ${moduleConfigs['Module Name']} should be published.`"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            id="scheduled_start_date"
                            name="scheduled_start_date"
                            title="Select a publish date"
                            class="form-control startdatepicker"
                            :value="activeRecord.scheduled_start_date"
                            @input="$emit('update', { prop: 'scheduled_start_date' })"
                        />
                    </div>

                    <!-- Marketing Message Publish End Date -->
                    <div class="form-group has-label flex-grow-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="scheduled_end_date">End Date</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                :title="`Schedule when this ${moduleConfigs['Module Name']} should no longer be published.`"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            id="scheduled_end_date"
                            name="scheduled_end_date"
                            class="form-control enddatepicker"
                            title="Select a published end date."
                            :value="activeRecord.scheduled_end_date"
                            @change="$emit('update', { prop: 'scheduled_end_date' })"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="d-md-flex align-items-start wrap-gap" style="--gap:30px;--gap-y:15px;">
            <div class="flex-grow-1 py-2">
                <div class="wrap-gap">
                    <!-- Marketing Message Site Location -->
                    <div class="form-group flex-grow-1" v-if="parseInt(moduleConfigs['Site Location'])">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="site_location">Location on Site</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                :title="`Enter the page you'd like to place the ${moduleConfigs['Module Name']} on.`"
                            >
                                <span class="fas fa-question-circle text-info cursor-pointer"></span>
                            </a>
                        </div>
                        <select
                            required
                            id="site_location"
                            name="site_location"
                            class="form-control"
                            :value="activeRecord.site_location"
                            title="Select a page to place this on"
                            @change="$emit('update', { prop: 'site_location' })"
                            data-invalid-feedback="Select a page to place this on."
                        >
                            <option value="" selected disabled hidden>Select a Page</option>
                            <option
                                v-for="(page, index) in pages"
                                :key="index"
                                :value="page.url"
                            >{{ page.url }}</option>
                        </select>
                        <div class="invalid-feedback">
                            Select a page to place this on.
                        </div>
                    </div>

                    <!-- Marketing Message Sort Order -->
                    <div class="form-group flex-grow-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="sort_id">Sort</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The order in which this should be displayed in on the frontend."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            id="sort_id"
                            v-mask="'######'"
                            name="sort_id"
                            class="form-control"
                            v-model="activeRecord.sort_id"
                            @input="$emit('update', { prop: 'sort_id' })"
                            title="Please enter the order in which this should be displayed in on the frontend"
                        />
                    </div>

                    <!-- Marketing Message Status -->
                    <div class="form-group flex-grow-1">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="mr-3">Status<span class="required"></span></span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Active = visible to customers. Inactive = not visible to customers."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="active_yes">
                                    <input
                                        checked
                                        required
                                        :value="1"
                                        type="radio"
                                        name="active"
                                        id="active_yes"
                                        class="form-check-input"
                                        v-model="activeRecord.active"
                                        title="Please choose an option"
                                        @change="$emit('update', { prop: 'active', value: 1 })"
                                        data-invalid-feedback="Please indicate if this is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Active
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="active_no">
                                    <input
                                        required
                                        :value="0"
                                        type="radio"
                                        name="active"
                                        id="active_no"
                                        class="form-check-input"
                                        v-model="activeRecord.active"
                                        title="Please choose an option"
                                        @change="$emit('update', { prop: 'active', value: 0 })"
                                        data-invalid-feedback="Please indicate if this is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Inactive
                                    <div class="invalid-feedback">
                                        Please indicate if this is active or inactive.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        name: 'VpanelMarketingMessageSettingsForm',
        props: [
            'activeRecord',
            'moduleConfigs'
        ],
        mounted(){
            let datepickers = ['start', 'end'];

            datepickers.forEach(datepicker => {
                if ($(`.${datepicker}datepicker`).length != 0) {
                    let activeRecrdVar = `scheduled_${datepicker}_date`
                    $(`.${datepicker}datepicker`).datetimepicker({
                        defaultDate: this.activeRecord[activeRecrdVar],
                        icons: {
                            time: "now-ui-icons tech_watch-time",
                            date: "now-ui-icons ui-1_calendar-60",
                            up: "fa fa-chevron-up",
                            down: "fa fa-chevron-down",
                            previous: 'now-ui-icons arrows-1_minimal-left',
                            next: 'now-ui-icons arrows-1_minimal-right',
                            clear: 'fa fa-trash',
                            close: 'fa fa-remove'
                        }
                    });

                    $(`.${datepicker}datepicker`).on("dp.change", e => {
                        this.$emit('update', { prop: activeRecrdVar, value: moment(e.date).format("MM/DD/YYYY h:mm A") });
                    });
                }
            })
        },
        computed: {
            ...mapState('marketingMessage/addModify', {
                pages: state => state.pages
            })
        }
    }
</script>