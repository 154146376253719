<template>
    <div class="row">
        <div class="col">
            <p class="h5">SEO Results Preview</p>
            <div class="row">
                <div class="col">
                    <div class="seo-preview">
                        <p class="seo-preview-title">{{ currentProduct.meta_title ? currentProduct.meta_title : 'This is an example seo title' }}</p>
                        <p class="seo-preview-url">{{ currentProduct.permalink ? `https://www.yoursite.com/${currentProduct.permalink}` : 'https://www.yoursite.com/this-is-an-example-seo-url' }}</p>
                        <p class="seo-preview-description">{{ currentProduct.meta_description ? currentProduct.meta_description : 'This is the meta description being pulled in to show a faux search result in the style of Google.' }}</p>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group has-label">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                                        <label class="required" for="meta_title">Meta Title</label>
                                        <p class="my-0 ml-3 text-info text-14">
                                            <span>{{ totalMetaTitleCharacters }}</span> Characters Remaining
                                        </p>
                                    </div>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        :title="`Write a unique title relative to the ${sectionName} name.`">
                                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                                    </a>
                                </div>
                                <input
                                    required
                                    type="text"
                                    maxlength="65"
                                    id="meta_title"
                                    name="meta_title"
                                    class="form-control"
                                    @keyup="updateCharCount"
                                    title="Please enter meta title"
                                    v-model="currentProduct.meta_title"
                                    data-invalid-feedback="Please enter a meta title"
                                >
                                <div class="invalid-feedback">
                                    Please enter a meta title
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group has-label">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                                        <label class="required" for="meta_keyword">Meta Keywords</label>
                                        <p class="my-0 ml-3 text-info text-14">
                                            <span>{{ totalMetaKeywordCharacters }}</span> Characters Remaining
                                        </p>
                                    </div>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        :title="`Choose 2-3 key words and/or phrases, separated by commas, relative to the ${sectionName} information/description.`"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <textarea
                                    rows="2"
                                    required
                                    maxlength="155"
                                    id="meta_keywords"
                                    class="form-control"
                                    name="meta_keywords"
                                    @keyup="updateCharCount"
                                    title="Please enter meta keywords"
                                    v-model="currentProduct.meta_keywords"
                                    data-invalid-feedback="Please enter a meta keywords"
                                ></textarea>
                                <div class="invalid-feedback">
                                    Please enter a meta keywords
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                                        <label class="col-form-label required" for="meta_description">Meta Description</label>
                                        <p class="my-0 ml-3 text-info text-14">
                                            <span>{{ totalMetaDescriptionCharacters }}</span> Characters Remaining
                                        </p>
                                    </div>
                                    <a
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-boundary="window"
                                        :title="`Write a unique description relative to the ${sectionName} information to help this ${sectionName} stand out in search engine results.`"
                                    ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                                </div>
                                <textarea
                                    rows="2"
                                    required
                                    maxlength="155"
                                    class="form-control"
                                    id="meta_description"
                                    name="meta_description"
                                    @keyup="updateCharCount"
                                    title="Please enter meta description"
                                    v-model="currentProduct.meta_description"
                                    data-invalid-feedback="Please enter a meta description"
                                ></textarea>
                                <div class="invalid-feedback">
                                    Please enter a meta description
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VpanelEcommSeoSettingsForm',
        props: [
            'sectionName',
            'activeRecord'
        ],
        data() {
			return {
                totalMetaTitleCharacters: 65,
                totalMetaKeywordCharacters: 155,
                currentProduct: this.activeRecord,
                totalMetaDescriptionCharacters: 155,
			}
        },
        methods: {
            updateCharCount(){
                let input     = event.target;
                let maxlength = input.maxLength;
                let newCount  = maxlength - input.value.length;

                if(input.id == 'meta_title'){
                    this.totalMetaTitleCharacters = newCount;
                } else if(input.id == 'meta_keywords'){
                    this.totalMetaKeywordCharacters = newCount;
                } else if(input.id == 'meta_description'){
                    this.totalMetaDescriptionCharacters = newCount;
                }
            }
        }
    }
</script>