<template>
    <div class="card">
        <div class="card-header pb-2 d-flex justify-content-between align-items-center">
            <p class="h5 m-0">
                <span class="far fa-images mr-1"></span>
                Images
            </p>

            <!-- Add Additional Images Button -->
            <VpanelAddAdditionalInputButton
                id="choose_product_image"
                data-input="product_image"
                @add-new-array-item="selectImage"
            />
        </div>
        <div class="card-body">
            <div class="alert alert-info" v-if="showVariantMessage">
                <p class="mb-0">This space is for generic product images. If you're looking to change variant images go to the variant modify section.</p>
            </div>
            <div class="alert alert-info" v-if="showResizeImageMessage">
                <p class="m-0">Image(s) will be resized after saving.</p>
            </div>
            <div class="product-image-wrappers">
                <div class="product-image-wrapper" :key="index" v-for="(image, index) in productImages">
                    <!-- Image Div -->
                    <VpanelEcommImageDiv
                        :image-index="index"
                        :image="getImage(image)"
                    />

                    <!-- Remove Additional Images Button -->
                    <VpanelRemoveAdditionalItemButton
                        @remove-additional-item="$emit('remove-item', [index, 'images'])"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import { openFileManager } from "@j/core/vpanel/vpanel-functions";
    export default {
        name: 'VpanelEcommMultiImageManagerCard',
        props: [
            'productImages',
            'showVariantMessage'
        ],
        data(){
            return {
                showResizeImageMessage: false
            }
        },
        methods:{
            getImage(image){
                return typeof image === 'object' && 'full' in image ? image.full : image;
            },
            selectImage(){
                let images = this.productImages;
                //will check if there are any empty product image values
                let shouldAddNewValue = Object.values(images).every(image => image);

                if(shouldAddNewValue){
                    let length     = Object.keys(images).length;
                    let imageIndex = 'add' + length;

                    //open file manager
                    let chooseImageButton = document.querySelector('#choose_product_image');
                    openFileManager(chooseImageButton, (fmObject) => {
                        // //will add new object to array
                        this.$emit('update', {prop: 'images', index: imageIndex, value: { thumb: fmObject[0].thumb_url, full: fmObject[0].url }});
                        this.showResizeImageMessage = true;
                    });
                } else {
                    //will show user a message if theres a image that doesn't have a value
                    showNotification('Make sure all images have been selected, before selecting a new one.', 'error');
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .product-image-wrappers {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 0.8rem;
    }

    .product-image-wrapper {
        position: relative;

        &::v-deep {
            .product-image {
                margin-right: 0 !important;
            }

            button.btn-danger {
                position: absolute;
                top: .5rem;
                right: .5rem;
                opacity: 0;
                transition: 200ms ease-out;
            }

            &:hover button.btn-danger {
                opacity: 1;
            }
        }
    }
</style>