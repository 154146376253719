<template>
    <div class="asset-manager-section">
        <add-new-asset-select :is-forbin-admin="isForbinAdmin" />

        <!-- <asset-manager-table
            v-if="getTableData()"
            :data="getTableData()"
            :searchable-data="['name','shortcut_key']"
            respond-at="md"
            :is-forbin-admin="isForbinAdmin"
        /> -->

        <!-- <asset-manager-table-beefy
            v-if="getTableData()"
            :data="getTableData()"
            :searchable-data="['name','shortcut_key']"
            respond-at="md"
            :items-per-page="4"
            :is-forbin-admin="isForbinAdmin"
        /> -->

        <asset-manager-list :csp-nonce="cspNonce" />

        <add-modify-asset-modal />
        <recycle-asset-modal />
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters } from "vuex";
    export default {
        name: 'AssetManagerIndex',
        created(){
            this.getAssets();
            this.setCsrfToken();
        },
        computed: {
            ...mapState("assetManager", {
                cspNonce: state => state.list.cspNonce,
                isForbinAdmin: state => state.isForbinAdmin,
            })
        },
        methods: {
            ...mapActions("assetManager", {
                setCsrfToken: 'setCsrfToken',
                getAssets: 'list/fetchAssetData',
            }),
            ...mapGetters("assetManager/list", [
                'getTableData'
            ])
        }
    }
</script>