<template>
    <div class="blog-comments">
        <p class="h3 title">{{ commentCount }} Comment{{ commentCount > 1 ? 's' : '' }}</p>

        <SiteBlogCommentForm :comment-max-characters="blog.commentMaxCharacters" />

        <div class="comment-list">
            <SiteBlogCommentItem
                v-for="(comment, id) in blog.comments"
                :key="id"
                :comment="comment"
            ></SiteBlogCommentItem>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SiteBlogComment',
        props: [ "blog" ],
        computed: {
            commentCount() {
                return Object.keys(this.blog.comments).length;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .blog-comments {
        margin-top: 1.5rem;
        border-top: 1px solid hsl(0deg,0%,95%);
        padding-top: 1.5rem;

        .title { margin-bottom: 1rem; }

        .comment-list {
            margin-top: 1.5rem;
            border-top: 1px solid hsl(0deg,0%,95%);
            padding-top: .5rem;
        }
    }
</style>