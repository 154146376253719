<template>
    <div class="vue-header" :data-variant="variant">
        <div class="dynamic-container">
            <div class="header-wrapper">
                <div class="logo">
                    <img :src="logoPath" alt="Company logo">
                    <VueLink link-path="/" :link-text="`${companyName} home`" link-class="absolute-fill text-throw" />
                </div>
                <div class="link-wrapper" :class="[`justify-content-${headerLinks.length && headerLinks.some(o => o) ? 'between' : 'end'}`]">
                    <div class="top-bar-links d-none d-md-flex wrap-gap" v-if="headerLinks.length && headerLinks.some(o => o)">
                        <template v-for="(link, index) in headerLinks">
                            <VueLink
                                v-if="link"
                                :class="[`top-bar-link-${index}`, getLinkClassFromIndex(index)]"
                                :key="link.text"
                                :link-obj="link"
                            />
                        </template>
                    </div>
                    <div class="nav">
                        <!-- nav-template="TemplateMegaMenu" -->
                        <VueNav
                            nav-template="TemplateMegaMenu"
                            :header-links="headerLinks"
                            :current-page-id="currentPageId"
                            :mobile-expanded="mobileNavExpanded"
                            :nav-info="navInfo"
                            @open-mobile-menu="openMobileMenu()"
                            @close-mobile-menu="closeMobileMenu()"
                        />
                    </div>
                </div>
                <div class="mobile-controls">
                    <!--<VueSiteSearch
                        search-template="PowerwebTemplate1"
                        :nav-info="navInfo"
                    />-->
                    <p class="open-mobile-menu" @click="openMobileMenu()">
                        <span class="fas fa-bars"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { navMixin } from '@v/Core/Site/Mixins/navMixin';
import { headerMixin } from '@v/Core/Site/Mixins/headerMixin';
import { headerFooterMixin } from '@v/Core/Site/Mixins/headerFooterMixin';

export default {
    name: "VueHeaderPowerwebTemplate2",
    mixins: [
        navMixin,
        headerMixin,
        headerFooterMixin,
    ],
    methods: {
        getLinkClassFromIndex(index) {
            switch(index) {
                case 0: return 'text-optimum-on-white';
                case 1: return 'text-optimum-on-white';
                case 2: return 'btn btn-secondary';
                case 3: return 'btn btn-primary';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';

    .vue-header > .dynamic-container {
        position: relative;
    }

    .header-wrapper {
        display: flex;
        align-items: stretch;

        .link-wrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 1rem;
        }
    }

    .logo {
        position: relative;
        max-height: 100px;
        max-width: min(200px, 50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin: 1rem 1rem 1rem 0;

        img {
            max-width: 100%;
            max-height: 100px;
            vertical-align: bottom;
        }
    }

    .vue-header {
        background-color: white;

        body:not([data-loaded]) & {
            display: none;
        }
    }

    .mobile-controls {
        @include not-desktop {
            display: flex;
            align-items: center;
        }

        @include desktop { display: none; }

        .open-mobile-menu {
            font-size: 24px;
            margin: 0 0 0 1rem;
            color: black;
        }
    }

    .top-bar-links {
        @include fluid-size(8px, 16px, --gap-x);
        @include fluid-size(4px, 8px, --gap-y);
        display: flex;
        align-items: center;
    }

    .nav-and-search {
        @include desktop {
            @include fluid-size(-8px, -16px, margin-right, $min-vw: $lg-screen, $max-vw: 1400px);
        }

        display: flex;
        align-items: center;
    }

    .dynamic-container {
        // Default container variant
        .vue-header:not([data-variant]) & {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        .vue-header[data-variant="full"] & {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }
    }

    .primary-links,
    .secondary-links {
        display: flex;

        a {
            color: var(--themeComplement-hsl);
            padding: .25rem 1rem;
        }
    }
</style>