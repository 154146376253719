<template>
    <div class="recent-blogs" v-if="blogs.length">
        <h4 v-if="!noHeader">Recent Blogs{{ headerTagText }}</h4>
        <div class="blogs-wrapper wrap-gap">
            <div class="blog" v-for="(blog, index) in blogs" :key="`blog_${index}`">
                <div class="image-wrapper" v-if="blog.thumbnail_image" v-show="imageExists[index]">
                    <img v-if="imageExists[index]" :src="blog.thumbnail_image" :alt="blog.thumbnail_image_alt" />
                    <img v-else class="d-none" :src="blog.thumbnail_image" @load="imageExistsAt(index)" />
                </div>
                <div class="content-wrapper">
                    <h5 class="title">{{ blog.title }}</h5>
                    <div class="content" v-html="blog.content"></div>
                    <a class="btn btn-primary" :href="blog.permalink">View More</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'SiteRecentBlogPosts',
    props: {
        "tags": Array,
        "noHeader": Boolean
    },
    data() {
        return {
            blogs: [],
            imageExists: []
        }
    },
    computed: {
        headerTagText() {
            if (this.tags?.length) {
                let tagString = this.tags.join(', ');
                return ` - ${tagString}`
            }
        }
    },
    watch: {
        tags: {
            deep: true,
            handler(n,o) {
                if (!_.isEqual(n,o)) {
                    this.fetchBlogs();
                }
            }
        }
    },
    methods: {
        imageExistsAt(index) {
            this.imageExists.splice(index, 1, true);
        },
        fetchBlogs() {
            let vm = this;
            axios.post('/blog-list/recent', {tags: this.tags || []})
                .then(res => {
                    for (var i = 0; i < res.data.blogs.length; i++) vm.imageExists.push(false); // Set up image array first
                    vm.blogs = res.data.blogs;
                })
                .catch(err => console.error(err))
        }
    },
    mounted() {
        this.fetchBlogs();
    }
}
</script>

<style lang="scss" scoped>
    .recent-blogs {
        margin: 2rem 0;
    }

    .blogs-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.5rem;

        .blog {
            margin: 0.5rem;
            flex-grow: 1;
            flex-basis: 0;
            min-width: min(100% - 1rem,300px);
            max-width: calc(100% - 1rem);
            display: flex;
            position: relative;
            flex-wrap: wrap;
            background-color: #fff;
            box-shadow: 0 10px 10px rgba(0,0,0,.1);

            .image-wrapper {
                position: relative;
                min-height: 200px;
                flex-basis: 40%;
                flex-grow: 1;
                min-width: 200px;

                img {
                    position: absolute;
                    height: 100% !important;
                    width: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }

            .content-wrapper {
                flex-basis: calc(60% - 1rem);
                flex-grow: 1;
                margin: 0.5rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 1rem;
                max-width: calc(100% - 1rem);
                height: 250px;

                .title {
                    margin: 0 0 1rem;
                }

                .content {
                    flex-grow: 1;
                    flex-basis: 100px;
                    overflow: hidden;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 50px;
                        background-image: linear-gradient(to top, white, transparent);
                    }
                }

                a { margin-top: 1rem; }
            }
        }
    }
</style>