<template>
	<div class="modal fade show" data-js-id="session-expiration-modal" role="dialog">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title d-flex align-items-center"><span class="fas fa-clock mr-1 text-default"></span>Are you still there?</h5>
				</div>
				<div class="modal-body">
                    <div class="alert alert-danger p-1">
                        <p class="m-0 text-center">You have been inactive for {{ inactivityThreshold }} minutes and will be logged out in</p>
                        <p class="h2 m-0 font-weight-bold text-center lh-xs">{{ this.timeRemaining }}</p>
                    </div>
				</div>
				<div class="modal-footer justify-content-end">
					<button type="button" class="btn btn-outline-primary mr-2" @click="logoutUser()">Log Out</button>
					<button type="button" class="btn btn-primary" data-dismiss="modal" @click="keepUserLoggedIn()">Stay Signed In</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    props: [
        'timeout'
    ],
    data() {
        return {
            inactivityThreshold: this.timeout, // in minutes
            timeGiven: 5, // in minutes
            timeRemaining: '',
            countdownTime: null
        }
    },
    computed: {
        modal() {
            return document.querySelector('[data-js-id="session-expiration-modal"]');
        }
    },
    methods: {
        startCounter() {
            const vm = this;
            window.vpanel_session_timeout_counter = setInterval(() => {
                let countdownTo = vm.countdownTime;

                if (!countdownTo) {
                    countdownTo = new Date().getTime() + (vm.timeGiven * 60000);
                    vm.countdownTime = countdownTo;
                }

                let now = new Date().getTime();
                let distance = countdownTo - now;
                let minutes = Math.floor(distance / 60000);
                let seconds = Math.floor((distance - (minutes * 60000)) / 1000)
                seconds = seconds < 10 ? '0' + seconds : seconds;

                if (distance > 0) {
                    this.timeRemaining = minutes + ':' + seconds;
                } else {
                    this.logoutUser()
                }
            }, 1000);
        },
        startSessionCheck() {
            //Get milliseconds until warning display
            let minutesToWarning = (this.inactivityThreshold) * 60000;
            const vm = this;

            return setTimeout(() => {
                //Make the call to check if user is still active
                axios.post('/vpanel/validate-user-session')
                    .then(res => {
                        const data = res.data;

                        if (data.session_invalid && data.message == 'invalid_user') {
                            axios.post('/vpanel/logout')
                                .then(res => {
                                    window.location.href = '/vpanel';
                                });
                        } else if (data.session_invalid && data.message == 'cache_empty') {
                            vm.startCounter();
                            vm.showModal();
                        } else {
                            let lastTime = moment(data.last_time);
                            let currentTime = moment();

                            this.inactivityThreshold = this.timeout - currentTime.diff(lastTime, 'minutes')

                            vm.startSessionCheck();
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        logApplicationError(err)
                    })
            }, minutesToWarning)
        },
        showModal() {
            let modals  = document.querySelectorAll('.modal');

            //Hide all other modals
            modals.forEach(modal => {
                $(modal).modal('hide');
            });

            $(this.modal).modal({
                backdrop: 'static',
                keyboard: false
            });
        },
        logoutUser() {
            this.modal.querySelector('.modal-body .alert-danger').innerHTML = '<p class="m-0 text-center">Logging you out...</p>';
            this.hideButtons();

            axios.post('/vpanel/logout')
                .then(res => {
                    window.location.href = '/vpanel';
                });
        },
        keepUserLoggedIn() {
            clearInterval(window.vpanel_session_timeout_counter);
            this.timeRemaining = "";
            this.countdownTime = null;

            $(this.modal).modal('hide');

            const vm = this;

            axios.get('/vpanel/renew-session')
                .then(res => {
                   vm.startSessionCheck();
                });
        },
        hideButtons() {
            let footerButtons = this.modal.querySelectorAll('.modal-footer button');

            if (footerButtons) {
                for (let index = 0; index < footerButtons.length; index++) {
                    const button = footerButtons[index];
                    button.classList.add('d-none');
                }
            }
        }
    },
    mounted() {
        this.startSessionCheck();
    }
}
</script>

<style>
</style>
