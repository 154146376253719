<template>
    <div class="product-option-section">
        <div class="mb-2">
            <VpanelEcommAddNewRecordButton namespace="productOption/addModify" button-text="Add a New Option" />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Product Option Table -->
        <VpanelProductOptionTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />

        <!-- Add/Modify Modal -->
        <VpanelEcommAddModifyModal
            section-name="Option"
            modal-icon="fas fa-sitemap"
            namespace="productOption/addModify"
            form-id="product-option-add-modify-form"
            form-name="vpanel-add-modify-product-option-form"
        />

        <!-- Delete Option Modal -->
        <VpanelEcommConfirmRecycleModal
            namespace="productOption"
            modal-id="product-option-recycle-modal"
            heading-text="Confirm Delete Product Option"
            ajax-route="/vpanel/modules/ecommerce/product/option/recycle"
            after-recycle-dispatch-action="productOption/list/refreshTableData"
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "VpanelProductOptionList",
        computed: {
            ...mapGetters('productOption/list', {
                tableData: 'getTableData',
            })
        }
    }
</script>