<template>
	<div class="datatable-section p-2">
		<div class="mb-2">
            <VpanelBlogAddNewRecordButton namespace="comment/addModify" button-text="Add a New Comment" />
		</div>

        <VpanelBlogCommentList />

		<VpanelAddModifyCommentModal :id="$route.params.id" />

		<!-- Delete Blog Comment Modal -->
        <VpanelBlogConfirmRecycleModal
            namespace="comment"
            modal-id="blog-comment-recycle-modal"
            heading-text="Confirm Delete Blog Comment"
            ajax-route="/vpanel/modules/blog/comment/destroy"
            after-recycle-dispatch-action="comment/list/removeRecordFromDataTable"
        >
            <template #modalContent>
                <p class="h4 font-weight-bold">Are you sure you want to delete this?</p>
                <p class="text-center m-0">This action is permanent. You will not be able to restore this comment.</p>
                <p class="text-center">Consider marking the comment as declined instead.</p>
            </template>
        </VpanelBlogConfirmRecycleModal>
	</div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        name: "VpanelBlogCommentIndex",
        created() {
            if (this.$route.params.id) {
                this.fetchCommentsByPostId(this.$route.params.id);
            } else {
                this.fetchComments();
            }

            this.fetchBlogs(false);
        },
        methods: {
            ...mapActions({
                fetchBlogs: "blog/list/fetchBlog",
                fetchComments: "comment/list/fetchComments",
                fetchCommentsByPostId : "comment/list/fetchCommentsByPostId"
            })
        }
    };
</script>