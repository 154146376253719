<template>
    <div class="row">
        <div class="col">
            <form id="asset-add-modify-form" class="asset-form needs-validation" name="asset-add-modify-form" novalidate @submit.prevent="submitAssetForm">
                <div class="form-errors"></div>
                <template v-if="isForbinAdmin">
                    <div class="form-errors-detailed"></div>
                </template>

                <input type="hidden" name="_token" :value="activeRecord._token" />
                <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />
                <input type="hidden" name="asset_type_id" :value="activeRecord.asset_type_id" />

                <div class="row">
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="asset_name">Asset Name</label>
                            <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title="The name of the asset."><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            class="form-control"
                            type="text"
                            name="name"
                            required
                            id="asset_name"
                            title="Please enter asset name"
                            v-model="activeRecord.name"
                            data-invalid-feedback="Please enter an asset name."
                        >
                        <div class="invalid-feedback">
                            Please enter an asset name.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="asset_value">Value</label>
                            <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title="Add your code, content or source location here."><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <textarea
                            class="form-control"
                            name="value"
                            id="asset_value"
                            title="Please enter asset value"
                            autocomplete="off"
                            required
                            v-model="activeRecord.value"
                            data-invalid-feedback="Please enter an asset value."
                        ></textarea>
                        <div class="invalid-feedback">
                            Please enter an asset value.
                        </div>
                    </div>
                </div>
                <div class="row" v-if="activeRecord.asset_type_name == 'Module'">
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="asset_data">Data</label>
                            <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title='Needs to be a json object for the different pieces of data you want to pass into the view. Ex: {"key1" : "value1", "key2" : "value2"}'><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <textarea
                            class="form-control"
                            name="data"
                            id="asset_data"
                            title="Please enter asset value"
                            autocomplete="off"
                            v-model="activeRecord.data"
                        ></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label for="asset_description" class="required">Description</label>
                            <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title="Consider explaining how this can be uniquely identified by others."><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <textarea
                            class="form-control"
                            name="description"
                            id="asset_description"
                            title="Please enter asset description"
                            autocomplete="off"
                            required
                            v-model="activeRecord.description"
                            data-invalid-feedback="Please enter an asset description."
                        ></textarea>
                        <div class="invalid-feedback">
                            Please enter an asset description.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="required">Is Asset Active?</span>
                            <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title="If the asset is marked inactive it will no longer be usable on pages"><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Is Asset Active?</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="asset_active_yes">
                                    <input
                                        class="form-check-input"
                                        required
                                        checked
                                        type="radio"
                                        title="Show page in navigation"
                                        name="status"
                                        id="asset_active_yes"
                                        :value="1"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the asset should be active."
                                    >
                                    <span class="form-check-sign"></span>
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="asset_active_no">
                                    <input
                                        class="form-check-input"
                                        required
                                        type="radio"
                                        title="Don't show page in navigation"
                                        name="status"
                                        id="asset_active_no"
                                        :value="0"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the asset should be active."
                                    >
                                    <span class="form-check-sign"></span>
                                    No
                                    <div class="invalid-feedback">
                                        Please indicate if the asset should be active.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </div>

        <used-on-pages-list
            :is-action-add="isActionAdd"
            :active-record="activeRecord"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from "vuex";
    export default {
        name: 'AssetAddModifyForm',
        props: [
            'isActionAdd',
            'activeRecord',
            'isFormProcessing'
        ],
        computed: {
            ...mapState('assetManager', {
                isForbinAdmin: state => state.isForbinAdmin,
            }),
        },
        methods: {
            ...mapActions('assetManager/addModifyAsset', [
                'submitAssetForm'
            ]),
            closeModal(){
                this.$emit('close-asset-modal');
            }
        }
    }
</script>