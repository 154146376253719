<template>
    <VueConfirmationModal
        :modal-id="modalId"
        @confirmed-modal="recycle"
        :heading-text="headingText"
        :namespace="`${namespace}/recycle`"
    />
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelEcommConfirmRecycleModal',
        props: [
            'modalId',
            'namespace',
            'ajaxRoute',
            'headingText',
            'afterRecycleDispatchAction'
        ],
        beforeCreate(){
            let storeNamespace = this.$options.propsData.namespace;

            this.$options.computed = {
                ...mapState(storeNamespace, {
                    activeRecord: state => state.addModify.activeRecord,
                })
            }

            this.$options.methods = {
                ...mapActions(storeNamespace + '/recycle', [
                    'recycleRecord',
                ]),
                recycle(){
                    this.recycleRecord({ ajaxRoute: this.ajaxRoute, recordId: this.activeRecord.id, dispatchAction: this.afterRecycleDispatchAction });
                }
            }
        }
    }
</script>