<template>
    <form id="product-hcpcs-code-add-form" class="needs-validation" name="product-hcpcs-code-add-form" novalidate @submit.prevent="submitProductHcpcsCodeForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="d-flex flex-column align-items-stretch">
                <div class="row">
                    <div class="col-12 form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="product_hcpcs_code">Name</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The name of the product tag"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            required
                            type="text"
                            name="name"
                            class="form-control"
                            id="product_hcpcs_code"
                            v-model="activeRecord.name"
                            title="Please enter an hcpcs code"
                            data-invalid-feedback="Please enter an hcpcs code."
                        />
                        <div class="invalid-feedback">
                            Please enter a hcpcs code.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    export default {
        name: 'VpanelAddProductHcpcsCodeForm',
        props: [
            'activeRecord',
            'errorMessage',
        ],
        methods: {
            submitProductHcpcsCodeForm(){
                let addCommit = 'productHcpcsCode/list/ADD_RECORD_TO_HCPCS_CODE_LIST';
                let addRoute  = '/vpanel/modules/ecommerce/product/hcpcs-code/store';

                this.$emit('submit-ecomm-form', {addModifyRoute: addRoute, addModifyCommit: addCommit, dataTableId: null  });
            },
            closeModal(){
                this.$emit('close-ecomm-modal');
            }
        }
    }
</script>