<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-filters d-flex flex-wrap justify-content-end mx-n1">
            <button class="btn btn-primary flex-shrink-0 m-1" @click.prevent="filterBy('asset_type_name','Module')">{{ isActiveFilter('asset_type_name','Module') ? 'Hide' : 'Show' }} Modules</button>
            <button class="btn btn-primary flex-shrink-0 m-1" @click.prevent="filterBy('id', selectedRows)">{{ isActiveFilter('id', selectedRows) ? 'Hide' : 'Show' }} Selected</button>
            <select class="form-control w-auto m-1" @change="filterBy('asset_type_name',$event.target.value)" :value="filteredProp == 'asset_type_name' ? filteredValue : ''">
                <option value="" selected hidden></option>
                <option v-for="filter in filterSelectValues" :key="'filter'+filter" :value="filter">{{ filter }}</option>
            </select>
            <button class="btn btn-outline-primary flex-shrink-0 m-1" @click.prevent="resetAll()">Reset Filters</button>
        </div>

        <div class="st-hidden-sorts">
            <p @click="sortBy('name')" :class="sortClass('name')">Name &amp; Description</p>
            <p @click="sortBy('shortcut_key')" :class="sortClass('shortcut_key')" >Shortcut Key</p>
            <p @click="sortBy('asset_type_name')" :class="sortClass('asset_type_name')">Type</p>
            <p @click="sortBy('status', 'number')" :class="sortClass('status')">Status</p>
        </div>

        <div class="simple-table" style="--cols:7;">
            <div class="first-col st-header">
                <label class="m-0">
                    <span v-show="selectedStatus('id') === -1"><span class="far fa-square"></span></span>
                    <span v-show="selectedStatus('id') === 0"><span class="fas fa-minus-square"></span></span>
                    <span v-show="selectedStatus('id') === 1"><span class="fas fa-check-square"></span></span>
                    <input
                        class="visually-hidden"
                        type="checkbox"
                        @change.prevent="selectAll('id')"
                        :checked="selectedStatus('id') >= 0"
                    />
                </label>
            </div>

            <p class="st-header" @click="sortBy('name')" :class="sortClass('name')">Name &amp; Description</p>
            <p class="st-header" @click="sortBy('shortcut_key')" :class="sortClass('shortcut_key')" >Shortcut Key</p>
            <p class="st-header" @click="sortBy('asset_type_name')" :class="sortClass('asset_type_name')">Type</p>
            <p class="st-header" @click="sortBy('status', 'number')" :class="sortClass('status')">Status</p>
            <p class="st-header">Used On</p>
            <p class="last-col st-header">Options</p>

            <template v-for="(row) in displayedList">
                <div class="first-col" :key="row.id+'select'">
                    <label class="m-0">
                        <span v-show="!isRowSelected(row.id)"><span class="far fa-square"></span></span>
                        <span v-show="isRowSelected(row.id)"><span class="fas fa-check-square"></span></span>
                        <input
                            class="visually-hidden"
                            type="checkbox"
                            name="selectedRows[]"
                            @change.prevent="selectRow(row.id)"
                            :checked="isRowSelected(row.id)"
                            :value="row.id"
                        />
                    </label>
                </div>

                <div class="d-block" :key="row.id+'name'">
                    <span class="st-hidden">Name &amp; Description</span>
                    <p class="font-weight-bold mb-1 text-16">{{ row.name }}</p>
                    <p class="m-0">{{ row.description }}</p>
                </div>

                <p :key="row.id+'key'">
                    <span class="st-hidden">Shortcut Key</span>
                    {{ row.shortcut_key }}
                </p>

                <p :key="row.id+'type'">
                    <span class="st-hidden">Type</span>
                    {{ row.asset_type_name }}
                </p>

                <div :key="row.id+'status'">
                    <span class="st-hidden">Status</span>
                    <p class="m-0" :class="row.status?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                </div>

                <div :key="row.id+'used_on'">
                    <span class="st-hidden">Used On</span>
                    <p class="m-0" v-html="pages(row)"></p>
                </div>

                <div class="last-col" :key="row.id+'options'">
                    <span class="st-hidden">Options</span>
                    <div class="st-buttons" v-if="showButtonsInRow(row)">
                        <button @click="setActiveDropdown(row.id)" type="button" class="btn btn-secondary">{{ isDropdownActive(row.id) ? 'Collapse' : 'Expand' }}</button>
                        <button @click="clickModify(row.id)" type="button" class="btn btn-primary">Modify</button>
                        <button @click="clickRecycle(row.id)" type="button" class="btn btn-outline-primary">Recycle</button>
                    </div>
                </div>

                <ExampleDropdown
                    :key="row.id+'dropdown'"
                    v-bind="dropdownBinder(row,row.id)"
                />
            </template>
        </div>
    </TableWrapper>
</template>

<script>
import tableMixin from '../../../../../Core/Global/Components/VueTable/mixins/tableMixin';
import { mapActions } from 'vuex';

export default {
    mixins: [tableMixin],
    props: ['isForbinAdmin'], // Not required, just adding for buttons
    methods: {
        ...mapActions('assetManager/list', [
            'clickRecycle',
            'clickModify',
        ]),
        status(row) {
            return row.status ? 'Active' : 'Inactive';
        },
        pages(row) {
            return row.pages.map(p=> `<a href="${location.origin + p.url}" target="_blank">${p.title}</a>`).join(', ');
        },
        showButtonsInRow(row) {
            let isAdmin = this.isForbinAdmin,
                inAcceptedTypes = ['Variable Data', 'HTML Widget'].some(t=>t==row.asset_type_name);

            return !!(isAdmin||inAcceptedTypes);
        },
    },
    computed: {
        filterSelectValues() {
            return [...new Set(this.dataFormatted.map(r=>r.asset_type_name))].sort();
        }
    }
}
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: minmax(min-content, max-content) repeat(calc(var(--cols) - 2), minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>