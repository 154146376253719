<template>
    <!-- Product Unit of Measure -->
    <div class="form-group has-label">
        <div class="d-flex justify-content-between align-items-flex-start">
            <label :for="`product_uom_${inputIndex}`">Unit of Measure</label>
            <div class="d-flex align-items-center flex-wrap justify-content-end mx-n1">
                <span class="text-secondary text-14 text-underline cursor-pointer mx-1 text-right" @click="openUomModal">
                    <span class="fas fa-plus-circle mr-1"></span>Add New
                </span>
                <a
                    href="#"
                    class="mx-1"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="Select a unit of measure or provide a new one (add new.)"
                >
                    <span class="fas fa-question-circle text-info cursor-pointer"></span>
                </a>
            </div>
        </div>
        <select
            name="product_uom"
            :value="inputValue"
            class="form-control"
            @change="$emit('update')"
            :id="`product_uom_${inputIndex}`"
            title="Select an unit of measure for this product"
        >
            <option value="" selected disabled>Select Unit of Measure</option>
            <option
                v-for="(uomId, uomName) in uoms"
                :key="uomId"
                :value="uomId"
                :selected="inputValue == uomId"
            >{{ uomName }}</option>
        </select>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'VpanelEcommUomInput',
        props: [
            'inputValue',
            'inputIndex'
        ],
        computed: {
            ...mapState('productUom/list', {
                uoms: state => state.allData,
            })
        },
        methods: {
            ...mapActions('productUom/addModify', {
                openUomModal: 'openModal',
            })
        }
    }
</script>