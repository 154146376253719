<template>
    <div class="location-module-section">
        <LocationSecondaryNav />

        <router-view :key="$route.fullPath" />
    </div>
</template>
<script>
    export default {
        name: "VpanelLocationModuleIndex"
    }
</script>