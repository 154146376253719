<template>
    <div class="card">
        <div class="parent-product">
            <!-- Image Div -->
            <VpanelEcommImageDiv :image="productImage" image-wrapper-width="width:80px;" />

            <div class="product-details">
                <p class="name">{{ productName }}</p>
                <RouterLink
                    exact
                    :to="{ name: 'Modify Product', params: { id: productId }}"
                    :title="`Internal link that takes you back to the product modify form of ${productName}`"
                >Back to product</RouterLink>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "VpanelBackToModifyProductCard",
        props: [
            'productId'
        ],
        computed: {
            ...mapGetters('productVariant', {
                productName: 'list/getProductName',
                productImage: 'list/getProductImage'
            })
        },
    }
</script>

<style lang="scss" scoped>
.parent-product {
    display: flex;
    padding: 1rem;

    .name {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }
}
</style>