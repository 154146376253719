<template>
    <router-view :key="$route.fullPath" :is-forbin-admin="isForbinAdmin" />
</template>
<script>
    export default {
        name: "VpanelFormsMainContentIndex",
        props: [
            'isForbinAdmin'
        ]
    }
</script>