<template>
    <div class="order-section">
        <!-- Table Loader -->
        <VueLoader v-if="tableData.isLoading" />

        <!-- Order Table -->
        <VpanelOrderTable
            v-else
            respond-at="md"
            :items-per-page="10"
            :searchable-data="[]"
            :all-table-info="tableData"
            :data="tableData.displayedList"
        />

        <!-- Delete Order Modal -->
        <VpanelEcommConfirmRecycleModal
            namespace="order"
            modal-id="order-recycle-modal"
            heading-text="Confirm Delete Order"
            ajax-route="/vpanel/modules/ecommerce/order/recycle"
            after-recycle-dispatch-action="order/list/refreshTableData"
        >
            <template #modalContent>
                <p class="h4 font-weight-bold">Are you sure you want to delete this?</p>
                <p class="text-center m-0">This action is permanent. You will not be able to restore this order. </p>
            </template>
        </VpanelEcommConfirmRecycleModal>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "VpanelOrderList",
        computed: {
            ...mapGetters('order/list', {
                tableData: 'getTableData'
            })
        }
    }
</script>