<template>
    <p class="hour-overview">
        <span class="title">{{ hours.title }}:</span>
        {{ shortHours }}
    </p>
</template>

<script>
export default {
    props: ["hours"],
    data() {
        let now = new Date(),
            daysOfWeek = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
            op = this.hours[daysOfWeek[now.getDay()].toLowerCase() + '_open'].split(":"),
            cl = this.hours[daysOfWeek[now.getDay()].toLowerCase() + '_close'].split(":");

        return {
            daysOfWeek: daysOfWeek,
            now: now,
            openDate: new Date( now.getFullYear(), now.getMonth(), now.getDate(), parseInt(op[0]), parseInt(op[1]), parseInt(op[2]) ),
            closeDate: new Date( now.getFullYear(), now.getMonth(), now.getDate(), parseInt(cl[0]), parseInt(cl[1]), parseInt(cl[2]) ),
        }
    },
    computed: {
        today() {
            return this.daysOfWeek[this.now.getDay()];
        },
        openToday() {
            return !this.hours[`${this.today.toLowerCase()}_closed`];
        },
        openNow() {
            return (this.now >= this.openDate && this.now < this.closeDate);
        },
        openSoon() {
            return (this.openToday && !this.openNow && this.now < this.openDate);
        },
        daysStartingTomorrow() {
            let index = this.daysOfWeek.indexOf(this.today);
            index = index < 6 ? index++ : 0;
            return this.daysOfWeek
                       // Cuts off days before today
                       .slice(index)
                       // and appends them to the end of the array
                       .concat(this.daysOfWeek.slice(0, index))
        },
        nextOpen() {
            return this.daysStartingTomorrow.reduce((a,c) => {
                // if nothing is set and it is closed on this day, return nothing and go to next day
                // if something is set already use that (get first), otherwise check if closed and repeat
                return (a || this.hours[`${c.toLowerCase()}_closed`]) ? a : c;
            }, '');
        },
        shortHours() {
            if (this.openToday && this.openNow) return `Open today until ${this.formatTime(this.hours[this.today.toLowerCase() + '_close'])}`;
            if (this.openSoon) return `Opens today at ${this.formatTime(this.hours[this.today.toLowerCase() + '_open'])}`;
            if (this.nextOpen) return `Opens ${this.nextOpen} at ${this.formatTime(this.hours[this.nextOpen.toLowerCase() + '_open'])}`
            return 'No regular business hours';
        }
    },
    methods: {
        formatTime(time) {
            let timeArr = time.split(":");
            if (timeArr[0] > 12) return `${timeArr[0] - 12}:${timeArr[1]} PM`;
            return `${timeArr[0].replace(/^0/, '')}:${timeArr[1]} AM`;
        }
    }
}
</script>

<style lang="scss" scoped>
    .hour-overview {
        color: hsl(0deg,0%,25%);

        .title { font-weight: bold; }
    }
</style>