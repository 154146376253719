<template>
    <div class="d-flex flex-column p-4 flex-grow-1">
        <div class="border-bottom">
            <p class="h5">
                <span class="fas fa-map-marker-alt mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}
            </p>
        </div>
        <div>
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                v-if="!isActionAdd"
                :status="getCurrentLocationStatus"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <!--Form Navigation-->
            <VpanelAddModifyLocationFormNav />

            <form class="location-add-modify-form needs-validation" name="location-add-modify-form" id="location-add-modify-form" novalidate @submit.prevent="submitLocationForm">
                <!-- Error Messages -->
                <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>
                <!-- Tabbed Content -->
                <div class="tab-content" id="add-modify-tab-content">
                    <div :class="{'show active': activeNavTab == 'add-modify-general-details-tab'}" class="tab-pane fade" id="add-modify-general-details" role="tab-panel" aria-labelledby="add-modify-general-details-tab">
                        <location-general-details-form
                            :active-record="activeRecord"
                            :all-location-types="allTypes"
                            :all-location-services="allServices"
                            :are-services-enabled="servicesEnabled"
                        />
                    </div>
                    <div :class="{'show active': activeNavTab == 'add-modify-contact-info-tab'}" class="tab-pane fade" id="add-modify-contact-info" role="tab-panel" aria-labelledby="add-modify-contact-info-tab">
                        <location-contact-info-form
                            :active-record="activeRecord"
                        />
                    </div>
                    <div :class="{'show active': activeNavTab == 'add-modify-hours-tab'}" class="tab-pane fade" id="add-modify-hours" role="tab-panel" aria-labelledby="add-modify-hours-tab">
                        <location-hours-form
                            :active-record="activeRecord"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary my-0 save-button">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'AddModifyLocationPage',
        computed: {
            ...mapState({
                formId: state => state.location.addModify.formId,
                allTypes: state => state.locationType.list.tableData,
                allServices: state => state.locationService.list.tableData,
                activeRecord: state => state.location.addModify.activeRecord,
                errorMessage: state => state.location.addModify.errorMessage,
                activeNavTab: state => state.location.addModify.activeFormTab,
                isActionAdd: state => state.location.addModify.formActionIsAdd,
                servicesEnabled: state => parseInt(state.location.moduleConfigs.ES),
                isFormProcessing: state => state.location.addModify.isFormProcessing,
            }),
            getModalHeader(){
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';

                return action + 'Location';
            },
            getCurrentLocationStatus(){
                return this.activeRecord.status ? 'Active' : 'Inactive';
            }
        },
        methods: {
            ...mapActions('location/addModify', [
                'submitForm',
                'checkIfPageIsValid'
            ]),
            submitLocationForm(){
                let addDispatch     = this.isActionAdd ? 'location/addModify/setActiveRecord' : null;
                let formAction      = this.isActionAdd ? 'store' : 'update';
                let addModifyRoute  = `/vpanel/modules/location/${formAction}`
                let routerPath      = this.isActionAdd ? 'Modify Location' : 'Location List';

                this.checkIfPageIsValid();

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    routerPath: routerPath,
                    isUsingModal: false,
                    dispatchPath: addDispatch
                });
            }
        }
    }
</script>