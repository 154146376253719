<template>
    <VpanelModuleConfigList
        :module-configs="moduleSettings"
        @submit-form="submitSettingsForm"
        @update-config-value="updateModuleSetting"
        form-id="update-marketing-message-module-setting"
    />
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: "VpanelMarketingMessageSettingList",
        computed: {
            ...mapState({
                moduleSettings: state => state.moduleSetting.list.data,
                activeRecord: state => state.moduleSetting.modify.activeRecord.config
            })
        },
        methods: {
            ...mapActions({
                submitForm: 'moduleSetting/modify/submitForm',
                fetchModuleConfigData: 'marketingMessage/fetchModuleConfigData'
            }),
            updateModuleSetting(payload){
                Vue.set(this.activeRecord, payload.config.id, payload.value);
            },
            submitSettingsForm(){
                this.submitForm({ ajaxRoute: '/vpanel/module-config/update'}).then(record => {
                    this.fetchModuleConfigData();
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .module-config-table { overflow: unset; }
</style>