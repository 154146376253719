<template>
    <button
        type="button"
        class="btn btn-danger m-1"
        @click="remove"
    >
        <span class="fas fa-trash-alt"></span>
    </button>
</template>
<script>
    export default {
        name: 'VpanelRemoveAdditionalItemButton',
        props: [
            'arrayCount'
        ],
        methods:{
            remove(){
                this.$emit('remove-additional-item');

                if(this.arrayCount == 1){
                    this.$emit('add-new-array-item');
                }
            }
        }
    }
</script>