<template>
    <form id="product-option-add-modify-form" class="needs-validation" name="product-option-add-modify-form" novalidate @submit.prevent="submitOptionForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="d-flex flex-column align-items-stretch">
                <div class="row">
                    <div class="col-12 form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="option_name">Name</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The name of the product option"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            required
                            type="text"
                            name="name"
                            id="option_name"
                            class="form-control"
                            v-model="activeRecord.name"
                            title="Please enter an option name"
                            data-invalid-feedback="Please enter an option name."
                        />
                        <div class="invalid-feedback">
                            Please enter a option name.
                        </div>
                    </div>
                    <div class="col-12 form-group px-2">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="required mr-3">Status</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="If the option is active it will show on the product form"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_yes">
                                    <input
                                        checked
                                        required
                                        :value="1"
                                        type="radio"
                                        name="status"
                                        id="status_yes"
                                        class="form-check-input"
                                        title="Please choose an option"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the option is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Active
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status_no">
                                    <input
                                        required
                                        :value="0"
                                        type="radio"
                                        name="status"
                                        id="status_no"
                                        class="form-check-input"
                                        title="Please choose a status"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please indicate if the option is active or inactive."
                                    />
                                    <span class="form-check-sign"></span>
                                    Inactive
                                    <div class="invalid-feedback">
                                        Please indicate if the option is active or inactive.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    export default {
        name: 'VpanelAddModifyProductOptionForm',
        props: [
            'isActionAdd',
            'activeRecord',
            'errorMessage',
        ],
        methods: {
            submitOptionForm(){
                let addModifyDispatch = 'productOption/list/refreshTableData';
                let addModifyRoute    = this.isActionAdd ? '/vpanel/modules/ecommerce/product/option/store' : '/vpanel/modules/ecommerce/product/option/update';

                this.$emit('submit-ecomm-form', {addModifyRoute: addModifyRoute, dispatchPath: addModifyDispatch });
            },
            closeModal(){
                this.$emit('close-ecomm-modal');
            }
        }
    }
</script>