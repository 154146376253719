<template>
    <div class="location-nav">
        <router-link
            exact
            :to="{ name: 'Location List'}"
            class="nav-link" active-class="current"
            title="Internal link that opens list of locations"
        >Locations</router-link>

        <router-link
            exact
            v-if="servicesEnabled"
            :to="{ name: 'Location Service List'}"
            class="nav-link" active-class="current"
            title="Internal link that opens list of location services"
        >Location Services</router-link>

        <router-link
            exact
            :to="{ name: 'Location Type List'}"
            class="nav-link" active-class="current"
            title="Internal link that opens list of location types"
        >Location Types</router-link>

        <router-link
            exact
            class="nav-link"
            active-class="current"
            :to="{ name: 'Location Setting List'}"
            title="Internal link that opens list of location module settings"
        >Settings</router-link>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        name: 'LocationSecondaryNav',
        computed: {
            ...mapState('location', {
                servicesEnabled: state => parseInt(state.moduleConfigs.ES)
            })
        }
    }
</script>