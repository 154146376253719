<template>
    <div class="product-option-wrapper">
        <p class="option-name">{{ name }}</p>
        <div class="product-option">
            <label
                v-for="(option, index) in options"
                :key="`option-${name}-${index}`"
                @click.prevent="!option.hidden ? updateRadio(option.value) : false"
                class="option-labels"
                :class="{'disabled':option.hidden}"
            >
                <input type="radio" :name="name" :value="option.value" :checked="value == option.value" :disabled="option.hidden"/>
                <span class="radio-button">{{ option.name }}</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: ["name", "options", "value"],
    methods: {
        updateRadio(val) {
            let result = this.value == val ? "" : val;
            this.$emit('input', result);
        }
    }
}
</script>

<style lang="scss" scoped>
    $themeColor-hsl: var(--themeColor-hsl, hsl(240deg,6%,37%));
    $themeCompliant-hsl: var(--themeCompliant-hsl, hsl(0deg, 0%, 100%));

    .product-option-wrapper {
        display: flex;
        align-items: stretch;
        margin: 4px;
    }

    .product-option {
        padding: 4px;
        border-radius: 0 5px 5px 0;
        background-color: hsl(0deg, 0%, 90%);
    }

    .option-name {
        margin: 0;
        font-weight: bold;
        font-size: 14px;
        padding: 4px 12px;
        line-height: 1.1;
        display: block;
        border: solid hsl(0deg, 0%, 90%);
        border-width: 1px 0 1px 1px;
        border-radius: 5px 0 0 5px;
        display: flex;
        align-items: center;
    }

    .option-labels {
        margin: 4px;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    .radio-button {
        padding: 4px 12px;
        display: block;
        border-radius: 5px;
        transition: 200ms ease-out;
        font-size: 13px;
        line-height: 1.1;
        background-color: white;
        cursor: pointer;

        input[disabled] + & {
            opacity: .5;
            pointer-events: none;
        }

        input:checked + & {
            background-color: $themeColor-hsl;
            border-color: $themeColor-hsl;
            color: $themeCompliant-hsl;
        }
    }
</style>