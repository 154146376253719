<template>
    <div class="card">
        <div class="card-header pb-2 d-flex justify-content-between align-items-center">
            <p class="h5 m-0">
                <span class="fab fa-youtube mr-1"></span>
                Videos
            </p>

            <button type="button" class="btn btn-secondary text-white" @click="addVideoUrl">
                <span class="fas fa-plus"></span>
            </button>
        </div>
        <div class="card-body">
            <div :key="index" v-for="(video, index) in productVideos">
                <div class="d-flex align-items-start mx-n1">
                    <!-- YouTube Sharing URL -->
                    <div class="form-group has-label m-1 flex-grow-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <label :for="`product_video_${index}`">YouTube Sharing URL</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Find the YouTube video you want to include, then copy and paste the “Share URL” from YouTube (click “share” located just under the video.)"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            :value="video"
                            class="form-control"
                            name="product_video"
                            :id="`product_video_${index}`"
                            :aria-label="`Add a video to this product ${index}`"
                            @input="updateProductValue({prop: 'videos', index: index})"
                        />
                    </div>

                    <!-- Remove Additional Video Button -->
                    <div>
                        <VpanelRemoveAdditionalItemButton
                            @add-new-array-item="addVideoUrl"
                            v-if="Object.keys(productVideos).length > 1"
                            :array-count="Object.keys(productVideos).length"
                            @remove-additional-item="removeAdditionalItem([index, 'videos'])"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'VpanelProductVideoForm',
        props: [
            'productVideos'
        ],
        created(){
            if(Object.keys(this.productVideos).length == 0){
                this.addVideoUrl();
            }
        },
        methods:{
            ...mapActions('product/addModify', [
                'updateProductValue',
                'removeAdditionalItem'
            ]),
            addVideoUrl(){
                let videos = this.productVideos;
                //will check if there are any empty product video values
                let shouldAddNewValue = Object.values(videos).every(video => video);

                if(shouldAddNewValue){
                    let length = Object.keys(videos).length;
                    //will add new object to array
                    this.updateProductValue({prop: 'videos', index: 'add' + length});
                } else {
                    //will show user a message if theres a video that doesn't have a value
                    showNotification('Make sure all video inputs are filled in, before adding a new one.', 'error');
                }
            },
        }
    }
</script>