export const loaderMixin = {
    template:  `<div class="loader">
                    <svg class="loader-circle" viewBox="25 25 50 50">
                        <title>Loading Circle</title>
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"/>
                    </svg>
                    <p class="loader-text">{{ loaderMessage }}</p>
                </div>`,
    props: {
        loaderMessage: {
            type: String,
            default: 'Loading...'
        }
    }
}