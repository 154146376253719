<template>
    <div class="staff-section p-3">
        <div class="mb-3">
            <VpanelStaffAddNewButton
                button-text="Add Staff"
                router-path="Add Staff"
                namespace="staff/addModify"
            />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="isTableLoading" />

        <!-- Staff Table -->
        <VpanelStaffTable
            v-else
            respond-at="md"
            :data="tableData"
            :items-per-page="10"
            :searchable-data="['full_name', 'position']"
        />

        <!-- Delete Staff Modal -->
        <VpanelStaffConfirmRecycleModal
            namespace="staff"
            modal-id="staff-recycle-modal"
            heading-text="Confirm Delete Staff Member"
            ajax-route="/vpanel/modules/staff/destroy"
            after-recycle-dispatch-action="staff/list/removeRecordFromDataTable"
        />

        <!-- Delete Staff Modal -->
        <VueConfirmationModal
            namespace="staff/recycle"
            modal-id="staff-recycle-modal"
            @confirmed-modal="deleteStaff"
            heading-text="Confirm Delete Staff Member"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelStaffList',
        computed: {
            ...mapState('staff/list', {
                tableData: state => state.tableData,
                isTableLoading: state => state.isTableLoading
            })
        },
        methods: {
            ...mapActions('staff/list', [
                'deleteRecord'
            ]),
            deleteStaff(){
                this.deleteRecord({namespace: 'staff', routeGroup: 'staff'});
            }
        }
    }
</script>