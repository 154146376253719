<template>
    <form id="form-submission-admin-form" class="form-submission-admin-form needs-validation" name="form-submission-admin-form" novalidate @submit.prevent="submitAdminForm">
        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="d-flex flex-column align-items-stretch">
                <div class="form-group">
                    <div class="radio-legend d-flex justify-content-between align-items-center">
                        <span class="required mr-3">Mark Submission As</span>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Mark this form submission as read and archive or uncheck to mark as unread and unarchive"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <fieldset>
                        <legend class="label sr-only">Mark Submission As</legend>
                        <div class="form-check form-check-inline" v-for="(status, index) in submissionStatuses" :key="index">
                            <label class="form-check-label" :for="`status_${status}_${index}`">
                                <input
                                    type="checkbox"
                                    :value="status"
                                    class="form-check-input"
                                    name="submission_status[]"
                                    title="Please choose an option"
                                    :id="`status_${status}_${index}`"
                                    @change="updateSubmissionValue(status, true)"
                                    :checked="status == 'Read' && submission.viewed || status == 'Archive' && submission.archived"
                                />
                                <span class="form-check-sign"></span>
                                {{ status }}
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div class="form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                            <label for="admin_comments">Note/Comment</label>
                        </div>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Add a note/comment to this form submission for other admins to read"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <textarea
                        rows="10"
                        maxlength="155"
                        id="admin_comments"
                        class="form-control"
                        name="admin_comments"
                        v-model="submission.admin_comments"
                        title="Please enter a note or comment"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-secondary-blue my-0 save-button">{{ isFormProcessing ? 'Processing...' : 'Update' }}</button>
        </div>
    </form>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'VpanelFormSubmissionAdminForm',
        props: [
            'submission'
        ],
        computed: {
            ...mapState('forms/submissions/modify', [
                'isFormProcessing'
            ]),
            submissionStatuses(){
                return ['Read', 'Archive'];
            }
        },
        methods: {
            ...mapActions('forms/submissions/modify', [
                'submitForm',
                'setValueInActiveRecord'
            ]),
            updateSubmissionValue(status, inputIsCheckbox){
                let inputValue = inputIsCheckbox ? event.target.checked : event.target.value;
                let prop       = status == 'Read' ? 'viewed' : 'archived'

                this.setValueInActiveRecord({prop: prop, value: inputValue});
            },
            submitAdminForm(){
                this.submitForm({
                    ajaxRoute: `/vpanel/modules/forms/submissions/update/${this.submission.id}`
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .form-submission-admin-form {
        border: 1px solid #eee;
        padding: 1rem;
        margin-top: 1rem;

        .form-check .form-check-sign:after {
            color: white;
        }
    }
</style>