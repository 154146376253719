<template>
    <TableWrapper
        :searchable="false"
        v-on="wrapperEvents"
        v-bind="wrapperBinder"
        :item-count="allTableInfo.itemCount"
        :active-page="allTableInfo.activePage"
        @update-page="allTableInfo.updateActivePage($event);setCurrentPageToSession($event);"
    >
        <!--Custom Table Search-->
        <VpanelEcommerceTableSearch namespace="order/list" @searched="updateTableDataAfterSearch" :search-model="allTableInfo.searchModel" />

        <div class="st-hidden-sorts">
            <p @click="allTableInfo.sortBy('order_number', null, 'number')" :class="allTableInfo.sortClass('order_number')">Order #</p>
            <p @click="allTableInfo.sortBy('customer:first_name')" :class="allTableInfo.sortClass('customer:first_name')">Customer</p>
            <p @click="allTableInfo.sortBy('total', null, 'number')" :class="allTableInfo.sortClass('total')">Amount</p>
            <p @click="allTableInfo.sortBy('status', null, 'number')" :class="allTableInfo.sortClass('status')">Status</p>
            <p @click="allTableInfo.sortBy('created_at', null, 'date')" :class="allTableInfo.sortClass('created_at')">Date Ordered</p>
        </div>
        <div class="simple-table" style="--cols:6;">
            <p class="first-col st-header" @click="allTableInfo.sortBy('order_number', null, 'number')" :class="allTableInfo.sortClass('order_number')">Order #</p>
            <p class="st-header" @click="allTableInfo.sortBy('customer:first_name')" :class="allTableInfo.sortClass('customer:first_name')">Customer</p>
            <p class="st-header" @click="allTableInfo.sortBy('total', null, 'number')" :class="allTableInfo.sortClass('total')">Amount</p>
            <p class="st-header" @click="allTableInfo.sortBy('status', null, 'number')" :class="allTableInfo.sortClass('status')">Status</p>
            <p class="st-header" @click="allTableInfo.sortBy('created_at', null, 'date')" :class="allTableInfo.sortClass('created_at')">Date Ordered</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <p class="first-col" :key="row.id+'order_number'">
                        <span class="st-hidden">Order #</span>
                        <strong>{{ row.order_number }}</strong>
                    </p>

                    <p :key="row.id+'name'">
                        <span class="st-hidden">Customer</span>
                        <strong>{{ row.name }}</strong>
                    </p>

                    <p :key="row.id+'total'">
                        <span class="st-hidden">Amount</span>
                        <strong>{{ row.total }}</strong>
                    </p>

                    <div :key="row.id+'status'">
                        <span class="st-hidden">Status</span>
                        <p class="m-0" :class="row.status?'text-success':'text-danger'"><strong>{{ status(row) }}</strong></p>
                    </div>

                    <p :key="row.id+'created_at'">
                        <span class="st-hidden">Date Ordered</span>
                        <strong>{{ row.created_at }}</strong>
                    </p>

                    <div class="last-col" :key="row.id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <button class="btn btn-primary m-1">Modify</button>
                            <button class="btn btn-secondary-blue m-1">Invoice</button>
                            <button type="button" class="btn btn-outline-primary" @click="recycleOrder(row.id)">Delete</button>
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelOrderTable',
        mixins: [ tableMixin ],
        props: [
            'allTableInfo',
        ],
        methods: {
            ...mapActions('order/list', [
                'recycleButtonAction',
                'setCurrentPageToSession',
                'updateTableDataAfterSearch'
            ]),
            status(row) {
                return row.status ? 'Active' : 'Inactive';
            },
            recycleOrder(orderId){
                this.recycleButtonAction({
                    recordId: orderId,
                    storeNamespace: 'order'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(5, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>