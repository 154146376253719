<template>
    <div class="featured_products_glide" v-if="products.length">
        <template v-if="products.length > 1">
            <div class="bullets" v-if="products.length < 13">
                <button
                    v-for="n in dotCount"
                    :key="'bullet' + (n - 1)"
                    class="bullet"
                    :class="{'bullet-active': slider.index == n - 1}"
                    @click="slider.go(`=${n - 1}`)"
                ></button>
            </div>
            <div class="arrows" data-glide-el="controls" v-else>
                <button class="prev" data-glide-dir="<"><span class="clip-arrow left"></span></button>
                <button class="next" data-glide-dir=">"><span class="clip-arrow right"></span></button>
            </div>
        </template>
		<div class="glide__track" data-glide-el="track">
			<div class="glide__slides">
                <div
                    class="glide__slide"
                    v-for="(product, index) in products"
					:key="`featured_product_${index}`"
                >
                    <SiteProductQuickViewCard :product="product" />
                </div>
			</div>
		</div>
	</div>
</template>

<script>
    import Glide from "@glidejs/glide";

    export default {
        name: 'SiteFeaturedProductSlider',
        props: [ 'products' ],
        data() {
            return {
                windowWidth: window.innerWidth,
                options: {
                    type: 'slider',
                    gap: 16,
                    bound: true,
                    perView: 5,
                    animationDuration: 200,
                    breakpoints: {
                        360: {
                            perView: 1
                        },
                        550: {
                            perView: 2
                        },
                        768: {
                            perView: 3
                        },
                        1024: {
                            perView: 4
                        },
                        2000: {
                            perView: 5
                        }
                    }
                },
                initSuccess: false,
                slider: {},
            }
        },
        computed: {
            dotCount() {
                let count = 0;
                let currentBreakPoint = Object.keys(this.options.breakpoints).reduce((a,c) => {
                    if (a == 0 && this.windowWidth <= parseInt(c)) a = parseInt(c);
                    return a;
                }, 0);

                count = this.products.length - ((currentBreakPoint ? this.options.breakpoints[currentBreakPoint].perView : this.options.perView) - 1);
                return count >= 1 ? count : 0;
            }
        },
        methods: {
            onResize() {
                setTimeout(() => {
                    this.windowWidth = window.innerWidth;
                    this.slider.update();
                }, 200);
            },
            init() {
                if (!this.initSuccess && document.querySelector('.featured_products_glide')) {
                    this.slider = new Glide('.featured_products_glide', this.options);
                    this.slider.mount();
                    this.initSuccess = true;

                    //disable the slider if only one slide
                    if (!(this.products.length > 1)) {
                        this.slider.disable();
                    }
                }
            }
        },
        updated() {
            this.init();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
        },
        mounted() {
            this.init();
            window.addEventListener('resize', this.onResize);
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../../../../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";

    .featured_products_glide {
        .glide__slides {
            padding-top: 1rem;
            padding-bottom: 1rem;
            align-items: stretch;
            margin: 0 auto;

            .glide__slide {
                height: auto;
            }
        }

        .bullets {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 -4px;

            button.bullet {
                margin: 4px;
                border: 2px solid white;
                outline: 0;
                height: 12px;
                width: 12px;
                padding: 0;
                background-color: transparent;
                transition: 200ms ease-out;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
                border-radius: 3px;

                &.bullet-active {
                    background-color: white;
                }
            }
        }

        .arrows {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0 -4px;

            button {
                margin: 4px;
                border: 2px solid white;
                outline: 0;
                padding: 0;
                background-color: var(--optimumOnWhite-hsl);
                transition: 200ms ease-out;
                filter: drop-shadow(0 0 10px rgba(0,0,0,0.1));
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;

                &:hover {
                    background-color: white;

                    .clip-arrow {
                        background-color: var(--optimumOnWhite-hsl);
                    }
                }
            }

            .clip-arrow {
                height: 12px;
                width: 6px;
                background-color: white;
                transition: 200ms ease-out;
            }
        }
    }
</style>