<script>
import { dataTableMixin } from "../Mixins/datatableMixin";

export default {
  mixins: [dataTableMixin],
}
</script>

<style>

</style>