<template>
    <div class="sct-value pr-2 sctv-color">
        <input
            type="color"
            :id="property"
            :name="name"
            title="Edit Color"
            :value="designOptions[property]"
            @input="updateValue(property)"
            class="form-control site-config-input"
        />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    props: ["property", "name"],
    computed: {
        ...mapState('siteConfig/design', {
            designOptions:   state => state.designOptions.theme,
        })
    },
    methods: {
        ...mapMutations('siteConfig/design', [
            'SET_VALUE_IN_DESIGN_OPTIONS'
        ]),
        updateValue(prop) {
            this.SET_VALUE_IN_DESIGN_OPTIONS([prop, event.target.value]);
        }
    }
}
</script>

<style>

</style>