<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p @click="sortBy('id', 'number')" :class="sortClass('id')">ID</p>
            <p @click="sortBy('name')" :class="sortClass('name')">Name</p>
            <p @click="sortBy('email')" :class="sortClass('email')">Type</p>
            <p @click="sortBy('created_at', 'date')" :class="sortClass('created_at')">Created At</p>
        </div>
        <div class="simple-table" style="--cols:5">
            <p class="first-col st-header" @click="sortBy('id', 'number')" :class="sortClass('id')">ID</p>
            <p class="st-header" @click="sortBy('name')" :class="sortClass('name')" >Name</p>
            <p class="st-header" @click="sortBy('email')" :class="sortClass('email')">Type</p>
            <p class="st-header" @click="sortBy('created_at', 'date')" :class="sortClass('created_at')">Created At</p>
            <p class="last-col st-header">Options</p>

            <template v-for="row in displayedList">
                <div class="first-col" :key="row.id+'id'">
                    <span class="st-hidden">ID</span>
                    {{ row.id }}
                </div>

                <div :key="row.id+'name'">
                    <span class="st-hidden">Name</span>
                    {{ row.name }}
                </div>

                <p :key="row.id+'email'">
                    <span class="st-hidden">Email</span>
                    {{ row.email }}
                </p>

                <p :key="row.id+'created_at'">
                    <span class="st-hidden">Created At</span>
                    {{ row.created_at }}
                </p>

                <div :key="row.id+'options'">
                    <span class="st-hidden">Options</span>
                    <div class="st-buttons">
                        <button class="btn btn-primary">Modify</button>
                        <button class="btn-outline-primary">Recycle</button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
/**
 * In vue 2 looping using a <template> element is kind of
 * annoying as you can't :key it, this is resolved in vue 3.
 * For now though you'll have to individually :key all direct
 * children of the looped <template>...which kind of stinks.
 *
 * Another limitation of using the css grid option and
 * not passing in a countable array of headers is that there
 * is no way for css to figure out how many columns there are.
 *
 * I've set up the table to accept a --cols custom css
 * property, which will set all columns to use a minimum width
 * of min-content and a max of auto if you don't care about
 * specific widths.
 *
 * There is another custom property --custom-col-structure
 * which can be used to manually set the col count and the
 * widths of all of the cols as well if you are looking for
 * some extra control.
 * */

import tableMixin from '../VueTable/mixins/tableMixin';

export default {
    mixins: [tableMixin],
    data() {
        return {
            // tableStuff: new DataSSFP('/asdfsadf', [])
        }
    },
    computed: {
        displayedList() {
            return this.tableStuff.displayedList
        }
    }
}
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(4, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>