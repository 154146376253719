<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-start">
            <p class="h5 mb-0 mr-3">
                <span class="fas fa-edit mr-1"></span>
                Variant Quick Edit
            </p>

            <!-- Add Additional Variants/Attributes Button -->
            <VpanelAddAdditionalInputButton
                @add-new-array-item="addProductAttribute"
            />
        </div>

        <div class="card-body pt-4">
            <div class="d-flex align-items-start" :key="attributeIndex" v-for="(variant, attributeIndex) in productAttributes">
                <div class="border mb-2 p-2 flex-grow-1">
                    <!-- Product Option Values Section -->
                    <VpanelProductOptionValuesFormSection
                        :product-attribute="variant"
                        @update="addProductOptionValues"
                        :attribute-index="attributeIndex"
                        :product-options="productOptions"
                    />

                    <!-- Product Attribute Section -->
                    <div class="row align-items-start">
                        <div class="col-lg-4">
                            <div class="d-flex flex-wrap flex-md-nowrap align-items-start mx-n2">
                                <!-- Attribute Store Price -->
                                <VpanelProductPriceAndWeightInput
                                    class="mx-2"
                                    :input="{
                                        'isRequired' : true,
                                        'label'      : 'store_price',
                                        'displayName': 'Store Price',
                                        'infoTitle'  : 'Enter the store price of this variant.'
                                    }"
                                    style="flex-grow:9999;"
                                    @update="updateVariantValue"
                                    :input-index="attributeIndex"
                                    :input-value="variant.store_price"
                                />
                                <!-- Attribute Qty -->
                                <div class="form-group has-label mx-2" style="width:calc(5ch + 2rem);flex-grow:1;">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <label :for="`product_attribute_qty_${attributeIndex}`">QTY</label>
                                        <a
                                            href="#"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            data-boundary="window"
                                            title="Provide the quantity available for this variant."
                                        >
                                            <span class="fas fa-question-circle text-info cursor-pointer"></span>
                                        </a>
                                    </div>
                                    <input
                                        type="number"
                                        class="form-control"
                                        :value="variant.qty"
                                        name="product_attribute_qty[]"
                                        title="Please enter the qty of the product"
                                        :id="`product_attribute_qty_${attributeIndex}`"
                                        @input="updateProductValue({ index: attributeIndex, prop: 'attributes', nestedIndex: 'qty', nested: true })"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Attribute SKU -->
                        <VpanelProductSkuInput
                            class="col-lg-4 mb-0"
                            :input-value="variant.sku"
                            :input-index="attributeIndex"
                            @update="updateProductValue({ index: attributeIndex, prop: 'attributes', nestedIndex: 'sku', nested: true })"
                        />

                        <!-- Product Unit of Measure Input -->
                        <VpanelEcommUomInput
                            :input-value="variant.uom"
                            :input-index="attributeIndex"
                            class="col-md-6 col-lg-4 mb-0"
                            @update="updateProductValue({ index: attributeIndex, prop: 'attributes', nestedIndex: 'uom', nested: true })"
                        />
                    </div>
                </div>
                <!-- Attribute Buttons -->
                <div class="ml-1 mr-n1 my-n1">
                    <!-- Modify Attribute/Variant Button -->
                    <button
                        type="button"
                        class="btn btn-secondary-blue m-1 d-block"
                        v-if="showVariantModifyButton(attributeIndex)"
                        @click.prevent="setActiveVariant([variant, true])"
                    >
                        <span class="fas fa-pencil-alt"></span>
                    </button>

                    <!-- Remove Attribute/Variant Button -->
                    <VpanelRemoveAdditionalItemButton
                        v-if="productAttributes.length > 1"
                        :array-count="productAttributes.length"
                        @add-new-array-item="addProductAttribute"
                        @remove-additional-item="recycleProductVariant(attributeIndex, variant)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: 'VpanelProductVariantPreviewForm',
        props: [
            'productOptions',
            'productAttributes'
        ],
        computed: {
            ...mapGetters('product/addModify', {
                formatPrice: 'getMoneyFormat'
            })
        },
        methods: {
            ...mapActions({
                updateProductValue: 'product/addModify/updateProductValue',
                addProductAttribute: 'product/addModify/addProductAttribute',
                setActiveVariant: 'productVariant/addModify/setActiveVariant',
                removeAdditionalItem: 'product/addModify/removeAdditionalItem',
                openConfirmDeleteVariantModal: 'productVariant/recycle/openModal',
                addProductOptionValues: 'product/addModify/addProductOptionValues',
            }),
            showVariantModifyButton(attributeIndex){
                return Object.keys(this.productAttributes[attributeIndex].option_values).length && this.productAttributes[attributeIndex]?.is_variant  ? true : false;
            },
            updateVariantValue(nestedIndex, inputValue, attributeIndex){
                this.updateProductValue({
                    index: attributeIndex,
                    value: inputValue,
                    prop: 'attributes',
                    nestedIndex: nestedIndex,
                    nested: true
                });
            },
            recycleProductVariant(attributeIndex, variant){
                if(!variant.id){
                    this.removeAdditionalItem([attributeIndex, 'attributes']);
                } else {
                    //sets the variant clicked as the activerecord
                    this.setActiveVariant([variant, false]);
                    //opens confirm delete modal
                    this.openConfirmDeleteVariantModal();
                }
            }
        }
    }
</script>