<template>
    <p
        v-bind="assistiveBinder"
        v-if="((showFormat) || !format) && message"
    >{{ message }}</p>
</template>

<script>
export default {
    name: "SiteFormBuilderAssistiveText",
    props: {
        formatDescription: { type: String },
        format: {
            type: Boolean,
            default: false
        },
        message: { type: String }
    },
    computed: {
        showFormat() {
            return this.format && this.message;
        },
        assistiveBinder() {
            let res = {
                class: ['fb-assistive']
            };

            if (this.showFormat) res.class.push('format');
            if (this.formatDescription) res['aria-label'] = this.formatDescription;

            return res;
        }
    }
}
</script>