<template>
    <div class="p-3">
        <div class="row">
            <div class="col-md-4 col-lg-3">
                <VpanelBackToModifyProductCard :product-id="id" />

                <VpanelProductVariantList />
            </div>
            <div class="col-md-8 col-lg-9">
                <RouterView :key="$route.fullPath" v-if="Object.keys(activeRecord).length > 0" />

                <div class="alert alert-info" v-else>
                    <p class="mb-0">Select a product variant from the list.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "VpanelProductVariantIndex",
        props: [
            'id'
        ],
        computed: {
            ...mapGetters('productVariant/addModify', {
                activeRecord: 'getActiveRecord'
            })
        }
    }
</script>