<template>
    <component
        :is="templateComponent"
        :design-config="designConfig"
    ></component>
</template>

<script>
export default {
    name: "SiteDesignPreview",
    props: [ "designConfig" ],
    computed: {
        template() {
            return this.designConfig?.templates?.[this.designConfig?.selectedTemplateId]?.name || 'Template1';
        },
        templateComponent() {
            let registeredComponents  = Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this.$options).components))), // It's gross but that's where they are lol
                prefix                = 'SiteDesignPreview',
                formattedTemplateName = this.template.replace(/\s/g,""),
                check                 = Object.keys(registeredComponents).includes(prefix + formattedTemplateName);

            return prefix + ( check ? formattedTemplateName : 'Template1');
        }
    }
}
</script>