<template>
    <VueModal :visible="isModalOpen" @on-close="closeModal" id="mc-view-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-binoculars mr-1 text-tertiary"></span>{{ activeRecord.name }} Details</h5>
        </template>

        <div class="wrap-gap flex-column" style="--gap:1rem;" v-if="!isModalProcessing">
            <div v-if="activeRecord.images && activeRecord.images.length" class="wrap-gap" style="--gap:.25rem;">
                <div v-for="(image, index) in activeRecord.images" :key="index" class="image-wrapper">
                    <img :src="image.image_path" :alt="image.title">
                </div>
            </div>

            <p><strong>Manufacturer:</strong> {{ activeRecord.manufacturer }}</p>

            <div v-if="activeRecord.attributes && activeRecord.attributes.length">
                <div class="wrap-gap">
                    <div v-for="(attribute, index) in activeRecord.attributes" :key="index" class="flex-grow-1">
                        <div class="wrap-gap flex-column" style="--gap:0px">
                            <p v-for="(optionValue, optionKey) in attribute.variants" :key="`option_value_${optionKey}`"><strong>{{ optionKey }}:</strong> {{ optionValue }}</p>
                            <p v-if="attribute.manufacturer_sku"><strong>Manufacturer SKU:</strong> {{ attribute.manufacturer_sku }}</p>
                            <p v-if="attribute.msrp > 0"><strong>MSRP:</strong> ${{ attribute.msrp }}</p>
                            <p v-if="attribute.minimum_advertised_price > 0"><strong>Minimum Advertised Price:</strong> ${{ attribute.minimum_advertised_price }}</p>
                            <p><strong>Weight:</strong> {{ attribute.weight }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <p><strong>Description:</strong> {{ activeRecord.description }}</p>
        </div>

        <!-- Modal Loader -->
        <VueLoader v-else class="py-10" />

        <template #footer>
            <div class="d-flex justify-content-end wrap-gap">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeModal">Cancel</button>
                <VpanelSingleAddMasterCatalogProductButton :selected-record="activeRecord" @button-click="closeModal" />
            </div>
        </template>
    </VueModal>
</template>
<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: 'VpanelViewMasterCatalogProductDetailsModal',
        computed: {
            ...mapGetters('masterCatalog/add', {
                activeRecord: 'getActiveRecord',
            }),
            ...mapState('masterCatalog/view', {
                isModalOpen: state => state.isModalOpen,
                isModalProcessing: state => state.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('masterCatalog/view', [
                'closeModal',
            ])
        }
    }
</script>

<style lang="scss" scoped>
    .image-wrapper {
        max-width: 150px;

        img {
            width: 100%;
            height: auto;
        }
    }

</style>