<template>
    <div class="p-3 flex-grow-1">
        <div class="border-bottom">
            <p class="h5">
                <span class="fas fa-images mr-1 text-tertiary"></span>&nbsp;{{ getAddModifyHeading('Marketing Message') }}
            </p>
        </div>
        <div>
            <!-- Modify Details Section -->
            <VueModifyFormDetailsSection
                class="py-1"
                v-if="!isActionAdd"
                :status="getStatusAsString"
                :added-date="activeRecord.created_at"
                :last-modified-date="activeRecord.updated_at"
            />

            <form class="marketing-message-add-modify-form needs-validation" name="marketing_message_add_modify_form" id="marketing-message-add-modify-form" novalidate>
                <!-- Error Messages -->
                <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

                <!-- Marketing Message Form Tabs Nav -->
                <VpanelAddModifyMarketingMessageFormNav />

                <!-- Tabbed Content -->
                <div class="tab-content" id="mm-add-modify-tab-content">
                    <!-- Marketing Message Layout Form -->
                    <div :class="{'show active': activeNavTab == 'add-modify-layout-tab'}" class="tab-pane fade" id="add-modify-layout" role="tab-panel" aria-labelledby="add-modify-layout-tab">
                        <div class="row">
                            <div class="col-md">
                                <VpanelMarketingMessageLayoutForm
                                    :active-record="activeRecord"
                                    @update="updateMarketingMessageValue"
                                    :tagline-required="parseInt(moduleConfigs['Tagline Required'])"
                                />
                            </div>

                            <div class="col-md-4 mt-3 mt-md-0">
                                <VpanelMarketingMessageImageForm
                                    :active-record="activeRecord"
                                    :module-configs="moduleConfigs"
                                    @update="updateMarketingMessageValue"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Marketing Message Button Settings Form -->
                    <div :class="{'show active': activeNavTab == 'add-modify-button-settings-tab'}" class="tab-pane fade" id="add-modify-button-settings" role="tab-panel" aria-labelledby="add-modify-button-settings-tab">
                        <VpanelMarketingMessageButtonSettingsForm
                            :active-record="activeRecord"
                            @update="updateMarketingMessageValue"
                            :disclaimer-enabled="parseInt(moduleConfigs['Disclaimer'])"
                        />
                    </div>

                    <!-- Marketing Message Settings Form -->
                    <div :class="{'show active': activeNavTab == 'add-modify-settings-tab'}" class="tab-pane fade" id="add-modify-settings" role="tab-panel" aria-labelledby="add-modify-settings-tab">
                        <VpanelMarketingMessageSettingsForm
                            :active-record="activeRecord"
                            :module-configs="moduleConfigs"
                            @update="updateMarketingMessageValue"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-end my-2 wrap-gap">
                    <!--Save button only shows in add-->
                    <button
                        type="button"
                        v-if="isActionAdd"
                        class="btn btn-primary"
                        :disabled="isFormProcessing"
                        @click.prevent="submitMarketingMessageForm('store')"
                    >{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>

                    <!--Publish button only shows in modify-->
                    <button
                        v-else
                        type="button"
                        :disabled="isFormProcessing"
                        class="btn btn-secondary-blue"
                        @click.prevent="submitMarketingMessageForm('publish')"
                    >{{ isFormProcessing ? 'Processing...' : 'Publish' }}</button>

                    <!--Save as draft button only shows in modify and if dual control module config is enabled-->
                    <button
                        type="button"
                        class="btn btn-primary"
                        :disabled="isFormProcessing"
                        @click.prevent="submitMarketingMessageForm('draft')"
                        v-if="parseInt(moduleConfigs['Dual Control']) && !isActionAdd"
                    >{{ isFormProcessing ? 'Processing...' : 'Save Draft' }}</button>

                    <!--Preview  button only shows in modify-->
                    <button type="button" class="btn btn-outline-tertiary" @click.prevent="previewMarketingMessage" v-if="!isActionAdd">Preview </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        name: "VpanelAddModifyMarketingMessagePage",
        computed: {
            ...mapGetters('marketingMessage/addModify', [
                'getStatusAsString',
                'getAddModifyHeading'
            ]),
            ...mapState('marketingMessage', {
                formId: state => state.addModify.formId,
                moduleConfigs: state => state.moduleConfigs,
                formChanged: state => state.addModify.formChanged,
                activeRecord: state => state.addModify.activeRecord,
                errorMessage: state => state.addModify.errorMessage,
                activeNavTab: state => state.addModify.activeFormTab,
                isActionAdd: state => state.addModify.formActionIsAdd,
                isFormProcessing: state => state.addModify.isFormProcessing
            })
        },
        methods: {
            ...mapActions('marketingMessage/addModify', [
                'submitForm',
                'setFormIsValid',
                'checkIfPageIsValid',
                'setValueInActiveRecord',
                'previewMarketingMessage'
            ]),
            updateMarketingMessageValue(payload){
                let inputValue = 'value' in payload ? payload.value : payload.parseAsInt ? parseInt(event.target.value) : event.target.value;

                this.setValueInActiveRecord({ prop: payload.prop, value: inputValue });
            },
            submitMarketingMessageForm(formAction){
                let addDispatch     = this.isActionAdd ? 'marketingMessage/addModify/setActiveRecord' : null;
                let addModifyRoute  = `/vpanel/modules/marketing-message/${formAction}`
                let routerPath      = this.isActionAdd ? 'Modify Marketing Message' : 'Marketing Message List';

                this.checkIfPageIsValid();

                this.submitForm({
                    ajaxRoute: addModifyRoute,
                    routerPath: routerPath,
                    dispatchPath: addDispatch
                });
            }
        }
    }
</script>