<template>
    <div class="marketing-message-section p-3">
        <div class="mb-2">
            <VpanelMarketingMessageAddModifyRecordButton
                button-text="Add Marketing Message"
                router-path="Add Marketing Message"
                namespace="marketingMessage/addModify"
            />
        </div>

        <!-- Table Loader -->
        <VueLoader v-if="isTableLoading" />

        <!-- Marketing Message Table -->
        <VpanelMarketingMessageTable
            v-else
            respond-at="md"
            :data="tableData"
            :items-per-page="10"
            :searchable-data="['title', 'tagline']"
        />

        <!-- Delete Marketing Message Modal -->
        <VueConfirmationModal
            heading-text="Confirm Delete"
            namespace="marketingMessage/recycle"
            @confirmed-modal="deleteMarketingMessage"
            modal-id="marketing-message-recycle-modal"
        />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'VpanelMarketingMessageList',
        computed: {
            ...mapState('marketingMessage/list', {
                tableData: state => state.tableData,
                isTableLoading: state => state.isTableLoading
            })
        },
        methods: {
            ...mapActions('marketingMessage/list', [
                'deleteMarketingMessage'
            ])
        }
    }
</script>