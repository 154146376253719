<template>
    <div class="sct-value pr-2 sctv-file">
        <input
            type="text"
            disabled
            :id="property"
            :name="property"
            title="Edit File"
            @input="updateValue(property)"
            :value="designOptions[property]"
            class="form-control site-config-input"
        />

        <button
            :id="`${property}_choose`"
            :data-input="property"
            class="btn btn-tertiary"
            @click.prevent="openFileManager"
            title="Opens the file manager modal where you can choose to upload a new file or choose from your file library"
        >Choose File</button>

        <button
            :id="`${property}_remove`"
            :data-input="property"
            @click.prevent="deleteFile"
            title="Clears the current file"
            class="btn btn-outline-primary"
        >Delete</button>

        <a
            target="_blank"
            class="btn btn-primary"
            v-if="designOptions[property]"
            :href="designOptions[property]"
            :title="`Opens ${designOptions[property]} in a new window`"
        >Preview file</a>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { openFileManager } from '../../../../../../js/core/vpanel/vpanel-functions';

export default {
    props: ["property"],
    computed: {
        ...mapState('siteConfig/design', {
            designOptions: state => state.designOptions.theme,
        })
    },
    methods: {
        ...mapMutations('siteConfig/design', [
            'SET_VALUE_IN_DESIGN_OPTIONS'
        ]),
        openFileManager() {
            openFileManager(event.target, (fmObject, file_path) => {
                this.SET_VALUE_IN_DESIGN_OPTIONS([this.property, fmObject[0].url]);
            });
        },
        deleteFile() {
            this.designOptions.logo = '';
            document.querySelector(`#${event.target.dataset.input}`).value = '';
        },
        updateValue(prop) {
            this.SET_VALUE_IN_DESIGN_OPTIONS([prop, event.target.value]);
        }
    }
};
</script>

<style lang="scss" scoped>
    .sctv-file {
        display: flex;
        flex-wrap: wrap;
        margin: -.25rem;

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            margin: .25rem;
            width: auto;
        }
    }
</style>