<template>
    <div class="card">
        <div class="card-header d-flex flex-column justify-content-between align-items-start">
            <p class="h5 mb-0">
                Options
            </p>
        </div>
        <div class="card-body">
            <VpanelProductOptionValuesFormSection
                :attribute-index="0"
                @update="updateVariant"
                :product-options="productOptions"
                :product-attribute="productAttribute"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'VpanelProductVariantOptionForm',
        props: [
            'productAttribute'
        ],
        computed: {
  	        ...mapState('productVariant/addModify', {
                productOptions: state => state.productOptions,
            })
        },
        methods: {
            updateVariant(payload){
                this.$emit('update', payload);
            }
        }
    }
</script>