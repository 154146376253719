<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <p class="h5 m-0">
                <span class="fas fa-cogs mr-1"></span>
                Features
            </p>

            <!-- Add Additional Features Button -->
            <VpanelAddAdditionalInputButton
                @add-new-array-item="addFeature"
            />
        </div>
        <div class="card-body">
            <div
                :key="index"
                v-for="(feature, index) in productFeatures"
                class="d-flex justify-content-between align-items-center m-n1"
            >
                <input
                    type="text"
                    :value="feature"
                    class="form-control m-1"
                    name="product_feature"
                    :id="`product_feature_${index}`"
                    :aria-label="`Add a feature to this product ${index}`"
                    @input="updateProductValue({prop: 'features',  index: index})"
                />

                <!-- Remove Additional Features Button -->
                <VpanelRemoveAdditionalItemButton
                    @add-new-array-item="addFeature"
                    v-if="Object.keys(productFeatures).length > 1"
                    :array-count="Object.keys(productFeatures).length"
                    @remove-additional-item="removeAdditionalItem([index, 'features'])"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'VpanelProductFeatureForm',
        props: [
            'productFeatures'
        ],
        created(){
            if(this.productFeatures.length == 0){
                this.addFeature();
            }
        },
        methods:{
            ...mapActions('product/addModify', [
                'updateProductValue',
                'removeAdditionalItem',
            ]),
            addFeature(){
                let features = this.productFeatures;
                //will check if there are any empty product feature values
                let shouldAddNewValue = Object.values(features).every(feature => feature);

                if(shouldAddNewValue){
                    //will add new object to array
                    features.push('');
                } else {
                    //will show user a message if theres a feature that doesn't have a value
                    showNotification('Make sure all feature inputs are filled in, before adding a new one.', 'error');
                }
            },
        }
    }
</script>