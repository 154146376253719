<template>
    <TableWrapper
        :searchable="false"
        v-on="wrapperEvents"
        v-bind="wrapperBinder"
        :item-count="allTableInfo.itemCount"
        :active-page="allTableInfo.activePage"
        @update-page="allTableInfo.updateActivePage($event);setCurrentPageToSession($event);"
    >
        <!--Custom Table Search-->
        <VpanelEcommerceTableSearch namespace="customer/list" @searched="updateTableDataAfterSearch" :search-model="allTableInfo.searchModel" />

        <div class="st-hidden-sorts">
            <p @click="allTableInfo.sortBy('first_name')" :class="allTableInfo.sortClass('first_name')">Name</p>
            <p @click="allTableInfo.sortBy('username')" :class="allTableInfo.sortClass('username')">Username</p>
            <p @click="allTableInfo.sortBy('status')" :class="allTableInfo.sortClass('status')">Status</p>
            <p @click="allTableInfo.sortBy('last_attempt_at', null, 'date')" :class="allTableInfo.sortClass('last_attempt_at')">Last Login</p>
        </div>
        <div class="simple-table" style="--cols:5;">
            <p class="first-col st-header" @click="allTableInfo.sortBy('first_name')" :class="allTableInfo.sortClass('first_name')">Name</p>
            <p class="st-header" @click="allTableInfo.sortBy('username')" :class="allTableInfo.sortClass('username')">Username</p>
            <p class="st-header" @click="allTableInfo.sortBy('status')" :class="allTableInfo.sortClass('status')">Status</p>
            <p class="st-header" @click="allTableInfo.sortBy('last_attempt_at', null, 'date')" :class="allTableInfo.sortClass('last_attempt_at')">Last Login</p>
            <p class="last-col st-header">Options</p>

            <template v-if="displayedList.length">
                <template v-for="row in displayedList">
                    <p class="first-col" :key="row.id+'name'">
                        <span class="st-hidden">Name</span>
                        <strong>{{ row.name }}</strong>
                    </p>

                    <p :key="row.id+'username'">
                        <span class="st-hidden">Username</span>
                        <strong>{{ row.username }}</strong>
                    </p>

                    <div :key="row.id+'status'">
                        <span class="st-hidden">Status</span>
                        <p class="m-0" :class="row.status == 'Active' ? 'text-success' : 'text-danger'"><strong>{{ row.status }}</strong></p>
                    </div>

                    <p :key="row.id+'last_attempt_at'">
                        <span class="st-hidden">Last Login</span>
                        <strong>{{ row.last_attempt_at }}</strong>
                    </p>

                    <div class="last-col" :key="row.id+'options'">
                        <span class="st-hidden">Options</span>
                        <div class="st-buttons">
                            <button type="button" class="btn btn-primary">Modify</button>
                            <button type="button" class="btn btn-secondary-blue">View</button>
                            <button type="button" class="btn btn-outline-danger" @click="recycleCustomer(row.id)">Delete</button>
                        </div>
                    </div>
                </template>
            </template>

            <div v-else>
                <p class="mb-0"><strong>No Results Found.</strong></p>
            </div>
        </div>
    </TableWrapper>
</template>

<script>
    import { mapActions } from 'vuex';
    import tableMixin from '@v/Core/Global/Components/VueTable/mixins/tableMixin';

    export default {
        name: 'VpanelCustomerTable',
        mixins: [ tableMixin ],
        props: [
            'allTableInfo',
        ],
        methods: {
            ...mapActions('customer/list', [
                'recycleButtonAction',
                'setCurrentPageToSession',
                'updateTableDataAfterSearch'
            ]),
            recycleCustomer(customerId){
                this.recycleButtonAction({
                    recordId: customerId,
                    storeNamespace: 'customer'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(4, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>