<template>
    <form id="product-uom-add-form" class="needs-validation" name="product-uom-add-form" novalidate @submit.prevent="submitProductUomForm">
        <!-- Error Messages -->
        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>

        <div class="form-group has-label m-0">
            <div class="d-flex justify-content-between align-items-center">
                <label class="required" for="product_uom">Name</label>
                <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-boundary="window"
                    title="The name of the unit of measure"
                ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
            </div>
            <input
                required
                type="text"
                name="name"
                id="product_uom"
                class="form-control"
                v-model="activeRecord.name"
                title="Please enter an unit of measure"
                data-invalid-feedback="Please enter an unit of measure."
            />
            <div class="invalid-feedback">
                Please enter an unit of measure.
            </div>
        </div>
    </form>
</template>
<script>
    export default {
        name: 'VpanelAddProductUomForm',
        props: [
            'activeRecord',
            'errorMessage',
        ],
        methods: {
            submitProductUomForm(){
                let addCommit = 'productUom/list/ADD_RECORD_TO_UOM_LIST';
                let addRoute  = '/vpanel/modules/ecommerce/product/uom/store';

                this.$emit('submit-ecomm-form', {addModifyRoute: addRoute, addModifyCommit: addCommit });
            },
            closeModal(){
                this.$emit('close-ecomm-modal');
            }
        }
    }
</script>