<template>
    <div class="vue-table-filters">
        <p class="vtf-button" :class="activeFilter(filter)" v-for="(filter, index) in filters" :key="index" @click="toggleFilter(filter)">{{ filter.name }}</p>
    </div>
</template>

<script>
export default {
    props: ["tableObj", "activeFilters"],
    data() {
        return {
            filters: this.tableObj.filters
        }
    },
    methods: {
        toggleFilter(filter) {
            this.$emit('filter', filter)
        },
        activeFilter(filter) {
            return this.activeFilters.filter(item => item.col == filter.col && item.value == filter.value).length ? 'active' : false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .vue-table-filters {
        display: flex;
        flex-wrap: wrap;
        margin: .75rem -.25rem;
    }

    .vtf-button {
        font-size: 12px;
        line-height: 1;
        padding: .25rem .75rem;
        border: 1px solid #ddd;
        border-radius: 50rem;
        margin: .25rem;
        cursor: pointer;
        transition: 200ms ease-out;

        &:hover { background-color: #ddd; }

        &.active {
            background-color: #222;
            border: 1px solid #111;
            color: white;

            &:hover { background-color: #111; }
        }
    }
</style>