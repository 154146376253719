<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div :class="{'float-wrapper': floatLabel, 'float-label': labelFloating}">
            <label class="form-label" :for="inputId">{{ inputLabel }}<span v-if="required" class="required">*</span></label>
            <textarea
                class="form-control"
                :rows="rows"
                :minlength="minlength"
                :maxlength="maxlength"
                :value="value || undefined"
                v-bind="inputBinder"
                v-on="inputListener"
                @focus="focus"
                @blur="unfocus"
            ></textarea>
        </div>
    </div>
</template>

<script>
import { mixin } from './mixins/inputFunctions';
export default {
    mixins: [mixin],
    props: {
        rows: {
            type: [Number, String],
            default: null,
            validator: (value) => {
                if (parseInt(value)) {
                    return parseInt(value) > 0;
                }
                console.error(`Property 'rows': '${value}' is invalid. Please enter an integer above 0.`);
            }
        },
        minlength: {
            type: [Number, String],
            default: null,
            validator: (value) => {
                return parseInt(value);
                console.error(`Property 'minlength': '${value}' is invalid. Please enter a number.`);
            }
        },
        maxlength: {
            type: [Number, String],
            default: null,
            validator: (value) => {
                return parseInt(value);
                console.error(`Property 'maxlength': '${value}' is invalid. Please enter a number.`);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .float-wrapper { position: relative; }

    .form-label {
        margin: 0;
        font-size: 14px;
        font-weight: bold;

        .float-wrapper & {
            position: absolute;
            left: .5rem;
            top: .875rem;
            transform: translateY(0);
            background-color: transparent;
            padding: 0 .25rem;
            transition: 200ms ease-out;
            pointer-events: none;
            line-height: 1;
            border-radius: 2px;
            opacity: .5;
        }

        .float-wrapper.float-label & {
            font-size: 12px;
            top: 0;
            left: .25rem;
            opacity: 1;
            background-color: white;
            transform: translateY(-50%);
        }
    }
</style>